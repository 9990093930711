export const getRights = function(user, context, objectName) {

    let rights;
    
    if (user.user.rights) {
        rights = user.user.rights[objectName];
    }

    return rights ? rights : { actions: {} };
}

export const hasFeature = function(user, context, features) {

    let _hasFeature = false;
    
    if (user.user.features) {
        
        if (Array.isArray(features)) {
            let featuresFound = 0;
            features.forEach( feature => {
                if (user.user.features.includes(features)) {
                    featuresFound += 1;
                }
            })
            _hasFeature = featuresFound === features.length;
        } else {
            _hasFeature = user.user.features.includes(features);
        }

    }

    return _hasFeature;
}