import React, { Component } from 'react';
import { translate } from 'react-i18next';
import FlagIconFactory from 'react-flag-icon-css'

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import * as phoneHelper from '../../_helpers';

const FlagIcon = FlagIconFactory(React, { useCssModules: false })

const styles = theme => ({
    container: {
       display: "inline-flex"
    },
	indicatif: {
		marginLeft: "10px",
		fontSize: "14px"
	},
	countryName: {
		marginLeft: "5px",
	},
	countrySelect: {
	},
	countrySelectIcon: {
    top: "calc(50% - 5px)"
	}
});

class LanguageInput extends Component {
		
	state = {
		language: "fr",
		selectBoxOpen: false,
		availableLanguages: ["fr", "en"],
		countryCodeByLanguage: {
			"fr": 'fr',
			"en": 'gb'
		}
	};
	
	constructor(props) {
		super(props);

		if(this.props.value && this.state.availableLanguages.indexOf(this.props.value) > 0) {
		
			this.state.language = this.props.value;
		}
	}
		
    handleCountryChange = event => {
		
		if(this.state.language != event.target.value) {
			this.updateAfterChange(event.target.value);
		}
    };
	
	
	updateAfterChange(currObjValue) {
		this.setState({language: currObjValue});
		return this.props.onChange(currObjValue);
	}
	
	handleCountryBoxOpen = () => {
		this.setState({selectBoxOpen: true});
	}
	
	handleCountryBoxClose = () => {
		this.setState({selectBoxOpen: false});
	}
	
	render() {
		
		const { t, classes, key, id, label, value, fieldError, onChange, readonly } = this.props;
		
		let hasErrors = false;

		const countryPicker = <Select 
								value={this.state.language}
								onChange={this.handleCountryChange}
								onOpen={this.handleCountryBoxOpen}
								onClose={this.handleCountryBoxClose}
								open={this.state.selectBoxOpen} 
								className="form-edit-field"
								classes={{
									select: classes.countrySelect,
									icon: classes.countrySelectIcon
								} }>
								{
								  this.state.availableLanguages.map((languageCode) => {
									
									const languageName = t('language-'+languageCode);
									const isSelected=(this.state.language==languageCode);
									
									return <MenuItem key={languageCode} value={languageCode}>
										    <span className={"flag-icon flag-icon-"+this.state.countryCodeByLanguage[languageCode]}></span>
											{(!isSelected||this.state.selectBoxOpen) && <span className={classes.countryName}>{languageName}</span>}
											{languageName && <span className={classes.indicatif}>{languageName}</span>}
										   </MenuItem>
								  })
								}
							  </Select>
										
	return <div className="form-edit-field">
				<FormControl error={hasErrors} >
				<InputLabel htmlFor="component-simple">{label}</InputLabel>
				{countryPicker}
				</FormControl>
        </div>
	}
}

const connectedLanguageInput = (withStyles(styles, { withTheme: true })(translate('translations')(LanguageInput)));
export { connectedLanguageInput as LanguageInput };