import { commonConstants } from '../_constants';
import { commonService } from '../_services';
import { history } from '../_helpers';

export const commonActions = {
    cloudInfo,
	getDataView,
    getAll,
	doSearchText,
	sendMediaFile,
	getMediaFileContent,
    read,
    edit,
    create,
    loadParentAndCreate,
    loadParentAndEdit,
    loadContext,
    updateCurrentEdition,
    save,
    deleteItem,
    archiveItem,
    goCreate,
    goEdit,
    buildReadUrl,
    goRead,
    liveAll,
    liveOne,
    liveStop,
    liveFull,
    setAppContext,
    readAndSetContext,
    sendEvent,
    exportAll,
    saveWorkflowAction,
    loadKpi
};

function getMediaFileContent(mediaId, filename) {
	commonService.getMediaFileContent(mediaId, filename);
}

function sendMediaFile(scope, mediaFileForm, callback) {
	
	return dispatch => {
			dispatch(request());

			commonService.sendMediaFile(mediaFileForm)
				.then( item => {
						dispatch(success(item));
						if (callback) {
							callback(item);
						}
					},
					error => dispatch(failure(error))
				);
		};

		function request() { return { type: commonConstants.SEND_MEDIA_FILE_REQUEST, scope } }
		function success(item) { return { type: commonConstants.SEND_MEDIA_FILE_SUCCESS, scope, item } }
		function failure(error) { return { type: commonConstants.SEND_MEDIA_FILE_FAILURE, scope, error } }	
}

function cloudInfo() {
    return dispatch => {
        dispatch(request());

        commonService.cloudInfo()
            .then( infos => {
                    dispatch(success(infos));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.CLOUDINFO_REQUEST } }
    function success(infos) { return { type: commonConstants.CLOUDINFO_SUCCESS, infos } }
    function failure(error) { return { type: commonConstants.CLOUDINFO_FAILURE, error } }
}

function getDataView(scope, model, searchInfos, callback) {
	
	return dispatch => {
			dispatch(request());

			commonService.getDataView(model, searchInfos)
				.then( items => {
						dispatch(success(items));
						if (callback) {
							callback(items);
						}
					},
					error => dispatch(failure(error))
				);
		};

		function request() { return { type: commonConstants.GET_VIEWDATA_REQUEST, scope } }
		function success(items) { return { type: commonConstants.GET_VIEWDATA_SUCCESS, scope, items } }
		function failure(error) { return { type: commonConstants.GET_VIEWDATA_FAILURE, scope, error } }
}

function getAll(scope, model, key, filters, callback, endpointSuffix) {

    return dispatch => {
        dispatch(request());

        commonService.getAll(model, filters, endpointSuffix)
            .then( items => {
                    dispatch(success(items));
                    if (callback) {
                        callback(items);
                    }
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.GETALL_REQUEST, scope, model } }
    function success(items) { return { type: commonConstants.GETALL_SUCCESS, scope, items, model, key: key } }
    function failure(error) { return { type: commonConstants.GETALL_FAILURE, scope, error, model, key: key } }
}

function loadKpi(scope, kpi, model, object, periode, parts) {
	
	return dispatch => {
        dispatch(request());
		
        commonService.loadKpi(kpi, model, object, periode, parts)
            .then( kpiResult => {
                    dispatch(success(kpiResult));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.LOAD_KPI_REQUEST, scope, kpi } }
    function success(kpiResult) { return { type: commonConstants.LOAD_KPI_SUCCESS, scope, kpi, kpiResult, periode } }
    function failure(error) { return { type: commonConstants.LOAD_KPI_FAILURE, scope, kpi, error } }	
}

function doSearchText(scope, fieldname, text, mainObjectName, mainObjectId) {
	
	return dispatch => {
        dispatch(request());
		
        commonService.doSearchText(fieldname, text, mainObjectName, mainObjectId)
            .then( items => {
                    dispatch(success(items));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.DOSEARCHTXT_REQUEST, scope, fieldname, text } }
    function success(items) { return { type: commonConstants.DOSEARCHTXT_SUCCESS, scope, items } }
    function failure(error) { return { type: commonConstants.DOSEARCHTXT_FAILURE, scope, error } }	
}

function exportAll(scope, model, key, filters) {
    return dispatch => {
        //dispatch(request());

        commonService.exportAll(model, filters)
            .then(
                items => console.log(items),
                error => console.log(error)
            );
    };

    /*function request() { return { type: commonConstants.GETALL_REQUEST, scope, model } }
    function success(items) { return { type: commonConstants.GETALL_SUCCESS, scope, items, model, key: key } }
    function failure(error) { return { type: commonConstants.GETALL_FAILURE, scope, error, model } }*/
}

function liveAll(scope, model, liveFields, key, filters) {
    return dispatch => {
        dispatch(request());

        commonService.liveAll(model, filters)
            .then(
                items => {
                    const itemsById = {}
                    items.forEach(item => {
                        itemsById[item.id] = item;
                    });
                    dispatch(success(itemsById))
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.LIVEALL_REQUEST, scope, model } }
    function success(itemsById) { return { type: commonConstants.LIVEALL_SUCCESS, scope, itemsById, fields: liveFields } }
    function failure(error) { return { type: commonConstants.LIVEALL_FAILURE, scope, error, model } }
}

function liveStop() {
    return dispatch => {
        dispatch(request());
    }

    function request() { return { type: commonConstants.LIVE_STOP } }
}

function liveFull() {
    return dispatch => {
        dispatch(request());

        commonService.liveFull()
            .then(
                result => {
                    dispatch(success(result))
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.LIVE_FULL_REQUEST } }
    function success(result) { return { type: commonConstants.LIVE_FULL_SUCCESS, result } }
    function failure(error) { return { type: commonConstants.LIVE_FULL_FAILURE, error } }
}

function readAndSetContext(scope, id, model, user, context) {

    return dispatch => {
        dispatch(request());

        commonService.getOne(id, model)
            .then(
                item => {

                    let customer;
                    let fleet;
                    if (model.name === 'customer') {
                        customer = item;
                    } else if (model.name === 'fleet') {
                        fleet = item;
                        customer = item.customer;
                    } else {
                        fleet = item.fleet;
                        customer = item.customer;
                    }
                    dispatch(setAppContext(customer, fleet, user, context));

                    dispatch(success(item))
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.READ_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.READ_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.READ_FAILURE, scope, error, model } }

}

function loadContext(scope, id, model) {
    return dispatch => {
        dispatch(request());

        commonService.getOne(id, model)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.LOADCONTEXT_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.LOADCONTEXT_SUCCESS, scope, parent: item, model } }
    function failure(error) { return { type: commonConstants.LOADCONTEXT_FAILURE, scope, error, model } }
}

function read(scope, id, model) {
    return dispatch => {
        dispatch(request());

        commonService.getOne(id, model)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.READ_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.READ_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.READ_FAILURE, scope, error, model } }
}

function liveOne(scope, id, model, liveFields) {
    return dispatch => {
        dispatch(request());

        commonService.liveOne(id, model)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.LIVEONE_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.LIVEONE_SUCCESS, scope, item, model, fields: liveFields} }
    function failure(error) { return { type: commonConstants.LIVEONE_FAILURE, scope, error, model } }
}

function edit(scope, id, model) {
    return dispatch => {
        dispatch(request());

        commonService.getOne(id, model)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.EDIT_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.EDIT_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.EDIT_FAILURE, scope, error, model } }
}

function create(scope, model, parentField, parent, user, context, withDefault) {
    return dispatch => {
        dispatch(request());

        commonService.create(model, parentField, parent, user, context, withDefault)
            .then(
                item => {
                    dispatch(success(item));
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.CREATE_REQUEST, scope, model, parent } }
    function success(item) { return { type: commonConstants.CREATE_SUCCESS, scope, item, model, parent } }
    function failure(error) { return { type: commonConstants.CREATE_FAILURE, scope, error, model, parent } }
}

function saveWorkflowAction(action, targetModel, targerId, scope, input, model, user, context, callback) {

    return dispatch => {
        dispatch(request());

        const save = () => {
            commonService.saveWorkflowAction(action, targetModel, targerId, input, model, user, context).then(
                result => {
                    dispatch(success(result));
                    if (callback) (
                        callback(result)
                    )
                },
                error => dispatch(failure(null, error))
            );
        }

        if (action.fields) {
            commonService.validateItem(true, input, model, user, context, action.fields).then( validationErrors => {
                if (validationErrors) {
                    dispatch(failure(validationErrors))
                } else {
                    save();
                }
            })
        } else  {
            save();
        }
    };

    function request() { return { type: commonConstants.WORKFLOWACTION_REQUEST, scope, model, input } }
    function success(result) { return { type: commonConstants.WORKFLOWACTION_SUCCESS, scope, result, model, input } }
    function failure(validationErrors, remoteError) { return { type: commonConstants.WORKFLOWACTION_FAILURE, scope, validationErrors, remoteError, model, input } }

}

function loadParentAndCreate(scope, model, parentField, idParent, modelParent, user, context, appcontext, withDefault, customData) {
    
	return dispatch => {
        dispatch(request());
        if (appcontext && appcontext[parentField] && appcontext[parentField].id === idParent) {
            commonService.create(model, parentField, appcontext[parentField], user, context, withDefault, customData)
            .then(
                item => {
                    dispatch(success(item, appcontext[parentField]));
                },
                error => dispatch(failure(error))
            );
        } else {
			
            commonService.getOne(idParent, modelParent).then(
                parent => {
                    if (parentField === 'customer') {
                        setAppContext(parent, null, user, context);
                    } else if (parentField === 'fleet') {
                        setAppContext(null, parent, user, context);
                    }
                    commonService.createWithInfos(model, parentField, parent, modelParent, user, context, withDefault, customData)
                    .then(
                        item => {
                            dispatch(success(item, parent));
                        },
                        error => dispatch(failure(error))
                    );
                },
                error => dispatch(failure(error))
            );
        }
        
    };

    function request() { return { type: commonConstants.CREATE_REQUEST, scope, model } }
    function success(item, parent) { return { type: commonConstants.CREATE_SUCCESS, scope, item, model, parent } }
    function failure(error) { return { type: commonConstants.CREATE_FAILURE, scope, error, model } }
}

function loadParentAndEdit(scope, id, model, parentField, idParent, modelParent, user, context, appcontext) {
    return dispatch => {
        dispatch(request());
        if (appcontext && appcontext[parentField] && appcontext[parentField].id === idParent) {
            commonService.getOne(id, model)
            .then(
                item => dispatch(success(item)),
                error => dispatch(failure(error))
            );
        } else {
            commonService.getOne(idParent, modelParent).then(
                parent => {
                    if (parentField === 'customer') {
                        dispatch(setAppContext(parent, null, user, context));
                    } else if (parentField === 'fleet') {
                        dispatch(setAppContext(parent.customer, parent, user, context));
                    }
                    commonService.getOne(id, model)
                    .then(
                        item => dispatch(success(item)),
                        error => dispatch(failure(error))
                    );
                },
                error => dispatch(failure(error))
            );
        }
        
    };

    function request() { return { type: commonConstants.EDIT_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.EDIT_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.EDIT_FAILURE, scope, error, model } }
}

function save(scope, creation, item, model, redirectUrl, user, context) {
    
    return dispatch => {
        dispatch(request());

        commonService.save(creation, item, model, user, context)
            .then(
                item => {
                    dispatch(success(item));
                    if (redirectUrl) history.push(redirectUrl);
                },
                error => dispatch(failure(item, error))
            );
    };

    function request() { return { type: commonConstants.SAVE_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.SAVE_SUCCESS, scope, item, model } }
    function failure(item, error) { return { type: commonConstants.SAVE_FAILURE, scope, item, model, error } }
}

function deleteItem(scope, item, model) {
    return dispatch => {
        dispatch(request());

        commonService.deleteItem(item, model)
            .then(
                deleted => dispatch(success(item)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.DELETE_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.DELETE_SUCCESS, scope, item, model } }
    function failure(error) { return { type: commonConstants.DELETE_FAILURE, scope, error, model } }
}

function archiveItem(scope, item, model, fromList, redirectUrl) {
    return dispatch => {
        dispatch(request());

        commonService.archiveItem(item, model)
            .then(
                archived => {
                    dispatch(success(item));
                    if (redirectUrl) history.push(redirectUrl);
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: commonConstants.ARCHIVE_REQUEST, scope, model } }
    function success(item) { return { type: commonConstants.ARCHIVE_SUCCESS, scope, item, model, fromList } }
    function failure(error) { return { type: commonConstants.ARCHIVE_FAILURE, scope, error, model } }
}

function updateCurrentEdition(scope, updatedValues, path, model, callback) {
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: commonConstants.UPDATE_ITEM_EDITION_REQUEST, scope, updatedValues, path, model, callback } }
}

function goCreate(model, parent, parentModel) {

    const urlObjectName = model.uriobject ? model.uriobject : model.name;

    let url;
    if (parent && parentModel) {
        url = '/' + parentModel.name + '/' + parent.id + '/edit/' + urlObjectName;
    } else {
        url = '/edit/' + urlObjectName;
    }

    return dispatch => {
        dispatch(request());
        history.push(url);
    };

    function request() { return { type: commonConstants.GO_CREATE_REQUEST, model, parent } }
}

function goEdit(model, object, parent, parentModel) {
    return () => {

        let url;
        if (parent && parentModel) {
            url = '/' + parentModel.name + '/' + parent.id + '/edit/' + model.name + '/' + object.id;
        } else {
            url = '/edit/' + model.name + '/' + object.id;
        }

        history.push(url);
    };
}

function goRead(model, object, parent, parentModel) {
    return () => {
        history.push(buildReadUrl(model, object, parent, parentModel));
    };
}

function buildReadUrl(model, object, parent, parentModel) {
    let url;
    if (parent && parentModel) {
        url = '/' + parentModel.name + '/' + parent.id + '/read/' + (model.uriobject ? model.uriobject : model.name) + '/' + object.id;
    } else {
        url = '/read/' + (model.uriobject ? model.uriobject : model.name) + '/' + object.id;
    }
    return url;
}

function setAppContext(customer, fleet, user, context) {
    return dispatch => {
        const appcontext = {};
        if (user.isServiceProvider) {
            if (customer || fleet) {
                appcontext.customer = customer;
                if (fleet) {
                    appcontext.fleet = fleet;
                } else if (appcontext.customer && appcontext.customer.fleets && appcontext.customer.fleets.length === 1) {
                    appcontext.fleet = appcontext.customer.fleets[0];
                }
                if (appcontext.fleet && !appcontext.customer) {
                   
                }
            }/*
            if (appcontext.customer && appcontext.customer.fleets) {
                if (appcontext.customer.fleets.length === 1) {
                    appcontext.fleet = appcontext.customer.fleets[0];
                } else {
                    appcontext.fleet = fleet ? appcontext.customer.fleets.find(_fleet => _fleet.id === fleet.id) : null; 
                }
            } else if (fleet) {
                appcontext.fleet = fleet;
            }*/
        } else if (user.isFleetManager) {
            appcontext.customer = user.user.customer;
            appcontext.fleet = context.fleets ? context.fleets[0] : null;
        }
        dispatch(request(appcontext));
    };

    function request(appcontext) { return { type: commonConstants.APP_CONTEXT_SET, appcontext } }
}

function sendEvent(event) {
    
    return dispatch => {
        

        commonService.sendEvent(event)
            .then(
                result => { 
                        dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    
    function success(result) { return { type: 'COMMON_EVENT_SUCCESS', result } }
    function failure(error) { return { type: 'COMMON_EVENT_FAILURE', error } }

    
}