import { kpiConstants } from '../_constants';
import { kpiService } from '../_services';
import { history } from '../_helpers';

export const kpiActions = {
    stationRefills,
    dispenserRefills,
    resumeRefills
}

function resumeRefills(periodeType, start, end) {
    return dispatch => {
        dispatch(request());

        kpiService.resumeRefills(periodeType, start, end)
            .then(
                items => dispatch(success(items)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kpiConstants.STATION_REFILLS_REQUEST } }
    function success(kpis) { return { type: kpiConstants.STATION_REFILLS_SUCCESS, kpis } }
    function failure(error) { return { type: kpiConstants.STATION_REFILLS_FAILURE, error } }
}

function stationRefills(stationId, periodeType, start, end) {
    return dispatch => {
        dispatch(request());

        kpiService.stationRefills(stationId, periodeType, start, end)
            .then(
                items => dispatch(success(items)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kpiConstants.STATION_REFILLS_REQUEST } }
    function success(kpis) { return { type: kpiConstants.STATION_REFILLS_SUCCESS, kpis } }
    function failure(error) { return { type: kpiConstants.STATION_REFILLS_FAILURE, error } }
}

function dispenserRefills(dispenserId, periodeType, start, end) {
    return dispatch => {
        dispatch(request());

        kpiService.dispenserRefills(dispenserId, periodeType, start, end)
            .then(
                items => dispatch(success(items)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kpiConstants.STATION_REFILLS_REQUEST } }
    function success(kpis) { return { type: kpiConstants.STATION_REFILLS_SUCCESS, kpis } }
    function failure(error) { return { type: kpiConstants.STATION_REFILLS_FAILURE, error } }
}