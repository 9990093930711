import React, { Component } from 'react';

import { translate } from 'react-i18next';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import Grid from '@material-ui/core/Grid';

import FolderSharedIcon from '@material-ui/icons/FolderShared';
import PersonIcon from '@material-ui/icons/Person';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

import FieldDisplay from './FieldDisplay';
import { QuantityGrammeReadView } from './QuantityGramme';
import { DurationReadView } from './Duration';
import { TemperatureView } from './Temperature';
import { SocView } from './Soc';
import {BadgeIcon} from '../icons/badge';

import moment from 'moment'

class RunningRefillView extends Component {
    
    steps = ['waitinqueue', 'firstinqueue', 'waitToStart', 'preparing', 'inProgress', 'ending', 'ended'];

    activeStep = 0;

    render() {
        const { t, user } = this.props;

        const dispenser = this.props.object;

        const refillState = dispenser.refiller.state;
        const attempt = dispenser.refiller.attempt;
        const activeStep  = this.steps.indexOf(refillState);

        const stepper = <Stepper nonLinear activeStep={activeStep}>
        {this.steps.map((label, index) => (
          <Step key={label}>
            <StepButton completed={index < activeStep}>
              {t('refill.state.'+label, label)}
            </StepButton>
          </Step>
        ))}
      </Stepper>

            let expirationTxt;
            if (attempt.timeout) {
                expirationTxt = <div className={'refill-expiration-bloc-right txtred'}>Temps restant avant expiration :  <DurationReadView value={{start: new Date(), end: new Date(attempt.timeout)}} /></div>;
            } else if (dispenser.waitUntil) {
                expirationTxt = <div className={'refill-expiration-bloc-right'}>Passage à la recharge suivante dans <DurationReadView value={{start: new Date(), end: new Date(dispenser.waitUntil)}} /></div>;
            }

            const expiration = <div className={'refill-expiration-bloc refill-expiration-bloc-on'}>
                <div className={'refill-expiration-bloc-left'}>
                    <div className="legende">{moment(attempt.createdAt).format('HH:mm:ss')} <div className="duration"><DurationReadView value={{start: attempt.createdAt, end: new Date()}} /></div></div>
                    <div>{t('attempt')} n° {attempt.index}</div>
                    </div>
                {expirationTxt}</div>;

            const authorization = dispenser.refiller && dispenser.refiller.authorization;

            const creatorInfos = authorization && authorization.deviceType === 'web' && authorization.creator ? <FieldDisplay label={'Autorisation délivrée par'} value={authorization.creator.firstName+' '+authorization.creator.lastName}/> : null;

            const deviceInfos = dispenser.refiller && dispenser.refiller.deviceInfos;
            let pos = t('pos.'+dispenser.refiller.pos, dispenser.refiller.pos);

            if (deviceInfos && deviceInfos.appVersion) {
                pos += ' ('+deviceInfos.appVersion+')';
            }
        const resumeInfos = <div>
            <FieldDisplay label="POS" value={pos} />
            {dispenser.refiller && dispenser.refiller.attempt ?<div><FieldDisplay label={t('quantity')} value={<QuantityGrammeReadView t={t} html={true} value={dispenser.refiller.attempt.quantity} />} />
            <FieldDisplay label={t('progress')} value={dispenser.refiller.attempt.progress+' %'} /></div> : null}
            {creatorInfos}
        </div>

        const authorizationInfos = authorization ? this._renderDriverInfos(authorization, user.isServiceProvider) : null;

        const vehicleCommunication = dispenser.vehicle && dispenser.refiller && dispenser.refiller.attempt && dispenser.refiller.attempt.vehicleCommunication ? true : false;

        const vehiculeInfos = dispenser.refiller && activeStep > 2 ? <div className="refill-vehicle-bloc">
            <div className={'refill-vehicle-bloc-illustration'+ (vehicleCommunication ? ' refill-vehicle-bloc-illustration-on':'')}><img height="77px" src={require('../../assets/img/car-illustration.png')}/></div>
            {vehicleCommunication ? <div>
                <FieldDisplay label={t('temperature')} value={<TemperatureView value={dispenser.vehicle.temperature}/>} />
                <FieldDisplay label={t('pressure')} value={dispenser.vehicle.pressure} />
                <FieldDisplay value={<SocView value={dispenser.vehicle.soc}/>} />
            </div> : <div className="legende">pas de communication</div>}
        </div> : null;

       return <div>
            {stepper}
            {expiration}
            <Grid container spacing={16} className="running-refill-bloc">
                <Grid item md={4}>{resumeInfos}</Grid>
                <Grid item md={4}>{authorizationInfos}</Grid>
                <Grid item md={4}>{vehiculeInfos}</Grid>
            </Grid>
            <div>
                {dispenser.refiller.previousAttempts.sort( (lrs, rhs) => { return rhs.index - lrs.index; }).map( attempt => {
                    return <div className={'refill-expiration-bloc refill-expiration-bloc-on'}>
                            <div className={'refill-expiration-bloc-left'}>
                                <div className="legende">{moment(attempt.createdAt).format('HH:mm:ss')}</div>
                                <div>{t('attempt')} n° {attempt.index} {t('refill.state.'+attempt.state, attempt.state)}</div>
                            </div>
                    </div>
                })}
            </div>
        </div>
    }

    _renderDriverInfos = (authorization, showCustomer) => {

        const driver = authorization.associatedDriver;

        const customerInfos = authorization.customer && showCustomer ? <Grid container spacing={8} direction="row" alignItems="center">
        <Grid item><FolderSharedIcon/></Grid>
        <Grid item>{authorization.customer.name}</Grid>
        </Grid> : null;

        const driverInfos = driver ? <Grid container spacing={8} direction="row" alignItems="center">
        <Grid item><PersonIcon/></Grid>
        <Grid item>{driver.firstName +' '+driver.lastName}</Grid>
        </Grid> : null;


        let badgeInfos = null;
        const badge = authorization.badge;

        if (badge) {

            badgeInfos = <Grid container spacing={8} direction="row" alignItems="center">
            <Grid item><BadgeIcon/></Grid>
            <Grid item>N° {badge.badgeId}</Grid>
            </Grid>
        }
        
        const peering = authorization.peering;
        const peeringInfos = peering ? <Grid container spacing={8} direction="row" alignItems="center">
            <Grid item><SmartphoneIcon/></Grid>
            <Grid item>N° {peering.device.phone.number}</Grid>
        </Grid> : null;
        
        let phoneInfos = null;
        if (!peering && driver && driver.devices && driver.devices.length > 0) {
            const phones = {};
            driver.devices.forEach( device => {
                phones[device.phone.number] = true;
            })
            phoneInfos = <Grid container spacing={8} direction="row" alignItems="center">
                <Grid item><SmartphoneIcon/></Grid>
                <Grid item>N° {Object.keys(phones).join(', ')}</Grid>
            </Grid>
        }

        

        return <div>{customerInfos}{driverInfos}{badgeInfos}{peeringInfos}{phoneInfos}</div>

    }
}

const connectedRunningRefillViewr = translate('translations')(RunningRefillView);
export { connectedRunningRefillViewr as RunningRefillView };