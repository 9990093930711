import React, { Component } from 'react';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { EditObject } from '../../_pages/common/EditObject';
import { ObjectsMap } from '../../_config/Objects';

import { commonActions } from '../../_actions';

const styles = theme => ({
    errorMessage: {
        color: 'red'
    }
});

const WORKFLOW_ACTION_SCOPE = 'workflowaction';

class WorkflowActionDialog extends Component {

    constructor(props) {
        super(props)
        this.scope = WORKFLOW_ACTION_SCOPE;
    }

    componentWillMount = () => {
        this.model = ObjectsMap['workflowAction_'+this.props.action.name];
        this.props.dispatch(commonActions.create(this.scope, this.model, undefined, undefined, this.props.user, this.props.context, this.props.newItem));
    }

    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getItem() {
        const data = this.getData();
        return data.item;
    }

    validationError() {
        const data = this.getData();
        return data.validationErrors;
    }

    handleActionInputObjectChange = (object) => {
        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, object, null, this.model));
    }

    handleValidate = () => {
        this.props.dispatch(commonActions.saveWorkflowAction(this.props.action, this.props.targetModel, this.props.targetId, this.scope, this.getItem(), this.model, this.props.user, this.props.context, (result) => {
            if (result.success) {
                this.props.handleClose(this.props.action, true);
            } 
        }));
    }

    handleCancel = () => {
        this.props.handleClose(this.props.action, false);
    }

    render() {

        const { t, title, action, scopedData, saving, classes } = this.props;
        
        const inputObject = this.getItem();

        const validationErrors = scopedData && scopedData.validationErrors ? scopedData.validationErrors.error : null;
        const remoteError = scopedData ? scopedData.remoteError ? scopedData.remoteError.object ? scopedData.remoteError.object[0] : scopedData.remoteError : null : null;

        return <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={action ? true : false}
          aria-labelledby="max-width-dialog-title"
        >
          {action.title && <DialogTitle id="max-width-dialog-title">{t(action.title)}</DialogTitle>}
          <DialogContent>
          {action.intro && <DialogContentText>{t(action.intro)}</DialogContentText>}
          
          {remoteError && <div className={classes.errorMessage}>{t(remoteError.message ? remoteError.message : remoteError)}</div>}

            {(action.fields ? true : false) && <EditObject
                    dispatch={this.dispatch}
                    scope={this.scope + "_object"}
                    fields={action.fields}
                    object={inputObject ? inputObject : {}}
                    model={this.model}
                    error={validationErrors}
                    handleChange={ object => {
                        this.handleActionInputObjectChange(object);
                    }}
                />}
          </DialogContent>
          <DialogActions>
            <Button disabled={saving} onClick={() => {this.handleCancel()}} variant="contained">
              {t('action.cancel')}
            </Button>
            <Button disabled={saving} onClick={() => {this.handleValidate()}} color="primary" variant="contained">
              {t('action.validate')}
            </Button>
          </DialogActions>
        </Dialog>

    }
    
}

function mapStateToProps(state) {
    const { workflow, data } = state;

    let scopedData;
    let saving;
    if (data) {
        scopedData = data[WORKFLOW_ACTION_SCOPE];
        if (scopedData) {
            saving = scopedData.saving;
        }
    }

    return {
        workflow,
        data,
        scopedData,
        saving
    };
}

const connectedWorkflowActionDialog = withStyles(styles, { withTheme: true })(translate('translations')(connect(mapStateToProps)(WorkflowActionDialog)));
export { connectedWorkflowActionDialog as WorkflowActionDialog };