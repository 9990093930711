import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import moment from 'moment'


const styles = theme => ({
    margin: {
      margin: '1rem',
    }
});

class ObjectInfoLine extends React.Component {

    render () {

        const { t, classes, object } = this.props;
        
        const createdAt = moment(object._metadata.timestamps.createdAt).format('LLL');
        const updatedAt = moment(object._metadata.timestamps.updatedAt).format('LLL');

        return (
            <div className="object-info-line">
                {t('created.on')} {createdAt} - {t('last.update.on')} {updatedAt}
            </div>
        );

    }

}

ObjectInfoLine.propTypes = {
    classes: PropTypes.object.isRequired,
    object: PropTypes.object.isRequired,
};
  
export default translate('translations')(withStyles(styles)(ObjectInfoLine));