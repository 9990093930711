import React, { Component } from 'react';

class AdresseView extends Component {

    render() {

     const { t, object } = this.props;
       return (
            <div>{object.street}, {object.zipcode} {object.city}, {object.country}</div>
        )
    }

    
}
export { AdresseView as AdresseView };