import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import BarChartView from '../common/BarChart';
import BarChartLoadingView from '../common/BarChartLoading';

class SocsSatisfactionBreaksView extends React.Component {

    render() {

        const { t, mode, loading, data, kpiField, socField, breakConfig } = this.props;
        
        if (loading) {
            return <BarChartLoadingView/>
        }

        if (data && data[kpiField] && data[kpiField].length > 0) {
            return <div style={{marginTop: 0, marginLeft: '2rem', marginRight: '2rem'}}>{this.renderKpiBloc( t, data[kpiField], socField, breakConfig )}</div>
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData, socField, breakConfig ) => {

            const datasetLevel0 = {
                label: '< 90',
                backgroundColor: '#ff000061',
                borderColor: '#ff0000ab',
                stack: '0'
            }

            const datasetLevel1 = {
                label: '90 < 95',
                backgroundColor: '#ffe6aa',
                borderColor: '#fecf5f',
                stack: '0'
            }

            const datasetLevel2 = {
                label: '>= 95',
                backgroundColor: '#7dff81',
                borderColor:  '#00f107',
                stack: '0'
            } 

            const repartition = {'0': {...breakConfig.defaultValues}, '1': {...breakConfig.defaultValues}, '2': {...breakConfig.defaultValues}, 'total': {...breakConfig.defaultValues}}

            rawData.forEach( endSocKpi => {
            
                const socValue = endSocKpi._id[socField]

                if (socValue !== 'unknown') {
                     
                    let repartitionKey;
                    if (socValue >= 95){
                        repartitionKey = '2'
                    } else if (socValue >= 90){
                        repartitionKey = '1'
                    } else {
                        repartitionKey = '0'
        
                    }
                    
                    breakConfig.matcher(endSocKpi, null, null, (key) => {
                        repartition[repartitionKey][key] += endSocKpi.count
                        repartition['total'][key] += endSocKpi.count
                    })

                }
                
            })

            const totaldata = repartition['total']
            const level0data = repartition['0']
            const level1data = repartition['1']
            const level2data = repartition['2']

            datasetLevel0.data = Object.keys(level0data).map( periodeKey =>  Math.round( level0data[periodeKey] / totaldata[periodeKey] * 100) )
            datasetLevel1.data = Object.keys(level1data).map( periodeKey =>  Math.round( level1data[periodeKey] / totaldata[periodeKey] * 100) )
            datasetLevel2.data = Object.keys(level2data).map( periodeKey =>  Math.round( level2data[periodeKey] / totaldata[periodeKey] * 100) )

            const data = {
                labels: breakConfig.labels,
                datasets: [datasetLevel0, datasetLevel1, datasetLevel2]
            };

            return <BarChartView
            data={data}
            options={ChartOptions.BAR_NO_LEGEND}
            />

    }

}

const translatedBloc = translate('translations')(SocsSatisfactionBreaksView);
export { translatedBloc as SocsSatisfactionBreaksView };