import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

class GPSView extends Component {

    render() {

     const { t, object } = this.props;
       return (
        <div className="form-read-field">
        <span className="form-read-field-label">{t('coordoonnees')} : </span><a target="_blank" href={'https://www.google.com/maps/search/?api=1&query=' + object.latitude +',' + object.longitude}>({object.latitude}, {object.longitude})</a>
        </div>
        )
    }

    
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    return {
        itemId,
        data,
    };
}

const connectedGPSView = connect(mapStateToProps)(translate('translations')(GPSView));
export { connectedGPSView as GPSView };