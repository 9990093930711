import { userConstants } from '../_constants';
import { commonConstants } from '../_constants';
import { userService } from '../_services';
import { history } from '../_helpers';

export const userActions = {
    login,
    logout,
    checkAccessToken,
    validateAccessAndSetPassword,
    validateAccessTOTPAndSetPassword,
    requestU2F,
    signU2F,
    requestTOTPSecret,
    requestTOTP,
    verifyTOTP,
    requestU2FForUser,
    requestTOTPForUser,
    gotoLogin,
    loadUserContextData,
    sendValidationEmail,
    passwordForgotten,
    checkResetPasswordToken,
    resetPassword
};

function login(username, password, rememberme) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password, rememberme)
            .then(
                loginResult => { 
                    if (loginResult.loggedIn) {
                        dispatch(success(loginResult));
                        dispatch(loadUserContextData(loginResult));
                        history.push('/');
                    } else if ((loginResult.twoauth.length === 1 || loginResult.twoauthprefered === 'U2F') && loginResult.twoauth[0] === 'U2F' && loginResult.challenges) {
                        dispatch(signU2F(loginResult));
                    } else if (loginResult.twoauth.length === 1 && loginResult.twoauth[0] === 'TOTP') {
                        dispatch(requestTOTP(loginResult));
                    } else {
                        dispatch(successTwoAuth(loginResult));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(loginResult) { return { type: userConstants.LOGIN_SUCCESS, loginResult } }
    function successTwoAuth(loginResult) { return { type: userConstants.LOGIN_REQUIRE_TWO_AUTH, loginResult } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
    
}

function loadUserContextData(userInfo) {

    return dispatch => {
        dispatch(request({ userInfo }));

        userService.loadUserContextData(userInfo)
            .then(
                userContext => { 
                    dispatch(success(userContext));
                    if (userInfo.isFleetManager) {
                        function requestContext(appcontext) { return { type: commonConstants.APP_CONTEXT_SET, appcontext } }
                        dispatch(requestContext({customer: userInfo.user.customer, fleet: userContext.activeFleet}));
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(userInfo) { return { type: userConstants.LOAD_USERCONTEXT_REQUEST, userInfo } }
    function success(userContext) { return { type: userConstants.LOAD_USERCONTEXT_SUCCESS, userContext } }
    function failure(error) { return { type: userConstants.LOAD_USERCONTEXT_FAILURE, error } }


}

function checkAccessToken(token) {
    return dispatch => {
        dispatch(request(token));

        userService.checkValidationAccessToken(token)
            .then(
                tokenState => { 
                    dispatch(success(tokenState));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(token) { return { type: userConstants.CHECK_ACCESS_TOKEN_REQUEST, token } }
    function success(tokenState) { return { type: userConstants.CHECK_ACCESS_TOKEN_SUCCESS, email: tokenState.email, mustSetPassword: (tokenState.a < 200), mustSetU2F: (tokenState.u > -1 && tokenState.u < 200) } }
    function failure(error) { return { type: userConstants.CHECK_ACCESS_TOKEN_FAILURE, error } }
}

function validateAccessAndSetPassword(token, password, confirmPassword) {
    return dispatch => {
        dispatch(request(token));

        userService.validateAccessAndSetPassword(token, password, confirmPassword)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(token) { return { type: userConstants.VALIDE_ACCESS_SETPASSWORD_REQUEST, token } }
    function success(tokenState) { return { type: userConstants.VALIDE_ACCESS_SETPASSWORD_SUCCESS, tokenState } }
    function failure(error) { return { type: userConstants.VALIDE_ACCESS_SETPASSWORD_FAILURE, error } }
}

function validateAccessTOTPAndSetPassword(token, password, confirmPassword, totpToken) {
    return dispatch => {
        dispatch(request(token));

        userService.validateAccessTOTPAndSetPassword(token, totpToken, password, confirmPassword)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(token) { return { type: userConstants.VALIDE_ACCESS_SETPASSWORD_REQUEST, token } }
    function success(tokenState) { return { type: userConstants.VALIDE_ACCESS_SETPASSWORD_SUCCESS, tokenState } }
    function failure(error) { return { type: userConstants.VALIDE_ACCESS_SETPASSWORD_FAILURE, error } }
}

function requestU2F(token, password, confirmPassword) {

    return dispatch => {
        dispatch(request(token));

        userService.requestU2F(token)
            .then(
                u2frequest => { 
                    dispatch(success(u2frequest));
                    dispatch(reponseU2F(token, password, confirmPassword, u2frequest));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(token) { return { type: userConstants.U2F_GETREQUEST_REQUEST, token, userMode: true } }
    function success(u2frequest) { return { type: userConstants.U2F_GETREQUEST_SUCCESS, u2frequest, userMode: true } }
    function failure(error) { return { type: userConstants.U2F_GETREQUEST_FAILURE, error, userMode: true } }

}

function requestU2FForUser(successCallback) {

    return dispatch => {
        dispatch(request());

        userService.requestU2FForUser()
            .then(
                u2frequest => { 
                    dispatch(success(u2frequest));
                    dispatch(reponseU2FForUser(u2frequest, successCallback));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userConstants.U2F_GETREQUEST_REQUEST, userMode: true } }
    function success(u2frequest) { return { type: userConstants.U2F_GETREQUEST_SUCCESS, u2frequest, userMode: true } }
    function failure(error) { return { type: userConstants.U2F_GETREQUEST_FAILURE, error, userMode: true } }

}

function requestTOTPSecret(token) {

    return dispatch => {
        dispatch(request());

        userService.requestTOTPSecret(token)
            .then(
                totprequest => { 
                    dispatch(success(totprequest));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userConstants.TOTP_GETREQUEST_REQUEST } }
    function success(totprequest) { return { type: userConstants.TOTP_GETREQUEST_SUCCESS, totprequest } }
    function failure(error) { return { type: userConstants.TOTP_GETREQUEST_FAILURE, error } }

}

function requestTOTPForUser() {

    return dispatch => {
        dispatch(request());

        userService.requestTOTPForUser()
            .then(
                totprequest => { 
                    dispatch(success(totprequest));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: userConstants.TOTP_GETREQUEST_REQUEST, userMode: true } }
    function success(totprequest) { return { type: userConstants.TOTP_GETREQUEST_SUCCESS, totprequest, userMode: true } }
    function failure(error) { return { type: userConstants.TOTP_GETREQUEST_FAILURE, error, userMode: true } }

}

function reponseU2FForUser(u2frequest, successCallback) {

    return dispatch => {
        dispatch(request(u2frequest));

        userService.reponseU2F(u2frequest)
            .then(
                registrationResult => { 
                    dispatch(success(registrationResult));
                    if (successCallback) {
                        successCallback(registrationResult);
                    }
                    //dispatch(registerU2FForUser(registrationResult));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(u2frequest) { return { type: userConstants.U2F_RESP_TO_REQUEST_REQUEST, u2frequest, userMode: true } }
    function success(registrationResult) { return { type: userConstants.U2F_RESP_TO_REQUEST_SUCCESS, registrationResult, userMode: true } }
    function failure(error) { return { type: userConstants.U2F_RESP_TO_REQUEST_FAILURE, error, userMode: true } }

}

function reponseU2F(token, password, confirmPassword, u2frequest) {

    return dispatch => {
        dispatch(request(u2frequest));

        userService.reponseU2F(u2frequest)
            .then(
                registrationResult => { 
                    dispatch(success(registrationResult));
                    dispatch(registerU2F(token, password, confirmPassword, u2frequest.id, registrationResult));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(u2frequest) { return { type: userConstants.U2F_RESP_TO_REQUEST_REQUEST, u2frequest } }
    function success(registrationResult) { return { type: userConstants.U2F_RESP_TO_REQUEST_SUCCESS, registrationResult } }
    function failure(error) { return { type: userConstants.U2F_RESP_TO_REQUEST_FAILURE, error } }

}

function registerU2F(token, password, confirmPassword, id, registrationResult) {
    return dispatch => {
        dispatch(request(registrationResult));

        userService.registerU2F(token, id, registrationResult, password, confirmPassword)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(registrationResult) { return { type: userConstants.U2F_REGISTER_REQUEST, registrationResult } }
    function success(result) { return { type: userConstants.U2F_REGISTER_SUCCESS, result } }
    function failure(error) { return { type: userConstants.U2F_REGISTER_FAILURE, error } }
}

function signU2F(loginResult) {

    return dispatch => {
        dispatch(request(loginResult));

        userService.signU2FChallenges(loginResult.token, loginResult.challenges)
            .then(
                signResult => { 
                    if (signResult.loggedIn) {
                        dispatch(success(signResult));
                        history.push('/');
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(loginResult) { return { type: userConstants.LOGIN_U2F_REQUEST, loginResult } }
    function success(signResult) { return { type: userConstants.LOGIN_U2F_SUCCESS, signResult } }
    function failure(error) { return { type: userConstants.LOGIN_U2F_FAILURE, error } }

}

function requestTOTP(loginResult) {
    return dispatch => {
        dispatch(request(loginResult));
    }

    function request(loginResult) { return { type: userConstants.LOGIN_TOTP_REQUEST, loginResult } }
}

function verifyTOTP(loginResult, totpToken) {

    return dispatch => {
        dispatch(request());

        userService.verifyTOTP(loginResult.token, totpToken)
            .then(
                verifyResult => { 
                    if (verifyResult.loggedIn) {
                        dispatch(success(verifyResult));
                        history.push('/');
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(loginResult) { return { type: userConstants.LOGIN_TOTP_VERIFY_REQUEST, loginResult } }
    function success(verifyResult) { return { type: userConstants.LOGIN_TOTP_VERIFY_SUCCESS, verifyResult } }
    function failure(error) { return { type: userConstants.LOGIN_TOTP_VERIFY_FAILURE, error } }

}

function sendValidationEmail(userAccess) {

    return dispatch => {
        dispatch(request(userAccess));

        userService.sendValidationEmail(userAccess)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(userAccess) { return { type: userConstants.SEND_VALIDATION_EMAIL_REQUEST, userAccess } }
    function success(result) { return { type: userConstants.SEND_VALIDATION_EMAIL_SUCCESS, result } }
    function failure(error) { return { type: userConstants.SEND_VALIDATION_EMAIL_FAILURE, error } }

}

function gotoLogin(email) {

    return dispatch => {
        dispatch(request(email));
        history.push('/login');
    }

    function request(email) { return { type: userConstants.GOTO_LOGIN_REQUEST, email } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function passwordForgotten(email) {

    return dispatch => {
        dispatch(request(email));

        userService.passwordForgotten(email).then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(email) { return { type: userConstants.PASSWORD_FORGOTTEN_REQUEST, email } }
    function success(result) { return { type: userConstants.PASSWORD_FORGOTTEN_SUCCESS, result } }
    function failure(error) { return { type: userConstants.PASSWORD_FORGOTTEN_FAILURE, error } }

}

function checkResetPasswordToken(token) {
    return dispatch => {
        dispatch(request(token));

        userService.checkResetPasswordToken(token)
            .then(
                tokenState => { 
                    dispatch(success(tokenState));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(token) { return { type: userConstants.CHECK_RESET_PWD_TOKEN_REQUEST, token } }
    function success(tokenState) { return { type: userConstants.CHECK_RESET_PWD_TOKEN_SUCCESS, email: tokenState.email } }
    function failure(error) { return { type: userConstants.CHECK_RESET_PWD_TOKEN_FAILURE, error } }
}


function resetPassword(token, password, confirmPassword) {
    return dispatch => {
        dispatch(request(token));

        userService.resetPassword(token, password, confirmPassword)
            .then(
                result => { 
                    dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(token) { return { type: userConstants.RESET_PWD_TOKEN_REQUEST, token } }
    function success(result) { return { type: userConstants.RESET_PWD_TOKEN_SUCCESS, result } }
    function failure(error) { return { type: userConstants.RESET_PWD_TOKEN_FAILURE, error } }
}