import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import BarChartView from '../common/BarChart';
import BarChartLoadingView from '../common/BarChartLoading';


class RefillGroupSummaryBreaks extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { loading, mode, data } = this.props;
        
        if (loading) {
            return <BarChartLoadingView/>
        }

        if (data && data.total && data.total.length > 0) {
            return this.renderKpiBloc( data.total )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( rawData ) => {
        
        const { t, breakConfig } = this.props;

        const datasetCount = {
            label: t('refillgroups'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#2a87fc',
            type: 'bar',
            //stack: 'Stack 0',
        }

        const datasetAttemptsCount = {
            label: t('attempts'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#273b54',
            type: 'bar',
            //stack: 'Stack 0',
        }

        const datasetQuantity = {
            label: t('quantity'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#ffe6ad6e',
            borderColor:  '#ffe6ad',
            borderWidth: 2,
            type: 'line',
            //stack: 'Stack 1',
        } 

        rawData.forEach( totalKpis => {

            breakConfig.matcher(totalKpis, datasetCount.data, totalKpis.count)
            breakConfig.matcher(totalKpis, datasetAttemptsCount.data, totalKpis.attemptsCount)
            breakConfig.matcher(totalKpis, datasetQuantity.data, KpiTools.formatQuantity(totalKpis.quantity))
            
        })

        datasetCount.data = Object.values(datasetCount.data)
        datasetAttemptsCount.data = Object.values(datasetAttemptsCount.data)
        datasetQuantity.data = Object.values(datasetQuantity.data)

        const data = {
            labels: breakConfig.labels,
            datasets: [datasetCount, datasetAttemptsCount, datasetQuantity]
        };

        return <BarChartView data={data} />

    }

}

const connectedBloc = translate('translations')(RefillGroupSummaryBreaks);
export { connectedBloc as RefillGroupSummaryBreaks };