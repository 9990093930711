export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_REQUIRE_TWO_AUTH: 'USERS_LOGIN_TWO_AUTH_REQUIRED',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGIN_U2F_REQUEST: 'USERS_LOGIN_U2F_REQUEST',
    LOGIN_U2F_SUCCESS: 'USERS_LOGIN_U2F_SUCCESS',
    LOGIN_U2F_FAILURE: 'USERS_LOGIN_U2F_FAILURE',

    LOGIN_TOTP_REQUEST: 'USERS_LOGIN_TOTP_REQUEST',

    LOGIN_TOTP_VERIFY_REQUEST: 'USERS_LOGIN_TOTP_VERIFY_REQUEST',
    LOGIN_TOTP_VERIFY_SUCCESS: 'USERS_LOGIN_TOTP_VERIFY_SUCCESS',
    LOGIN_TOTP_VERIFY_FAILURE: 'USERS_LOGIN_TOTP_VERIFY_FAILURE',

    PASSWORD_FORGOTTEN_REQUEST: 'USERS_PASSWORD_FORGOTTEN_REQUEST',
    PASSWORD_FORGOTTEN_SUCCESS: 'USERS_PASSWORD_FORGOTTEN_SUCCESS',
    PASSWORD_FORGOTTEN_FAILURE: 'USERS_PASSWORD_FORGOTTEN_FAILURE',

    CHECK_RESET_PWD_TOKEN_REQUEST: 'USERS_CHECK_RESET_PWD_TOKEN_REQUEST',
    CHECK_RESET_PWD_TOKEN_SUCCESS: 'USERS_CHECK_RESET_PWD_TOKEN_SUCCESS',
    CHECK_RESET_PWD_TOKEN_FAILURE: 'USERS_CHECK_RESET_PWD_TOKEN_FAILURE',

    RESET_PWD_TOKEN_REQUEST: 'USERS_RESET_PWD_TOKEN_REQUEST',
    RESET_PWD_TOKEN_SUCCESS: 'USERS_RESET_PWD_TOKEN_SUCCESS',
    RESET_PWD_TOKEN_FAILURE: 'USERS_RESET_PWD_TOKEN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    CHECK_ACCESS_TOKEN_REQUEST: 'USERS_CHECK_ACCESS_TOKEN_REQUEST',
    CHECK_ACCESS_TOKEN_SUCCESS: 'USERS_CHECK_ACCESS_TOKEN_SUCCESS',
    CHECK_ACCESS_TOKEN_FAILURE: 'USERS_CHECK_ACCESS_TOKEN_FAILURE',

    VALIDE_ACCESS_SETPASSWORD_REQUEST: 'USERS_VALIDE_ACCESS_SETPASSWORD_TOKEN_REQUEST',
    VALIDE_ACCESS_SETPASSWORD_SUCCESS: 'USERS_VALIDE_ACCESS_SETPASSWORD_TOKEN_SUCCESS',
    VALIDE_ACCESS_SETPASSWORD_FAILURE: 'USERS_VALIDE_ACCESS_SETPASSWORD_TOKEN_FAILURE',

    U2F_GETREQUEST_REQUEST: 'U2F_GETREQUEST_REQUEST',
    U2F_GETREQUEST_SUCCESS: 'U2F_GETREQUEST_SUCCESS',
    U2F_GETREQUEST_FAILURE: 'U2F_GETREQUEST_FAILURE',

    TOTP_GETREQUEST_REQUEST: 'TOTP_GETREQUEST_REQUEST',
    TOTP_GETREQUEST_SUCCESS: 'TOTP_GETREQUEST_SUCCESS',
    TOTP_GETREQUEST_FAILURE: 'TOTP_GETREQUEST_FAILURE',

    U2F_RESP_TO_REQUEST_REQUEST: 'U2F_RESP_TO_REQUEST_REQUEST',
    U2F_RESP_TO_REQUEST_SUCCESS: 'U2F_RESP_TO_REQUEST_SUCCESS',
    U2F_RESP_TO_REQUEST_FAILURE: 'U2F_RESP_TO_REQUEST_FAILURE',

    U2F_REGISTER_REQUEST: 'U2F_REGISTER_REQUEST',
    U2F_REGISTER_SUCCESS: 'U2F_REGISTER_SUCCESS',
    U2F_REGISTER_FAILURE: 'U2F_REGISTER_FAILURE',

    GOTO_LOGIN_REQUEST: 'GOTO_LOGIN_REQUEST',

    EDIT_REQUEST: 'USERS_EDIT_REQUEST',
    EDIT_SUCCESS: 'USERS_EDIT_SUCCESS',
    EDIT_FAILURE: 'USERS_EDIT_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    SAVE_REQUEST: 'USERS_SAVE_REQUEST',
    SAVE_SUCCESS: 'USERS_SAVE_SUCCESS',
    SAVE_FAILURE: 'USERS_SAVE_FAILURE',

    UPDATE_USER_EDITION_REQUEST: 'USERS_UPDATE_EDITION',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    LOAD_USERCONTEXT_REQUEST: 'LOAD_USERCONTEXT_REQUEST',
    LOAD_USERCONTEXT_SUCCESS: 'LOAD_USERCONTEXT_SUCCESS',
    LOAD_USERCONTEXT_FAILURE: 'LOAD_USERCONTEXT_FAILURE',

    SEND_VALIDATION_EMAIL_REQUEST: 'SEND_VALIDATION_EMAIL_REQUEST',
    SEND_VALIDATION_EMAIL_SUCCESS: 'SEND_VALIDATION_EMAIL_SUCCESS',
    SEND_VALIDATION_EMAIL_FAILURE: 'SEND_VALIDATION_EMAIL_FAILURE',
};