import React from 'react';
import { translate } from 'react-i18next';

import { Bar } from 'react-chartjs-2';

import ChartOptions from './ChartOptions';

export default class BarChartView extends React.PureComponent {

    render() {

        const { data, options } = this.props;

        return <Bar data={data} options={options ? options : ChartOptions.BAR} />
    }

}

const translated = translate('translations')(BarChartView);
export { translated as BarChartView };