export const AppColors = {
    CLEAR: 'rgba(0, 0, 0, 0)',
    BLUE: '#54A2E5',
    BLUE_TRANSPARENT: 'rgba(5,86,163, 0.3)',
    BLUE_TRANSPARENT_2: 'rgba(5,86,163, 0.6)',
    BLUE_LIGHT: '#019CCA',
    BLUE_LIGHT_TRANSPARENT: 'rgba(1,156,202, 0.3)',
    BLUE_LIGHT_TRANSPARENT_2: 'rgba(1,156,202, 0.6)',
    GREEN: '#8CC63F',
    GREEN_TRANSPARENT: 'rgba(140,198,63, 0.3)',
    GREEN_TRANSPARENT_2: 'rgba(140,198,63, 0.6)',
    GREY_LIGHT: '#EAEAEA',
    GREY_DARK: '#999999',
    ORANGE: '#F8CD6B',
    ORANGE_TRANSPARENT: 'rgba(248,205,107, 0.3)',
    ORANGE_TRANSPARENT_2: 'rgba(248,205,107, 0.6)',
    RED: '#E5332B',
    RED_TRANSPARENT: 'rgba(229,51,43, 0.3)',
    RED_TRANSPARENT_2: 'rgba(229,51,43, 0.6)',
    PINK: '#EB008A',
    PINK_TRANSPARENT: 'rgba(235,0,138, 0.3)',
    PINK_TRANSPARENT_2: 'rgba(235,0,138, 0.6)',
    WHITE: '#FFFFFF',
    PURPLE: '#9013FE',
    PURPLE_TRANSPARENT: 'rgba(144,19,254, 0.3)',
    PURPLE_TRANSPARENT_2: 'rgba(144,19,254, 0.6)',
    OR: 'rgba(200,160,0, 1)',
    OR_TRANSPARENT: 'rgba(200,160,0, 0.3)',
    OR_TRANSPARENT_2: 'rgba(200,160,0, 0.6)',
    MARRON: 'rgba(139,87,42, 1)',
    MARRON_TRANSPARENT: 'rgba(139,87,42, 0.3)',
    TURQUOISE: 'rgba(55,162,180, 1)',
    TURQUOISE_TRANSPARENT: 'rgba(55,162,180, 0.3)',
    TURQUOISE_TRANSPARENT_2: 'rgba(55,162,180, 0.6)',
}

export const NEGATIF_COLOR = AppColors.BLUE;
export const POSITIF_COLOR = AppColors.PINK;

export const AppColorsDark = [AppColors.BLUE, AppColors.ORANGE, AppColors.PINK, AppColors.TURQUOISE,
    AppColors.MARRON, AppColors.RED, AppColors.GREEN, AppColors.OR];

export const AppColorsLight = [AppColors.BLUE_TRANSPARENT, AppColors.ORANGE_TRANSPARENT,
    AppColors.PINK_TRANSPARENT, AppColors.TURQUOISE_TRANSPARENT
    , AppColors.MARRON_TRANSPARENT,
    AppColors.RED_TRANSPARENT, AppColors.GREEN_TRANSPARENT, AppColors.OR_TRANSPARENT];

export const AppColorsLight2 = [AppColors.BLUE_TRANSPARENT_2, AppColors.ORANGE_TRANSPARENT_2,
            AppColors.PINK_TRANSPARENT_2, AppColors.TURQUOISE_TRANSPARENT_2
            , AppColors.MARRON_TRANSPARENT,
            AppColors.RED_TRANSPARENT_2, AppColors.GREEN_TRANSPARENT_2, AppColors.OR_TRANSPARENT_2];

export const AppColorsClasses = new Array();

AppColorsClasses[AppColors.BLUE] = 'blue';
AppColorsClasses[AppColors.BLUE_TRANSPARENT] = 'bluetransparent';
AppColorsClasses[AppColors.BLUE_LIGHT] = 'bluelight';
AppColorsClasses[AppColors.BLUE_LIGHT_TRANSPARENT] = 'bluelighttransparent';
AppColorsClasses[AppColors.GREEN] = 'green';
AppColorsClasses[AppColors.GREEN_TRANSPARENT] = 'greentransparent';
AppColorsClasses[AppColors.GREY_LIGHT] = 'grey';
AppColorsClasses[AppColors.ORANGE] = 'orange';
AppColorsClasses[AppColors.ORANGE_TRANSPARENT] = 'orangetransparent';
AppColorsClasses[AppColors.RED] = 'red';
AppColorsClasses[AppColors.RED_TRANSPARENT] = 'redtransparent';
AppColorsClasses[AppColors.PINK] = 'pink';
AppColorsClasses[AppColors.PINK_TRANSPARENT] = 'pinktransparent';
AppColorsClasses[AppColors.WHITE] = 'white';
AppColorsClasses[AppColors.PURPLE] = 'purple';
AppColorsClasses[AppColors.PURPLE_TRANSPARENT] = 'purpletransparent';
AppColorsClasses[AppColors.OR] = 'gold';
AppColorsClasses[AppColors.OR_TRANSPARENT] = 'goldtransparent';
AppColorsClasses[AppColors.MARRON] = 'marron';
AppColorsClasses[AppColors.MARRON_TRANSPARENT] = 'marrontransparent';
AppColorsClasses[AppColors.TURQUOISE] = 'turquoise';
AppColorsClasses[AppColors.TURQUOISE_TRANSPARENT] = 'turquoisetransparent';
