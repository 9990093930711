import React, { Component } from 'react';
import { translate } from 'react-i18next';

class RefillAttemptView extends Component {

    render() {
     const { t, object, model } = this.props;

      return <div>
          TENTATIVE
      </div>
    }
    
}

const connectedAuthorizationView = translate('translations')(RefillAttemptView);
export { connectedAuthorizationView as RefillAttemptView };