import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import localCache from '../_helpers/local.cache';

import { history } from '../_helpers';

const appSpecificAssetFolder = process.env.REACT_APP__SPC_ASSET_FLD ? process.env.REACT_APP__SPC_ASSET_FLD : "fillndrive";

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: '100%',//`calc(100% - ${drawerWidth}px)`,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  grow: {
    flexGrow: 1,
  },
  breadcrumbLi: {
    float: 'left'
  },
  appBarColorPrimary: {
    backgroundColor: 'none',
    background: 'linear-gradient(0.25turn, #273b54, #2a87fc)'
  },
  menuSubHeaderRoot: {
    color: '#2A87FC',
    fontSize: '0.875rem',
    boxSizing: 'border-box',
    listStyle: 'none',
    lineHeight: '48px',
    fontWeight: 500,
  },
  menuIconRoot: {
    color: '#042859',
    display: 'inline-flex',
    flexShrink: 0,
    marginRight: '-10px',
  },
  customLink: {
	color: "rgba(0, 0, 0, 0.87)"
  },
  customToolbarFooter: {
	marginTop: '40px',
	marginBottom: '20px',
	position: 'relative'
  },
  toolbarFooterCnt: {
	marginLeft: '60px',
	position: 'absolute',
	bottom: '0'
  },
  flexBox: {
	display: 'flex',
	flexFlow: 'column'
  },
  flexCntFull: {
	flex: "1 1 auto"
  }
});

class ResponsiveDrawer extends React.Component {
  state = {
    mobileOpen: false,
    anchorEl: null,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleProfil = () => {
    this.setState({ anchorEl: null });
    history.push('/profil');
  };

  handleLogout = () => {
    history.push('/login');
  };
  
  handleMenuItemClick = (url) => {
	localCache.resetCachesWithPrefix("list_id_");
	history.push(url);
  };


  
  render() {
    const { t, api, user, menu, classes, theme, appcontext } = this.props;
    
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const withBreadcrumb = true;
    
    const Breadcrumbs = (props) => (
        withBreadcrumb && this.props.main ?
        (<div className="breadcrumbs">
            <ul className='container'>
                
            </ul>
        </div>) : (null)
    )

    const BreadcrumbsItem = ({ match }) => (
        <span>
            <li className={match.isExact ? 'breadcrumbLi breadcrumb-active' : 'breadcrumbLi'}>
                <Link to={match.url || ''} >
                    {match.url}
                </Link>
            </li>
            <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
        </span>
    )

	const welcomePart = <Link to="/" className={"menu-link "+classes.customLink}>
							<ListItem button>
							  <ListItemIcon classes={{root: classes.menuIconRoot}}><HomeIcon/></ListItemIcon>
							  <ListItemText primary={t('home.title')} />
							</ListItem>
						</Link>

    const menuContent = menu && Object.keys(menu).map( (menuPartKey, partIndex) => {
        const menuPart = menu[menuPartKey];

        if (menuPart.items.length > 0) {
          const menuPartItems = menuPart.items.map( (item, index) => {
            
            if (item.appcontext && (!appcontext || !appcontext[item.appcontext])) {
              return null;
            }
            
            let itemUrl = item.url;
            if (item.appcontext && item.url.indexOf(':parentId') > -1) {
              const parent = appcontext[item.appcontext];
              itemUrl = itemUrl.replace(':parentId', parent.id);
            }
            
            return (
                <div key={'part_' + partIndex+'_item_'+index} onClick={() => { this.handleMenuItemClick(itemUrl) } }>
					<ListItem button to={item.key}>
					  <ListItemIcon classes={{root: classes.menuIconRoot}}>{item.icon}</ListItemIcon>
					  <ListItemText primary={item.name} />
					</ListItem>
				</div>
            );
          }).filter( item => item );

          let menuPartTopTitle;
          if (menuPartKey === 'fleet' && user && user.user && user.isServiceProvider && appcontext.customer) {
            menuPartTopTitle = (<div className="menu-part-toptitle">{appcontext.customer.name}</div>);
          }

          return (menuPartItems.length > 0 &&
            <div key={'menupart_' + partIndex }>
              {menuPartTopTitle}
              <ListSubheader component="div" classes={{root: classes.menuSubHeaderRoot}}>{t(menuPart.name)}</ListSubheader>
              {menuPartItems}
            </div>
          );
        } else {
          return (null);
        }
    });

    const apiInfos = api && api.infos ? api.infos.version : '-';

    const drawer = (
      <div className={"toolbar-content "+classes.flexBox}>
        <div className={classes.toolbar} >
        </div>
        <Divider />
        <div className={classes.flexBox+" "+classes.flexCntFull}>
			<List>
				{welcomePart}
				{menuContent}
			</List>
			<div className={classes.customToolbarFooter+ " "+ classes.flexCntFull}>
				<div className={classes.toolbarFooterCnt}>
					<div className="legende">UI version : {process.env.REACT_APP_VERSION}</div>
					<div className="legende">API version : {apiInfos}</div>
				</div>
			</div>
		</div>
      </div>
    );
 
    return <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} classes={{colorPrimary: classes.appBarColorPrimary}}>
          <Toolbar>
          <img height="32px" src={require('../assets/img/'+appSpecificAssetFolder+'/logo-h.svg')}/>
            <Breadcrumbs/>
            <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
            
            </Typography>
            <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
              <Typography variant="subtitle2" color="inherit" noWrap>
                    {user && user.user && user.user.email}
              </Typography>
                  <AccountCircle className="icon-profil"/>
                </IconButton>
                <span className="customer-name">{user && user.user && ((user.user.customer && user.user.customer.name) || (user.user.serviceProvider && user.user.serviceProvider.name))} </span>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleProfil}>{t('action.profil')}</MenuItem>
              <MenuItem onClick={this.handleLogout}>{t('action.logout')}</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swap with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
  }
}

ResponsiveDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { api, data, appcontext } = state;
  const { main } = data;
  return {
    api,
    main,
    appcontext
  };
}

const connectedResponsiveDrawer = connect(mapStateToProps)(withStyles(styles, { withTheme: true })(translate('translations')(ResponsiveDrawer)));
export { connectedResponsiveDrawer as ResponsiveDrawer };