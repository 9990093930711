import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

export default class PieChartLoadingView extends React.PureComponent {

    render() {

        return <div style={{paddingLeft: 20, paddingRight: 20,    display: 'flex', justifyContent: 'center'}}>
            <Skeleton variant="circle" height={200} width={200}/></div>
    }

}