import React, { Component } from 'react';

import { StateColorHelper } from '../../_helpers';

class StateReadView extends Component {
    
    render() {
    
       const { t, value, label, inline, square } = this.props;
        const state = value ? value : 'unknown';
        const stateLabel = label ? label : state;
    const content = square ? (<div className={"state-square state-square-" + StateColorHelper.stateToColorClass(state)}><div className="state-square-label">{stateLabel}</div></div>) : <div className="state-field"><div className={'state-pastille pastille-' + StateColorHelper.stateToColorClass(state)}></div><div className="state-value">{t('state.' + stateLabel)}</div></div>;

       return inline ? <div className="inline">{content}</div> : content;
    }
}

export { StateReadView as StateReadView };