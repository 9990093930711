import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import List from '@material-ui/core/List'; 
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import SearchIcon from '@material-ui/icons/Search';

import { commonActions } from '../../_actions';

const styles = theme => ({
	
	globalDiv: {
		overflow: "visible",
		position: "relative"
	},
	
	label: {
		color: 'rgba(0, 0, 0, 0.54)',
		fontSize: "12px"
	},
	
	delButton: {

		'&:hover': { 
			cursor: "pointer"
		}
	},
	
	inputHr: {
		borderBottom: "1px solid rgba(0, 0, 0, 0.87)"
	},
	
	resultsGlobalDiv: {
		width: "400px",
		maxHeight: "200px",
		overflow: 'auto',
		zIndex: "2000",
		position: "absolute"
    },
	
	resultList: {
		position: "relative",
		paddingRight: '20px',
	},
	
	result_preview: {
		fontSize: "12px",
		color: "#2A87FC",
		fontWeight: 500
	},
	
	result_mainInfo: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end'
	},

	result: {
		'&:hover': { 
			backgroundColor: 'rgba(0, 0, 0, 0.14)'
		},
		zIndex: "2000",
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'flex-start'
	},
	
	result_postview: {
		fontSize: "12px",
		fontStyle: 'italic'
	},
	
	actionChoose: {
		color: 'rgba(0, 0, 0, 0.24)',
		fontStyle: 'italic'
	},
	
	selectionComponent: {
		width: "fit-content", 
        height:"fit-content" 
	}
});

class SearchText extends Component {
	
	state = {displayPossibilities: false};
	
	constructor(props) {
		super(props);
		this.fieldname = this.props.field.name;
	}
	
	onChange(e) {
		if(e.target.value == null) {
			this.setState({displayPossibilities: false, currText: ""});
		} else if (e.target.value.length < 3) {
			this.setState({displayPossibilities: false, currText: e.target.value});
		} else {
			this.props.dispatch(commonActions.doSearchText(this.props.scope, this.fieldname, e.target.value, this.props.mainObjectName, this.props.mainObjectId));
			this.setState({displayPossibilities: true, currText: e.target.value});
		}
	}
	
	getData() {
		
        let { scope, data, classes } = this.props;

        if (data && scope) {
            const scopedData = data[scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }
	
	onBlur() {
		this.setState({displayPossibilities: false, currText: ""});
	}
	
	onSelectItem(id, item) {
		this.onBlur();
		this.props.onChange(item);
		//this.setState({selection: item, displayPossibilities: false, currText: ""});
	}
	
	doNothing(e) {
		e.preventDefault();
		//this.setState({displayPossibilities: true});
	}
	
	delSelection() {
		this.props.onChange({});
		this.setState({selection: null, displayPossibilities: false, currText: ""});
	}
	
	computeDisplayPreView(item) {
		var display = this.props.field.res_display_pre;
		
		Object.keys(item).forEach((key) => {
			display = display.replace("<"+key+">", item[key]);
		});
		
		return display;
	}
	
	computeDisplay(item) {
		
		var display = this.props.field.res_display;
		
		Object.keys(item).forEach((key) => {
			display = display.replace("<"+key+">", item[key]);
		});
		
		return display;
	}
	
	computeDisplayPostView(item) {
		
		var display = this.props.field.res_display_post;
		
		Object.keys(item).forEach((key) => {
			display = display.replace("<"+key+">", item[key]);
		});
		
		return display;
	}
	
	render() {
								
		const { classes, t, id, label } = this.props;
		
		const selectionDisplayPreview = this.state.selection ? this.computeDisplayPreView(this.state.selection) : null;
		const selectionDisplay = this.state.selection ? this.computeDisplay(this.state.selection) : null;
		const selectionDisplayPostview = this.state.selection ? this.computeDisplayPostView(this.state.selection) : null;
		
		const currText = this.state.currText ? this.state.currText : "";
		
		const resultItems = this.getData();
		
		return	<div className="form-edit-field">
					<div className={classes.globalDiv} >
						{
							!this.state.selection && 
							<span>
								<TextField autoComplete="off" id={id+"_textfield"} value={currText} label={label} onChange={(e) => {this.onChange(e)} } onBlur={() => this.onBlur() } InputProps={{
						startAdornment: (
							<InputAdornment position="start">
							<SearchIcon />
							</InputAdornment>
						),
						}}
		helperText={t('driver.search.help')}/>
							</span>
						}
						
						{
							this.state.selection &&
							<div className={classes.selectionComponent}>
								<InputLabel className={classes.label}>{label}</InputLabel>
								<Paper>
									<span className={classes.result_preview}>{selectionDisplayPreview}</span>
									<span>{selectionDisplay}</span>
									<span className={classes.result_postview}>{selectionDisplayPostview}</span>
									<span className={classes.delButton} onClick={() => this.delSelection()}>  X</span>
								</Paper>
								<hr className={classes.inputHr}/>
							</div>
						}
						
						{
							this.state.displayPossibilities && resultItems.loading &&
							<Paper className={classes.resultsGlobalDiv}>
								<List className={classes.resultList}>
									<ListItem button className={classes.result} >
										<span className={classes.actionChoose}>{t('search.in.progress')+"..."}</span>
									</ListItem>
								</List>
							</Paper>
						}
						
						{
							this.state.displayPossibilities && !resultItems.loading && resultItems.queryRes && 
							<Paper className={classes.resultsGlobalDiv} onMouseDown={(e) => this.doNothing(e)}>
								<List className={classes.resultList}>
									<ListItem button className={classes.result} onMouseDown={(e) => this.doNothing(e)}>
										<span className={classes.actionChoose}>{resultItems.queryRes.length > 0 ? (resultItems.queryRes.length +' '+ (resultItems.queryRes.length > 1 ? t('results') : t('result'))) : t('no.result')}</span>
									</ListItem>
									{
										resultItems.queryRes.map((item, index) => {
											return <ListItem button key={item.firstName+" "+item.lastName} className={classes.result} onMouseDown={() => this.onSelectItem(index, item)}>
													<span className={classes.result_preview}>{this.computeDisplayPreView(item)}</span>
													<div className={classes.result_mainInfo}>
													<span>{this.computeDisplay(item)}</span>
													<span className={classes.result_postview}>{this.computeDisplayPostView(item)}</span>
													</div>
												  </ListItem>
										})
									}
								</List>
							</Paper>
						}
					</div>
				</div>
	}
}

function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext } = state;
    const { user, context } = authentication;
    return {
        itemId,
        data,
        user,
        appcontext,
        context
    };
}

const connectedCustomReadObject = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SearchText)));
export { connectedCustomReadObject as SearchText };