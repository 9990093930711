import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { userActions } from '../../_actions';

class UserView extends Component {
    
    handleSendValidationEmail = (userAccess) => {
        this.props.dispatch(userActions.sendValidationEmail(userAccess));
    }

    render() {

     const { t, user} = this.props;
       return (
           <div>
            <div>{t('email')} : {user.email}</div>
            <div>{t('firstName')} : {user.firstName}</div>
            <div>{t('lastName')} : {user.lastName}</div>

            {this._renderAccess(user.access)}

           </div>
        )
    }

    _renderAccess = (accesses) => {
        const { t } = this.props;
        const listAcces = accesses.map( (acces, index) => {
            return (
                <ListItem key={'acces_' + index}>
          <ListItemText
            primary={acces.context}
            secondary={
            <React.Fragment>
              <Typography component="span" color="textPrimary">
              {t('validated')} : {acces.validated ? t('yes') : t('no')}
              </Typography>
              {""}
            </React.Fragment>
          }/>
          <ListItemSecondaryAction>
          {!acces.validated &&
          <Button variant="contained" size="small" onClick={() => this.handleSendValidationEmail(acces)}>
                    Envoyer email de validation
                </Button>}
          </ListItemSecondaryAction>
        </ListItem>
            );
        });

        return (
            <List subheader={<ListSubheader>Accès ({listAcces.length})</ListSubheader>}>
            {listAcces}
            </List>
        );
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    const user = data.main.item;
    return {
        itemId,
        data,
        user
    };
}

const connectedUserView = connect(mapStateToProps)(translate('translations')(UserView));
export { connectedUserView as UserView };