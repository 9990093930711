import React, { Component } from 'react';

import { PushPinIcon } from '../icons/pushpin';

import { StateColorHelper } from '../../_helpers';

class StationStateReadView extends Component {
    
    render() {
    
       const { t, value, label, inline } = this.props;
       const state = value.manualState || value.connected !== false ? value.publicState : 'offline'; 
        const stateLabel = label ? label : 'state.'+state;
        
        const content = <div className={"station-state station-state-" + StateColorHelper.stateToColorClass(state)}><div className="station-state-label">{value.manualState ? <PushPinIcon/> : null}{t(stateLabel)}</div></div>

       return inline ? <div className="inline">{content}</div> : content;
    }
}

export { StationStateReadView as StationStateReadView };