import { commonConstants } from '../_constants';

function mergeObject(localObject, remoteObject, fields) {
  const keys = fields ? Object.keys(fields) : ((remoteObject && typeof remoteObject === "object") ? Object.keys(remoteObject) : undefined);
  if (keys) {
    keys.forEach( field => {
        const fieldConfig = fields ? fields[field] : undefined;
        const localValue = localObject[field];
        const remoteValue = remoteObject[field];
        if (fieldConfig && fieldConfig.liveOnly) {
          localObject[field] = remoteValue;
        } else {
          if (Array.isArray(localValue)) { 
            const newArray = [];
            localValue.forEach( (v, index) => {
              try {
                mergeObject(v, remoteValue[index], fieldConfig ? fieldConfig.fields : null);
              } catch(err) {
                console.log(err, field, v, index, remoteValue);
              }
              newArray.push(v);
            })
            localObject[field] = newArray;
          } else if (localValue !== null && typeof localValue === "object") {
            mergeObject(localValue, remoteValue, fieldConfig ? fieldConfig.fields : null);
          } else {
            localObject[field] = remoteValue;
          }
        }
    })
  }
}

function updateObject(object, path, newValue) {
  if (path && path.length > 0) {
    const currentPath = path.shift();
    let subObject = object[currentPath];
    if (subObject === undefined || subObject === null) {
      subObject = {};
      object[currentPath] = subObject;
    }
    object[currentPath] = updateObject(object[currentPath], path, newValue);
    return object;
  } else {
    return {...object, ...newValue};
  }
}

function wrapInScope(state, action, object) {
  /*if (action.scope) {
    var result = {}
    result[action.scope] = object; 
    return {...state, ...result};
  } else {
    return object;
  }*/
  return object;
}

function extractStateFromScope(state, action) {
  /*if (action.scope) {
    let result = state[action.scope];
    if (!result) {
      result = {};
    }
    return result;
  } else {
    return state;
  }*/
  return state;
}

function doReduce(state, action) {
  switch (action.type) {
    //case commonConstants.LOADCONTEXT_REQUEST:
    //  return {...state, ...{context: null}};
    case commonConstants.LOADCONTEXT_SUCCESS:
      return {parent: action.parent};
    //case commonConstants.LOADCONTEXT_FAILURE:
    //  return {...state, ...{context: null}};
    default:
      return state
  }
}

export function context(state = {}, action) {

  const newState = wrapInScope(state, action, doReduce(extractStateFromScope(state, action), action));

  return newState;

}