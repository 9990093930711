import { commonConstants } from '../_constants';

const initialState = {};

export function appcontext(state = initialState, action) {
  switch (action.type) {
    case commonConstants.APP_CONTEXT_SET:
      return {...state, ...action.appcontext};
    default:
      return state
  }
}