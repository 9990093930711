import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment'

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { InlineDatePicker } from 'material-ui-pickers';

class DatePicker extends Component {

  formatPeriodeDateSelectedLabel = (date, invalidLabel) => {

      if (!date) {
        return null;
      }

      const t = this.props.t;

      let datem = moment(date);
      
      if (date && datem.isValid()) {
            return datem.format('DD/MM/YYYY');
      }

      return invalidLabel;
  };

    render() {

     const { t, label, object, value, fieldError, onChange, disableFuture, required, disabled } = this.props;

     const hasErrors = fieldError && fieldError.length > 0;

       return <div className="form-edit-field">
                <FormControl error={hasErrors} >
                    <InputLabel htmlFor="component-simple"></InputLabel>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                          <InlineDatePicker value={value} onChange={onChange} disableFuture={disableFuture} 
                            labelFunc={this.formatPeriodeDateSelectedLabel}
                            label={label} disabled={disabled}/>
                      </MuiPickersUtilsProvider>
                      {hasErrors && <FormHelperText>{t(fieldError[0].message)}</FormHelperText>}
                </FormControl>
		   </div>
    }
    
}

const connectedDatePicker = translate('translations')(DatePicker);
export { connectedDatePicker as DatePicker };