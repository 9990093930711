import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

class ChoiceListLight extends Component {
    
    isChoiceSelected = item => {
        return this.props.selectedItems && this.props.selectedItems.find( selectedItem => selectedItem.id === item.id) !== undefined
    }

    handleChange = (item, checked) => {
        let newSelectedItems;
        const selectedItems = this.props.selectedItems ? this.props.selectedItems : [];
        if (checked) {
            newSelectedItems = selectedItems.concat([item]);
        } else {
            newSelectedItems = selectedItems.filter( selectedItem => selectedItem.id != item.id);
        }
        this.props.onChange(newSelectedItems);
    }

    render() {

        const { t, id, label, items, selectedItems, fieldError, model, readonly, onChange } = this.props;
        
        const hasErrors = fieldError && fieldError.length > 0;
        
        return (
            <div>
            <div className="list-field-header">
                    <div className="list-field-title">
                    {model.icon}
                    {label}
                    </div>
                    </div>
                <div className="form-edit-field-container">
                    <FormControl error={hasErrors}>
                        <FormGroup row>
                        {items && items.map( (item, index) => this._renderOneChoice(item, index, model))}
                        </FormGroup>
                    </FormControl>
                    {hasErrors && <FormHelperText>{t(fieldError[0].message)}</FormHelperText>}
                </div>
            </div>
        );
    }

    _renderOneChoice = (item, index, model) => {
        const label = model.toString ? model.toString(item) : item.name;
        const isSelected = this.isChoiceSelected(item);
        return (<FormControlLabel
            key={'choice_item_'+index}
            control={
            <Checkbox
                checked={isSelected}
                onChange={ () => this.handleChange(item, !isSelected)}
                value={item.id}
            />
            }
            label={label}
        />)
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedCustomReadObject = connect(mapStateToProps)(ChoiceListLight);
export { connectedCustomReadObject as ChoiceListLight };