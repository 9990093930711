import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment'

class DurationReadView extends Component {
    
    render() {
       const { value } = this.props;
       return (value && (value.start || value.end)) ? (value.start && value.end) ? moment(value.start).from(value.end, true) : '-' : this._renderSimpleValue(value);
    }

    _renderSimpleValue = (value) => {
        return value>=0 ? format(Math.round(value)) : '-';
    }
}

const format = (duration) => {
    let time;
    if (duration < 60) {
        time = duration > 0 ? duration + ' sec' : duration;
    } else if (duration < 3600) {
        const minutes = Math.floor(duration / 60);
        const secondes = duration - (minutes * 60);
        time = minutes+ ' min';
        if (secondes > 0) {
            time += ' '+secondes+' sec';
        }
    } else if (duration < 86400) {
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration - (hours * 3600)) / 60);
        const secondes = duration - (hours * 3600) - (minutes * 60);
        time = hours+'h '+ minutes+ ' min';
        if (secondes > 0) {
            time += ' '+secondes+' sec';
        }
    } else {
        const days = Math.floor(duration / 86400);
        const hours = Math.floor((duration - (days * 86400)) / 3600);
        const minutes = Math.floor((duration - (days * 86400) - (hours * 3600)) / 60);
        const secondes = duration - (days * 86400) - (hours * 3600) - (minutes * 60);
        time = days+'j '+ hours+'h '+ minutes+ ' min';
        if (secondes > 0) {
            time += ' '+secondes+' sec';
        }
    }
    return time;
}


function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedDurationReadView = connect(mapStateToProps)(DurationReadView);
export { connectedDurationReadView as DurationReadView };