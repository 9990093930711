import React from 'react';

export default class ProgressionPercentView extends React.PureComponent {

    render() {
        const {currentValue, previousValue, lessIsBetter} = this.props;
    
        const progression = previousValue > 0 ? (100 - Math.round(currentValue / previousValue * 100)) : -Math.round(currentValue * 100)
    
        if (previousValue > currentValue) {
            return <span className={'kpi-value kpi-value-progress kpi-value-progress-' + (lessIsBetter ? 'positif' : 'negatif')}>-{progression}%</span>
        } else if (previousValue < currentValue) {
            return <span className={'kpi-value kpi-value-progress kpi-value-progress-' + (lessIsBetter ? 'negatif' : 'positif')}>+{-progression}%</span>
        } else {
            //quantityProgression = <span className={'kpi-value kpi-value-progress'}>{t('progress.stable')}</span>
        }

        return null
    }

}