import { authHeader, endpoints } from '../_helpers';
import { ObjectsMap } from '../_config';
import { isValidPhone, formatPhone }from '../_helpers';

import moment from 'moment'

export const commonService = {
    cloudInfo,
	getDataView,
    getAll,
	doSearchText,
	sendMediaFile,
	getMediaFileContent,
    getOne,
    create,
	createWithInfos,
    validateItem,
    save,
    deleteItem,
    archiveItem,
    liveAll,
    liveOne,
    liveFull,
    logout,
    sendEvent,
    exportAll,
    saveWorkflowAction,
    loadKpi
};

function getMediaFileContent(mediaId, filename) {

	var url = new URL(`${endpoints.api}/media_content`); 	
	
	const requestOptions = {
        method: 'GET',
		headers: authHeader()
    };

	url.searchParams.append("id", mediaId);

	return fetch(url, requestOptions).then(res => {
		return res.blob().then((blob) => {
			
			// 2. Create blob link to download
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			
			// 3. Append to html page
			document.body.appendChild(link);
			
			// 4. Force download
			link.click();
			
			// 5. Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	});
}

function sendMediaFile(mediaFileForm) {
	
	var url = new URL(`${endpoints.api}/media_file_tmp`); 	
	
	const requestOptions = {
        method: 'POST',
		headers: authHeader(),
		body: mediaFileForm
    };

	return fetch(url, requestOptions).then(handleResponse);
}

const prepareFilterValue = (value) => {
    if (value instanceof Date) {
        return moment(value).format('YYYY-MM-DD');
    } else {
        return value;
    }
}

function loadKpi(kpi, model, object, periode, parts) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    const url = new URL(`${endpoints.api}/kpi/${model.name}/${object ? (object.id + '/') : ''}${kpi}`)
    if (periode) {
        url.searchParams.append('periode', periode.type);
        if (periode.start) {
            url.searchParams.append('start', moment(periode.start).format('DD/MM/YYYY'));
        }
        if (periode.end) {
            url.searchParams.append('end', moment(periode.end).format('DD/MM/YYYY'));
        }
        if (periode.break) {
            url.searchParams.append('periodeBreak', 1);
        }
    }

    if (parts) {
        url.searchParams.append('parts', parts.join(','));
    }

    return fetch(url, requestOptions).then(handleResponse);
}

function getDataView(model, searchInfos) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
	

	var url = new URL(`${endpoints.api}/${model.name}/${searchInfos.id}/${searchInfos.field}`);
	
    return fetch(url, requestOptions).then(handleResponse);
}

function getAll(model, params, endpointSuffix) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };


    const suffixUrl = endpointSuffix ? endpointSuffix : model.name;

    var url = new URL(`${endpoints.api}/${suffixUrl}`);
    
    if (params) {
        Object.keys(params).forEach(key => {
            let filterValue = params[key];
            if (filterValue !== undefined) {
                if (Array.isArray(filterValue)) {
                    filterValue = filterValue.map( v => prepareFilterValue(v) ).join(',');
                } else {
                    filterValue = prepareFilterValue(filterValue);
                }
                url.searchParams.append(key, filterValue);
            }
        })
    }

    return fetch(url, requestOptions).then(handleResponse);
}

function doSearchText(fieldname, text, mainObjectName, mainObjectId) {
	
	 const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    var url = new URL(`${endpoints.api}/text`);
    
    if (mainObjectName && mainObjectId) {
        url.searchParams.append(mainObjectName, mainObjectId);
    }

	url.searchParams.append("fieldname", fieldname);
    url.searchParams.append("text", text);
	
	return fetch(url, requestOptions).then(handleResponse);
}

function exportAll(model, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    var url = new URL(`${endpoints.api}/${model.name}/export`);
    
    if (params) {
        Object.keys(params).forEach(key => {
            let filterValue = params[key];
            if (filterValue !== undefined) {
                if (Array.isArray(filterValue)) {
                    filterValue = filterValue.map( v => prepareFilterValue(v) ).join(',');
                } else {
                    filterValue = prepareFilterValue(filterValue);
                }
                url.searchParams.append(key, filterValue);
            }
        })
    }

    return fetch(url, requestOptions).then(res => {
        
        downloadFile(res, 'export.csv');
       
    });
}

function downloadFile(res, defaultFileName) {

    let fileName = defaultFileName;

        for(let entry of res.headers.entries()) {
            if (entry[0] === 'content-disposition') {
                fileName = entry[1].replace('attachment; filename=', '');
            }
        }
        res.blob().then(function(blob) {
             
        var a = document.createElement('a');
        a.download = fileName;
        a.rel = 'noopener';
        var binaryData = [];
        binaryData.push(blob);
        a.href = window.URL.createObjectURL(new Blob(binaryData, {type: "text/csv"}))
        setTimeout(function () { 
            try {
                a.dispatchEvent(new MouseEvent('click'))
              } catch (e) {
                var evt = document.createEvent('MouseEvents')
                evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
                                      20, false, false, false, false, 0, null)
                a.dispatchEvent(evt)
              }
         }, 0)
          })

}

function liveAll(model, params) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var url = new URL(`${endpoints.api}/live/${model.name}`);

    if (params) {
        Object.keys(params).forEach(key => {
            let filterValue = params[key];
            if (Array.isArray(filterValue)) {
                filterValue = filterValue.join(',');
            }
            url.searchParams.append(key, filterValue);
        })
    }

    return fetch(url, requestOptions).then(handleResponse);
}

function liveFull() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    var url = new URL(`${endpoints.api}/livefull`);
    return fetch(url, requestOptions).then(handleResponse);
}


function cloudInfo() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${endpoints.api.replace('/manager/api', '')}/info`, requestOptions).then(handleResponse);
}

function getOne(itemId, model) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${endpoints.api}/${model.name}/${itemId}`, requestOptions).then(handleResponse);
}

function liveOne(itemId, model) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${endpoints.api}/live/${model.name}/${itemId}`, requestOptions).then(handleResponse);
}


function deleteItem(item, model) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${endpoints.api}/${model.name}/${item.id}`, requestOptions).then(handleResponse);
}

function archiveItem(item, model) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${endpoints.api}/${model.name}/${item.id}`, requestOptions).then(handleResponse);
}

function create(model, parentField, parent, user, context, withDefault, customData) {
	return createWithInfos(model, parentField, parent, null, user, context, withDefault, customData);
}

function createWithInfos(model, parentField, parent, parentModel, user, context, withDefault, customData) {

    let newItem = withDefault ? withDefault : {}
    
    if (parent && parentField) {
        
		newItem[parentField] = parent;
		
    }
	
	newItem = model.createObject ? model.createObject(newItem, user, context, parent, parentModel, customData) : newItem;

    if (model.creation && model.creation.fields) {
        model.creation.fields.forEach( field => {

            const defaultValue = model.fields[field.name].default;
            if (defaultValue) {
                newItem[field.name] = defaultValue;
            }
            
            if (context && field.type === 'context') {
                const contextValue = context[field.field];
                if (contextValue !== undefined) {
                    newItem[field.name] = contextValue;
                }
            }
        });
    }

    return Promise.resolve(newItem);
}

function isFieldEmpty(fieldValue, fieldConfig) {
    return fieldValue === undefined || fieldValue === null || fieldValue.length < 1;
}

function validateField(creation, fieldName, fieldValue, fieldConfig, user, context, subObjectInputFields) {
    
    return new Promise(function(resolve, reject) {
        
        const isEmpty = isFieldEmpty(fieldValue, fieldConfig);

        let error;
            
        if (fieldConfig.required && isEmpty) {
            error = [{message: 'field.required'}];
        }
        
        if (fieldConfig.object && subObjectInputFields) {
            if (error) {
                resolve({field: fieldName, error});
            } else if (!fieldValue || fieldValue.length < 1) {
                resolve();
            } else {

                const subObjectConfig = ObjectsMap[fieldConfig.object];
                if (fieldConfig.array) {
                    return Promise.all(fieldValue.map( value => validateItem(creation, value, subObjectConfig, user, context, subObjectInputFields))).then( errors => {

                        const errorCount = errors.filter(error => error).length;
                        const subobjectError = errorCount > 0 ? {field: fieldName, error: errors} : undefined;

                        resolve(subobjectError);
                    })
                } else {
                    return validateItem(creation, fieldValue, subObjectConfig, user, context, subObjectInputFields).then( errors => {
                        const subobjectError = errors ? {field: fieldName, error: errors} : undefined;
                        resolve(subobjectError);
                    });
                }
            }
        } else {
            
            
            if (!isEmpty) {

                if (!error && fieldConfig.maxLength && fieldValue.length > fieldConfig.maxLength) {
                    error = [{message: 'field.maxLength'}];
                }
                
                if (!error && fieldConfig.type === 'phone' && !isValidPhone(fieldValue)) {
					error = [{message: 'field.badFormat'}];
                }				
				
                if (!error && fieldConfig.regexp && !(''+fieldValue).match(fieldConfig.regexp)) {
                    error = [{message: 'field.badFormat'}];
                }
            }

            resolve({field: fieldName, error});
        }
    });
}

function validateItem(creation, item, model, user, context, withFields) {
    
    return new Promise(function(resolve, reject) {

        const formFields = withFields ? withFields : (creation ? model.creation.fields : model.edition.fields);
    
        const promises = [];
        formFields.forEach( formField => {
	
			if(!formField.not_an_input) {

				const fieldConfig = model.fields[formField.name];
				const fieldValue = item[formField.name];

				let subFields = null;

				if (formField.fields) {
					subFields = formField.fields;
				} else if (fieldConfig.object && fieldConfig.child) {
					const subObjectModel = ObjectsMap[fieldConfig.object];
					subFields = (creation ? subObjectModel.creation.fields : subObjectModel.edition.fields);
					if (subFields) {
						subFields  = subFields.filter( subField => { return (!subField.hidden && subField.type !== 'choice')});
					}
				}

				promises.push(validateField(creation, formField.name, fieldValue, fieldConfig, user, context, subFields));
			}
        });
        return Promise.all(promises).then( results => {
            
            const errors = results.filter( result => result && result.error );
            let ouputErrors;
            if (errors.length > 0) {
                ouputErrors = {fields: {}};
                errors.forEach( error => {
            
                    if (error) {
                        let outputError;
                        if (error && (error.error || error.subobject)) {
                            outputError = {};
                            if (error.error) {
                                outputError.error = error.error;
                            }
                            if (error.subobject) {
                                outputError.subobject = error.subobject;
                            }
                            ouputErrors.fields[error.field] = error.error;
                        } 
                        
                    }
                });
            }
            
            resolve(ouputErrors ? {error: ouputErrors} : undefined);
        });

    });
}

function save(creation, item, model, user, context) {

    return validateItem(creation, item, model, user, context).then( errors => {
        
        if (errors && errors.error) {
            return Promise.reject(errors.error);
        } else {

        let method;
        let url = `${endpoints.api}/${model.name}`;
        let payload = {};
        let fields;
        if (creation) {
            method = 'POST';
            fields = model.creation.fields;
        } else {
            method = 'PATCH';
            url = `${url}/${item.id}`;
            fields = model.edition.fields;
        }

        fields.forEach(field => {
            let fieldValue;
            const fieldUserContext = model.fields[field.name].userContext;
            if (fieldUserContext && context[fieldUserContext]) {
                fieldValue = context[fieldUserContext];
            } else {
                fieldValue = item[field.name];
            }
			
			fieldValue = formatField(fieldValue, model.fields[field.name]);
            payload[field.name] = (fieldValue !== undefined) ? fieldValue : null;
        });

        const requestOptions = {
            method: method,
            headers: {...authHeader(), ...{ 'Content-Type': 'application/json' }},
            body: JSON.stringify(payload),
        };

        return fetch(url, requestOptions)
            .then(handleResponse)
            .then(item => {
                // save successful
                return item;
            });
        }
    });
}

function formatField(fieldValue, fieldConfig) {
	
	var finalValue = fieldValue;
	
	if(fieldValue) {
	
		if(fieldConfig.type === 'phone') {
			finalValue = formatPhone(fieldValue);
		} else if (fieldConfig.type === 'periode') {
            finalValue = {date: fieldValue.start};
            if (fieldValue.periodeType === 'daily') {
                finalValue.type = 1;
            } else if (fieldValue.periodeType === 'weekly') {
                finalValue.type = 2;
            } else if (fieldValue.periodeType === 'monthly') {
                finalValue.type = 3;
            } else if (fieldValue.periodeType === 'yearly') {
                finalValue.type = 4;
            } else {
                finalValue.type = 1;
            }
		}
	}
	
	return finalValue;
}

function saveWorkflowAction(action, targetModel, targetId, item, model, user, context) {

    /*return validateItem(true, item, model, user, context, action.fields).then( errors => {
        
        if (errors) {
            return Promise.reject(errors);
        } else {*/

        let method = 'POST';
        let url = `${endpoints.api}/${targetModel.name}/${targetId}/${action.name}`;
        let payload = {};

        if (action.fields) {
            action.fields.forEach(field => {
                let fieldValue;
                const fieldUserContext = model.fields[field.name].userContext;
                if (fieldUserContext && context[fieldUserContext]) {
                    fieldValue = context[fieldUserContext];
                } else {
                    fieldValue = item[field.name];
                }

                fieldValue = formatField(fieldValue, model.fields[field.name]);

                payload[field.name] = (fieldValue !== undefined) ? fieldValue : null;
            });
        }

        const requestOptions = {
            method: method,
            headers: {...authHeader(), ...{ 'Content-Type': 'application/json' }},
            body: JSON.stringify(payload),
        };

        const fetchPromise = fetch(url, requestOptions);

        if (action.outputType === 'file') {
            return fetchPromise.then(res => {
        
                downloadFile(res, 'export.csv');
                return Promise.resolve({success: true});
            });
        } else {
            return fetchPromise
            .then(handleResponse)
            .then(result => {
                if (result.error) {
                    return Promise.reject(result.error);
                }
                // save successful
                return result;
            });
        }

        
        //}
    //});
}

function sendEvent(event) {

    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), ...{ 'Content-Type': 'application/json' }},
        body: JSON.stringify(event.payload),
    };

    let url = `${endpoints.api}${event.url}`;

    return fetch(url, requestOptions)
    .then(handleResponse)
    .then(result => {
        return result;
    });
}

function logout(sessionExpired) {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    if (sessionExpired) window.location.reload();
}

function handleResponse(response) {
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout(true);
    }
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.error) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}