import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { translate } from 'react-i18next';

import { history } from '../_helpers';
import { ObjectsMap } from '../_config';

import moment from 'moment'

import Filter from './Filter';

import { DurationReadView } from './read/Duration';
import { StateReadView } from './read/State';
import { StationStateReadView } from './read/StationState';
import { StationDispenserStateReadView } from './read/StationDispenserState';
import { QuantityGrammeReadView } from './read/QuantityGramme';
import { DateTimePeriodePicker } from './read/DateTimePeriodePicker';
import { SelectPickerChoice } from './edition/SelectPickerChoice';
import { VehicleComState } from './read/VehicleComState';
import { SocView } from './read/Soc';
import { PosView } from './read/Pos';
import { EnergyTypeView } from './read/EnergyType';
import { LogLevelView } from './read/LogLevel';
import localCache from '../_helpers/local.cache';

function desc(a, b, sortingGetter) {
  if (sortingGetter(b) < sortingGetter(a)) {
    return -1;
  }
  if (sortingGetter(b) > sortingGetter(a)) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  if(!cmp) {
    return array;
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0].item, b[0].item);
    if (order !== 0) return order;
    return a[1].item - b[1].item;
  });
  return stabilizedThis.map(el => el[0]);
}

const dateSorter = (value) => {
  return value ? new Date(value).getTime() : 0;
}

function getSorting(order, orderBy, model) {
  const field = model.fields[orderBy];
  const sortingGetter = field && field.sortingGetter ? field.sortingGetter : field && field.type === 'date' ? o => dateSorter(o[orderBy]) : o => o[orderBy];
  return orderBy ? (order === 'desc' ? (a, b) => desc(a, b, sortingGetter) : (a, b) => -desc(a, b, sortingGetter)) : null;
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { t, classes, onSelectAllClick, order, orderBy, numSelected, rowCount, columns, hasColumnActions, showSelectColumn, showExpandColumn, columnFilters, handleFilterChanged } = this.props;

    return (
      <TableHead>
        <TableRow>
        {showExpandColumn &&
          <TableCell padding="checkbox">
            
          </TableCell>}
          {showSelectColumn &&
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>}
          {columns.map(row => {
            const colFilter = columnFilters[row.id];
            return (
              <TableCell
                key={row.id}
                numeric={row.numeric}
                padding={row.disablePadding ? 'none' : 'dense'}
                sortDirection={orderBy === row.id ? order : false}
                classes={{paddingDense: classes.paddingDense}}
              ><div className="table-header-cell-content">
                <Tooltip
                  title={t('action.sort')}
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {t(row.label)}
                  </TableSortLabel>
                </Tooltip>
                  
                  {colFilter && <Filter cacheRefId={this.props.cacheRefId} row={row} fieldConfig={colFilter.fieldConfig} values={colFilter.values} handleSelectionChanged={(selection) => handleFilterChanged(row.id, selection)}></Filter>}
                </div>
              </TableCell>
            );
          }, this)}
          {hasColumnActions && <TableCell className="table-col-action">
            Actions
          </TableCell>}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  //orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  hasColumnActions: PropTypes.bool.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    zIndex: 20
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flex: '0 0 auto',
  },
  blocSearch: {
    padding: '2px 4px 2px 14px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10
  },
  littleIcon: {
    width: '1rem',
    height: '1rem',
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});


let EnhancedTableToolbar = props => {
  const { t, tableTitle, rowCount, numSelected, classes, addAction, exportAction, customActions, handleUrlAction, loading, filters, onFilterChange, canSearch, textSearched, onSearchChange, onClearSearch } = props;
  const showDelete = false;

  const addButton = addAction ? 
  (<Tooltip title={t('action.add.new')}>
    <IconButton aria-label={t('action.add.new')} onClick={addAction}>
      <AddIcon />
    </IconButton>
  </Tooltip>) : (null);

  const exportButton = exportAction ? (<Tooltip title={t('action.export')}>
  <IconButton aria-label={t('action.export')} onClick={exportAction} disabled={rowCount < 1 ? true : false}>
    <VerticalAlignBottomIcon />
  </IconButton>
</Tooltip>) : (null);

  const customActionsButtons = customActions ? customActions.map(customAction => {
	  return <Tooltip title={customAction.label ? t(customAction.label) : ""}>
				<IconButton aria-label={customAction.label ? t(customAction.label) : ""}
							onClick={() => handleUrlAction(customAction.url)}>
					{customAction.icon}
				</IconButton>
			 </Tooltip>
  }) : null

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {filters && Object.keys(filters).map( filterName => {
        const filter = filters[filterName];
		const now = new Date();
		var defVal = null;
		
        if (filter.type === 'periode') {
	      defVal = filter.default ? (Array.isArray(filter.default()) ? filter.default()[0] : filter.default()) : now;
          return <DateTimePeriodePicker cacheRefId={props.cacheRefId} pickerId={'filter_'+filterName} key={'filter_'+filterName} label={filter.label} onChange={value => onFilterChange(filterName, filter, [value.start, value.end])} defaultDate={defVal} periodeTypes={['daily', 'weekly', 'monthly']} required={filter.required ? true : false} pagination={true} disableFuture={true} disabled={loading ? true : false}/>
        } else if (filter.type === 'date') {
          defVal = filter.default ? (Array.isArray(filter.default()) ? filter.default()[0] : filter.default()) : now;
		  return <DateTimePeriodePicker cacheRefId={props.cacheRefId} pickerId={'filter_'+filterName} key={'filter_'+filterName} label={filter.label} onChange={value => onFilterChange(filterName, filter, [value.start, value.end])} defaultDate={defVal} required={filter.required ? true : false} pagination={true} disableFuture={true} disabled={loading ? true : false}/>
        } else if (filter.type === 'choice') {
		  defVal = filter.default ? (filter.default() != null ? filter.default() : []) : [];
          return <SelectPickerChoice cacheRefId={props.cacheRefId} pickerId={'filter_'+filterName} key={'filter_'+filterName} label={filter.label} onChange={value => onFilterChange(filterName, filter, value)} choices={filter.choicesource} selected={defVal} required={filter.required ? true : false} disabled={loading ? true : false}/>
        } 
        return null;
      })}
  
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} éléments sélectionnés
          </Typography>
        ) : (
          null
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
          
          {canSearch ? <Paper className={classes.blocSearch} elevation={1}>
      
        <SearchIcon />
      
      <InputBase className={classes.input} placeholder="Rechercher" value={textSearched ? textSearched : ''} onChange={(e) => onSearchChange(e.target.value)}/>
      <IconButton className={classes.iconButton} aria-label="Clear" onClick={onClearSearch}>
        <ClearIcon className={classes.littleIcon}/>
      </IconButton>
    </Paper> : null}
        {numSelected > 0 ? showDelete ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (null) : 
          (addButton)}
          {exportButton}
		  {customActionsButtons}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
  buttons: PropTypes.array
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    position: 'relative'
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  loadingBloc: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: '#fafafaa6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  noDataBloc: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  },
  filterBtn: {
    padding: 5,
    color: '#e1e1e1'
  },
  filterBtnOn: {
    color: '#000000'
  },
  paddingDense: {
    padding: '4px 26px 4px 12px'
  },
  colorBtn: {
    color: '#042859' 
  }
});


class EnhancedTable extends React.Component {

  state = {
    order: 'asc',
    orderBy: null,
    selected: this.props.selectedItems ? this.props.selectedItems : [],
    expanded: {},
    data: this.props.rows,
    page: 0,
    rowsPerPage: 10,
    columnFilters: {}
  };

  constructor(props) {
    super(props);

    const table_preferences = JSON.parse(localStorage.getItem('table_preferences'));

    this.state.rowsPerPage = table_preferences && table_preferences.rowsPerPage ? table_preferences.rowsPerPage : 10
  
	this.restoreState();
	
	this.noActions = this.props.noActions;
  }

  saveStateUpdate(toMerge) {
		
	if(toMerge) {
		
		Object.keys(toMerge).forEach((key) => {
			localCache.cacheVal(this.props.tableId, key, toMerge[key]);
		});
	}
  }
  
  restoreState() {
  
	let displayCache = localCache.getCache(this.props.tableId);
	
	if(displayCache) {
		
		if(displayCache.activeColFilter) {
			this.state.activeColFilter = displayCache.activeColFilter;
		}
		
		if(displayCache.page) {
			this.state.page = displayCache.page;
		}
	}
  }
  
  componentWillMount() {
    //this.state.orderBy = this.props.columns[0].id;
    const { model, columns } = this.props;
    
    columns.forEach( col => {
      const fieldConfig = model.fields[col.id];
      if (fieldConfig.tableFilter) {
        this.state.columnFilters[col.id] = { fieldConfig: model.fields[col.id] };
      }
    })
  }

  handleUrlAction(url) {
	history.push(this.props.onBuildActionURL(url));
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleExpand = rowIndex => {
    const newExpanded = this.state.expanded;
    if (newExpanded[rowIndex]) {
      newExpanded[rowIndex] = false;
    } else {
      newExpanded[rowIndex] = true;
    }
    this.setState({ expanded: newExpanded });
  }

  handleSelectAllClick = event => {
    let newSelected;
    if (event.target.checked) {
      newSelected = this.state.data.map(n => n.id);
    } else {
      newSelected = [];
    } 
    if (this.props.handleSelect) {
      this.props.handleSelect(newSelected);
    }
    this.setState({ selected: newSelected });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if (this.props.handleSelect) {
        this.props.handleSelect(newSelected);
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
	this.saveStateUpdate({ page });
    if (this.props.onChangePage) {
      this.props.onChangePage(page);
    }
  };


  handleChangeRowsPerPage = event => {
    const table_preferences = {rowsPerPage: event.target.value};
    localStorage.setItem('table_preferences', JSON.stringify(table_preferences));
    this.setState(table_preferences);
  };

  handleDoubleClick = (event, row) => {
    if (this.props.doubleClickAction) {
      this.props.doubleClickAction(row);
    } else if (this.props.onBuildReadURL) {
      history.push(this.props.onBuildReadURL(row));
    }
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  isExpanded = rowIndex => this.state.expanded[rowIndex];

  renderCellAction(t, classes, hasColumnActions, line, onBuildReadURL, onBuildEditURL, deleteAction, archiveAction) {
    
    let canEdit = onBuildEditURL ? true : false;
    if (canEdit && line._metadata && line._metadata.rights) {
      if (!line._metadata.rights.update) {
        canEdit = false
      }
    }

    let canDelete = deleteAction ? true : false;
    if (canDelete && line._metadata && line._metadata.rights) {
      if (!line._metadata.rights.delete) {
        canDelete = false
      }
    }

    let canArchive = archiveAction ? true : false;
    if (canArchive && line._metadata && line._metadata.rights) {
      if (!line._metadata.rights.archive) {
        canArchive = false
      }
    }

    const cellAction = hasColumnActions ? (<TableCell>
      {onBuildReadURL &&
        <Tooltip title={t('action.read')}>
        <Link to={onBuildReadURL(line)}>
        <IconButton className={classes.colorBtn}>
          <PageviewIcon />
          </IconButton>
          </Link>
        </Tooltip>
      }
      {canEdit &&
        <Tooltip title={t('action.edit')}>
         <Link to={onBuildEditURL(line)}>
         <IconButton className={classes.colorBtn}>
          <EditIcon />
          </IconButton>
          </Link>
        </Tooltip>
      }
      {canDelete &&
        <Tooltip title={t('action.delete')}>
        <IconButton aria-label={t('action.delete')} onClick={() => deleteAction(line)}>
          <DeleteIcon />
        </IconButton>
        </Tooltip>
      }
      {canArchive &&
        <Tooltip title={t('action.delete')}>
        <IconButton aria-label={t('action.delete')} onClick={() => archiveAction(line)}>
          <DeleteIcon />
        </IconButton>
        </Tooltip>
      }
    </TableCell>
    ) : (null);

    return cellAction;
  }

  renderCellSelect(item, isSelected) {
    if (this.props.handleSelect) {
      return (<TableCell padding="checkbox">
      <Checkbox checked={isSelected} onClick={event => this.handleClick(event, item.id)} />
    </TableCell>);
    } else {
      return (null);
    }
  }

  renderCellExpand(rowIndex, isExpanded) {
    
      return (<TableCell padding="checkbox">
        <IconButton
          aria-label="Expand"
          onClick={() => this.handleExpand(rowIndex)}
          >
          {isExpanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
          </IconButton>
    </TableCell>);
    
  }

  prepareTableFilterValues = (t, model, columns, rows) => {

    //

    const colFilterKeys = Object.keys(this.state.columnFilters);
    if (colFilterKeys.length > 0) {

      colFilterKeys.forEach( colFilterKey => {
        this.state.columnFilters[colFilterKey].values = {};
      });

      rows.forEach( row => {
        colFilterKeys.forEach( colFilterKey => {
          const columnFilter = this.state.columnFilters[colFilterKey];

          let rowValue = row[colFilterKey];
          
          if (rowValue !== null && rowValue !== undefined) {
            
            let toDisplayValues;

            if (columnFilter.fieldConfig.array) {
              toDisplayValues = rowValue;
            } else {
              toDisplayValues = [rowValue];
            }

            toDisplayValues.forEach( toDisplayValue => {

              let value;
              let label;
  
              if (columnFilter.fieldConfig.object) {
                value = toDisplayValue.id;
				
                label = ObjectsMap[columnFilter.fieldConfig.object].toString(toDisplayValue, t);
              } else {
                value = toDisplayValue;
                if (columnFilter.fieldConfig.values) {
                  label = t(columnFilter.fieldConfig.values[value]);
                } else if (columnFilter.fieldConfig.type === 'boolean') {
                  label = t((value === true || value === 1 ? 'yes':'no'))
                } else {
                  label = value;
                }
                
              }
  
              if (value !== null && value !== undefined) {
                columnFilter.values[value] = label;
              }

            })

          }
        })
      });
    }

  }

  handleFilterChanged = (colId, selection) => {
    const activeColFilter = this.state.activeColFilter ? this.state.activeColFilter : {};
    if (selection && selection.length > 0) {
      activeColFilter[colId] = selection;
    } else {
      delete activeColFilter[colId];
    }
	localCache.cacheVal(this.props.tableId, "activeColFilter", activeColFilter);
    this.setState({activeColFilter: activeColFilter});
  }

  handleSearchChange = (textSearched) => {
    this.setState({textSearched: textSearched})
  }

  handleClearSearch = () => {
    this.setState({textSearched: null})
  }

  filterRows = rows => {
    
    const { activeColFilter } = this.state;

    if (!activeColFilter) {
      return rows;
    }

    
    const filteredFields = activeColFilter ? Object.keys(activeColFilter) : [];
    const doFilterCol = filteredFields.length > 0;

    if (!doFilterCol) {
      return rows;
    }

    return rows.filter( (row, index) => {

      let rowIsOK = true;

      if (doFilterCol) {
        const filteredValueNOK = filteredFields.filter( filteredField => {
          const rowValue = row[filteredField];
          if (rowValue != null && rowValue != undefined) {
            const value = rowValue.id ? rowValue.id : (''+rowValue);
            return !(activeColFilter[filteredField].indexOf(value) > -1);
          }
          return true
        });

        rowIsOK = filteredValueNOK.length === 0;
      }

      return rowIsOK;

    })

  }

  prepareToRenderRows = (t, model, columns, rows, textSearched) => {
    
    const searchRegExp = textSearched ? new RegExp(textSearched, "i") : null;

    return rows.map( n => {
      
      const toRenderRow = { item: n, displayedValues: {} };
      
      columns.forEach(col => {
      const field = model.fields[col.id];
      
      let value;
      let objValue;
      if (field && field.computed) {
        objValue = field.computed(n, t);
      } else {
        objValue = n[col.id];
      }
	  
      if (field && field.object) {

        if (objValue) {

          const values = field.array ? objValue : [objValue];

          const displayableValues = values.map( obj => {
            let displayableValue = obj ? ObjectsMap[field.object].toString(obj, t) : '';

            if (field && field.type === 'energyType') {
              displayableValue = <EnergyTypeView t={t} value={objValue} />;
            }

            return <span className="array-obj-value">{displayableValue}</span>
          })

          value = <span>{displayableValues}</span>;

          
        } else {
          value = ''
        }

      } else if (field && field.type === 'boolean') {
        value = t((objValue === true || objValue === 1 ? 'yes':'no'));
      } else if (field && field.type === 'date') {
        const momentFormat = field.format ? field.format : 'LLL';
        value = objValue ? moment(objValue).format(momentFormat) : '';
      } else if (field && field.type === 'duration') {
        value = <DurationReadView value={objValue}/>
      } else if (field && field.type === 'state') {
        value = <StateReadView t={t} value={objValue}/>
      } else if (field && field.type === 'stationstate') {
        value = <StationStateReadView t={t} value={objValue} />;
      } else if (field && field.type === 'stationdispenserstate') {
        value = <StationDispenserStateReadView t={t} value={objValue} />;
      } else if (field && field.type === 'quantity-gramme') {
        value = <QuantityGrammeReadView t={t} value={objValue}/>
      } else if (field && field.type === 'vehicleComState') {
        value = <VehicleComState t={t} value={objValue}/>
      } else if (field && field.type === 'soc') {
        value = <SocView t={t} value={objValue} small={true}/>
      } else if (field && field.type === 'pos') {
        value = <PosView t={t} value={objValue} small={true}/>
      } else if (field && field.type === 'energyType') {
        value = <EnergyTypeView t={t} value={objValue} small={true}/>
      } else if (field && field.type === 'loglevel') {
        value = <LogLevelView t={t} value={objValue} />
      } else if (field.values) {
        value = objValue !== undefined && objValue !== null ? t(field.values[objValue]) : null;
      } else if (field && field.type === 'multilabel') {
		  
		  value = <div></div>;
		  
		  if(objValue) {
		  
			  value = <div>
					  {
						objValue.map(label => <div>{t(label)}</div>)
					  }
					  </div>
			  ; 
		  }
		  
      } else {
        value = objValue;//deepFind(n,col.id);
      }

      toRenderRow.displayedValues[col.id] = value;

      if (searchRegExp && !toRenderRow.matchSearch && value && searchRegExp.test(value)) {
        toRenderRow.matchSearch = true;
      }

    })
    
    return toRenderRow;

  });

  }

  filterSearchRows = (toRenderRows, textSearched) => {
    if (!textSearched) {
      return toRenderRows;
    }

    return toRenderRows.filter( toRenderRow => {
      return toRenderRow.matchSearch;
    })
  }

  render() {
    
    const { t, loading, model, tableTitle, rows, columns, classes, withPagination, addAction, onBuildReadURL, onBuildEditURL, onBuildActionURL, deleteAction, archiveAction, exportAction, customActions, ExpandedView, filters, onFilterChange, onChangePage } = this.props;

    this.prepareTableFilterValues(t, model, columns, rows);

    const filteredRows = this.filterRows(rows);
    const toRenderRows = this.prepareToRenderRows(t, model, columns, filteredRows, this.state.textSearched);

    const searchedToRenderRows = this.filterSearchRows(toRenderRows, this.state.textSearched);
    
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, searchedToRenderRows.length - page * rowsPerPage);

    const hasColumnActions = (onBuildReadURL || onBuildEditURL || deleteAction || archiveAction) ? true : false;
    const showSelectColumn = this.props.handleSelect ? true : false;
    const showExpandColumn = ExpandedView ? true : false;

    let addedColumns = 0;
    if (hasColumnActions) {
      addedColumns += 1;
    }

    if (showSelectColumn) {
      addedColumns += 1;
    }

    if (showExpandColumn) {
      addedColumns += 1;
    }

    function deepFind(obj, path) {
      var paths = path.split('.')
        , current = obj
        , i;
    
      for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
          return undefined;
        } else {
          current = current[paths[i]];
        }
      }
      return current;
    }

    let toDisplayRows = searchedToRenderRows && searchedToRenderRows.length > 0 ? stableSort(searchedToRenderRows, getSorting(order, orderBy, model)) : [];
    if (withPagination) {
      toDisplayRows = toDisplayRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
	
    return (
      <div className={classes.root}>
      {((tableTitle && addAction) || filters || exportAction || customActions) &&
		(!this.noActions) &&
			<EnhancedTableToolbar cacheRefId={this.props.tableId} t={t} rowCount={searchedToRenderRows.length} numSelected={selected.length} tableTitle={tableTitle} addAction={addAction} exportAction={exportAction} customActions={customActions} handleUrlAction={(url) => this.handleUrlAction(url)} loading={loading} filters={filters} onFilterChange={onFilterChange} canSearch={this.props.canSearch} onClearSearch={this.handleClearSearch} onSearchChange={this.handleSearchChange} textSearched={this.state.textSearched}/>}
		
		<div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              classes={classes}
              cacheRefId={this.props.tableId}
              columnFilters={this.state.columnFilters}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={searchedToRenderRows.length}
              columns={columns}
			  noActions={this.noActions}
              hasColumnActions={!this.noActions&&hasColumnActions}
              showSelectColumn={showSelectColumn}
              showExpandColumn={showExpandColumn}
              t={t}
              classes={classes}
              handleFilterChanged={(colId, selection) => this.handleFilterChanged(colId, selection)}
            />
            <TableBody>
              {toDisplayRows.map((n, rowIndex) => {
                  
                  const isSelected = this.isSelected(n.item.id);
                  const isExpanded = this.isExpanded(rowIndex);
                  const mainRow = (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={'main_'+rowIndex}
                      selected={isSelected}
                      onDoubleClick={(e) => {this.handleDoubleClick(e, n.item);}}
                    >
                      {showSelectColumn && this.renderCellSelect(n.item, isSelected)}
                      {showExpandColumn && this.renderCellExpand(rowIndex, isExpanded)}
                      {columns.map(col => {
                        
                        return (<TableCell key={'cell_' + col.id} padding={'dense'} numeric={col.numeric} scope="row" classes={{paddingDense: classes.paddingDense}}>{n.displayedValues[col.id]}</TableCell>);
                      })}
                      {!this.noActions&&this.renderCellAction(t, classes, hasColumnActions, n.item, onBuildReadURL, onBuildEditURL, deleteAction, archiveAction)}
                    </TableRow>
                    
                  );

                    if (showExpandColumn) {
                      
                      return [mainRow, (
                        <TableRow key={'expanded_'+rowIndex} className={isExpanded ? 'expanded' : 'collapsed'}><TableCell colSpan={columns.length + addedColumns}><ExpandedView value={n.item}/></TableCell></TableRow>
                      )];
                    }

                  return mainRow;
                })}
              {withPagination && emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={columns.length + addedColumns} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {withPagination && <TablePagination
          component="div"
          count={searchedToRenderRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage={'Lignes par page'}
        />}
        {loading && <div className={classes.loadingBloc}>
          <div>Chargement des données en cours...</div>
        </div>}
        {!loading && searchedToRenderRows.length === 0 && <div className={classes.noDataBloc}>
          <div>Aucune donnée à afficher</div>
        </div>}
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  tableTitle: PropTypes.string.isRequired,
  withPagination: PropTypes.bool,
  addAction: PropTypes.func,
  deleteAction: PropTypes.func
};

export default translate('translations')(withStyles(styles)(EnhancedTable));