import React, { PureComponent } from 'react';

export class LogLevelView extends PureComponent {
    
    render() {
       const { t, value } = this.props;
    
        if (!value) {
            return null;
        }

        let levelName;
        if (value === 0) {
            levelName = 'trace';
        } else if (value === 1) {
            levelName = 'debug';
        }  else if (value === 2) {
            levelName = 'info';
        }  else if (value === 3) {
            levelName = 'analytics';
        }  else if (value === 4) {
            levelName = 'error';
        }  else if (value === 99) {
            levelName = 'crash';
        }
       return <span className={"loglevel-view loglevel-" +levelName}>{t('loglevel-'+levelName)}</span>;
    }
}