import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import UsbIcon from '@material-ui/icons/Usb';
import EditIcon from '@material-ui/icons/Edit';

import { history } from '../../_helpers';
import { commonActions } from '../../_actions';
import { EditObject } from '../common/EditObject';
import Breadcrumb from '../../_components/Breadcrumb';
import { getRights } from '../../_helpers';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem'
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      partTitle: {
          marginTop: '2rem',
          marginBottom: '2rem',
      }
});

class ProfilU2FEdition extends React.Component {

    constructor(props) {
        super(props)
        this.scope = 'main';
        this.state = {expanded: false};
        this.model = this.props.model;
        this.itemId = this.props.match.params.itemId;
        this.rights = getRights(this.props.user, this.scope, this.model.name);
        this.fields = this.model.edition.fields;
    }

    componentWillMount() {
        const { t, user, model } = this.props;
        const breadcrumb = [];
        breadcrumb.push({icon: (<AccountCircle/>), name: 'Profil de ' + user.user.firstName +' '+user.user.lastName, url:'/profil'})
        breadcrumb.push({icon: (<UsbIcon/>), name: 'U2F', url: '/profil/u2f'});
        breadcrumb.push({icon: <EditIcon/>, name: 'edition'});
        this.breadcrumb = breadcrumb;

        this.props.dispatch(commonActions.edit(this.scope, this.itemId, this.model));
    }

    componentWillUnmount() {
        
    }


    handleSubmit = event => {
        const item = this.props.item;
        let redirectionUrl = '/profil/u2f';
        this.props.dispatch(commonActions.save(this.scope, false, item, this.model, redirectionUrl, this.props.user, this.props.context));
    }

    handleCancel = e => {
        history.goBack();
    } 

    handleObjectChange = object => {
        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, object, null, this.model));
    }
    
    render() {
        const { t, user, item, classes } = this.props;

        const breadcrumb = this.breadcrumb;

        const footer = (
            <div>
            <Divider />
            <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center" >
                <Button variant="contained" size="small" className={classes.button} onClick={this.handleCancel}>
                <ArrowBackIcon />
                {t('action.cancel')}
            </Button>
            <Button variant="contained" size="small" className={classes.button} color="primary" onClick={this.handleSubmit}>
                <SaveIcon />
                {t('action.save')}
            </Button>
            </Grid>
            </div>
    );

        return (
            <div>
                <Breadcrumb elements={breadcrumb}/>
                {item &&
                    <div>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <EditObject
                                key="editobject"
                                dispatch={this.dispatch}
                                scope={this.scope + "_object"}
                                fields={this.fields}
                                object={item}
                                model={this.props.model}
                                handleChange={ object => {
                                    this.handleObjectChange(object);
                                }}
                            />
                        </Grid>
                    </Grid>
                    {footer}
                    </div>
                    }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data, authentication } = state;
    
    const { user, context} = authentication;

    return {
        data,
        item: data.main ? data.main.item : null,
        user,
        context
    };
}

const connectedProfilU2FEdition = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ProfilU2FEdition)));
export { connectedProfilU2FEdition as ProfilU2FEdition };