import React, { Component } from 'react';

export class QuantityGrammeReadView extends Component {
    
    render() {
       const { value, html, unit} = this.props;
       if (value) {
           const preparedValue = this.prepare(value, unit);
           if (html) {
                return  <span>{preparedValue.value} <span class="unit">{preparedValue.unit}</span></span>;
            }
            return  preparedValue.value + ' ' + preparedValue.unit;
       } else {
           return ''
       }
    }

    prepare = (value, unit) => {
        if (unit === 'kg') {
            return {value: Math.round(value * 100) / 100, unit: 'Kg'};
        } else if (value < 1000) {
            return {value: Math.round(value), unit: 'g'};
        } else {
            return {value: (Math.round(value / 10) / 100), unit: 'Kg'};
        }
    }
}