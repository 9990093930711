import React, { PureComponent } from 'react';

import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20';
import BatteryCharging30Icon from '@material-ui/icons/BatteryCharging30';
import BatteryCharging50Icon from '@material-ui/icons/BatteryCharging50';
import BatteryCharging60Icon from '@material-ui/icons/BatteryCharging60';
import BatteryCharging80Icon from '@material-ui/icons/BatteryCharging80';
import BatteryCharging90Icon from '@material-ui/icons/BatteryCharging90';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';

export class SocView extends PureComponent {
    
    render() {
       const { value, small } = this.props;
    
        if (!value) {
            return <span className={"soc-view soc-level-unknown"}><BatteryUnknownIcon/> -</span>;
        }

        let icon;
        let socLevel = '';
        if(value >= 99) {
            icon = <BatteryChargingFullIcon/>
            socLevel = 'high';
        } else if(value >= 90) {
            icon = <BatteryCharging90Icon/>
            socLevel = 'high';
        } else if(value >= 80) {
            icon = <BatteryCharging80Icon/>
            socLevel = 'medium';
        } else if(value >= 60) {
            icon = <BatteryCharging60Icon/>
            //if(value >= 80) {
            //    socLevel = 'high';
            //} else {
                socLevel = 'medium';
            //}
        } else if(value >= 50) {
            icon = <BatteryCharging50Icon/>
            socLevel = 'medium';
        } else if(value >= 30) {
            icon = <BatteryCharging30Icon/>
            socLevel = 'low';
        } else {
            icon = <BatteryCharging20Icon/>
            socLevel = 'low';
        }

       return <span className={"soc-view soc-level-" + socLevel+(small ? ' small' : '')}>{icon} {value+' %'}</span>;
    }
}