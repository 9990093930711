import React, { PureComponent } from 'react';

import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	profil: {
		color: "blue",
		fontSize: '10px'
	},
	flexBox: {
		display: "flex",
		flexDirection: "row"
	},
	flexCnt: {
		height: "30px",
		marginLeft: "20px",
		flex: "1 1 auto",
		position: "relative"
	}
});

class Roles extends PureComponent {
    
    render() {
       const { value, state, t, translationKey, profilTranslationKey, classes } = this.props;

       return <div className={classes.flexBox} >
				   {
					   value.map((aVal, index) => { 
							return 	<div className={classes.flexCnt}> 
										<div className={classes.profil}>{t(profilTranslationKey+aVal.profil)}</div>
										<div>{t(translationKey+aVal.name)}</div>
									</div>
						})
				   }
			  </div>;
    }
}


const connectedRoles = withStyles(styles, { withTheme: true })(translate('translations')(Roles));
export { connectedRoles as Roles };