import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import PageviewIcon from '@material-ui/icons/Pageview';
import { commonActions } from '../../_actions';
import ObjectInfoLine from '../../_components/ObjectInfoLine';
import Breadcrumb from '../../_components/Breadcrumb';
import { PopinDialog } from '../../_components/edition/PopinDialog';
import { DataView } from '../../_components/read/DataView';
import { MainList } from '../../_pages/common/MainList';
import { WorkflowActionDialog } from '../../_components/edition/WorkflowAction';
import { ReadObject, CustomReadObject } from './ReadObject';
import { KpiView } from '../../_components/kpi/KpiView';
import { getRights } from '../../_helpers';

import { ObjectsMap } from '../../_config';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    container: {
      padding: '1rem',
      paddingTop: '0.1rem',
    },
    DataViewContainer: {
      
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
    buttonEmbedded: {
        margin: 0,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
    rightBloc: {
        marginBottom: '1rem'
    }
});

const extractLiveFields = model => {
    const liveFields = {};
    Object.keys(model.fields).forEach(field => {
        const fieldConfig = model.fields[field];
        if (fieldConfig.live) {
            const liveField = {...fieldConfig}
            if (liveField.object) {
                liveField.liveFields = extractLiveFields(ObjectsMap[liveField.object]);
            }
            liveFields[field] = liveField;
        }
    });
    return liveFields;
}

class ItemReadPage extends React.Component {

    state = {

    }

    constructor(props) {
        super(props)
		this.dispatch = this.props.dispatch;
		this.scope = this.props.scope;
        this.model = this.props.model;
        this.parentModel = this.props.parent ? ObjectsMap[this.props.model.fields[this.props.parent].object] : null;
        this.itemId = this.props.embedded ? this.props.itemId : this.props.match.params.itemId;
        this.rights = getRights(this.props.user, this.props.context, this.model.name);
        this.fields = this.model.view.fields;
        this.parts = this.model.view.parts;
        this.workflowActions = this.model.view.workflowActions;
		
        let hasFeatures = true;
        if (this.model.liveView && this.model.liveView.features) {
            this.model.liveView.features.forEach( feature => {
                if (!this.props.user.user.features.includes(feature)) {
                    hasFeatures = false;
                }
            })
        }

        this.canShowLiveView = this.model.liveView && (!this.model.liveView.profils || this.model.liveView.profils.find( profil => this.props.user.user.profils.indexOf(profil) > -1)) && hasFeatures
    }

    componentDidMount() {
        
        this.props.liveClient.view(this.itemId, this.model.name, 1)

        this.loadObject();

        const liveFields = extractLiveFields(this.model);
        if (Object.keys(liveFields).length > 0) {
            this.liveTimer = setInterval( () => {
                if (this.props.liveFailed) {
                    clearInterval(this.liveTimer);
                } else {
                    this.props.dispatch(commonActions.liveOne(this.scope, this.itemId, this.model, liveFields));
                }
            }, 1000);
        }
    }

    componentWillUnmount() {
        if (this.liveTimer) {
            clearInterval(this.liveTimer);
        }
        this.props.liveClient.view(this.itemId, this.model.name, 0)
    }

    loadObject() {
        this.props.dispatch(commonActions.readAndSetContext(this.scope, this.itemId, this.model, this.props.user, this.props.context));
    }

    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getItem() {
        const data = this.getData();
        return data.item;
    }

    handleEdit = () => {
        let url = '/edit/' + this.props.uriobject + '/' + this.itemId;
        if (this.props.parent) {
            const item = this.getItem();
            url = '/' + this.props.parent + '/' + item[this.props.parent].id + url;
        }
        history.push(url);
    }

    handleArchive = () => {
        this.props.dispatch({ type:'OPEN_DELETE_DIALOG'});
    }

    handleAction = (url) => {
        url = url.replace(':objectId', this.itemId);
        history.push(url);
    }

    handleWorkflowAction = (workflowAction, model, itemId) => {
        this.setState({workflowAction: workflowAction, workflowActionModel: model ? model : this.model, workflowActionItemId: itemId ? itemId : this.itemId});
    }

    handleShowKpi() {
        let url = '/read/' + this.props.uriobject + '/' + this.itemId + '/kpi';
        if (this.props.parent) {
            const item = this.getItem();
            url = '/' + this.props.parent + '/' + item[this.props.parent].id + url;
        }
        history.push(url);
    }

    handleCancelDialog = () => {
        this.props.dispatch({ type:'CLOSE_DELETE_DIALOG'});
    }

    handleConfirmDialog = () => {
        const item = this.getItem();
        let redirectionUrl;
        if (this.props.parent) {
            //redirectionUrl = '/read/' + this.parentModel.name + '/' + this.props.match.params.parentId;
            redirectionUrl = '/' + this.parentModel.name + '/' + this.props.match.params.parentId + '/list/' + this.props.uriobject;
        } else {
            redirectionUrl = '/list/' + this.props.uriobject;
        }
        this.props.dispatch(commonActions.archiveItem(this.scope, item, this.model, false, redirectionUrl));
        this.props.dispatch({ type:'CLOSE_DELETE_DIALOG'});
    }

    render() {
        const { id, t, classes, embedded, parent, user, context, appcontext, kpi, showDeleteDialog } = this.props;

        const item = this.getItem();
        
        const breadcrumb = [];

        if (parent) {

            if (this.props.user.isServiceProvider) {

                breadcrumb.push({icon: this.parentModel.icon, name: this.parentModel.name + 's', url: parent !== 'fleet' ? ('/list/' + this.parentModel.name) : null});

                if (item && item[parent]) {
                    breadcrumb.push({icon: (null), name: item[parent].name, url: '/read/' + this.parentModel.name + '/' + item[parent].id})
                    breadcrumb.push({icon: this.model.icon, name: this.model.name + 's'});
                    breadcrumb.push({icon: <PageviewIcon/>, name: 'read'});
                }

            } else if (this.props.user.isFleetManager) {

                if (parent !== 'fleet') {
                    breadcrumb.push({icon: this.parentModel.icon, name: this.parentModel.name + 's', url: '/list/' + this.parentModel.name});

                    if (item && item[parent]) {
                        breadcrumb.push({icon: (null), name: item[parent].name, url: '/read/' + this.parentModel.name + '/' + item[parent].id})
                    }
                }

                if (item && item[parent]) {
                breadcrumb.push({icon: this.model.icon, name: this.model.name + 's', url: '/' + this.parentModel.name + '/' + item[parent].id + '/list/' + this.props.uriobject});
                }
                    breadcrumb.push({icon: <PageviewIcon/>, name: 'read'});

            }

            

        } else {
            breadcrumb.push({icon: this.model.icon, name: this.model.name + 's', url: '/list/' + this.props.uriobject});
            breadcrumb.push({icon: <PageviewIcon/>, name: 'read'});
        }

        const objectRights = item && item._metadata && item._metadata.rights ? item._metadata.rights : null;
        const canUpdate = this.rights.update && item && (!objectRights || objectRights.update)
        const canArchive = this.rights.archive && item && (!objectRights || objectRights.archive)

        const header = embedded ? (null) : (
            <div className="page-header">
                <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center" >
                    <Grid item xs={6}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="center" >
                                {item && this.workflowActions && this.workflowActions.map( (workflowAction, index) => 
                                    {
                                        if (this.rights.actions && this.rights.actions[workflowAction.name]) {
                                            let userEnabled = true;
                                            if (objectRights && objectRights.actions) {
                                                userEnabled = objectRights.actions[workflowAction.name]
                                            }
                                            const enabled = userEnabled ? (workflowAction.enabled ? workflowAction.enabled(item) : true) : false;
                                            return enabled ? <Button key={'btn-workflowAction-' + index} variant="contained" size="small" className={classes.button} onClick={() => this.handleWorkflowAction(workflowAction)}>
                                                {workflowAction.icon}
                                                {t('workflowaction.'+workflowAction.name, workflowAction.name)}
                                            </Button> : null;
                                        } else {
                                            return null;
                                        }
                                    }
                                )}
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            container
                            direction="row"
                            justify="flex-end"
                            alignItems="center" >
                                {this.props.actions && this.props.actions.map( (action, index) => 
                                    <Button key={'btn-action-' + index} variant="contained" size="small" className={classes.button} onClick={() => this.handleAction(action.url)}>
                                        {action.icon}
                                        {action.name}
                                    </Button>
                                )}
                                {canUpdate && <Button variant="contained" size="small" className={classes.button} onClick={this.handleEdit}>
                                    <EditIcon />
                                    {t('action.edit')}
                                </Button>}
                                {canArchive && <Button variant="contained" size="small" className={classes.button} onClick={this.handleArchive}>
                                    <DeleteIcon />
                                    {t('action.delete')}
                                </Button>}
                        </Grid>
                    </Grid>
                </Grid>
                
            
            </div>
        );

        const footer = embedded ? (null) : (
                <div className="page-footer">
                <Divider />
                {this.itemId &&
                    <ObjectInfoLine object={item} />
                }
                </div>
        );
		
        const objectContent =  this.props.model.view.component ? (<CustomReadObject
                tag={this.props.model.view.component}
                dispatch={this.dispatch}
                scope={this.scope + "_object"}
                fields={this.fields}
                parts={this.parts}
                object={item}
                model={this.props.model}
                />) : (
            <ReadObject
                dispatch={this.dispatch}
                scope={this.scope + "_object"}
                fields={this.fields}
                parts={this.parts}
                object={item}
				routesConfig={this.props.routesConfig}
                model={this.props.model}
                handleWorkflowAction={this.handleWorkflowAction}
            />
        );

        let liveContent;
        if (this.canShowLiveView && item) {

            const showLiveView = this.props.model.liveView.show ? this.props.model.liveView.show(user, appcontext, context, item) : true;

            if (showLiveView) {

                liveContent =  this.props.model.liveView.component ? (<CustomReadObject
                    tag={this.props.model.liveView.component}
                    dispatch={this.dispatch}
                    scope={this.scope + "_object"}
                    fields={this.props.model.liveView.fields}
                    object={item}
                    model={this.props.model}
                    />) : (<ReadObject
                dispatch={this.dispatch}
                scope={this.scope + "_object"}
                fields={this.props.model.liveView.fields}
                object={item}
                model={this.props.model}
                />)
            }
        }

		let DataViews;
		
		if(this.props.model.view.data_views) {
			
			DataViews = <div className={classes.DataViewContainer}>
			
							{
								this.props.model.view.data_views.map((data_view, index) => {
									return 	<Paper className={[classes.container, classes.rightBloc]} elevation={0}>
												<DataView id={"data-view_"+index}
														  dispatch={this.dispatch}
														  scope={this.scope+"_dataview_"+index}
														  model={this.props.model} 
														  data_view={data_view}
														  item={item}
												/>
											</Paper>
								})
							}
						</div>
		}
		
		let DataMainList;
		
		if(this.props.model.view.main_list) {
			
			const mainList = this.props.model.view.main_list;
			var filteredListName = null;
			
			
			if(mainList.filter_field && item) {
				this.props.match.params.filteredListId = item.id;
				filteredListName = mainList.filter_field;
			}
			
			
			DataMainList = 	<MainList 
								listId={id+"_dataview_list"}
								scope={this.scope+"_dataview_list"} 
								uriobject={this.props.model.view.main_list.uriobject}
								match={this.props.match}
								model={ObjectsMap[this.props.model.view.main_list.uriobject]}
								noActions={true}
								filteredListName={filteredListName}
							/>
        }
        
        const viewKpis = this.rights.kpi && this.props.model.kpis ? this.props.model.kpis.items.filter( item => item.view ) : null
        
        return (
            <div>
                <Breadcrumb menu={this.props.menu} elements={breadcrumb}/>
                {item &&
                <div className="read-item-container">
                {header}
                {viewKpis || this.props.model.liveView || this.props.model.view.kpi || this.props.model.view.data_views ? <Grid container spacing={2}>
                    
                    <Grid item xs={9}>
                        
                        {objectContent}
                        
                    </Grid>
                    
                    <Grid item xs={3}>
                        
                        
                        {viewKpis && <Paper className={[classes.container, classes.rightBloc]} elevation={0}>{viewKpis.map( (kpi, kpiIndex) => <KpiView kpi={kpi} model={this.model} object={item} scope={this.scope} mode={1} onMorePress={kpiIndex === 0 ? () => this.handleShowKpi() : null}/>)}</Paper>}
                        {DataViews}
                        {liveContent &&
                        <Paper className={classes.container} elevation={0}>
                            <div className="live-content">{liveContent}</div>
                        </Paper>}
                    </Grid>
                </Grid> : <Grid container>
                    <Grid item xs={12}>
                        {objectContent}
                    </Grid>
                </Grid>}
				<div>{DataMainList}</div>
                {footer}
                </div>
                }
                {this.state.workflowAction && <WorkflowActionDialog action={this.state.workflowAction} handleClose={(action, done) => {this.setState({workflowAction: null, workflowActionModel: null, workflowActionItemId: null}); if (done && action.needReload) {this.loadObject()}}} user={this.props.user} context={this.props.context} targetModel={this.state.workflowActionModel} targetId={this.state.workflowActionItemId} />}
                {showDeleteDialog && <PopinDialog title={'Confirmation'} intro="Etes-vous sur de vouloir supprimer cet élément ?" validationLabel={t('action.delete')} handleCancel={this.handleCancelDialog} handleValidate={this.handleConfirmDialog} />}
            </div>
        );
        
    }
}

function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext } = state;
    
    const { user, context} = authentication;

    return {
        itemId,
        data,
        user,
        context,
        appcontext,
        liveFailed: data.main ? data.main.liveFailed : null,
        kpi: data.main ? data.main.kpi : null,
        showDeleteDialog: data.showDeleteDialog ? data.showDeleteDialog : false,
        todelete_item: data.todelete_item ? data.todelete_item : null
    };
}

const connectedItemReadPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ItemReadPage)));
export { connectedItemReadPage as ItemReadPage };