import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddInListButton } from '../edition/AddInListButton';

import { ReadObject, CustomReadObject } from '../../_pages/common/ReadObject';

export default class FieldView_ListEmbeddedView extends Component {

    render() {
        const { t, label, items, objectModel, config, rights, handleReadChild, handleAddChild, handleEditChild, handleAction, handleWorkflowAction, user, context, dispatch } = this.props;
        
        if (rights.list && (rights.create || items.length > 0)) {

            return <div className="form-read-field">
                    <div className="list-field-header">
                        <div className="list-field-title">
                        {objectModel.icon}
                        {label}
                        </div>
                        {rights.create && <AddInListButton t={t} onClick={handleAddChild}/>}
                    </div>
                    <div>
                    <div className="embeddedlist-container">
                    {items.map( (item, index) => {
                        return <div key={'item_' + index} className="embeddedlist-item">
                        {config.component ? (<CustomReadObject
                                    tag={config.component}
                                    dispatch={dispatch}
                                    object={item}
                                    model={objectModel}
                                    />) : (
                            <Card>
                                <CardContent>
                                <Typography color="textSecondary">{item.name}</Typography>
                                <div className="embedded_object-content">
                                <ReadObject
                                    dispatch={dispatch}
                                    fields={config.fields}
                                    parts={config.parts}
                                    object={item}
                                    model={objectModel}
                                    />
                                </div>
                                </CardContent>
                                <CardActions disableActionSpacing>
                                    {rights.read &&
                                        <IconButton aria-label={t('action.read')} onClick={() => handleReadChild(item)}>
                                            <PageviewIcon />
                                        </IconButton>
                                    }
                                    {rights.update &&
                                        <IconButton aria-label={t('action.edit')} onClick={() => handleEditChild(item)}>
                                            <EditIcon />
                                        </IconButton>
                                    }
                                    {rights.delete &&
                                        <IconButton aria-label={t('action.delete')}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                    {objectModel.actions &&
                                        objectModel.actions.map( (action, actionIndex) => {
                                            const showAction = action.show ? action.show(item, user, context) : true;
                                            return (
                                                showAction && <IconButton key={'action-' + actionIndex}aria-label={t(action.label)}  onClick={() => handleAction(item, action)}>
                                            {action.icon} <Typography variant="button">{t(action.label)}</Typography>
                                            </IconButton>
                                            );
                                        })
                                    }
                                </CardActions>
                                </Card>)} 
                        </div>
                    })}
                    </div>
                    </div>
                </div>
        }

        return null;
    }
}
