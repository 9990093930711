import { commonConstants } from '../_constants';

function mergeObject(localObject, remoteObject, fields) {
  const keys = fields ? Object.keys(fields) : ((remoteObject && typeof remoteObject === "object") ? Object.keys(remoteObject) : undefined);
  if (keys) {
    keys.forEach( field => {
        const fieldConfig = fields ? fields[field] : undefined;
        const localValue = localObject[field];
        const remoteValue = remoteObject[field];
        if (fieldConfig && fieldConfig.liveOnly) {
          localObject[field] = remoteValue;
        } else {
          if (Array.isArray(localValue)) { 
            const newArray = [];
            localValue.forEach( (v, index) => {
              try {
                mergeObject(v, remoteValue[index], fieldConfig ? fieldConfig.fields : null);
              } catch(err) {
                console.log(err, field, v, index, remoteValue);
              }
              newArray.push(v);
            })
            localObject[field] = newArray;
          } else if (localValue !== null && typeof localValue === "object") {
            mergeObject(localValue, remoteValue, fieldConfig ? fieldConfig.fields : null);
          } else {
            localObject[field] = remoteValue;
          }
        }
    })
  }
}

function updateObject(object, path, newValue) {
  if (path && path.length > 0) {
    const currentPath = path.shift();
    let subObject = object[currentPath];
    if (subObject === undefined || subObject === null) {
      subObject = {};
      object[currentPath] = subObject;
    }
    object[currentPath] = updateObject(object[currentPath], path, newValue);
    return object;
  } else {
    return {...object, ...newValue};
  }
}

function wrapInScope(state, action, object) {
  if (action.scope) {
    var result = {}
    result[action.scope] = object; 
    return {...state, ...result};
  } else {
    return object;
  }
}

function extractStateFromScope(state, action) {
  if (action.scope) {
    let result = state[action.scope];
    if (!result) {
      result = {};
    }
    return result;
  } else {
    return state;
  }
}

function doReduce(state, action) {
  switch (action.type) {
	
	case commonConstants.GET_VIEWDATA_REQUEST: {
		return state;
	}
	
	case commonConstants.GET_VIEWDATA_SUCCESS: {
		
		return {
			...state,
			queryRes: action.items,
			loading: false
		}
	}
	
	case commonConstants.GET_VIEWDATA_FAILURE: {
		return state;
	}
	  
	case commonConstants.SEND_MEDIA_FILE_REQUEST: {
		return state;
	}
		
	case commonConstants.SEND_MEDIA_FILE_SUCCESS: {
		
		return {
			...state,
			item: action.item,
			loading: false
		}
	}
	
	case commonConstants.SEND_MEDIA_FILE_FAILURE: {
		return state;
	} 
	  
	case commonConstants.DOSEARCHTXT_REQUEST: {
		
		return {
			...state,
			queryRes: null,
			loading: true
		}
	}
	
	case commonConstants.DOSEARCHTXT_SUCCESS: {
		
		return {
			...state,
			queryRes: action.items,
			loading: false
		}
	}
	
	case commonConstants.DOSEARCHTXT_FAILURE: {
		
		return {
			...state,
			loading: false
		}
	}
	  
    case commonConstants.GETALL_REQUEST:
    const GETALL_REQUESTresult = {...state, ...{
      loading: true,
      model: action.model
    }};

    if (action.key) {
      const sources = state.sources ? state.sources : {};
      sources[action.key] = null;
      GETALL_REQUESTresult.sources = sources;
    } else{
      GETALL_REQUESTresult.items = null;
    } 

      return GETALL_REQUESTresult;
    case commonConstants.GETALL_SUCCESS:
      const result = {
        model: action.model
      };
      if (action.key) {
        const sources = state.sources ? state.sources : {};
        sources[action.key] = action.items;
        result.sources = sources;
        return {...state, ...result};
      } else{
        result['items'] = action.items;
      } 
      return result;
    case commonConstants.GETALL_FAILURE:
        return {...state, ...{ 
          error: action.error,
          model: action.model
  
        }};
    case commonConstants.DELETE_SUCCESS:
    case commonConstants.ARCHIVE_SUCCESS:
      const afterDeleteState = { 
        model: action.model
      };

      if (action.fromList) {
        afterDeleteState.items = state.items.filter(item => item.id !== action.item.id)
      }

      return afterDeleteState;
      case commonConstants.CREATE_REQUEST:
      return {
        loading: true,
        model: action.model
      };
    case commonConstants.CREATE_SUCCESS:
      return {
        item: action.item,
        model: action.model,
        sources: state.sources
      };
    case commonConstants.CREATE_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
    case commonConstants.READ_REQUEST:
      return {
        loading: true,
        model: action.model
      };
    case commonConstants.READ_SUCCESS:
      return {
        item: action.item,
        model: action.model
      };
    case commonConstants.READ_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
    /*case commonConstants.LOADCONTEXT_REQUEST:
      return {...state, ...{context: null}};
    case commonConstants.LOADCONTEXT_SUCCESS:
      return {...state, ...{context:{parent: action.parent}}};
    case commonConstants.LOADCONTEXT_FAILURE:
      return {...state, ...{context: null}};*/
    case commonConstants.EDIT_REQUEST:
      return {
        loading: true,
        model: action.model
      };
    case commonConstants.EDIT_SUCCESS:
      return {
        item: action.item,
        model: action.model,
        sources: state.sources
      };
    case commonConstants.EDIT_FAILURE:
      return { 
        error: action.error,
        model: action.model
      };
    case commonConstants.UPDATE_ITEM_EDITION_REQUEST:
      let newItem;
      if (action.path && action.path.length > 0) {
        newItem = updateObject(state.item, action.path, action.updatedValues);
      } else {
        newItem = {...state.item, ...action.updatedValues}
      }

      if (action.callback) {
        action.callback(newItem);
      }

      return {
        item: newItem,
        model: action.model,
        sources: state.sources,
        error: state.error,
		saving: state.saving
      };
      case commonConstants.SAVE_REQUEST:
      return {...state, ...{
        saving: true
      }};
    case commonConstants.SAVE_SUCCESS:
      return {
        item: action.item,
        model: action.model
      };
    case commonConstants.WORKFLOWACTION_REQUEST:
      return {...state, ...{error: null, saving: true}};    
    case commonConstants.WORKFLOWACTION_SUCCESS:
      return {...state, ...{workflowActionResult: result, saving: false}};
    case commonConstants.SAVE_FAILURE:
    return {...state, ...{
      saving: false,
      error: action.error
    }};
    case commonConstants.WORKFLOWACTION_FAILURE:
      return {...state, ...{
        saving: false,
        validationErrors: action.validationErrors,
        remoteError: action.remoteError
      }};
    case commonConstants.GO_CREATE_REQUEST:
        return { 
          parent: action.parent
        };
    case commonConstants.LIVEALL_REQUEST:
    case commonConstants.LIVEONE_REQUEST:
        return {
          ...state, ...{livePull: true, liveFailed: false}
        }
    case commonConstants.LIVE_STOP:
        return {
          ...state, ...{livePull: false, liveFailed: false}
        }
    case commonConstants.LIVEALL_SUCCESS:
        if (state.items) {
          const liveresult = {
            model: state.model,
            items: [],
            liveFailed: false
          };
          state.items.forEach(item => {
            mergeObject(item, action.itemsById[item.id], action.fields);
            liveresult.items.push(item);
          });
          return liveresult;
        } else {
          return state;
        }
        
    case commonConstants.LIVEONE_SUCCESS:
        if (state.item) {
          mergeObject(state.item, action.item, action.fields);
        }
        const newState = {...state, ...{liveFailed: false}};
        if (action.item) {
          newState.kpi = action.item.kpi;
        }
        return newState;
    case commonConstants.LIVE_FULL_SUCCESS:
        return {...state, ...{livefull: action.result, liveFailed: false}}
    case commonConstants.LIVEONE_FAILURE:
    case commonConstants.LIVEALL_FAILURE:
        return {...state, ...{liveFailed: true}};
    case 'OPEN_DELETE_DIALOG':
        return {...state, ...{showDeleteDialog: true, todelete_item: action.item}}
    case 'CLOSE_DELETE_DIALOG':
        return {...state, ...{showDeleteDialog: false, todelete_item: null}}
    case commonConstants.LOAD_KPI_REQUEST:

        const requestKpiState = {...state};
        
        if (!requestKpiState.kpis) {
          requestKpiState.kpis = {}
        }

        requestKpiState.kpis[action.kpi+'_loading'] = true

        return requestKpiState;
    case commonConstants.LOAD_KPI_FAILURE:

            const failureKpiState = {...state};
            
            if (!failureKpiState.kpis) {
              failureKpiState.kpis = {}
            }
    
            failureKpiState.kpis[action.kpi+'_loading'] = false
    
            return failureKpiState;
    case commonConstants.LOAD_KPI_SUCCESS:
        
        const newKpiState = {...state};
        
        if (!newKpiState.kpis) {
          newKpiState.kpis = {}
        }

        newKpiState.kpis[action.kpi+'_loading'] = false
        newKpiState.kpis[action.kpi] = action.kpiResult
        if (action.periode) {
          newKpiState.kpis[action.kpi+'_periode'] = action.periode
        } else {
          delete newKpiState.kpis[action.kpi+'_periode']
        }

        return newKpiState;
    default:
      return state
  }
}

export function data(state = {}, action) {

  const newState = wrapInScope(state, action, doReduce(extractStateFromScope(state, action), action));

  return newState;

}