import React from 'react';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import BlocSubPartView from '../common/BlocSubPart';
import ProgressionPercentView from '../common/ProgressionPercent';

class AttemptsSummaryView extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { t, mode, loading, data, previousData } = this.props;
        
        if (loading) {
            return this.renderLoading(t)
        }

        if (data && data.total) {
            return this.renderKpiBloc( t, data.total, previousData.total )
        }

        return <NoDataView mode={mode}/>
    }

    renderLoading = ( t ) => {

        const countContent = <div style={{paddingRight: 20}}><Skeleton/></div>
        const efficiencyContent = <div style={{paddingRight: 20}}><Skeleton/></div>
        const anomaliesContent = <div style={{paddingRight: 20}}><Skeleton/></div>

        return this.__renderBloc(t, countContent, efficiencyContent, anomaliesContent)

    }

    renderKpiBloc = ( t, rawData, previousRawData ) => {
        
        const emptyTotalValue = {count: 0, failedToStart: 0, endError: 0}

        const totalData = rawData.length > 0 ? rawData[0] : emptyTotalValue
        
        const previousTotalData = previousRawData && previousRawData.length > 0 ? previousRawData[0] : emptyTotalValue;
        

        let countProgression;
        let failedToStartProgression;
        let endErrorProgression;
        if (previousTotalData) {
            
            countProgression = <ProgressionPercentView currentValue={totalData.count} previousValue={previousTotalData.count}/>
            failedToStartProgression = <ProgressionPercentView currentValue={totalData.failedToStart} previousValue={previousTotalData.failedToStart}/>
            endErrorProgression = <ProgressionPercentView currentValue={totalData.endError} previousValue={previousTotalData.endError}/>
        }

        const countContent = <div>
                                <span className={'kpi-value kpi-value-main'}>{totalData.count}</span>
                                {countProgression}
                            </div>

        const failedToStartContent = <div>
                                    <span className={'kpi-value kpi-value-main'}>{totalData.failedToStart}</span>
                                    {failedToStartProgression}
                                </div>

        const endErrorContent = <div>
                                    <span className={'kpi-value kpi-value-main'}>{totalData.endError}</span>
                                    {endErrorProgression}
                                </div>

        return this.__renderBloc(t, countContent, failedToStartContent, endErrorContent)

    }

    __renderBloc = ( t, count, failedToStart, endError ) => {

        const content = <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" >
                            <Grid item xs={4}>
                                <div className={'kpi-view-subtitle'}>{t('count')}</div>
                                {count}
                            </Grid>
                            <Grid item xs={4}>
                                <div className={'kpi-view-subtitle'}>{t('failedToStart')}</div>
                                {failedToStart}
                            </Grid>
                            <Grid item xs={4}>
                                <div className={'kpi-view-subtitle'}>{t('endError')}</div>
                            {endError}
                            </Grid>
                        </Grid>

        return <BlocSubPartView content={content}/>
    }

}

const connectedBloc = translate('translations')(AttemptsSummaryView);
export { connectedBloc as AttemptsSummaryView };