import React from 'react';
import { connect } from 'react-redux';
import { history } from '../../_helpers';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

import { commonActions } from '../../_actions';

import { ReadObject, CustomReadObject } from './ReadObject';
import { getRights } from '../../_helpers';


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    container: {
      padding: '1rem',
      paddingTop: '0.1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
    rightBloc: {
        marginBottom: '1rem'
    }
});

class ItemPreviewPage extends React.Component {

    state = {

    }

    constructor(props) {

		super(props);
		
        this.scope = this.props.scope;
        this.model = this.props.model;
        this.itemId = this.props.itemId;
        this.rights = getRights(this.props.user, this.props.context, this.model.name);
        this.fields = this.model.preview ? this.model.preview.fields : [];
        this.parts = this.model.preview ? this.model.preview.parts : [];
	}

    componentDidMount() {
        this.loadObject();
	}
	
	loadObject() {
        this.props.dispatch(commonActions.readAndSetContext(this.scope, this.itemId, this.model, this.props.user, this.props.context));
    }
	
    getItem() {
        const data = this.getData();
        return data.item;
    }

    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

	render() {
		
		const { t, classes, embedded, user, context, appcontext, kpi, showDeleteDialog } = this.props;

		const item = this.getItem();
		
		const objectContent =  this.props.model.view.component ? (<CustomReadObject
				tag={this.props.model.view.component}
				dispatch={this.dispatch}
				scope={this.scope + "_object"}
				fields={this.fields}
				parts={this.parts}
				object={item}
				model={this.props.model}
				/>) : (
			<ReadObject
				dispatch={this.dispatch}
				scope={this.scope + "_object"}
				fields={this.fields}
				parts={this.parts}
				object={item}
				model={this.props.model}
				handleWorkflowAction={this.handleWorkflowAction}
			/>
		);

		return (
			<div>
				{item &&
				<div className="preview-item-container">
				{
					<Grid container spacing={24}>
						<Grid item xs={12}>
							{objectContent}
						</Grid>
					</Grid>
				}
				</div>
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	
    const { data, authentication, appcontext } = state;
    
    const { user, context} = authentication;

    return {
        data,
        user,
        context,
        appcontext
    };
}

const connectedItemPreviewPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ItemPreviewPage)));
export { connectedItemPreviewPage as ItemPreviewPage };