import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider'

import { ChoiceList } from '../ChoiceList';
import { commonActions } from '../../../_actions';

import { ObjectsMap } from '../../../_config';
import { objectsHelper } from '../../../_helpers';

import { TextInput } from '../../../_components/edition/TextInput';
import { SearchText } from '../../../_components/edition/SearchText';
import { Upload } from '../../../_components/edition/Upload';
import { ChoiceListLight } from '../../../_components/edition/ChoiceListLight';
import { U2FRegistration } from '../../../_components/edition/U2FRegistration';
import { TOTPRegistration } from '../../../_components/edition/TOTPRegistration';
import { PhoneInput } from '../../../_components/edition/PhoneInput';
import { LanguageInput } from '../../../_components/edition/LanguageInput';
import { CountryInput } from '../../../_components/edition/CountryInput';
import { DateTimePeriodePicker } from '../../../_components/read/DateTimePeriodePicker';
import { DatePicker } from '../../../_components/edition/DatePicker';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem',
      minWidth: '200px'
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      selectEmpty: {
        marginTop: theme.spacing.unit * 2,
      },
      optgroup: {
		fontSize: "12px",
		color: "#2A87FC",
        fontWeight: 500,
        paddingTop: "2px",
        paddingBottom: "2px",
        opacity: 1
	},
});

class EditObject extends React.Component {

    disableStore = {};

    constructor(props) {
        super(props)
    }

    componentWillMount() {

        this.sourcedFields = [];
        this.reverseDependancyMap = {};
        this.fieldsByName = {};
        this.props.fields.forEach( field => {
            
            this.fieldsByName[field.name] = field;

            if (!field.embedded && (field.type === 'choice' || field.type === 'choicelist' || field.type === 'choicelistlight') && field.sourcetype === 'object') {
                
                const fieldDef = this.props.model.fields[field.name];
                if (!(fieldDef.hideIfEmbedded && this.props.embedded)) {
                    this.sourcedFields.push(field);
                    
                }
            }

            if (field.dependancy) {
                Object.keys(field.dependancy).forEach( targetDependancyFieldName => {
                    let targetDependancyConfig = field.dependancy[targetDependancyFieldName];
                    if (targetDependancyConfig.required) {
                        this.disableStore[field.name] = () => {
                            const v = this.props.object[targetDependancyFieldName];
                            return v === undefined || v === null;
                        }
                    }
                    let targetDependancyList = this.reverseDependancyMap[targetDependancyFieldName];
                    if (!targetDependancyList) {
                        targetDependancyList = {};
                        this.reverseDependancyMap[targetDependancyFieldName] = targetDependancyList;
                    }
                    targetDependancyList[field.name] = {field: field, dependancyConfig: targetDependancyConfig};
                })
            }
            
        });

        this.sourcedFields.forEach( field => {
            const fieldDef = this.props.model.fields[field.name];
            this.loadSource(field, fieldDef);
        })

    }

    loadSource(field, fieldDef, callback) {
        let filters = null;

        if (fieldDef.filter) {
            filters = {};
            fieldDef.filter.forEach( filter => {
                if (filter.path) {
                    filters[filter.field] = objectsHelper.getFieldValue(filter.path, this.props.object, this.props.model);
                } else if (filter.value) {
                    filters[filter.field] = filter.value;
                } else if (filter.mainobject && this.props.data && this.props.data.main && this.props.data.main.item && this.props.data.main.item.id) {
                    filters[this.props.data.main.model.name] = this.props.data.main.item.id;
                }
            });
        }

        if (field.filter) {
            if (filters === null) {
                filters = {};
            }
            field.filter.forEach( filter => {
                if (filter.path) {
                    filters[filter.field] = objectsHelper.getFieldValue(filter.path, this.props.object, this.props.model);
                } else if (filter.value) {
                    filters[filter.field] = filter.value;
                } else if (filter.mainobject && this.props.data && this.props.data.main && this.props.data.main.item && this.props.data.main.item.id) {
                    filters[this.props.data.main.model.name] = this.props.data.main.item.id;
                }
            });
        }
        
        this.props.dispatch(commonActions.getAll(this.props.scope, ObjectsMap[field.source], field.name, filters, (sourceItems) => {
            if (callback) {
                callback(field, fieldDef, sourceItems);
            }
        }, fieldDef.sourceEndpointSuffix ? fieldDef.sourceEndpointSuffix : field.sourceEndpointSuffix));
    }

    checkDependancy(sourceField) {

        let dependancyConfig = this.reverseDependancyMap[sourceField.name];
		
        if (dependancyConfig) {
            Object.keys(dependancyConfig).forEach( fieldDepandancyName => {
                const item = dependancyConfig[fieldDepandancyName];
                if (item.dependancyConfig.sourcefilter) {
                    this.loadSource(item.field, this.props.model.fields[item.field.name], (field, fieldDef, sourceItems) => {
                        const selectedValue = this.props.object[field.name];
                        if (selectedValue && !sourceItems.find( item => item.id === selectedValue.id)) {
                            this.updateObject(field, null);
                        }
                    })
                }
                if (item.dependancyConfig.copy) {
                    if (item.dependancyConfig.copy.fields) {
                        const sourceObject = this.props.object[sourceField.name];
                        if (sourceObject) {
                            const targetObject = this.props.object[item.field.name] ? this.props.object[item.field.name] : {};
                        Object.keys(item.dependancyConfig.copy.fields).forEach( source => {
                            
							var target = item.dependancyConfig.copy.fields[source];
        
							if(typeof target == 'object') {
								
								if(sourceObject[source]) {
									targetObject[target.target] = sourceObject[source];
								} else {
									targetObject[target.target] = target.default;
								}
							} else {
								 targetObject[target] = sourceObject[source];
							}
                        })
						
                        this.updateObject(item.field, targetObject);
                    }
                }
            } })
            /**/
        }
    }
    
    getData() {
        let { scope, data } = this.props;

        if (data && scope) {
            const scopedData = data[scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getSource(fieldName) {
        const data = this.getData();
        const source = data && data.sources && data.sources[fieldName];

        return source;
    }

    makelLabelField = (element, fieldConfig) => {
        let label = fieldConfig.displayName ? this.props.t(fieldConfig.displayName) : this.props.t(element.name);
        if (fieldConfig.required) {
            label += ' *';
        }
        return label;
    }

    makeInputFields = (fields, object, classes, path, headerFields) => {

        const { t, user, appcontext, context, embedded, error } = this.props;
			
		const inputFields = [];
        
        const fieldsErrors = (error && error.fields) || (this.props.embeddedErrors && this.props.embeddedErrors.fields) || (this.props.embeddedErrors && this.props.embeddedErrors.error && this.props.embeddedErrors.error.fields) || {};
        
        if (object) {
            fields.forEach((element, index) => {
                
                let showField = true;
                if (element.show) {
                    showField = element.show(user, appcontext, context, object);
                }

                var globalFieldConfig = this.props.model ? this.props.model.fields[element.name] : {};

				globalFieldConfig = globalFieldConfig ? globalFieldConfig : {};

                if (showField && embedded && globalFieldConfig.hideIfEmbedded) {
                    showField = false;
                }

                if (showField && 
					!element.hidden && 
					!(headerFields&&!element.header_field) && 
					!(!headerFields&&element.header_field)) {
                        
                    const fieldError = fieldsErrors[element.name];
                    
                    const disableField = this.disableStore[element.name];
                        
                    if (element.type === 'structure') {
                        let structValue = object ? object[element.name] : {};
                        structValue = structValue ? structValue : {};

                        const subConfig = ObjectsMap[globalFieldConfig.object];
                        
                        inputFields.push(
                            (<div key={"form_struct_" +index}>
                                    <div className="list-field-header">
                                    <div className="list-field-title">
                                    {subConfig.icon}
                                    {t(element.name)}
                                    </div>
                                    </div>
                                    <div>
                                    <ConnectedEditObject
                                        model={subConfig}
                                        embedded={true}
                                        embeddedErrors={fieldError && fieldError.error}
                                        classes={classes}
                                        fields={element.fields}
                                        object={structValue}
                                        handleChange={ object => {
                                            this.handleChangeSubObject(element, object);
                                        }}
                                    />
                                    </div>
                                </div>
                            )
                        );

                    } else if (element.type === 'object') {
                        let objectValue = object ? object[element.name] : {};
                        objectValue = objectValue ? objectValue : {};
                        
                        const subConfig = ObjectsMap[globalFieldConfig.object];
                        
                        inputFields.push(
                            (<div key={"form_object_" +index}>
                                    <div className="list-field-header">
                                    <div className="list-field-title">
                                    {subConfig.icon}
                                    {t(element.name)}
                                    </div>
                                    </div>
                                    <div>
                                    <ConnectedEditObject
                                        scope={this.props.scope}
                                        model={subConfig}
                                        embedded={true}
                                        embeddedErrors={fieldError}
                                        classes={classes}
                                        fields={element.fields}
                                        object={objectValue}
                                        handleChange={ object => {
                                            this.handleChangeSubObject(element, object);
                                        }}
                                    />
                                    </div>
                                    </div>
                            )
                        );

                    } else if (element.type === 'choice') {
                        let options;
                        
                        let source;
                        
                        let value;
                        let multiple = globalFieldConfig.array ? true : false;
                        
                        

                        if (element.sourcetype === 'object') {
                            source = this.getSource(element.name);
                            const sourceConfig =  ObjectsMap[globalFieldConfig.object];

                            if (multiple) {
                                value = object[element.name] ? object[element.name].map( el => el.id ) : [];
                            } else {
                                value = object[element.name] ? object[element.name].id : '';
                            }

                            const groupField = element.groupField;

                            options = []; 
                            const groupCheck = {};
                            if (source) {
                                const sourceFieldGroupConfig = groupField ? sourceConfig.fields[groupField]: null;
                                source.forEach( (value, index) => {
                                    if (groupField && !groupCheck[value[groupField]]) {
                                        const groupValue = value[groupField];
                                        groupCheck[groupValue] = true;
                                        options.push(<MenuItem disabled className={classes.optgroup} key={'option_group' + element.name + '_' + index} value={'group_'+groupValue}>{t( (sourceFieldGroupConfig.translationKey ? (sourceFieldGroupConfig.translationKey + groupValue) : groupValue), groupValue)}</MenuItem>)
                                    }
                                    const valueLabel = sourceConfig.toString ? sourceConfig.toString(value) : value.name;
                                    options.push(<MenuItem key={'option_' + element.name + '_' + index} value={value.id}>{ t((globalFieldConfig.translationKey ? (globalFieldConfig.translationKey + valueLabel) : valueLabel), valueLabel)}</MenuItem>)

                                })

                            }

                        } else if (globalFieldConfig.values || element.values) {
                            source = globalFieldConfig.values ? globalFieldConfig.values : element.values;
                            value = object[element.name] ? object[element.name] : multiple ? [] : '';
                            options = (Object.keys(source).map( (sourceKey, index) => {
                                const sourceValue = source[sourceKey];
                                return <MenuItem key={'option_' + element.name + '_' + index} value={sourceKey}>{t((globalFieldConfig.translationKey ? (globalFieldConfig.translationKey + sourceValue) : sourceValue), sourceValue)}</MenuItem>;
                            }));
                        } else {
                            source = [];
                            value = object[element.name];
                            options = (null);
                        }
						
						if(!element.fill_then_hide) {
							inputFields.push(
								(<FormControl key={"form_input_" +index} className={classes.formControl} error={fieldError !== undefined}>
										<InputLabel htmlFor="component-simple">{this.makelLabelField(element, globalFieldConfig)}</InputLabel>
								<Select
                                    readOnly={element.readonly ? true : false} 
									disabled={disableField && disableField()}
                                    value={value}
                                    multiple={multiple}
									onChange={ e => {
										this.handleChangeFromChoice(element, source, element.sourcetype, e, element.groupField, element.uniqueByGroup);
									}}
									inputProps={{
										name: element.name,
										id: element.name + '-choice',
									}}
                                    >
									{globalFieldConfig.required && 
										<MenuItem value="" disabled>
										<em>{t('action.choose')}</em>
										</MenuItem>
									}
									{!globalFieldConfig.required && 
										<MenuItem value="">
										<em>{t('none')}</em>
										</MenuItem>
									}
									{options}
								</Select>
								{fieldError && <FormHelperText id="component-error-password">{t(fieldError[0].message)}</FormHelperText>}
								</FormControl>
								)
							);
						}
                    } else if (element.type == 'search_text') {
						
						inputFields.push(<SearchText 
                                            id={"search_text_" + index}
                                            mainObjectName={this.props.data.main.model.name}
                                            mainObjectId={this.props.data.main.item.id}
											scope={this.props.scope}
											dispatch={this.props.dispatch} 
											classes={classes}
											field={element}
											label={element.label ? t(element.label) : this.makelLabelField(element, globalFieldConfig)}
											t={t}
											onChange={(value) => { this.handleChangeWithValue(element, value) }}
										 />);
						
					} else if (element.type === 'choicelist') {

                        let source = this.getSource(element.name);
                        const selectedItems = object[element.name] ? object[element.name] : null;
                        
                        const subConfig = ObjectsMap[globalFieldConfig.object];

                        inputFields.push(
                            (<div key={"form_choicelist_" +index}>
                            <Divider/>
                            <ChoiceList
                                icon={subConfig.icon}
                                title={t(subConfig.name + 's')}
                                inFieldName={element.name}
                                model={subConfig}
                                items={source}
                                selectedItems={selectedItems}
                                handleSelect={selectedItems => {this.handleChangeFromSelect(element, selectedItems)}}
                                canCreate={element.canCreate}
                                embedded={element.embedded}
                                embeddedErrors={fieldError}
                            />
                            </div>)
                        );
                    } else if (element.type === 'choicelistlight') {

                        const source = this.getSource(element.name);
                        const subConfig = ObjectsMap[globalFieldConfig.object];

                        inputFields.push(
                            <ChoiceListLight t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                fieldError={fieldError}
                                onChange={selectedItems => {this.handleChangeFromSelect(element, selectedItems)}}
                                readonly={element.readonly}
                                items={source}
                                selectedItems={object[element.name]}
                                model={subConfig}
                            />
                        );
                    } else if (element.type === 'ownlist') {
                        const items = object[element.name];
                        if (items) {
                            const config = ObjectsMap[globalFieldConfig.object];
                            
                            const itemsFields = items.map( (item, index) => <ConnectedEditObject
                                embedded={true}
                                model={config}
                                key={element.name + '_' + index}
                                classes={classes}
                                fields={item.id ? config['edition'].fields : config['creation'].fields}
                                object={item}
                                handleChange={ object => {
                                    this.handleChangeSubObject(element, object, index);
                                }}
                            />);
                            inputFields.push(
                            <div key={"form_ownlist_" +index}>
                                <Typography>{t(element.name)}</Typography>
                                <Button onClick={ e => {this.handleInsertNewObject(element)}}>Add</Button>
                                {itemsFields}
                            </div>
                            );
                        }
                    } else if (element.type === 'switch' || globalFieldConfig.type === 'boolean') {
                        inputFields.push(
                            <FormControl className={classes.formControl}>
                                <div className="form-input-label">{this.makelLabelField(element, globalFieldConfig)}</div>
                        <FormControlLabel
                        key={"form_input_" +index}
                        control={
                            <Switch
                            checked={object[element.name]}
                            onChange={this.handleChange(element, !(object[element.name] === true))}
                            value={object[element.name]}
                            />
                        }
                        label={t(object[element.name] === true || object[element.name] === 1 ? 'yes' : 'no')}
                        /></FormControl>);
                    } else if (globalFieldConfig.type === 'u2fregistration') {
                        inputFields.push(
                            <U2FRegistration t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                value={object[element.name]}
                                fieldError={fieldError}
                                onChange={(value) => {this.handleChangeWithValue(element, value)}}
                                readonly={element.readonly}
                            />);
                    } else if (globalFieldConfig.type === 'totpregistration') {
                        inputFields.push(
                            <TOTPRegistration t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                value={object[element.name]}
                                fieldError={fieldError}
                                onChange={this.handleChange(element)}
                                readonly={element.readonly}
                            />);
							
                    } else if(element.type === 'phone') {

						inputFields.push(
							<PhoneInput t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                value={object[element.name]}
                                fieldError={fieldError}
                                onChange={value => this.handleChangeWithValue(element, value)}
                                readonly={element.readonly}
							/>
						);

					} else if(element.type === 'language') {

						inputFields.push(
							<LanguageInput t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                value={object[element.name]}
                                fieldError={fieldError}
                                onChange={value => this.handleChangeWithValue(element, value)}
                                readonly={element.readonly}
							/>
						);

					} else if(element.type === 'country') {

						inputFields.push(
							<CountryInput t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                value={object[element.name]}
                                fieldError={fieldError}
                                onChange={value => this.handleChangeWithValue(element, value)}
                                readonly={element.readonly}
							/>
						);

					} else if(element.type === 'date') {

						inputFields.push(
                            <DatePicker
                                t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                value={object[element.name]}
                                onChange={value => this.handleChangeWithValue(element, value)}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                defaultDate={new Date()}
                                fieldError={fieldError}
                                readonly={element.readonly}/>
						);

					} else if(element.type === 'periode') {

						inputFields.push(
                            <DateTimePeriodePicker
                                t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                value={object[element.name]}
                                onChange={value => this.handleChangeWithValue(element, value)}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                defaultDate={new Date()}
                                periodeTypes={['daily', 'weekly', 'monthly']}
                                defaultPeriodeType={'daily'}
                                fieldError={fieldError}
                                initOnMount={true}
                                pagination={true}
                                disableFuture={true}
                                readonly={element.readonly}/>
						);

					} else if(element.type === 'upload') {
						
						var initFilename = "";
						var uploadId = null;
						
						if(object[element.name]) {
							
							uploadId = object[element.name].id;
							
							if(element.filenameField) {
								initFilename = object[element.name][element.filenameField];
							}
						}

						inputFields.push(<Upload 
							classes={classes} 
							uploadId={uploadId}
							initFilename={initFilename}
							onChange={(value) => this.handleChangeWithValue(element, value)}
							label={this.makelLabelField(element, globalFieldConfig)}
							dispatch={this.props.dispatch}
							scope={this.props.scope+"_"+index}
							fieldError={fieldError}
							/>);

					} else {
                        
                        inputFields.push(
                            (<TextInput t={t}
                                key={'form_input_' +index}
                                id={'form_input_' +index}
                                label={this.makelLabelField(element, globalFieldConfig)}
                                value={object[element.name]}
                                fieldError={fieldError}
                                onChange={this.handleChange(element)}
                                readonly={element.readonly}
                            />
                            )
                        );

                    }

                }
            });
        }
		
        return inputFields;

      }

      handleChange = (field, value) => e => {
        this.updateObject(field, (value !== undefined) ? value : e.target.value);
      }

      handleChangeWithValue = (field, value) => {
        this.updateObject(field, value);
      }

      handleChangeFromChoice = (field, source, sourcetype, e, groupField, uniqueByGroup) => {
        
        if (Array.isArray(e.target.value)) {
            if (sourcetype === 'object') {
                let value = source.filter( element => e.target.value.indexOf(element.id) > -1);

                let oldValue = this.props.object[field.name];

                if (groupField && uniqueByGroup && oldValue && oldValue.length > 0) {

                    const byGroupValues = {};
                    value.forEach( v => {
                        let tab = byGroupValues[v[groupField]];
                        if (!tab) {
                            tab = []
                            byGroupValues[v[groupField]] = tab;
                        }
                        tab.push(v)
                    })

                    value = value.filter( v => {
                        const groupValues = byGroupValues[v[groupField]]
                        if (groupValues.length > 1) {
                            return !oldValue.find( oldV => oldV.id === v.id)
                        } else {
                            return true;
                        }
                    })

                }
                console.log(this.props.object[field.name], value)

                this.updateObject(field, value);
            }  else {
                const value = [];
                e.target.value.forEach( v=> {
                    if (source[v]) {
                        value.push(v);
                    }
                })
                this.updateObject(field, value);
            }
            
        }  else {
            const value = sourcetype === 'object' ? source.find( element => element.id === e.target.value) : source[e.target.value] ? e.target.value : null;
            this.updateObject(field, value);
        }
      }

      handleChangeFromSelect = (field, selectedItems) => {
        this.updateObject(field, selectedItems);
    }

      handleChangeSubObject = (field, value, index) => {
        this.updateObject(field, value, index);
    }

    handleInsertNewObject = (field) => {
        this.props.object[field].push({});
        this.notifyChange();
    }

    updateObject = (field, value, index) => {
        if (index >= 0) {
            this.props.object[field.name][index] = value;
        } else {
            this.props.object[field.name] = value;
        }
        this.checkDependancy(field);
        this.notifyChange();
    }

    notifyChange() {
        if (this.props.handleChange) {
            this.props.handleChange(this.props.object);
        }
    }

    render() {
        const { t, classes, object, fields, embedded } = this.props;
        
        const headerFormFields = this.makeInputFields(fields, object, classes, undefined,true);
        const formFields = this.makeInputFields(fields, object, classes, undefined, false);
		
		var result;
        
        const objectErrors = this.props.error && this.props.error.object || this.props.embeddedErrors && this.props.embeddedErrors.object;

		if(headerFormFields.length > 0) {
			result = 
				<div>
					<div>{!embedded ? headerFormFields.map( formField => (<div className="formRow">{formField}</div>)) : headerFormFields}</div>
					<div>{!embedded ? formFields.map( formField => (<div className="formRow">{formField}</div>)) : formFields}</div>
				</div>
		} else {
            result = !embedded ? formFields.map( formField => (<div className="formRow">{formField}</div>)) : formFields;
        }
			
        return objectErrors ? <div><div className="object-error-bloc">{objectErrors.map( objectError => <div>{t(objectError.message, t('error.object.notvalid'))}</div>)}</div>{result}</div> : result;
    }
}

function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext } = state;
    const { user, context } = authentication;
    return {
        itemId,
        data,
        user,
        appcontext,
        context
    };
}

const ConnectedEditObject = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(EditObject)));
export { ConnectedEditObject as EditObject };