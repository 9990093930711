import React, { PureComponent } from 'react';

export default class FieldDisplay extends PureComponent {

    render() {

        const  { label, value, fieldClass, valueClass } = this.props;

        const className = fieldClass ? fieldClass : 'form-read-field';

        return <div className={className}>
                    {label ? <span className={className + '-label'}>{label} : </span> : null}
                    <span className={className + '-value' + (valueClass ? ' '+valueClass : '')}>{value}</span>
                </div>;
    }

}