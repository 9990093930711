import websocket from 'websocket';

import { authHeader, endpoints } from '../_helpers';

export default class LiveCLient {

    constructor() {
        this.backupMessage = null;
    }

    run() {
        this.reconnect = true;
        this.tryConnect()
        console.log('LiveClient is running !')

    }

    view(id, objectName, state) {
        
        const client = this.client;
        const msg = JSON.stringify({code: 1, id: id, objectName: objectName, state: state})
        if (client) {
            client.send(msg)
        } else {
            this.backupMessage = msg
        }
    }

    tryConnect() {

        let user = JSON.parse(localStorage.getItem('user'));

        if (user && user.token) {

            var WebSocketClient = websocket.w3cwebsocket;
            const client = new WebSocketClient(endpoints.websocket, user.token, window.location.hostname, authHeader());
            
            client.onerror= (error) => {
                this.client = null;
                console.error('Connect Error: ' , error);
            };

            client.onopen = () => {
                if (this.backupMessage) {
                    client.send(this.backupMessage)
                }
                this.client = client;
                console.log('WebSocket Client Connected');
            };

            client.onmessage = (message) => {
                console.log('WebSocket message', JSON.parse(message.data));
            };

            client.onclose= (error) => {
                console.error('Connect closed: ' ,error);
                if (this.reconnect) {
                    setTimeout(() => {
                        if (this.reconnect) {
                            this.tryConnect()
                        }
                    }, 5000)
                }
            };

        } else {
            console.error('UNABLE TO INIT LIVEWEB, NO USER')
        }

    }

    disconnect() {
        this.reconnect = false;
        const client = this.client;
        if (client) {
            client.close(1000, 'bye')
        }
    }

}