import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AssessmentIcon from '@material-ui/icons/Assessment';

import Breadcrumb from '../../_components/Breadcrumb';
import { KpiView } from '../../_components/kpi/KpiView';
import { DateTimePeriodePicker } from '../../_components/read/DateTimePeriodePicker';

const styles = theme => ({
    gridCell: {
        alignSelf: 'flex-start'
    },
    formControl: {
        marginRight: '1rem',
        minWidth: '250px'
    }
});


const MainStyledTabs = withStyles(theme => ({
    indicator: {
      backgroundColor: '#042859'
    }
  }))(props => <Tabs {...props} />);


  const MainStyledTab = withStyles(theme => ({
    selected: {
        backgroundColor: '#001435',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '5px 5px 0 0',
    }
  }))(props => <Tab disableRipple {...props} />);

const StyledTabs = withStyles(theme => ({
    indicator: {
      display: 'none'
    },
  }))(props => <Tabs {...props} />);

const StyledTab = withStyles(theme => ({
    root: {
      textTransform: 'none',
      color: '#042859',
      borderBottom: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      borderRadius: theme.typography.pxToRem(5),
      opacity: 1
    },
    selected: {
        backgroundColor: '#f7faff',  
    },
  }))(props => <Tab disableRipple {...props} />);

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 0 }}>
        {props.children}
      </Typography>
    );
}

class KpiListPage extends React.Component {

    constructor(props) {
        super(props)
        this.dispatch = this.props.dispatch;
		this.scope = this.props.scope;
        
        this.defaultDate = new Date();
        this.state = {...{periodeType: 'daily', subPeriodeType: 'hourly', tab0: 0, tab1: 0}};
    }
    
    componentDidMount() {
        
    }

    handleDatePeriodeChange = config => {
        this.periode = {type: config.periodeType, start: config.start, end: config.end, break: true}
        this.setState(config);
    };

    handleChangeTab = (event, tabId, newValue) => {
        const newState = {};
        newState['tab'+tabId] = newValue
        this.setState(newState);
    }

    render() {

        const { t, classes, data, parent, user, context, appcontext, kpis } = this.props;

        const tabLevel0 = this.state['tab0']
        const tabLevel1 = this.state['tab1']

        const breadcrumb = [];

        breadcrumb.push({icon: <AssessmentIcon/>, name: 'kpi'});
 
        return <div>
            <Breadcrumb menu={this.props.menu} elements={breadcrumb}/>
            <div className="pageContent">
                <div className="kpi-filter-bloc">
                    <DateTimePeriodePicker value={this.state.start} onChange={this.handleDatePeriodeChange} label={t('Periode')} defaultDate={this.defaultDate} periodeTypes={['daily', 'last7days', 'weekly', 'last14days', 'last30days', 'monthly', 'last90days', 'trimester', 'semester', 'yearly']} defaultPeriodeType={'daily'} initOnMount={true} pagination={true} disableFuture={true} />
                </div>
                
                <MainStyledTabs
                value={tabLevel0}
                onChange={(e, v) => this.handleChangeTab(e, 0, v)}
                >
                    {kpis.map( kpiItem => {
                        return <MainStyledTab
                        label={t(kpiItem.kpi.name)}
                        />
                        })
                    }
            </MainStyledTabs>
            {this.periode && kpis.map( (kpiItem, kpiIndex) => {
                        return tabLevel0 === kpiIndex ? this.__renderKpiTab(t, kpiItem, kpiIndex, tabLevel1) : null
                        })
                    }
            </div>
        </div>;
    } 

    __renderKpiTab = (t, kpiItem, kpiIndex, tabLevel1) => {
        return <TabContainer key={'tab_' + kpiIndex}>
            <div className="bloc-container">
            {kpiItem.kpi.full.parts &&<StyledTabs
                /*orientation="vertical"*/
                value={tabLevel1}
                onChange={(e, v) => this.handleChangeTab(e, 1, v)}
                centered
                >
                    {kpiItem.kpi.full.parts.map( part => {
                        return <StyledTab
                        label={t(part.name)}
                        icon={part.icon}
                        />
                        })
                    }
            </StyledTabs>}
            {kpiItem.kpi.full.parts.map( (part, partIndex) => {
                return tabLevel1 === partIndex ? <TabContainer key={'tab_part_' + partIndex}>
                    <KpiView kpi={kpiItem.kpi} periode={this.periode} mode={2} model={kpiItem.model} scope={'kpi'} parts={[part.type]}/> 
                </TabContainer> : null
                })
            }
            </div>
        </TabContainer>
    }

}


function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext, kpi } = state;
    
    const { user, context} = authentication;

    return {
        itemId,
        data,
        user,
        context,
        appcontext,
        kpi
    };
}

const connectedKpiListPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(KpiListPage)));
export { connectedKpiListPage as KpiListPage };