import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import DoughnutChartView from '../common/DoughnutChart';
import PieChartLoadingView from '../common/PieChartLoading';

import { DurationReadView } from '../../read/Duration';

const BACKGROUND_COLORS = ['#ff000061', '#ffe6aa', '#7dff81', '#ff000061', '#ffe6aa', '#7dff81']
const BORDER_COLORS = ['#ff0000ab', '#fecf5f', '#00f107', '#ff0000ab', '#fecf5f', '#00f107']

const COLORS = BACKGROUND_COLORS.map( (value, index) => {
    return {backgroundColor: value, borderColor: BORDER_COLORS[index]} 
})

class AvailabilitySummaryPieView extends React.Component {

    render() {

        const { t, mode, loading, data, previousData } = this.props;
        
        if (loading) {
            return <PieChartLoadingView/>
        }

        if (data && data.total && data.total.length > 0) {
            return this.renderKpiBloc( t, data.total, previousData.total )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData, previousRawData ) => {

        const poss = []
        const labels = [t('state.open'), t('state.partial'), t('state.breakdown'), t('state.maintenance'), t('state.closed'), t('state.shutdown')]
        const colors = []
        

        

        const kpiRow = rawData[0]
        const previousRow = previousRawData && previousRawData.length > 0 ? previousRawData[0] : {}

        const values = [kpiRow.openDuration, kpiRow.partialDuration, kpiRow.breakdownDuration, kpiRow.maintenanceDuration, kpiRow.closedDuration, kpiRow.shutdownDuration]
        const previousValues = [previousRow.openDuration, previousRow.partialDuration, previousRow.breakdownDuration, previousRow.maintenanceDuration, previousRow.closedDuration, previousRow.shutdownDuration]

        const valueLabels = values.map( (value, index) => {
            return <DurationReadView t={t} value={value}/>
        })

        const progressionValues = values.map( (value, index) => {
            return KpiTools.computeProgression(value, previousValues[index])
        })

        const options = KpiTools.makeCustomTooltips(labels, valueLabels, COLORS.map( color => { return {backgroundColor: color, borderColor: color}}), progressionValues)

        const data = {
            labels: labels,
            datasets: [
            {
                data: values,
                backgroundColor: BACKGROUND_COLORS
            }
            ]
        };

        return <DoughnutChartView data={data} options={options}/>

    }

}

const translatedBloc = translate('translations')(AvailabilitySummaryPieView);
export { translatedBloc as AvailabilitySummaryPieView };