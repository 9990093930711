import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import AssessmentIcon from '@material-ui/icons/Assessment';
import PageviewIcon from '@material-ui/icons/Pageview';

import { commonActions, kpiActions } from '../../_actions';
import Breadcrumb from '../../_components/Breadcrumb';
import { KpiView } from '../../_components/kpi/KpiView';
import { DateTimePeriodePicker } from '../../_components/read/DateTimePeriodePicker';

import { getRights } from '../../_helpers';

import { ObjectsMap } from '../../_config';

const styles = theme => ({
    gridCell: {
        alignSelf: 'flex-start'
    },
    formControl: {
        marginRight: '1rem',
        minWidth: '250px'
    }
});


const MainStyledTabs = withStyles(theme => ({
    indicator: {
      backgroundColor: '#042859'
    }
  }))(props => <Tabs {...props} />);


  const MainStyledTab = withStyles(theme => ({
    selected: {
        backgroundColor: '#001435',
        color: 'white',
        fontWeight: 'bold',
        borderRadius: '5px 5px 0 0',
    }
  }))(props => <Tab disableRipple {...props} />);

const StyledTabs = withStyles(theme => ({
    indicator: {
      display: 'none'
    },
  }))(props => <Tabs {...props} />);

const StyledTab = withStyles(theme => ({
    root: {
      textTransform: 'none',
      color: '#042859',
      borderBottom: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      borderRadius: theme.typography.pxToRem(5),
      opacity: 1
    },
    selected: {
        backgroundColor: '#f7faff',  
    },
  }))(props => <Tab disableRipple {...props} />);

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 0 }}>
        {props.children}
      </Typography>
    );
}

class KpiItemPage extends React.Component {

    constructor(props) {
        super(props)
        this.dispatch = this.props.dispatch;
		this.scope = this.props.scope;
        this.model = this.props.model;
        this.parentModel = this.props.parent ? ObjectsMap[this.props.model.fields[this.props.parent].object] : null;
        this.itemId = this.props.embedded ? this.props.itemId : this.props.match.params.itemId;
        this.rights = getRights(this.props.user, this.props.context, this.model.name);
        this.defaultDate = new Date();
        this.kpis = this.rights.kpi && this.props.model.kpis ? this.props.model.kpis.items.filter( item => item.full ) : null

        const tabLevel1 = {};

        this.kpis.forEach( (kpi, index) => {
            tabLevel1[index] = 0
        })

        this.state = {periodeType: 'daily', subPeriodeType: 'hourly', tabLevel0: 0, tabLevel1: tabLevel1};
    }
    
    componentDidMount() {
        this.props.dispatch(commonActions.readAndSetContext('main', this.itemId, this.model, this.props.user, this.props.context));
    }

    handleDatePeriodeChange = config => {
        this.periode = {type: config.periodeType, start: config.start, end: config.end, break: true}
        this.setState(config);
    };

    handleChangeTabLevel0 = (event, newValue) => {
        this.setState({tabLevel0: newValue});
    }

    handleChangeTabLevel1 = (event, tabLevel0, newValue) => {
        const tabLevel1 = this.state.tabLevel1;
        tabLevel1[tabLevel0] = newValue
        this.setState({tabLevel1: tabLevel1});
    }

    render() {

        const { t, classes, data, parent, user, context, appcontext, kpi } = this.props;

        const tabLevel0 = this.state['tabLevel0']
        const tabLevel1 = this.state['tabLevel1'][tabLevel0]

        this.breadcrumb = [];

        const item = data && data.main && data.main.item ? data.main.item : null

        const breadcrumb = [];

        if (parent) {

            if (user.isServiceProvider) {

                breadcrumb.push({icon: this.parentModel.icon, name: this.parentModel.name + 's', url: parent !== 'fleet' ? ('/list/' + this.parentModel.name) : null});

                if (item && item[parent]) {
                    breadcrumb.push({icon: (null), name: item[parent].name, url: '/read/' + this.parentModel.name + '/' + item[parent].id})
                    breadcrumb.push({icon: this.model.icon, name: this.model.name + 's'});
                    breadcrumb.push({icon: <AssessmentIcon/>, name: 'kpi'});
                }

            } else if (user.isFleetManager) {

                if (parent !== 'fleet') {
                    breadcrumb.push({icon: this.parentModel.icon, name: this.parentModel.name + 's', url: '/list/' + this.parentModel.name});

                    if (item && item[parent]) {
                        breadcrumb.push({icon: (null), name: item[parent].name, url: '/read/' + this.parentModel.name + '/' + item[parent].id})
                    }
                }

                if (item && item[parent]) {
                breadcrumb.push({icon: this.model.icon, name: this.model.name + 's', url: '/' + this.parentModel.name + '/' + item[parent].id + '/list/' + this.props.uriobject});
                }
                    breadcrumb.push({icon: <AssessmentIcon/>, name: 'kpi'});

            }

            

        } else {
            breadcrumb.push({icon: this.model.icon, name: this.model.name + 's', url: '/list/' + this.props.uriobject});
            breadcrumb.push({icon: <AssessmentIcon/>, name: 'kpi'});
        }
 
        return <div>
            <Breadcrumb menu={this.props.menu} elements={breadcrumb}/>
            <div className="pageContent">
                <div className="kpi-filter-bloc">
                    <DateTimePeriodePicker value={this.state.start} onChange={this.handleDatePeriodeChange} label={t('Periode')} defaultDate={this.defaultDate} periodeTypes={['daily', 'last7days', 'weekly', 'last14days', 'last30days', 'monthly', 'last90days', 'trimester', 'semester', 'yearly']} defaultPeriodeType={'daily'} initOnMount={true} pagination={true} disableFuture={true} disabled={kpi && kpi.loading} />
                </div>
                
                <MainStyledTabs
                value={tabLevel0}
                onChange={(e, v) => this.handleChangeTabLevel0(e, v)}
                >
                    {this.kpis.map( kpi => {
                        return <MainStyledTab
                        label={t(kpi.name)}
                        />
                        })
                    }
            </MainStyledTabs>
            {(item || !this.itemId) && this.periode && this.kpis.map( (kpi, kpiIndex) => {
                        return tabLevel0 === kpiIndex ? this.__renderKpiTab(t, kpi, kpiIndex, item, tabLevel0, tabLevel1) : null
                        })
                    }
            </div>
        </div>;
    } 

    __renderKpiTab = (t, kpi, kpiIndex, item, tabLevel0, tabLevel1) => {
        return <TabContainer key={'tab_' + kpiIndex}>
            <div className="bloc-container">
            {kpi.full.parts &&<StyledTabs
                /*orientation="vertical"*/
                value={tabLevel1}
                onChange={(e, v) => this.handleChangeTabLevel1(e, tabLevel0, v)}
                centered
                >
                    {kpi.full.parts.map( part => {
                        return <StyledTab
                        label={t(part.name)}
                        icon={part.icon}
                        />
                        })
                    }
            </StyledTabs>}
            {kpi.full.parts.map( (part, partIndex) => {
                return tabLevel1 === partIndex ? <TabContainer key={'tab_part_' + partIndex}>
                    <KpiView kpi={kpi} periode={this.periode} mode={2} model={this.model} object={item} scope={'kpi'} parts={[part.type]}/> 
                </TabContainer> : null
                })
            }
            </div>
        </TabContainer>
    }

}


function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext, kpi } = state;
    
    const { user, context} = authentication;

    return {
        itemId,
        data,
        user,
        context,
        appcontext,
        kpi
    };
}

const connectedKpiItemPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(KpiItemPage)));
export { connectedKpiItemPage as KpiItemPage };