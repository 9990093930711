import React, { Component } from 'react';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';

import { userActions } from '../../_actions';

class U2FRegistration extends Component {
    
    handleAddKey = () => {
        this.props.dispatch(userActions.requestU2FForUser( registrationResult => {
            if (this.props.onChange) {
                this.props.onChange(registrationResult);
            }
        }));
    }

    render() {
    
       const { t, id, label, value, fieldError, readonly, onChange, authentication } = this.props;

       return (
           <div className="form-edit-field">
            <div>Associé : {value ? 'oui' : 'non'}</div>
            <Button variant="contained" size="small" disabled={authentication.waitingU2FRequest || authentication.u2frequest} onClick={() => this.handleAddKey()}>Associer</Button>
            {authentication.u2frequest && <span> Appuyez sur votre clé clignotante pour l'associer</span>}
            </div>
       );
    }
}

function mapStateToProps(state) {
    const { itemId, data, authentication } = state;
    
    return {
        itemId,
        data,
        authentication
    };
}

const connectedU2FRegistration = connect(mapStateToProps)(U2FRegistration);
export { connectedU2FRegistration as U2FRegistration };