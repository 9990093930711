import React, { PureComponent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import WebIcon from '@material-ui/icons/Web';
import {BadgeIcon} from '../icons/badge';
 
export class PosView extends PureComponent {
    
    render() {
       const { t, value, small } = this.props;
    
        if (!value) {
            return null;
        }

        let icon;
        let text;
        if (value === 'fillndrive.app') {
            icon = <Tooltip title={t('pos.'+value, value)}><SmartphoneIcon/></Tooltip>
            text = small ? null : t('pos.'+value, value)
        } else if (value === 'fillndrive.badge') {
            icon = <Tooltip title={t('pos.'+value, value)}><BadgeIcon /></Tooltip>
            text = small ? null : t('pos.'+value, value)
        } else if (value === 'fillndrive.manager') {
            icon = <Tooltip title={t('pos.'+value, value)}><WebIcon/></Tooltip>
            text = small ? null : t('pos.'+value, value)
        } else {
            text = t('pos.'+value, value);
        }

       return <span className={"pos-view"}>{icon}{text}</span>;
    }
}