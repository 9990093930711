import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

class DriverView extends Component {

    render() {

     const { t, driver} = this.props;
       return (
           <div>
            <div>{t('firstName')} : {driver.firstName}</div>
            <div>{t('lastName')} : {driver.lastName}</div>
           </div>
        )
    }

    
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    const driver = data.main.item;
    return {
        itemId,
        data,
        driver
    };
}

const connectedUserView = connect(mapStateToProps)(translate('translations')(DriverView));
export { connectedUserView as DriverView };