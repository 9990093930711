import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment'

class DateReadView extends Component {
    
    render() {
    
       const { value, format } = this.props;

        const momentFormat = format ? format : 'LLL';

       return value ? moment(value).format(momentFormat) : '-';
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedDateReadView = connect(mapStateToProps)(DateReadView);
export { connectedDateReadView as DateReadView };