import React, { Component } from 'react';

import { ObjectsMap } from '../../_config';
import { getRights } from '../../_helpers';

import FieldViewSimple from './FieldViewSimple';
import { FieldPreview } from './FieldPreview';
import FieldView_Structure from './FieldView_Structure';
import FieldView_ListLight from './FieldView_ListLight';
import FieldView_ListEmbeddedView from './FieldView_ListEmbeddedView';
import FieldView_List from './FieldView_List';
import FieldView_ListChildren from './FieldView_ListChildren';

export default class FieldView extends Component {
    
    componentWillMount() {
        const { t, objectModel, field, user, context } = this.props;
        
        this.fieldConfig = objectModel ? objectModel.fields[field.name] : {};
        
        if (this.fieldConfig.computed) {
            this.valueGetter = source => {return source ? this.fieldConfig.computed(source, t) : null};
        } else {
            this.valueGetter = source => {
				
				var value = source ? source[field.name] : null;
				
				if(value != null && typeof value === 'object' && value.constructor === Object) {
					value.preview = field.preview;
				}
				
				return value;
			};
        }

        if (field.type === 'structure' || this.fieldConfig.object) {
            this.subObjectModel = ObjectsMap[this.fieldConfig.object];
        }

        if (this.fieldConfig.object) {
            this.subObjectRights = this.subObjectModel.defaultRights ? this.subObjectModel.defaultRights : getRights(user, context, this.subObjectModel.name);
        }

        this.label = this.makeFieldName(field.name, this.fieldConfig);
    }

    makeFieldName = (fieldName, fieldConfig) => {
        return fieldConfig.displayName ? this.props.t(fieldConfig.displayName) : this.props.t(fieldName);
    }

    render() {
		
        const { t, object, field, objectModel, user, appcontext, context, handleAddChild, handleReadChild, handleEditChild, handleAction, handleWorkflowAction, handleBuildReadUrl, classes } = this.props;

        const showField = field.show ? field.show(user, appcontext, context, object) : true;
        if (!showField) {
            return null;
        }

        const fieldValue = this.valueGetter(object);

		
        if (((fieldValue === null) || (fieldValue === undefined)) && field.hideIfEmpty) {
            return null;
        }
        
        const previewModel = ObjectsMap[this.fieldConfig.object];
		const allowPreview = fieldValue && fieldValue.preview && getRights(user, context, previewModel.name).list;
		
        if (field.type === 'structure') {

            const structValue = fieldValue ? fieldValue : {};
            return <FieldView_Structure label={this.label} value={structValue} fields={field.fields} objectModel={this.subObjectModel} dispatch={this.props.dispatch} classes={classes}/>;

        } else if (field.type === 'listlight') {

            const arrayItems = fieldValue ? fieldValue : [];
            return <FieldView_ListLight t={t} label={this.label} items={arrayItems} classes={classes}/>

        } else if (field.type === 'list' && field.embeddedView) {

            const arrayItems = fieldValue ? fieldValue : [];
            return <FieldView_ListEmbeddedView t={t}
                label={this.label}
                items={arrayItems}
                rights={this.subObjectRights}
                objectModel={this.subObjectModel} dispatch={this.props.dispatch}
                config={this.subObjectModel.viewEmbedded}
                fields={this.subObjectModel.viewEmbedded.fields}
                parts={this.subObjectModel.viewEmbedded.parts}
                user={user}
                context={context}
                handleAddChild={() => handleAddChild(this.subObjectModel)}
                handleReadChild={(child) => handleReadChild(this.subObjectModel, child)}
                handleEditChild={(child) => handleEditChild(this.subObjectModel, child)}
                handleAction={(child, action) => handleAction(this.subObjectModel, child, action)}
                handleWorkflowAction={handleWorkflowAction}
                classes={classes}/>
        } else  if (field.type === 'list') {

            const arrayItems = fieldValue ? fieldValue : [];
            return <FieldView_List t={t}
                label={this.label} items={arrayItems} rights={this.subObjectRights}
                objectModel={this.subObjectModel} dispatch={this.props.dispatch}
                fields={field.fields ? field.fields : this.subObjectModel.list.fields}
                user={user}
                context={context}
                handleAddChild={() => handleAddChild(this.subObjectModel)}
                handleBuildReadChildURL={(child) => handleBuildReadUrl(this.subObjectModel, child)}
                handleEditChild={(child) => handleEditChild(this.subObjectModel, child)}
                handleAction={(child, action) => handleAction(this.subObjectModel, child, action)}
                classes={classes}/>
        } else if (field.type === 'embeddedlist') {

            const arrayItems = fieldValue ? fieldValue : [];
            return <FieldView_ListChildren t={t}
                label={this.label} items={arrayItems} rights={this.subObjectRights}
                objectModel={this.subObjectModel} dispatch={this.props.dispatch}
                fields={this.subObjectModel.viewEmbedded.fields}
                user={user}
                context={context}
                handleAddChild={() => handleAddChild(this.subObjectModel)}
                handleReadChild={(child) => handleReadChild(this.subObjectModel, child)}
                handleEditChild={(child) => handleEditChild(this.subObjectModel, child)}
                handleAction={(child, action) => handleAction(this.subObjectModel, child, action)}
                handleWorkflowAction={handleWorkflowAction}
                classes={classes}/>
        } else if(fieldValue && fieldValue.preview && allowPreview){

			return <FieldPreview 
						scope={this.props.scope+"_preview_"+previewModel.name+"_"+fieldValue.id} 
						parent={object} 
						label={this.label} 
						value={fieldValue} 
						fieldConfig={this.fieldConfig} 
						subObjectModel={this.subObjectModel}
						model={previewModel}
						routesConfig={this.props.routesConfig}
						dispatch={this.props.dispatch} />
		} else {
			
			var finalVal = (this.fieldConfig.type=="label") ? t(fieldValue) : fieldValue;
			
            return <FieldViewSimple classes={classes} t={t} label={this.label} value={finalVal} fieldConfig={this.fieldConfig} subObjectModel={this.subObjectModel} dispatch={this.props.dispatch}/>
        }
    }
}
