import { ObjectsMap } from '../_config';

export const objectsHelper = {
    getFieldValue
};

function getFieldValue(fieldName, object, model) {
    
    const splitIndex = fieldName.indexOf('.');
    if (splitIndex < 0) {
        let lastValue = null;
        if (object) {
            if (Array.isArray(object)) {
                lastValue = object.map( item => item[fieldName]);
            } else {
                lastValue = object[fieldName];
            }
        }
        
        return lastValue;
    }
    const currentFieldName = fieldName.substring(0, splitIndex);
    const subObject = object ? object[currentFieldName] : null;

    const subFieldName = fieldName.substring(splitIndex + 1, fieldName.length);
    
    const value = subObject ? getFieldValue(subFieldName, subObject, ObjectsMap[model.fields[currentFieldName].object]) : null;
    
    return value;
}