import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import moment from 'moment';
import Divider from '@material-ui/core/Divider';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { commonActions } from '../../_actions';

import { ObjectsMap } from '../../_config';
import { getRights } from '../../_helpers';

import { history } from '../../_helpers';

const styles = theme => ({
	
	title: {
		color: "#042859",
    	fontWeight: "bold"
	},
	container: {
		overflow: "auto"
	}
});


class DataView extends PureComponent {

	state = {
		data: {}
	};

	constructor(props) {
		
		super(props);
		
		this.scope     = this.props.scope;
		this.model     = this.props.model;
		this.data_view = this.props.data_view;
		this.item      = this.props.item;

		this.subObjectModel = ObjectsMap[this.model.fields[this.data_view.field].object];
		this.subObjectRights = getRights(this.props.user, this.props.context, this.subObjectModel.name);
	}

	componentDidMount() {
		this.props.dispatch(commonActions.getDataView(this.scope, this.model, {
			id: this.item.id,
			field: this.data_view.field
		}));
	}
	
    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }
	
	computeFinalValue(value, type) {
		
		var finalValue = value;
		
		if(type == "date") {
			finalValue = moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
		}

		return finalValue;
	}

	handleGoTo = (url) => {
		history.push(url);
	}

	render() {

		const { classes, t, id } = this.props;
	
		const data = this.getData();
		const result = data.queryRes ?  data.queryRes[this.data_view.field] : null;
		const nbRes = result ? result.length : 0;
		let Component = this.data_view.component;

		return 	<div>
					{ 
						data.loading && 
						<p>{t('loading.data')}</p> 
					}
					{ 
						!data.loading &&
						<div>
							<div>
								<Grid container xs={12} >
									<Grid item xs={12}><br/></Grid>
									<Grid item xs={4}>
										<span className={classes.title}>{t(this.data_view.title)}</span>
									</Grid>
									<Grid item xs={4} />
									<Grid container xs={4} alignItems="flex-start" justify="flex-end" >
										<Button key={id+'-dataview-button'} variant="contained" size="small" onClick={() => this.handleGoTo('/list/'+this.subObjectModel.name+'/'+this.model.name+'/'+this.item.id ) } style={{boxShadow: 'none', fontSize: '0.7rem', padding: '2px 8px', color: '#042959', backgroundColor: '#f9f9f9'}}>
												{t('see.more')}
										</Button> 
									</Grid>
								</Grid>
							</div>
							{ 
							  (nbRes > 0) &&
							  <div>
								{result.map( (item, index) => {
									const content = <div key={'dataview_'+index}>{index > 0 ? <Divider/> : null}<Component t={t} object={item} model={this.subObjectModel} {...this.data_view.props}/></div>;
									if (this.subObjectRights.read) {
										return <CardActionArea  key={'dataview_'+index} onClick={() => this.handleGoTo('/read/'+this.subObjectModel.name+'/'+item.id)}>{content}</CardActionArea>;
									} else {
										return content;
									}
									}
								)}
							  </div>
							}
							{
								(nbRes == 0) &&
								<div className="legende">{t('nodata.todisplay')}</div>
							}
						</div>
					}
				</div>
	}
}


function mapStateToProps(state) {
	
	const { data, authentication, appcontext } = state;
    const { user, context} = authentication;
	
    return {
        data,
        user, 
        context,
        appcontext
    };
}

const ConnectedDataView = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(DataView)));
export { ConnectedDataView as DataView };