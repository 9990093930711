import React, { Component } from 'react';
import { connect } from 'react-redux';

import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';

class AddInListButton extends Component {
    
    render() {
    
       const { t, onClick } = this.props;

       return (
            <Chip
            deleteIcon={<AddIcon/>}
            label={t('action.add.new')}
            clickable
            onClick={onClick}
            onDelete={onClick}
        />
       );
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedAddInListButton = connect(mapStateToProps)(AddInListButton);
export { connectedAddInListButton as AddInListButton };