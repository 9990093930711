import React from 'react';

import { commonActions } from '../../_actions';

import Grid from '@material-ui/core/Grid';

import PersonIcon from '@material-ui/icons/Person';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import {BadgeIcon} from '../../_components/icons/badge';

export default class HomeDashBoard extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.liveTimer = setInterval( () => {
            this.props.dispatch(commonActions.liveFull());
        }, 1000);
    }
    
    componentWillUnmount() {
        if (this.liveTimer) {
            clearInterval(this.liveTimer);
        }
    }

    _renderSessionInfos = (refiller, showCustomer) => {

        const authorization = refiller.authorization;

        if (!authorization) {
            return null;
        }

        const driver = authorization.associatedDriver;

        const driverInfos = driver ? <Grid container spacing={8} direction="row" alignItems="center">
        <Grid item><PersonIcon/></Grid>
        <Grid item>{authorization.customer && showCustomer ? authorization.customer.name+' - ': ''}{driver.firstName +' '+driver.lastName}</Grid>
        </Grid> : null;


        let badgeInfos = null;
        const badge = authorization.badge;

        if (badge) {

            badgeInfos = <Grid container spacing={8} direction="row" alignItems="center">
            <Grid item><BadgeIcon/></Grid>
            <Grid item>N° {badge.badgeId}</Grid>
            </Grid>
        }
        
        const peering = authorization.peering;
        const peeringInfos = peering ? <Grid container spacing={8} direction="row" alignItems="center">
            <Grid item><SmartphoneIcon/></Grid>
            <Grid item>N° {peering.device.phone.number}</Grid>
        </Grid> : null;
        
        let phoneInfos = null;
        if (!peering && driver && driver.devices && driver.devices.length > 0) {
            const phones = {};
            driver.devices.forEach( device => {
                phones[device.phone.number] = true;
            })
            phoneInfos = <Grid container spacing={8} direction="row" alignItems="center">
                <Grid item><SmartphoneIcon/></Grid>
                <Grid item>N° {Object.keys(phones).join(', ')}</Grid>
            </Grid>
        }

        return <div>{driverInfos}{badgeInfos}{peeringInfos}{phoneInfos}</div>

    }

}