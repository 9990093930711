import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const appName = process.env.REACT_APP_APPNAME ? process.env.REACT_APP_APPNAME : "FillnDrive";

i18n
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "product.name": appName,
          "context.admin": "ADMINISTRATION",
          "context.manager": "MANAGER",
          "home.title": "Home",
          "yes": "Yes",
          "no": "No",
          "none": "None",
          "context": "Context",
          "validated": "Validated",
          "firstName": "First name",
          "lastName": "Last name",
          "user": "User",
          "users": "Users",
          "group": "Group",
          "userGroup": "Group",
          "userGroups": "Groups",
          "operator": "Station operator", 
          "serviceProvider": "Mobility Operator",
          "driver": "Driver",
          "drivers": "Drivers",
          "driver.search": "Search driver",
          "driver.search.help": "Type the first 3 characters to search (name, surname, or phone number)",
          "device": "Device",
          "devices": "Devices",
          "energy": "Energy",
          "energys": "Energies",
          "energies": "Energies",
          "delivered.energys": "Delivered energies",
          "energyType": "Energy Type",
          "energyTypes": "Energy Types",
          "contract": "Contract",
          "contracts": "Contracts",
          "customer": "Customer",
          "customers": "Customers",
		  "objType":"Object type",
		  "parentType":"Parent type",
		  "media":"Media",
          "station": "Station",
          "stations": "Stations",
          "dispenser": "Dispenser",
          "dispensers": "Dispensers",
          "barrier": "Barrier",
          "barriers": "Barriers",
          "refill": "Attempt",
          "refills": "Attempts",
          "show.refills": "Show attempts",
          "refillgroup": "Refill",
          "refillgroups": "Refills",
          "show.refillgroups": "Show refills",
          "fleet": "Fleet",
          "fleets": "Fleets",
          "vehicle": "Vehicle",
          "vehicles": "Vehicles",
          "vehicleModele": "Vehicle Model",
          "vehicleModeles": "Vehicle Models",
          "vehicleType": "Vehicle type",
          "vehicleTypes": "Vehicle types",
          "vehicleSharing": "Vehicle Sharing",
          "vehicleSharings": "Vehicle Sharings",
          "badge": "Badge",
          "badges": "Badges",
		  "search.in.progress" : "Search in progress",
          "stationAvailability": "Station Availability Event",
          "stationAvailabilitys": "Station Availability Events",
          "dispenserAvailability": "Dispenser Availability Event",
          "dispenserAvailabilitys": "Dispenser Availability Events",
          "created.on": "Created on",
          "last.update.on": "Last update on",
          "account.activation": "Activation",
          "name": "Name",
		  "imports": "Imports",
          "email": "Email address",
          "email.error.required": "Email address is required",
          "password": "Password",
          "password.error.required": "Password is required",
          "confirmPassword": "Password confirmation",
          "confirmPassword.error.required": "Password confirmation is required",
          "double.security": "Double security",
          "u2f.please.click.key.to.login": "Push the warning button on your security key",
          "u2f.sign.error": "Key checking has failed",
          "validaction.account.error": "This link is no longer valid",
          "u2f.compatibility.error": "To authenticate to "+appName+", your browser must be compatible with U2F security keys.",
          "twoauth.solution.U2F": "U2F Security key",
          "twoauth.solution.TOTP": "Mobile application",
          "totp.verify.error": "Token checking has failed",
          "account": "Account",
		  "action.import": "Import",
          "action.login": "Login",
          "action.validate": "Validate",
          "action.cancel": "Cancel",
          "action.next": "Next",
          "action.gologin": "Go to login page",
          "action.logout": "Logout",
          "action.add.new": "Add",
          "action.save": "Save",
          "action.choose": "Choose",
          "action.edit": "Edit",
          "action.open": "Open",
          "action.sendsmspeering": "Send SMS mobile app",
          "action.profil": "Manage my account",
          "action.sort":"Sort",
          "action.filter":"Filter",
          "action.select":"Select",
		  "action.import.analyze":"Analyze import file",
		  "action.import.do":"Import data",
          "form.error.login.not.equal.confirmpassword": "Password and password confirmation are not equals",
          "u2f.addkey.plugin": "Plug in your U2F key that you want to associate with your account and click the next button.",
          "u2f.addkey.save": "Saving your key",
          "useraccess.validated": "Your account is ready, you can now login",
          "loading.context": "Loading your context...",
          "loading.data": "Loading data...",
		      "last.refills": "Last refills",
          "menu.fleet": "Fleet",
		  "menu.imports" : "Imports",
          "menu.admin": "Administration",
          "menu.station": "Stations",
          "menu.customer": "Customers",
          "creation": "Creation",
          "edition": "Edition",
          "read": "Read",
          "phone": "Phone number",
          "enabled": "Enabled",
          "badgeId": "Badge number",
          "vin": "VIN",
          "immatriculation": "Registration",
          "modele": "Modele",
          "field.required": "Field required",
          "field.badFormat": "Bad value",
          "profil": "Profil",
          "profils": "Profils",
          "profil.ADMIN": "Platorm administrator",
          "profil.STATION_OPERATOR": "Station operator",
          "profil.SERVICE_MANAGER": "Mobility operator",
          "profil.FLEET_MANAGER": "Fleet manager",
          "role": "Role",
          "roles": "Roles",
          "role.administrator": "Administrator",
          "role.manager": "Manager",
          "role.viewer": "Viewer",
          "address": "Address",
          "street": "Street",
          "zipcode": "Zip code",
          "city": "City",
          "country": "Country",
          "gps": "GPS",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "connected": "Connected",
          "vehicleSharingAutomatique": "Automatic vehicle sharing",
          "defaultVehicleSharing": "Default share",
          "login.failed": "Login has failed, please check your login and password",
          "state": "State",
          "stationState": "Station state",
          "start": "Start",
          "end": "End",
          "duration": "Duration",
          "quantity": "Quantity",
          "steps": "Steps",
          "step": "Step",
          "barrier.destphone": "External phone",
          "idrelay": "Relay Code",
          "barrier.wsphone": "Phone Input WS",
		  "nbImportable": "Importable lines count",
		  "nbErrorsAnalyze": "Error lines during analyze count",
		  "nbErrorsImport": "Error lines during import count",
		  "lineNumber": "Line",
		  "data":"Data",
		  "errorAnalyze": "Error (analyze)",
		  "errorImport": "Error (import)",
		  "immatriculation.alreadyexist": "Registration plate already exists",
		  "import.state.error": "Error",
		  "import.state.created": "Created",
		  "import.state.analyze_run": "Analyze running",
		  "import.state.ready": "Ready to import",
		  "import.state.imp_run": "Import running",
		  "import.state.imp_done": "Finish",
          "public": "Public",
          "label": "Label",
		  "see.more" : "See more",
          'peeringState': "Mobile app state",
          "refill.state.waitinqueue": "in the waiting line",
          "refill.state.firstinqueue": "wait to unlock",
          "refill.state.waitToStart": "wait to start",
          "refill.state.preparing": "preparing",
          "refill.state.inProgress": "in progress",
          "refill.state.ending": "ending",
          "refill.state.ended": "ended",
          "refill.state.cancelled": "cancelled",
          "refill.state.unlocktimeout": "not unlocked",
          "refill.state.starttimeout": "not started",
          "refill.state.inError": "error",
          "refill.endStatus.0": "-",
          "refill.endStatus.1": "Local button Stop",
          "refill.endStatus.3": "L2 dispenser error",
          "refill.endStatus.4": "Target dispenser pressure reached",
          "refill.endStatus.6": "Station error",
          "refill.endStatus.7": "Interrupt signal",
          "refill.endStatus.8": "SAEJ",
          "refill.endStatus.9": "Stop signal",
          "refill.endStatus.10": "POS stop",
          "refill.endStatus.11": "Stop charging gradient mode",
          "refill.endStatus.12": "Vehicle pressure target reached",
          "refill.endSubStatus.0": "-",
          "refill.endSubStatus.1": "Initial Pressure out of range",
          "refill.endSubStatus.2": "Volume tank out of range",
          "refill.endSubStatus.3": "Fallback not authorized",
          "refill.endSubStatus.4": "H2 temp above T30 table",
          "refill.endSubStatus.5": "Target Pressure reached with com",
          "refill.endSubStatus.7": "Target Pressure reached without com",
          "refill.endSubStatus.9": "Target Pressure top off reached with com",
          "refill.endSubStatus.10": "Wrong initial calculation",
          "rememberme": "Remember me",
          "stationstate-online": "Available",
          "stationstate-wferror": "PLC connection error",
          "added.on": "added on",
          "associated": "associated",
          "plc": "PLC Informations",
          "pos.version": "POS version",
          "wfconnector.version": "WF Connector version",
          "fuelingStatus": "Fueling status",
          "queue": "Queue",
          "nozzlePosition": "Nozzle position",
          "unlocked": "Unlocked",
          "state.open": "Open",
          "state.partial": "Partial",
          "state.busy": "Busy",
          "state.maintenance": "Maintenance",
          "state.breakdown": "Breakdown",
          "state.closed": "Closed",
          "state.empty": "Empty",
          "state.unknown": "unknown",
          "state.offline": "Offline",
          "publicState": "Public state",
          "liveState": "Live state",
          "lastping": "Last ping",
          "progress": "Progress",
          "filter.refill.date":"Refills date",
          "customer.status.closed": "Closed",
          "customer.status.created": "Created",
          "customer.status.ready": "Ready",
          "customer.status.locked": "Locked",
          "running": "running",
          "not.started": "not started",
          "success": "success",
          "error": "error",
          "nodata.todisplay": "no data to display",
          "filter.daily": "Daily",
          "filter.weekly": "Weekly",
          "filter.monthly": "Monthly",
          "filter.yearly": "Yearly",
          "filter.refill.pos": "POS",
          "periode.from": "from",
          "periode.to": "to",
          "pos.nexopus.app": appName+" App",
          "pos.nexopus.manager": appName+" Manager",
          "pos.nexopus.badge": appName+" Badge",
          "pos.fillndrive.app": "App",
          "pos.fillndrive.manager": "Manager",
          "pos.fillndrive.badge": "Badge",
          "pos.tokheim": "Tokheim",
          "pos.gtis": "GTIS",
          "userRole": "Role",
          "ADMIN": "Administrator",
          "MANAGER": "Manager",
          "VIEWER": "Viewer",
          "availability": "Availability",
          "action.authorizeunlock.dispenser": "Authorize fueling",
          "action.authorizeunlock.intro": "Fill the information related to the driver",
          "action.authorizeunlock": "Authorize",
          "action.startrefill.dispenser": "Start fueling",
          "action.startrefill.intro": "Check the nozzle is connected to the vehicle before starting",
          "action.startrefill": "Start",
          "action.stoprefill.dispenser": "Stop fueling",
          "action.stoprefill.intro": "",
          "action.stoprefill": "Stop",
          "action.unlockdispenser.dispenser": "Unlock dispenser",
          "action.unlockdispenser.intro": "",
          "action.unlockdispenser": "Unlock",
          "action.lockdispenser.dispenser": "Lock dispenser",
          "action.lockdispenser.intro": "",
          "action.lockdispenser": "Lock",
          "workflowaction.authorizeunlock": "Authorize fueling",
          "workflowaction.startrefill": "Start fueling",
          "workflowaction.stoprefill": "Stop fueling",
          "workflowaction.unlockdispenser": "Unlock dispenser",
          "workflowaction.lockdispenser": "Lock dispenser",
          "workflowaction.usersendemailvalidation": "Send activation email",
          "action.usersendemailvalidation": "Send activation email",
          "workflowaction.devicesendpeering": "Send SMS with Token",
          "workflowaction.deviceunassociatepeering": "Unassociate the device",
		  "workflowaction.analyzeImport": "Analyze import file",
		  "workflowaction.doImport": "Import data",		  
          "action.devicesendpeering": "Send Mobile App Association Token by SMS",
          "action.unassociate": "Unassociate",
          "action.deviceunassociatepeering": "Unassociate the device",
          "action.deviceunassociatepeering.intro": "Attention, the currently associated phone can not be used anymore. Perform this action only if the user has changed phones or reinstalled the application.",
          "authorization.deviceType": "Source",
          "pos": "POS",
          "delivery.by":"Deliveried by",
          "manufacturer":"Manufacturer",
          "model":"Model",
          "os":"OS",
          "version":"Version",
          "appVersion":"App version",
          "deviceInfos": "Device informations",
          "authorization": "Authorization",
          "vehicleCount": "Queue",
          "activeSession": appName+" Informations",
          "contactInfos": "Contact informations",
          "workflowaction.notenabled": "Action not enabled.",
          "action.disable": "Disable",
          "card.reader.online": "Card reader available",
          "card.reader.offline": "Card reader out of service",
          "attempt": "Attempt",
          "lastAccess": "Last access",
          "supportphone": "Support phone number",
          "attemptsCount": "Attempts",
          "attemptCount": "N°",
          "attempts": "Attempts",
          "attempt": "Attempt",
          "com.lost": "Lost",
          "phoneNumbers": "Phone",
          "workflowaction.useraccessreinit": "Reset access",
          "quantity.delivered": "Quantity delivered",
          "refillsCount": "Number of refills",
          "quantity.avg": "Average quantity",
          "ircom.with": "with vehicle communication",
          "ircom.without": "without vehicle communication",
          "quantity.total": "Total quantity",
          "duration.avg": "Average duration",
          "duration.ecart": "Standard deviation duration",
          "performance": "Performance",
          "repartition.count": "Breakdown by number",
          "repartition.quantity": "Breakdown by quantity",
          "repartition.identified": "Breakdown by vehicle identification",
          "userrole.ADMIN": "Administrator - standard",
          "userrole.MANAGER": "Manager - standard",
          "userrole.VIEWER": "Viewer - standard",
          "dataexport": "Export",
          "dataexports": "Exports",
          "dataExports": "Exports",
          "description": "Description",
          "outputFields": "Columns",
          "column": "Column",
          "order": "Order",
          "example": "Example",
          "dataExport.outputField.desc.refill.customer.reference": "Customer reference",
          "dataExport.outputField.example.refill.customer.reference": "50001",
          "dataExport.outputField.desc.refill.customer.name": "Customer name",
          "dataExport.outputField.example.refill.customer.name": "Customer A",
          "dataExport.outputField.desc.refill.date": "Start date",
          "dataExport.outputField.example.refill.date": "01/01/2019",
          "dataExport.outputField.desc.refill.hour": "Start time",
          "dataExport.outputField.example.refill.hour": "10:07",
          "dataExport.outputField.desc.refill.energyType.productReference": "Product Reference",
          "dataExport.outputField.example.refill.energyType.name": "10",
          "dataExport.outputField.desc.refill.quantity": "Quantity delivered (in kg)",
          "dataExport.outputField.example.refill.quantity": "2.35",
          "dataExport.outputField.desc.refill.badge.number": "Badge number",
          "dataExport.outputField.example.refill.badge.number": "A123456B",
          "dataExport.outputField.desc.refill.transactionId": "Transaction ID",
          "dataExport.outputField.example.refill.transactionId": "fr.airliquide.orly_D01_1560255593197",
          "dataExport.outputField.desc.refill.vehicle.immatriculation": "Vehicle registration",
          "dataExport.outputField.example.refill.vehicle.immatriculation": "123-AB-94",
          "dataExport.outputField.desc.refill.driver": "Driver Name/First Name",
          "dataExport.outputField.example.refill.driver": "Jean Dupont",
          "dataExport.outputField.desc.refill.station.reference": "Station reference",
          "dataExport.outputField.example.refill.station.reference": "10",
          "dataExport.outputField.desc.refill.station.name": "Station name",
          "dataExport.outputField.example.refill.station.name": "Orly",
          "dataExport.outputField.desc.refill.dispenser.reference": "Dispenser reference",
          "dataExport.outputField.example.refill.dispenser.reference": "1",
          "dataExport.outputField.desc.refill.endStatus": "End Status",
          "dataExport.outputField.example.refill.endStatus": "10",
          "dataExport.outputField.desc.refill.endSubStatus": "End Sub Status",
          "dataExport.outputField.example.refill.endSubStatus": "1",
          "dataExport.outputField.desc.refill.pos": "POS",
          "dataExport.outputField.example.refill.pos": "fillndrive.app",
          "dataExport.outputField.desc.refill.endDate": "End Date",
          "dataExport.outputField.example.refill.endDate": "01/01/2019",
          "dataExport.outputField.desc.refill.endHour": "End time",
          "dataExport.outputField.example.refill.endHour": "10:10",
          "dataExport.outputField.desc.refill.fuelingId": "PLC refill Id",
          "dataExport.outputField.example.refill.fuelingId": "123456789",

          "dataExport.outputField.desc.dispenserAvailability.station.reference": "Station reference",
          "dataExport.outputField.example.dispenserAvailability.station.reference": "10",
          "dataExport.outputField.desc.dispenserAvailability.station.name": "Station name",
          "dataExport.outputField.example.dispenserAvailability.station.name": "Orly",
          "dataExport.outputField.desc.dispenserAvailability.dispenser": "Dispenser reference",
          "dataExport.outputField.example.dispenserAvailability.dispenser": "1",
          "dataExport.outputField.desc.dispenserAvailability.start": "Start date/time",
          "dataExport.outputField.example.dispenserAvailability.start": "01/01/2019 10:20",
          "dataExport.outputField.desc.dispenserAvailability.end": "End date/time",
          "dataExport.outputField.example.dispenserAvailability.end": "01/01/2019 10:25",
          "dataExport.outputField.desc.dispenserAvailability.event": "Event that triggers the change of state",
          "dataExport.outputField.example.dispenserAvailability.event": "",
          "dataExport.outputField.desc.dispenserAvailability.cloudOnline": "Dispenser connected to the cloud",
          "dataExport.outputField.example.dispenserAvailability.cloudOnline": "true",
          "dataExport.outputField.desc.dispenserAvailability.cloudOnlineBefore": "Dispenser connected to the cloud before the change of state",
          "dataExport.outputField.example.dispenserAvailability.cloudOnlineBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.wfconnectorOnline": "WF connector Connected",
          "dataExport.outputField.example.dispenserAvailability.wfconnectorOnline": "true",
          "dataExport.outputField.desc.dispenserAvailability.wfconnectorOnlineBefore": "WF connector Connected before the change of state",
          "dataExport.outputField.example.dispenserAvailability.wfconnectorOnlineBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.plcOnline": "PLC link connected",
          "dataExport.outputField.example.dispenserAvailability.plcOnline": "true",
          "dataExport.outputField.desc.dispenserAvailability.plcOnlineBefore": "PLC link connected before the change of state",
          "dataExport.outputField.example.dispenserAvailability.plcOnlineBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.canUpdatePLC": appName+" can drive the PLC",
          "dataExport.outputField.example.dispenserAvailability.canUpdatePLC": "true",
          "dataExport.outputField.desc.dispenserAvailability.canUpdatePLCBefore": appName+" can drive the PLC before the change of state",
          "dataExport.outputField.example.dispenserAvailability.canUpdatePLCBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.publicState": "Dispenser internal state",
          "dataExport.outputField.example.dispenserAvailability.publicState": "open",
          "dataExport.outputField.desc.dispenserAvailability.publicStateBefore": "Dispenser internal state before the change of state",
          "dataExport.outputField.example.dispenserAvailability.publicStateBefore": "maintenance",
          "dataExport.outputField.desc.dispenserAvailability.manualState": "Dispenser Manual state",
          "dataExport.outputField.example.dispenserAvailability.manualState": "closed",
          "dataExport.outputField.desc.dispenserAvailability.manualStateBefore": "Dispenser manual state before the change of state",
          "dataExport.outputField.example.dispenserAvailability.manualStateBefore": "open",
          "dataExport.outputField.desc.dispenserAvailability.energyStates": "States by product",
          "dataExport.outputField.example.dispenserAvailability.energyStates": "{\"350\":\"open\",\"700\":\"open\"}",
          "dataExport.outputField.desc.dispenserAvailability.energyStatesBefore": "State by product before the change of state",
          "dataExport.outputField.example.dispenserAvailability.energyStatesBefore": "{\"350\":\"maintenance\",\"700\":\"maintenance\"}",
          "apiClients": "API",
          "keyName": "Key name",
          "keyDate": "Key date",
          "publicKey": "Public key",
          "no.result": "no result",
          "result": "result",
          "results": "results",
          "loglevel-trace": "trace",
          "loglevel-debug": "debug",
          "loglevel-info": "info",
          "loglevel-analytics": "analytics",
          "loglevel-error": "error",
          "loglevel-crash": "crash",
          "sendsms.failed": "Failed to send SMS, please retry later.",
          "badgeId.alreadyexist": "This badge Id is already in use",
          "phoneNumber.alreadyexist": "This phone number is already in use",
          "email.alreadyused": "This email address is already in use",
          "error.object.notvalid": "Data are not valid",
          "inputfield.required": "Field required, missing value",
          "object.notfound": "Unknown value",
          "field.required.driver_or_vehicle": "A device must associated to a driver or a vehicle",
          "language-fr": "French",
          "language-en": "English",
          "language": "Language",
          "h2mapShared": "Share live status with H2MAP",
          "network": "Network",
          "networks": "Networks",
          "stationNetwork": "Network",
          "stationNetworks": "Networks",
          "menu.contracts": "Contracts", 
          "customerContract": "Customer contract",
          "customerContracts": "Customer contracts",
          "private": "Private",
          "customerContract.status.created": "Created",
          "customerContract.status.active": "Active",
          "customerContract.status.closed": "Closed",
          "customerContract.status.outdated": "Outdated",
          "vehicleModel": "Vehicle model",
          "vehicleModels": "Vehicle models",
          "workflowaction.activate": "Activate",
          "action.contract.activate": "Activate contract",
          "workflowaction.close": "Close",
          "action.contract.close": "Close contract",
          "customerContract.alreadyexist": "A contract associated with this fleet and at least 1 same station network is already active over the same period.",
          "inputfield.dateMustEndAfterStart": "The end date must be greater than the start date",
          "operatorContracts": "Operator contracts",
          "operatorContract": "Operator contract"
        }
      },
      fr: {
        translations: {
          "product.name": appName,
          "context.admin": "ADMINISTRATION",
          "context.manager": "MANAGER",
          "home.title": "Accueil",
          "yes": "Oui",
          "no": "Non",
          "none": "Aucun",
          "context": "Context",
          "validated": "Validé",
          "firstName": "Prénom",
          "lastName": "Nom",
          "user": "Utilisateur",
          "users": "Utilisateurs",
          "group": "Groupe",
          "userGroup": "Groupe",
          "userGroups": "Groupes",
          "operator": "Operateur de station",
          "serviceProvider": "Opérateur de mobilité",
          "driver": "Conducteur",
          "drivers": "Conducteurs",
          "driver.search": "Rechercher un conducteur",
          "driver.search.help": "Tapez les 3 premiers caractères à rechercher (du nom, prénom, ou n° de téléphone)",
          "device": "Téléphone",
          "devices": "Téléphones",
          "energy": "Energie",
          "energys": "Energies",
          "energies": "Energies",
          "delivered.energys": "Energies délivrées",
          "energyType": "Type d'energie",
          "energyTypes": "Types d'energie",
          "contract": "Contrat",
          "contracts": "Contrats",
          "customer": "Client",
          "customers": "Clients",
		  "objType":"Type d'objet",
		  "parentType":"Type parent",
		  "media":"Media",
          "station": "Station",
          "stations": "Stations",
          "dispenser": "Dispenser",
          "dispensers": "Dispensers",
          "barrier": "Barrière",
          "barriers": "Barrières",
          "refill": "Tentative",
          "refills": "Tentatives",
          "show.refills": "Afficher les tentatives",
          "refillgroup": "Recharge",
          "refillgroups": "Recharges",
          "show.refillgroups": "Afficher les recharges",
          "fleet": "Flotte",
          "fleets": "Flottes",
          "vehicle": "Véhicule",
          "vehicles": "Véhicules",
          "vehicleModele": "Modèle de vehicule",
          "vehicleModeles": "Modèles de vehicule",
          "vehicleType": "Type de véhicules",
          "vehicleTypes": "Types de véhicules",
          "vehicleSharing": "Partage de véhicules",
          "vehicleSharings": "Partages de véhicules",
          "badge": "Badge",
          "badges": "Badges",
		  "search.in.progress" : "Recherche en cours",
          "stationAvailability": "Changement de disponibilité",
          "stationAvailabilitys": "Changements de disponibilité",
          "dispenserAvailability": "Changement de disponibilité",
          "dispenserAvailabilitys": "Changements de disponibilité",
          "created.on": "Créé le",
          "last.update.on": "Mis à jour le",
          "account.activation": "Activation",
          "name": "Nom",
		  "imports": "Imports",
          "email": "Adresse email",
          "email.error.required": "L'adresse email est manquante",
          "password": "Mot de passe",
          "password.error.required": "Le mot de passe est manquant",
          "confirmPassword": "Confirmation du mot de passe",
          "confirmPassword.error.required": "La confirmation du mot de passe est manquante",
          "double.security": "Double sécurité",
          "u2f.please.click.key.to.login": "Appuyez sur le bouton clignotant de votre clé de sécurité",
          "u2f.sign.error": "La vérification de votre clé de sécurité à échouché",
          "validaction.account.error": "Ce lien de validation ne semble plus valide.",
          "u2f.compatibility.error": "Pour valider votre compte et vous authentifier sur "+appName+", il est nécessaire que votre navigateur soit compatible avec les clés de sécurité U2F.",
          "twoauth.solution.U2F": "Clé de sécurité U2F",
          "twoauth.solution.TOTP": "Application mobile",
          "totp.verify.error": "La vérification du code a échoué.",
          "account": "Compte",
		  "action.import": "Importer",
          "action.login": "Login",
          "action.validate": "Valider",
          "action.cancel": "Annuler",
          "action.next": "Suivant",
          "action.gologin": "Aller à la page de connexion",
          "action.logout": "Déconnexion",
          "action.add.new": "Ajouter",
          "action.save": "Enregistrer",
          "action.choose": "Sélectionner",
          "action.read": "Consulter",
          "action.edit": "Modifier",
          "action.open": "Ouvrir",
          "action.export": "Exporter",
          "action.delete": "Supprimer",
          "action.profil": "Gérer mon profil",
          "action.sendsmspeering": "Envoyer SMS App mobile",
          "action.sort":"Trier",
          "action.filter":"Filtrer",
          "action.select":"Sélectionner",
		  "action.import.analyze":"Analyser le fichier import",
		  "action.import.do":"Importer les données",
          "form.error.login.not.equal.confirmpassword": "Le mot de passe et la confirmation du mot de passe ne sont pas égaux",
          "u2f.addkey.plugin": "Branchez la clé U2F que vous souhaitez associer à votre compte et cliquez sur le bouton suivant.",
          "u2f.addkey.save": "Enregistrement de votre clé",
          "useraccess.validated": "Votre compte est validé !",
          "loading.context": "Chargement de votre espace en cours...",
          "loading.data": "Chargement des données en cours...",
		      "last.refills": "Dernières recharges",
          "menu.fleet": "Flotte",
          "menu.admin": "Administration",
          "menu.station": "Stations",
          "menu.customer": "Clients",
          "creation": "Création",
          "edition": "Edition",
          "read": "Consultation",
          "phone": "Numéro de téléphone",
          "enabled": "Actif",
          "badgeId": "Numéro badge",
          "vin": "VIN",
          "immatriculation": "Immatriculation",
          "modele": "Modèle",
          "field.required": "Champ obligatoire",
          "field.badFormat": "Valeur incorrecte",
          "profil": "Profil",
          "profils": "Profils",
          "profil.ADMIN": "Administrateur plateforme",
          "profil.STATION_OPERATOR": "Opérateur de station",
          "profil.SERVICE_MANAGER": "Opérateur de mobilité",
          "profil.FLEET_MANAGER": "Gestionnaire de flotte",
          "role": "Role",
          "roles": "Roles",
          "role.administrator": "Administrateur",
          "role.manager": "Manager",
          "role.viewer": "Consultant",
          "address": "Adresse",
          "street": "Rue",
          "zipcode": "Code postal",
          "city": "Ville",
          "country": "Pays",
          "gps": "Coordonnées GPS",
          "latitude": "Latitude",
          "longitude": "Longitude",
          "connected": "Connecté",
          "vehicleSharingAutomatique": "Gestion automatique du partage",
          "defaultVehicleSharing": "Partage par défaut",
          "login.failed": "L'authentification a échoué, veuillez vérifier votre identifiant et votre mot de passe.",
          "state": "Etat",
          "stationState": "Etat de la station",
          "start": "Début",
          "end": "Fin",
          "duration": "Durée",
          "quantity": "Quantité",
          "steps": "Etapes",
          "step": "Etape",
          "barrier.destphone": "Téléphone prestataire",
          "idrelay": "Code relay",
          "barrier.wsphone": "Téléphone entrée WS",
		  "nbImportable": "Nombre de ligne importable",
		  "nbErrorsAnalyze": "Nombre de ligne en erreur lors de l'analyse",
		  "nbErrorsImport": "Nombre de ligne en erreur lors de l'import",
		  "lineNumber": "Ligne",
		  "data":"Données",
		  "errorAnalyze": "Erreur (analyse)",
		  "errorImport": "Erreur (import)",
		  "immatriculation.alreadyexist": "Immatriculation existante",
		  "import.state.error": "En erreur",
		  "import.state.created": "Crée",
		  "import.state.analyze_run": "Analyse en cours",
		  "import.state.ready": "Prêt a importer",
		  "import.state.imp_run": "Import en cours",
		  "import.state.imp_done": "Fini",
          "public": "Public",
          "label": "Libellé",
		  "see.more" : "Voir plus",
          'peeringState': "Application mobile",
          "app.peered": "Associé",
          "app.notpeered": "Non associé",
          "refill.state.waitinqueue": "en file d'attente",
          "refill.state.firstinqueue": "attente dévérouillage",
          "refill.state.waitToStart": "attente démarrage",
          "refill.state.preparing": "démarrage",
          "refill.state.inProgress": "en cours",
          "refill.state.ending": "arrêt",
          "refill.state.ended": "terminé",
          "refill.state.cancelled": "annulé",
          "refill.state.unlocktimeout": "Non dévérouillé à temps",
          "refill.state.starttimeout": "Non démarré à temps",
          "refill.state.inError": "erreur",
          "refill.endStatus.0": "-",
          "refill.endStatus.1": "Arrêt bouton local",
          "refill.endStatus.3": "Erreur L2 dispenser",
          "refill.endStatus.4": "Cible pression dispenser atteinte",
          "refill.endStatus.6": "Erreur station",
          "refill.endStatus.7": "Signal d'interruption",
          "refill.endStatus.8": "SAEJ",
          "refill.endStatus.9": "Signal d'arrêt",
          "refill.endStatus.10": "Arrêt POS",
          "refill.endStatus.11": "Arrêt recharge mode dégradé",
          "refill.endStatus.12": "Cible pression véhicule atteinte",
          "refill.endSubStatus.0": "-",
          "refill.endSubStatus.1": "Initial Pressure out of range",
          "refill.endSubStatus.2": "Volume tank out of range",
          "refill.endSubStatus.3": "Fallback not authorized",
          "refill.endSubStatus.4": "H2 temp above T30 table",
          "refill.endSubStatus.5": "Target Pressure reached with com",
          "refill.endSubStatus.7": "Target Pressure reached without com",
          "refill.endSubStatus.9": "Target Pressure top off reached with com",
          "refill.endSubStatus.10": "Wrong initial calculation",
          "rememberme": "Se souvenir de moi",
          "stationstate-online": "disponible",
          "stationstate-wferror": "erreur liaison PLC",
          "dispenserFuelingStatus-noStatus": "indisponible",
          "dispenserFuelingStatus-readyToFuel": "prêt",
          "dispenserFuelingStatus-preparingToFuel": "démarrage",
          "dispenserFuelingStatus-inProgress": "recharge en cours",
          "dispenserFuelingStatus-stopping": "arrêt en cours",
          "dispenserFuelingStatus-stopped": "arrêté",
          "added.on": "date d'ajout",
          "associated": "associé",
          "stateTechnique": "Statut technique",
          "plc": "Informations PLC",
          "pos.version": "POS Version",
          "wfconnector.version": "WF Connector version",
          "fuelingStatus": "Statut recharge",
          "queue": "File d'attente",
          "nozzlePosition": "Pistolet levé",
          "unlocked": "Dévérouillé",
          "state.open": "Disponible",
          "state.partial": "Partiel",
          "state.busy": "Occupé",
          "state.maintenance": "Maintenance",
          "state.breakdown": "En panne",
          "state.closed": "Fermé",
          "state.unavailable": "Indisponible",
          "state.empty": "Vide",
          "state.unknown": "inconnu",
          "state.offline": "hors ligne",
          "publicState": "Etat public",
          "liveState": "Etat interne",
          "lastping": "Dernier ping",
          "current.refillSession": "Utilisateur mobile",
          "current.refill": "Recharge",
          "progress": "Progression",
          "filter.refill.date":"Date des recharges",
          "customer.status.closed": "Fermé",
          "customer.status.created": "Créé",
          "customer.status.ready": "Prêt",
          "customer.status.locked": "Vérrouillé",
          "status": "Etat",
          "running": "en cours",
          "not.started": "non démarré",
          "success": "succes",
          "error": "erreur",
          "nodata.todisplay": "aucune donnée à afficher",
          "filter.daily": "Jour",
          "filter.weekly": "Semaine",
          "filter.monthly": "Mois",
          "filter.yearly": "Année",
          "filter.refill.pos": "POS",
          "periode.from": "du",
          "periode.to": "au",
          "pos.nexopus.app": appName+" App",
          "pos.nexopus.manager": appName+" Manager",
          "pos.nexopus.badge": appName+" Badge",
          "pos.fillndrive.app": "App",
          "pos.fillndrive.manager": "Manager",
          "pos.fillndrive.badge": "Badge",
          "pos.tokheim": "Tokheim",
          "pos.gtis": "GTIS",
          "userRole": "Role",
          "ADMIN": "Administrateur",
          "MANAGER": "Manager",
          "VIEWER": "Consultant",
          "availability": "Disponibilité",
          "station.availability.event.serveronline": "Redémarrage serveur",
          "station.availability.event.serveroffline": "Arrêt serveur",
          "station.availability.event.stationonline": "Station en ligne",
          "station.availability.event.stationoffline": "Station hors ligne",
          "station.availability.event.wfconnectoronline": "WF en ligne",
          "station.availability.event.wfconnectoroffline": "WF hors ligne",
          "station.availability.event.stationstate": "Changement état station",
          "station.availability.event.plcoffline": "Perte lien PLC",
          "station.availability.event.plconline": "Lien PLC en ligne",
          "station.availability.event.conline": "Connecté au cloud",
          "station.availability.event.coffline": "Déconnecté du cloud",
          "station.availability.event.energy_update": "Changement état energie",
          "livequantity": "Quantité temps réelle",
          "action.authorizeunlock.dispenser": "Autoriser une recharge sur le distributeur",
          "action.authorizeunlock.intro": "Renseignez les informations concernant le conducteur autorisé à recharger son véhicule.",
          "action.authorizeunlock": "Autoriser",
          "action.startrefill.dispenser": "Démarrer la recharge",
          "action.startrefill.intro": "Vérifiez que le conducteur a bien connecté le pistolet à son véhicule avant de démarrer la recharge", 
          "action.startrefill": "Démarrer",
          "action.stoprefill.dispenser": "Arrêter la recharge",
          "action.stoprefill.intro": "",
          "action.stoprefill": "Arrêter",
          "action.unlockdispenser.dispenser": "Dévérouiller le distributeur",
          "action.unlockdispenser.intro": "",
          "action.unlockdispenser": "Dévérouiller",
          "action.lockdispenser.dispenser": "Vérouiller le distributeur",
          "action.lockdispenser.intro": "",
          "action.lockdispenser": "Vérouiller",
          "workflowaction.authorizeunlock": "Autoriser une recharge",
          "workflowaction.startrefill": "Démarrer la recharge",
          "workflowaction.stoprefill": "Arrêter la recharge",
          "workflowaction.unlockdispenser": "Dévérouiller",
          "workflowaction.lockdispenser": "Vérouiller",
          "workflowaction.enable": "Activer",
          "workflowaction.disable": "Désactiver",
          "authorization.deviceType": "Source",
          "pos": "POS",
          "delivery.by":"Délivrée par",
          "manufacturer":"Marque",
          "model":"Modèle",
          "os":"OS",
          "version":"Version",
          "appVersion":"App version",
          "deviceInfos": "Informations téléphone",
          "authorization": "Autorisation",
          "vehicleCount": "File d'attente",
          "activeSession": "Information "+appName,
          "contactInfos": "Informations du contact",
          "reference": "Référence",
          "workflowaction.notenabled": "Cette action n\'est plus possible, veuilez réessayer plus tard.",
          "action.enable": "Activer",
          "action.disable": "Désactiver",
          "waitUntil": "Temporisation",
          "timeout": "Expire dans",
          "timeout.at": "expire le",
          "expired": "expiré",
          "action.validate":"Valider",
          "action.lock":"Vérouiller",
          "action.unlock":"Dévérouiller",
          "action.close":"Fermer",
          "action.send": "Envoyer",
          "workflowaction.customervalidate":"Valider",
          "workflowaction.customerlock":"Vérouiller",
          "workflowaction.customerunlock":"Dévérouiller",
          "workflowaction.customerclose":"Fermer",
          "workflowaction.stationforcestate":"Forcer état de la station",
          "action.stationforcestate": "Forcer l\'état de la station",
          "action.forcestate": "Forcer l\'état", 
          "action.stationforcestate.intro": "Vous pouvez choisir ici l'\état de la station à afficher aux utilisateurs. Attention cet état surcharge l'état interne de la station !", 
          "workflowaction.stationclearstate":"Repasser en état interne",
          "action.stationclearstate": "Repasser en état interne",
          "action.clearstate": "Repasser en état interne",
          "action.stationclearstate.intro": "Repasser en état interne afin d'afficher l'état courant de la station et retirer l'état forcé.", 
          "action.customervalidate": "Valider le client",
          "action.customerlock": "Vérouiller le client",
          "action.customerunlock": "Dévérouiller le client",
          "action.customerclose": "Fermer le client",
          "workflowaction.dataexport":"Exporter les données",
          "action.dataexport": "Exporter les données", 
          "action.dataexport.intro": "Choisissez la période pour laquelle vous souhaitez exporter les données, puis cliquer sur le bouton \"Exporter\" pour télécharger le fichier.", 
          "c2a.passwordforgotten": "Mot de passe oublié ?",
          "password.forgotten.failed": "L'adresse email indiquée semble invalide",
          "title.passwordforgotten": "Récupération de compte",
          "title.resetpassword": "Réinitialisation de mot de passe",
          "resetpassword.validated": "Votre mot de passe a été réinitialisé avec succès !",
          "reset.pwd.token.validation.error": "Le lien ne semble plus valide",
          "password.forgotten.success": "Un email vous a été envoyé avec un lien vous permettant de réinitialiser votre mot de passe.",
          "card.reader.online": "Lecteur de carte disponible",
          "card.reader.offline": "Lecteur de carte hors service",
          "soc.start": "SOC début",
          "soc.end": "SOC fin",
          "vehicleRefill.startState": "Etat démarrage",
          "vehicleRefill.endState": "Etat fin",
          "temperature": "Température",
          "pressure": "Pression",
          "soc": "SOC",
          "vehicle.refill.state": "Etat véhicule",
          "owner": "Propriétaire",
          "access": "Accès",
          "workflowaction.usersendemailvalidation": "Envoyer email d'activation",
          "action.usersendemailvalidation": "Envoyer un email d'activation du compte",
          "workflowaction.devicesendpeering": "Envoyer Token par SMS",
          "workflowaction.deviceunassociatepeering": "Désassocier le téléphone",
          "workflowaction.apiRevokeKey": "Révoquer la clé",
		  "workflowaction.analyzeImport": "Analyser le fichier import",
		  "workflowaction.doImport": "Importer les données",
          "action.devicesendpeering": "Envoyer Token d'association application mobile par SMS",
          "action.unassociate": "Désassocier",
          "action.reinit": "Réinitialiser",
          "action.useraccessreinit": "Réinitialiser l'accès",
          "action.deviceunassociatepeering": "Désassocier le téléphone",
          "action.deviceunassociatepeering.intro": "Attention, le téléphone actuellement associé ne pourra plus être utilisé. Réalisez cette action uniquement si l'utilisateur a changé de téléphone ou s'il a réinstallé l'application.",
          "action.apiRevokeKey": "Révoquer la clé API",
          "action.apiRevokeKey.intro": "Attention, en révoquant la clé API, les clients webservice utilisant cette clé ne pourront plus fonctionner.",
          "workflowaction.apiDownloadKey": "Télécharger la clé privée",
          "action.downloadprivatekey": "Télécharger la clé privée",
          "action.downloadprivatekey.intro": "Une fois la clé privée téléchargée, elle sera définitivement supprimée, vous ne pourrez donc plus télécharger. Assurez-vous de la stocker dans un emplacement sécurisée.",
          "workflowaction.apiGenerateKey": "Générer la clé API",
          "action.generatekey": "Générer la clé API",
          "vehicleCommunication": "Communication véhicule",
          "vehicleComState": "Communication véhicule",
          "attempt": "Tentative",
          "lastAccess": "Dernière connexion",
          "supportphone": "N° Téléphone support",
          "attemptsCount": "Tentatives",
          "attemptCount": "N°",
          "realAttemptsCount": "Tentatives",
          "attempts": "Tentatives",
          "attempt": "Tentative",
          "com.lost": "Perdue",
          "phoneNumbers": "N° téléphone",
          "workflowaction.useraccessreinit": "Réinitialiser l'accès",
          "quantity.delivered": "Quantité délivrée",
          "refillsCount": "Nombre de recharges",
          "quantity.avg": "Quantité moyenne",
          "ircom.with": "avec communication véhicule",
          "ircom.without": "sans communication véhicule",
          "quantity.total": "Quantité totale",
          "duration.avg": "Durée moyenne",
          "duration.ecart": "Ecart type durée",
          "performance": "Performance",
          "repartition.count": "Répartition par nombre",
          "repartition.quantity": "Répartition par quantité",
          "repartition.identified": "Répartition par identification véhicule",
          "userrole.ADMIN": "Administrateur - standard",
          "userrole.MANAGER": "Manager - standard",
          "userrole.VIEWER": "Consultant - standard",
          "dataexport": "Export",
          "dataexports": "Exports",
          "dataExports": "Exports",
          "description": "Description",
          "outputFields": "Colonnes",
          "column": "Colonne",
          "order": "Ordre",
          "example": "Exemple",
          "dataExport.outputField.desc.refill.customer.reference": "Référence client",
          "dataExport.outputField.example.refill.customer.reference": "50001",
          "dataExport.outputField.desc.refill.customer.name": "Nom du client",
          "dataExport.outputField.example.refill.customer.name": "Client A",
          "dataExport.outputField.desc.refill.date": "Date de début",
          "dataExport.outputField.example.refill.date": "01/01/2019",
          "dataExport.outputField.desc.refill.hour": "Heure de début",
          "dataExport.outputField.example.refill.hour": "10:07",
          "dataExport.outputField.desc.refill.energyType.productReference": "Référence produit",
          "dataExport.outputField.example.refill.energyType.name": "10",
          "dataExport.outputField.desc.refill.quantity": "Quantité délivrée (en kg)",
          "dataExport.outputField.example.refill.quantity": "2.35",
          "dataExport.outputField.desc.refill.badge.number": "Numéro de badge",
          "dataExport.outputField.example.refill.badge.number": "A123456B",
          "dataExport.outputField.desc.refill.transactionId": "Identifiant de transaction",
          "dataExport.outputField.example.refill.transactionId": "fr.airliquide.orly_D01_1560255593197",
          "dataExport.outputField.desc.refill.vehicle.immatriculation": "Immatriculation du véhicule",
          "dataExport.outputField.example.refill.vehicle.immatriculation": "123-AB-94",
          "dataExport.outputField.desc.refill.driver": "Nom/Prénom du conducteur",
          "dataExport.outputField.example.refill.driver": "Jean Dupont",
          "dataExport.outputField.desc.refill.station.reference": "Référence de la station",
          "dataExport.outputField.example.refill.station.reference": "10",
          "dataExport.outputField.desc.refill.station.name": "Nom de la station",
          "dataExport.outputField.example.refill.station.name": "Orly",
          "dataExport.outputField.desc.refill.dispenser.reference": "Référence du distributeur",
          "dataExport.outputField.example.refill.dispenser.reference": "1",
          "dataExport.outputField.desc.refill.endStatus": "Status de fin",
          "dataExport.outputField.example.refill.endStatus": "10",
          "dataExport.outputField.desc.refill.endSubStatus": "Sous Status de fin",
          "dataExport.outputField.example.refill.endSubStatus": "1",
          "dataExport.outputField.desc.refill.pos": "POS",
          "dataExport.outputField.example.refill.pos": "fillndrive.app",
          "dataExport.outputField.desc.refill.endDate": "Date de fin",
          "dataExport.outputField.example.refill.endDate": "01/01/2019",
          "dataExport.outputField.desc.refill.endHour": "Heure de fin",
          "dataExport.outputField.example.refill.endHour": "10:10",
          "dataExport.outputField.desc.refill.fuelingId": "Identifiant du plein local à la station",
          "dataExport.outputField.example.refill.fuelingId": "123456789",

          "dataExport.outputField.desc.dispenserAvailability.station.reference": "Référence de la station",
          "dataExport.outputField.example.dispenserAvailability.station.reference": "10",
          "dataExport.outputField.desc.dispenserAvailability.station.name": "Nom de la station",
          "dataExport.outputField.example.dispenserAvailability.station.name": "Orly",
          "dataExport.outputField.desc.dispenserAvailability.dispenser": "Référence du distributeur",
          "dataExport.outputField.example.dispenserAvailability.dispenser": "1",
          "dataExport.outputField.desc.dispenserAvailability.start": "Date/Heure de début",
          "dataExport.outputField.example.dispenserAvailability.start": "01/01/2019 10:20",
          "dataExport.outputField.desc.dispenserAvailability.end": "Date/Heure de fin",
          "dataExport.outputField.example.dispenserAvailability.end": "01/01/2019 10:25",
          "dataExport.outputField.desc.dispenserAvailability.event": "Evènement qui déclenche le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.event": "",
          "dataExport.outputField.desc.dispenserAvailability.cloudOnline": "Distributeur connectée au cloud",
          "dataExport.outputField.example.dispenserAvailability.cloudOnline": "true",
          "dataExport.outputField.desc.dispenserAvailability.cloudOnlineBefore": "Distributeur connectée au cloud avant le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.cloudOnlineBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.wfconnectorOnline": "Connecteur WF connectée",
          "dataExport.outputField.example.dispenserAvailability.wfconnectorOnline": "true",
          "dataExport.outputField.desc.dispenserAvailability.wfconnectorOnlineBefore": "Connecteur WF connectée avant le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.wfconnectorOnlineBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.plcOnline": "Lien PLC connecté",
          "dataExport.outputField.example.dispenserAvailability.plcOnline": "true",
          "dataExport.outputField.desc.dispenserAvailability.plcOnlineBefore": "Lien PLC connecté avant le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.plcOnlineBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.canUpdatePLC": appName+" peut piloter le PLC",
          "dataExport.outputField.example.dispenserAvailability.canUpdatePLC": "true",
          "dataExport.outputField.desc.dispenserAvailability.canUpdatePLCBefore": appName+" peut piloter le PLC avant le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.canUpdatePLCBefore": "false",
          "dataExport.outputField.desc.dispenserAvailability.publicState": "Etat interne du distributeur",
          "dataExport.outputField.example.dispenserAvailability.publicState": "open",
          "dataExport.outputField.desc.dispenserAvailability.publicStateBefore": "Etat interne du distributeur avant le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.publicStateBefore": "maintenance",
          "dataExport.outputField.desc.dispenserAvailability.manualState": "Etat manuel du distributeur",
          "dataExport.outputField.example.dispenserAvailability.manualState": "closed",
          "dataExport.outputField.desc.dispenserAvailability.manualStateBefore": "Etat manuel du distributeur avant le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.manualStateBefore": "open",
          "dataExport.outputField.desc.dispenserAvailability.energyStates": "Etats par produit",
          "dataExport.outputField.example.dispenserAvailability.energyStates": "{\"350\":\"open\",\"700\":\"open\"}",
          "dataExport.outputField.desc.dispenserAvailability.energyStatesBefore": "Etats par produit avant le changement d'état",
          "dataExport.outputField.example.dispenserAvailability.energyStatesBefore": "{\"350\":\"maintenance\",\"700\":\"maintenance\"}",
          "apiClients": "API",
          "keyName": "Nom de la clé",
          "keyDate": "Date de la clé",
          "publicKey": "Clé publique",
          "keyId": "ID Clé",
          "no.result": "Aucun résultat",
          "result": "résultat",
          "results": "résultats",
          "loglevel-trace": "trace",
          "loglevel-debug": "debug",
          "loglevel-info": "info",
          "loglevel-analytics": "analytics",
          "loglevel-error": "erreur",
          "loglevel-crash": "crash",
          "sendsms.failed": "Echec d'envoie du SMS, veuillez réessayer ultérieurement.",
          "badgeId.alreadyexist": "Ce numéro de badge est déjà utilisé",
          "phoneNumber.alreadyexist": "Ce numéro de téléphone est déjà utilisé",
          "email.alreadyused": "Cette adresse email est déjà utilisée",
          "error.object.notvalid": "Les données sont invalides",
          "inputfield.required": "Champ obligatoire, valeur manquante",
          "object.notfound": "Valeur incorrecte/inconnue",
          "field.required.driver_or_vehicle": "Un téléphone doit être associé à un conducteur ou un véhicule",
          "language-fr": "Français",
          "language-en": "Anglais",
          "language": "Langue",
          "h2mapShared": "Partager le statut avec H2MAP",
          "network": "Réseau",
          "networks": "Réseaux",
          "stationNetwork": "Réseau",
          "stationNetworks": "Réseaux",
          "menu.contracts": "Contrats", 
          "customerContract": "Contrat client",
          "customerContracts": "Contrats client",
          "private": "Privé",
          "customerContract.status.created": "Créé",
          "customerContract.status.active": "Actif",
          "customerContract.status.closed": "Clôturé",
          "customerContract.status.outdated": "Terminé",
          "vehicleModel": "Modèle de véhicule",
          "vehicleModels": "Modèles de véhicule",
          "workflowaction.activate": "Activer",
          "action.contract.activate": "Activer le contrat",
          "workflowaction.close": "Clôturer",
          "action.contract.close": "Clôturer le contrat",
          "customerContract.alreadyexist": "Un contrat associé à cette flotte et à au moins 1 même réseau de station est déjà actif sur la même période.",
          "inputfield.dateMustEndAfterStart": "La date de fin doit supérieure à la date de début",
          "operatorContracts": "Contrats Opérateurs",
          "operatorContract": "Contrat Opérateurs",
          "kpi.top5.title": "Top 5 clients",
          "progress.stable": "stable",
          "poss": "Points de vente",
          "efficiency": "Efficacité",
          "summary": "Résumé",
          "kpi.refillgroups.title": "Recharges",
          "periode.last30days": "30 derniers jours",
          "periode.today": "Aujourd'hui",
          "total": "Total",
          "average": "Moyenne",
          "nodata.onperiode": "Aucune donnée sur la période",
          "unknown": "Inconnu",
          "not.identified": "Non identifié",
          "anomalies": "Anomalies",
          "filter.last7days": "7 derniers jours",
          "filter.last14days": "14 derniers jours",
          "filter.last30days": "30 derniers jours",
          "filter.last90days": "90 derniers jours",
          "filter.trimester": "Trimestre",
          "filter.semester": "Semestre",
          "count": "Nombre",
          "socs.start": "SOCs de début",
          "socs.end": "SOCs de fin",
          "vehicleCommunicationLost": "Communication perdue",
          "failedToStart": "Echec démarrage",
          "endError": "Terminé en échec",
          "not.satisfying": "Non satisfaisant",
          "satisfying": "Satisfaisant",
          "very.satisfying": "Trés satisfaisant",
          "state.shutdown": "Eteint",
          "endStatus": "Statuts de fin",
        }
      }
    },
    fallbackLng: 'en',

    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    keySeparator: false,
 
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
 
    react: {
      wait: true
    }
  });
 
export default i18n;
