import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

class AuthorizationView extends Component {

    render() {
     const { t, object } = this.props;

        const details = object.deviceType === 'web' ? (
            <div className="form-read-field">
                <span className="form-read-field-label">{t('delivery.by')} : </span>{object.creator ? (object.creator.firstName+' '+object.creator.lastName) : '-'}
           </div>
        ) : object.deviceType === 'badge' ? (
            <div className="form-read-field">
                <span className="form-read-field-label">{t('badge')} : </span>{object.badge ? (object.badge.badgeId) : '-'}
           </div>
        ) : null;

       return (<div className="plcbloc">
           <div className="form-read-field">
                <span className="form-read-field-label">Id : </span>{t(object.id)}
           </div>
            {details}
       </div>)
    }
    
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    return {
        itemId,
        data,
    };
}

const connectedAuthorizationView = connect(mapStateToProps)(translate('translations')(AuthorizationView));
export { connectedAuthorizationView as AuthorizationView };