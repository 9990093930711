import React from 'react';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import BlocSubPartView from '../common/BlocSubPart';
import ProgressionPercentView from '../common/ProgressionPercent';

class RefillGroupSummary extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { t, mode, loading, data, previousData } = this.props;
        
        if (loading) {
            return this.renderLoading(t, mode)
        }

        if (data && data.total) {
            const margin = mode === 2 ? '4rem' : 0
            return <div style={{marginTop: margin, marginBottom: margin}}>{this.renderKpiBloc( t, mode, data.total, previousData.total )}</div>
        }
 
        return <NoDataView mode={mode}/>
    }

    renderLoading = ( t, mode ) => {

        const quantityContent = <div style={{paddingRight: 20}}><Skeleton/></div>
        const countContent = <div style={{paddingRight: 20}}><Skeleton/></div>
        const efficiencyContent = <div style={{paddingRight: 20}}><Skeleton/></div>
        const anomaliesContent = <div style={{paddingRight: 20}}><Skeleton/></div>

        return this.__renderBloc(t, mode, quantityContent, countContent, efficiencyContent, anomaliesContent)

    }

    renderKpiBloc = ( t, mode, rawData, previousRawData ) => {
        
        const emptyTotalValue = {quantity: 0, count: 0, countFirstAttemptSuccess: 0, failedToStartCount: 0, endWithErrorCount: 0}

        const totalData = rawData.length > 0 ? rawData[0] : emptyTotalValue

        const totalQuantity = KpiTools.formatQuantity(totalData.quantity)
        const totalEfficiency = totalData.count > 0 ? Math.round(totalData.countFirstAttemptSuccess / totalData.count * 100) : 0
        const totalAnomalies = totalData.failedToStartCount + totalData.endWithErrorCount
        
        const previousTotalData = previousRawData && previousRawData.length > 0 ? previousRawData[0] : emptyTotalValue;
        const previousTotalAnomalies = previousTotalData.failedToStartCount + previousTotalData.endWithErrorCount

        let quantityProgression;
        let countProgression;
        let efficiencyProgression;
        let anomaliesProgression;

        if (previousTotalData) {
            
            const previousTotalQuantity = KpiTools.formatQuantity(previousTotalData.quantity)

            quantityProgression = <ProgressionPercentView currentValue={totalQuantity} previousValue={previousTotalQuantity}/>
            countProgression = <ProgressionPercentView currentValue={totalData.count} previousValue={previousTotalData.count}/>
            anomaliesProgression = <ProgressionPercentView currentValue={totalAnomalies} previousValue={previousTotalAnomalies}/>

            if (totalData.count > 0 && previousTotalData.count > 0) {
                const previousTotalEfficiency = Math.round(previousTotalData.countFirstAttemptSuccess / previousTotalData.count * 100)
                efficiencyProgression = <ProgressionPercentView currentValue={totalEfficiency} previousValue={previousTotalEfficiency}/>
            }
        }

        const quantityContent = <div>
                                    <span className={'kpi-value kpi-value-main'}>{totalQuantity}</span>
                                    <span className={'kpi-value kpi-value-help'}>Kg</span>
                                    {quantityProgression}
                                </div>

        const countContent = <div>
                                <span className={mode ===  2 ? 'kpi-value kpi-value-main' : 'kpi-value kpi-value-second'}>{totalData.count}</span>
                                {countProgression}
                            </div>

        const efficiencyContent = <div>
                                    <span className={mode ===  2 ? 'kpi-value kpi-value-main' : 'kpi-value kpi-value-second'}>{totalData.count > 0 ? (totalEfficiency+'%') : ('-')}</span>
                                    {efficiencyProgression}
                                </div>

        const anomaliesContent = <div>
                                    <span className={mode ===  2 ? 'kpi-value kpi-value-main' : 'kpi-value kpi-value-second'}>{totalAnomalies}</span>
                                    {anomaliesProgression}
                                </div>

        return this.__renderBloc(t, mode, quantityContent, countContent, efficiencyContent, anomaliesContent)

    }

    __renderBloc = ( t, mode, quantity, count, efficiency, anomalies ) => {

        if (mode === 2) {
            return <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center" >
                        <Grid item xs={3}>
                            <div className={'kpi-view-subtitle'}>{t('quantity.total')}</div>
                            {quantity}
                        </Grid>
                        <Grid item xs={3}>
                            <div className={'kpi-view-subtitle'}>{t('count')}</div>
                            {count}
                        </Grid>
                        <Grid item xs={3}>
                            <div className={'kpi-view-subtitle'}>{t('efficiency')}</div>
                            {efficiency}
                        </Grid>
                        <Grid item xs={3}>
                            <div className={'kpi-view-subtitle'}>{t('anomalies')}</div>
                        {anomalies}
                        </Grid>
                    </Grid>
        }

        const content = <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" >
                            <Grid item xs={4}>
                                <div className={'kpi-view-subtitle'}>{t('count')}</div>
                                {count}
                            </Grid>
                            <Grid item xs={4}>
                                <div className={'kpi-view-subtitle'}>{t('efficiency')}</div>
                                {efficiency}
                            </Grid>
                            <Grid item xs={4}>
                                <div className={'kpi-view-subtitle'}>{t('anomalies')}</div>
                            {anomalies}
                            </Grid>
                        </Grid>

        return <div>
                    <BlocSubPartView subtitle={t('quantity.total')} content={quantity}/>
                    <BlocSubPartView content={content}/>
            </div>
    }

}

const connectedBloc = translate('translations')(RefillGroupSummary);
export { connectedBloc as RefillGroupSummary };