const CACHE_IDS_PROPS = "local.cache.CACHES_IDS";

function cacheVal(cacheId, key, val) {
	
	var now = new Date();
	var cache = null;
	
	if(cacheId) {
	
		cache = getCache(cacheId);

		cache[key] = val;
		
		window.sessionStorage.setItem(cacheId, JSON.stringify(__serializeJson(cache)));
	}
}

function getCache(cacheId) {
	
	var cache = null;
	
	if(cacheId) {
		cache = __deSerializeJson(JSON.parse(window.sessionStorage.getItem(cacheId)));
		addCacheId(cacheId);
	}

	if(cache == null) {
		cache = { };
	}

	return cache;	
}

function resetCachesWithPrefix(cacheIdPrefix) {
	
	getCacheIds().forEach(function(cacheId) {
		if(cacheId.startsWith(cacheIdPrefix)) {
			resetCache(cacheId);
		}
	});
}

function resetCache(cacheId) {
	
	if(cacheId) {
		window.sessionStorage.setItem(cacheId, null);
	}
}

function __serializeJson (obj) {

	var serializedObj = { type: "unknown", val: obj };
	
	if(obj != null && obj != undefined) {
	
		if(typeof obj === 'string') {
		
			serializedObj = { type: "string", val: obj };
		
		} else if(obj instanceof Date) {
			
			serializedObj = { type: "date", val: obj.toString() };
		
		} else if (typeof obj === 'number') {
			
			serializedObj = { type : "number", val : obj.toString() };

		} else if(Array.isArray(obj)) {
			
			serializedObj.type = "array" ;
			serializedObj.val = [];
			
			obj.forEach(function(item) {
				serializedObj.val.push(__serializeJson(item));
			});
			
		} else if (typeof obj === 'object' && obj.constructor === Object) {
		
			serializedObj.type = "object";
			serializedObj.val = {};
			
			Object.keys(obj).forEach(function(key) {
				serializedObj.val[key] = __serializeJson(obj[key]);
			});
		}
	}
	
	return serializedObj;
}

function __deSerializeJson(obj) {
	
	var desirealizedObj = null;
	
	if(obj != null && obj != undefined) {
	
		if(obj.type == 'string') {
			
			desirealizedObj = obj.val;
			
		} else if(obj.type == 'date') {

			desirealizedObj = new Date(obj.val);
			
		} else if(obj.type == 'number') {
			
			// Si besoin, ajouter la gestion pour float et 
			//autre type de nombre.
			desirealizedObj = parseInt(obj.val);
			
		} else if(obj.type == 'array') {
			
			desirealizedObj = [];
			
			obj.val.forEach(function(item) {
				desirealizedObj.push(__deSerializeJson(item));
			});
			
		} else if(obj.type == 'object') {
			
			desirealizedObj = {};
			
			Object.keys(obj.val).forEach(function(key) {
				desirealizedObj[key] = __deSerializeJson(obj.val[key]);
			});
			
		} else if(obj.type == 'unknown') {
			desirealizedObj = obj.val;
		}
	}

	return desirealizedObj;
}

function addCacheId(cacheId) {

	var cachesIdArray = JSON.parse(window.sessionStorage.getItem(CACHE_IDS_PROPS));
	var cachesIdSet = new Set(cachesIdArray);
	var newCachesIdArray = [];
	
	if(cacheId) {
		cachesIdSet.add(cacheId);
	}
	
	newCachesIdArray = Array.from(cachesIdSet);
	
	window.sessionStorage.setItem(CACHE_IDS_PROPS, JSON.stringify(newCachesIdArray));
}

function getCacheIds() {
	var cachesIdArray = JSON.parse(window.sessionStorage.getItem(CACHE_IDS_PROPS));
	return new Set(cachesIdArray);
}


module.exports.cacheVal = cacheVal;
module.exports.getCache = getCache;
module.exports.resetCache = resetCache;
module.exports.resetCachesWithPrefix = resetCachesWithPrefix;