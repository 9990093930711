import React, { PureComponent } from 'react';

import { DateReadView } from './Date';
import { DurationReadView } from './Duration';
import { StateReadView } from './State';
import { StationStateReadView } from './StationState';
import { StationDispenserStateReadView } from './StationDispenserState';
import { QuantityGrammeReadView } from './QuantityGramme';
import { TemperatureView } from './Temperature';
import { SocView } from './Soc';
import { PosView } from './Pos';
import { VehicleComState } from './VehicleComState';
import { EnergyTypeView } from './EnergyType';
import { Roles } from './Roles';

import Button from '@material-ui/core/Button';

import FieldDisplay from './FieldDisplay';

import { commonActions } from '../../_actions';

export default class FieldViewSimple extends PureComponent {

    render() {
        const { classes, t, label, value, fieldConfig, subObjectModel, fieldClass, valueClass } = this.props;
            
        let displayedValue;
		if (fieldConfig.object && fieldConfig.type === 'roles') {
			
			displayedValue = <Roles value={value} 
								t={t} 
								translationKey={fieldConfig.translationKey}
								profilTranslationKey={subObjectModel.fields["profil"].translationKey}
							/>;
				  
        } else {
			
			if (fieldConfig.object) {
            
				if (fieldConfig.array) {
					displayedValue = value ? (subObjectModel.toString ? value.map( v => subObjectModel.toString(v)).join(',') : value.join(',')) : '';
				} else {
					displayedValue = value ? (subObjectModel.toString ? subObjectModel.toString(value) :value) : '';

					if (fieldConfig.type === 'energyType') {
						displayedValue = <EnergyTypeView t={t} value={value} />;
					}

					if(fieldConfig.type === 'download') {
						displayedValue = <Button color="primary" onClick={() => this.handleDownload()}>
											{displayedValue}
										 </Button>
					}
				}
				
			} else if (fieldConfig.values) {
				displayedValue = (value !== null && value != undefined) ? t(fieldConfig.values[value]) : '';
			} else if (fieldConfig.type === 'boolean') {
				displayedValue = t((value === true || value === 1 ? 'yes':'no'));
			} else if (fieldConfig.type === 'date') {
				displayedValue = <DateReadView value={value} format={fieldConfig.format}/>;
			} else if (fieldConfig.type === 'duration') {
				displayedValue = <DurationReadView value={value} />;
			} else if (fieldConfig.type === 'quantity-gramme') {
				displayedValue = <QuantityGrammeReadView t={t} value={value}/>
			} else if (fieldConfig.type === 'state') {
				displayedValue = <StateReadView t={t} value={value} />;
			} else if (fieldConfig.type === 'stationstate') {
				displayedValue = <StationStateReadView t={t} value={value} />;
			} else if (fieldConfig.type === 'stationdispenserstate') {
				displayedValue = <StationDispenserStateReadView t={t} value={value} />;
			} else if (fieldConfig.type === 'temperature') {
				displayedValue = <TemperatureView t={t} value={value} />;
			} else if (fieldConfig.type === 'vehicleComState') {
				displayedValue = <VehicleComState t={t} value={value} />;
			} else if (fieldConfig.type === 'soc') {
				displayedValue = <SocView t={t} value={value} />;
			} else if (fieldConfig.type === 'pos') {
				displayedValue = <PosView t={t} value={value} />;
			} else if (fieldConfig.type === 'energyType') {
				displayedValue = <EnergyTypeView t={t} value={value} />;
			} else {
				displayedValue = value;
			}

			if (fieldConfig.translationKey) {
				displayedValue = t(fieldConfig.translationKey + displayedValue, displayedValue);
			}
		}

        return <FieldDisplay label={label} value={displayedValue} fieldClass={fieldClass} valueClass={valueClass}/>
    }
	
	handleDownload() {
		
		var id = this.props.value.id;
		var filename = this.props.value[this.props.fieldConfig.filenameProvider];
		
		commonActions.getMediaFileContent(id, filename);
	}
}
