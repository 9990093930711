import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import SearchIcon from '@material-ui/icons/Search';
import AttachmentIcon from '@material-ui/icons/AttachFile';

import { commonActions } from '../../_actions';

const styles = theme => ({
	
	globalDiv: {
		marginLeft: '20px'
	}
});

class Upload extends Component {
	
	state = {};
	
	constructor(props) {
		super(props);
		this.fileInput = React.createRef();
		this.scope = this.props.scope + "_media_file";
		this.uploadId = this.props.uploadId;
	}
	
	componentWillMount() {
		if(this.props.initFilename) {
			this.setState({selectedFilePath: this.props.initFilename});
		}
	}
	
	onSelectFile(e) {
			
		var data = this.getData();
		var formData = new FormData();
		var theFile = this.fileInput.current.files[0]
		formData.append("file", theFile);

		if(this.uploadId) {
			formData.append("id", this.uploadId);
		} if(data != null && data.item != null) {
			formData.append("id", data.item.id);
		}
		
		this.props.dispatch(commonActions.sendMediaFile(this.scope, formData, (newMedia) => {
			this.props.onChange(newMedia);
			this.setState({selectedFilePath: theFile.name});
		}));
	}		
	
	getData() {
		
		let { data, classes } = this.props;

		if (data && this.scope) {
			const scopedData = data[this.scope];
			data = scopedData ? scopedData : data;
		}  
		
		return data;
	}
	
	render() {
		
		const {value} = this.props;

		const { t, classes, label } = this.props;		
		const filepath = this.state.selectedFilePath ? this.state.selectedFilePath : "";
		
		return 	<div className={classes.globalDiv}>

					<TextField label={t(label)} id="raised-button-file" value={filepath} disabled="true"></TextField>

					<Button component="label">
					  <AttachmentIcon/>
					  <input
						type="file"
						ref={this.fileInput}
						onChange={(e) => this.onSelectFile(e)}
						style={{ display: "none"}}	
					  />	
					</Button>
				</div>
	}
}


function mapStateToProps(state) {
	
	const { item, itemId, data, authentication, appcontext } = state;
	const { user, context } = authentication;

	return {
		item,
		itemId,
		data,
		user,
		appcontext,
		context
	};
}

const connectedCustomReadObject = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Upload)));
export { connectedCustomReadObject as Upload };