import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import DoughnutChartView from '../common/DoughnutChart';
import PieChartLoadingView from '../common/PieChartLoading';

const BACKGROUND_COLORS = ['#ff000061', '#ffe6aa', '#7dff81']
const BORDER_COLORS = ['#ff0000ab', '#fecf5f', '#00f107']

const COLORS = BACKGROUND_COLORS.map( (value, index) => {
    return {backgroundColor: value, borderColor: BORDER_COLORS[index]} 
})

class SocsSatisfactionView extends React.Component {

    render() {

        const { t, mode, loading, data, previousData, kpiField, socField } = this.props;
        
        if (loading) {
            return <PieChartLoadingView/>
        }

        if (data && data[kpiField] && data[kpiField].length > 0) {
            return <div style={{marginTop: 0, marginLeft: '2rem', marginRight: '2rem'}}>{this.renderKpiBloc( t, data[kpiField], previousData[kpiField], socField )}</div>
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData, previousRawData, socField ) => {

        const repartition = {'0': 0, '1': 0, '2': 0}
        

        let total = 0

        rawData.forEach( endSocKpi => {
            const socValue = endSocKpi._id[socField]
            if ( socValue !== 'unkwown') {
                
                if (socValue >= 95){
                    repartition['2'] += endSocKpi.count
                } else if (socValue >= 90){
                    repartition['1'] += endSocKpi.count
                } else {
                    repartition['0'] += endSocKpi.count
                }
                
                total += endSocKpi.count
            }
        })

        const previousRepartition = {'0': 0, '1': 0, '2': 0}
        let previousTotal = 0

        previousRawData.forEach( endSocKpi => {

            const socValue = endSocKpi._id[socField]

            if (socValue !== 'unkwown') {
                
                if (socValue >= 95){
                    previousRepartition['2'] += endSocKpi.count
                } else if (socValue >= 90){
                    previousRepartition['1'] += endSocKpi.count
                } else {
                    previousRepartition['0'] += endSocKpi.count
                }
                
                previousTotal += endSocKpi.count
            }
        })

        const repartitionPercents = Object.values(repartition).map( value => { return Math.round( value / total * 100)})

        const progression = {}

        Object.keys(previousRepartition).forEach( key => {
            progression[key] = KpiTools.computeProgression(Math.round( repartition[key] / total * 100), Math.round( previousRepartition[key] / previousTotal * 100))
        })

        const progressionValues = Object.values(progression)

        const legendLabels = ['< 90', '90 < 95', '>= 95']
        const valueLabels = repartitionPercents.map( p => p+'%')
        

        const options = KpiTools.makeCustomTooltips(legendLabels, valueLabels, COLORS, progressionValues)

        const data = {
            labels: legendLabels,
            datasets: [
            {
                label: valueLabels, 
                data: repartitionPercents,
                backgroundColor: BACKGROUND_COLORS,
                borderColor: BORDER_COLORS
            }
            ]
        };

        return <DoughnutChartView data={data} options={options}/>

    }

}

const translatedBloc = translate('translations')(SocsSatisfactionView);
export { translatedBloc as SocsSatisfactionView };