import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

class TextInput extends Component {
    
    state = {};
    input = null;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.state.value = (this.props.value !== null &&  this.props.value!==undefined) ? this.props.value : '';
      }

    componentWillReceiveProps(nextProps) {
        const { value } = nextProps;
        this.setState({value: value});
    }

    render() {
    
       const { t, id, label, fieldError, readonly, onChange, onBlur, icon } = this.props;

        const endAdornment = icon ? <InputAdornment position="end">
                {icon}
            </InputAdornment> : null;

        const hasErrors = fieldError && fieldError.length > 0;

       return (
           <div className="form-edit-field">
        <FormControl error={hasErrors} >
            <InputLabel htmlFor="component-simple">{label}</InputLabel>
            <Input inputRef={(input) => { this.input = input; }} id={id} disabled={readonly ? true : false} value={this.state.value} defaultValue={this.state.value} onChange={onChange} onBlur={onBlur} endAdornment={endAdornment}/>
            {hasErrors && <FormHelperText id={'component-error-' + id}>{t(fieldError[0].message)}</FormHelperText>}
        </FormControl>
        </div>
       );
    }
}

const connectedCustomReadObject = TextInput;
export { connectedCustomReadObject as TextInput };