import React from 'react';

import Grid from '@material-ui/core/Grid';

export default class GridRowView extends React.PureComponent {

    render() {

        const { mode, spacing, justify, cells, fullSize } = this.props

        return <Grid
            container
            direction="row"
            justify={justify ? justify : "center"}
            alignItems="center"
            spacing={spacing}>
                {this.__makeGridCells(mode, cells, fullSize ? fullSize : (4/3))}
            </Grid>
    
    }

    __makeGridCells = (mode, cells, fullSize) => {

        const notEmptyElements = cells.filter( element => element )
    
        const columnCount = notEmptyElements.length
        //const columnXsSize = columnCount > 6 ? 1 : 12 / columnCount
        const columnXsSize = columnCount > 6 ? 1 :( 12 / (columnCount > 1 ? columnCount : mode < 2 ? 1 : fullSize) )
        return notEmptyElements.map( (element, index) =>
            <Grid key={'grid_cell_'+index} item xs={columnXsSize}>
                {element}
            </Grid>
        )
    
    }

}