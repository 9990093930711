import React from 'react';
import moment from 'moment';
import underscore from 'underscore';

import { translate } from 'react-i18next';

import AccountCircle from '@material-ui/icons/AccountCircle';
import GroupIcon from '@material-ui/icons/Group';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PersonIcon from '@material-ui/icons/Person';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EvStationIcon from '@material-ui/icons/EvStation';
import OpacityIcon from '@material-ui/icons/Opacity';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocationIcon from '@material-ui/icons/LocationOn';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import ScannerIcon from '@material-ui/icons/Scanner';
import KitchenIcon from '@material-ui/icons/Kitchen';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ShareIcon from '@material-ui/icons/Share';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SmsIcon from '@material-ui/icons/Sms';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import UsbIcon from '@material-ui/icons/Usb';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import Battery80Icon from '@material-ui/icons/Battery80';
import SendIcon from '@material-ui/icons/Send';
import StopIcon from '@material-ui/icons/Stop';
import {BadgeIcon} from '../_components/icons/badge';
import {PushPinIcon} from '../_components/icons/pushpin';
import {PushPinOffIcon} from '../_components/icons/pushpinoff';
import SubjectIcon from '@material-ui/icons/Subject';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import DnsIcon from '@material-ui/icons/Dns';

import ExtensionIcon from '@material-ui/icons/Extension';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import GetAppIcon from '@material-ui/icons/GetApp';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import BatteryChargingFullIcon from '@material-ui/icons/BatteryChargingFull';

import {RefillStepRaw} from '../_components/read/RefillStepRaw';

import {RefillsByDayResumeView} from '../_components/kpi/RefillsByDayResume';
import {StateReadView} from '../_components/read/State';
import { StationDispenserStateReadView } from '../_components/read/StationDispenserState';
import { EnergyTypeView } from '../_components/read/EnergyType';
import { RunningRefillView } from '../_components/read/RunningRefill';
import { DeviceInfos } from '../_components/read/DeviceInfos';
import { RefillGroupView } from '../_components/read/RefillGroup';

import { makePeriode } from '../_helpers';

const PROFIL_VALUES = {'SERVICE_MANAGER': 'SERVICE_MANAGER', 'STATION_OPERATOR': 'STATION_OPERATOR'};

export const Objects = [
    {name: 'userRole', fields: {
        'id': {},
        'name': {create: true, update: false, required: true, translationKey: 'userrole.'},
        'profil': {create: true, update: true, values: PROFIL_VALUES, translationKey: 'profil.'},
        'operator': {create: true, update: true, object:'operator'},
        'serviceProvider': {create: true, update: true, object:'serviceProvider'},
        'customer': {create: true, update: true, object:'customer'},
    }, list: {
        fields: [
            {name: 'name'},
            {name: 'profil'},
            {name: 'serviceProvider'},
            {name: 'customer'}
        ]
    }, view: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            {name: 'profil', type: 'choice', sourcetype: 'value'},
            {name: 'name', type: 'text'},
        ]
    }, creation: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            {name: 'profil', type: 'choice', sourcetype: 'value'},
            {name: 'name', type: 'text'},
        ]
    }, edition: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            {name: 'profil', type: 'choice', sourcetype: 'value'},
            {name: 'name', type: 'text'},
        ]
    }, toString: (o, t) => { 
		let role = t ? t('userrole.'+o.name) : o.name;
		let profil = t ? t('profil.'+o.profil) : o.profil;
		
		return role +' ('+profil+')';
	}},
    {name: 'userGroup', icon: <GroupIcon/>, fields: {
        'id': {},
        'name': {create: true, update: false, required: true},
        'operator': {create: true, update: true, object:'operator'},
        'serviceProvider': {create: true, update: true, object:'serviceProvider'},
        'customer': {create: true, update: true, object:'customer'},
    }, list: {
        fields: [
            {name: 'name'},
        ]
    }, view: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, toString: o => o.name },
    {name: 'user', icon: (<AccountCircle/>), menu: true , fields: {
        'id': {},
        'operator': {create: true, update: true, object:'operator'},
        'serviceProvider': {object:'serviceProvider'},
        'customer': {object:'customer'},
        'email': {create: true, update: false, required: true, maxLength: 250, regexp: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/},
        'firstName': {required: true},
        'lastName': {required: true},
        'phone': {maxLength: 10, regexp:/^[0-9]{10}$/},
        'profils': {array: true, required: true, values: PROFIL_VALUES, translationKey: 'profil.'},
        'group': {object:'userGroup', tableFilter: { }},
        'roles': {translationKey: 'userrole.', array: true, object:'userRole', type: 'roles', required: true, filter: [{
            field: 'profil',
            path: 'profils'
        }, {
            field: 'customer',
            path: 'customer.id'
        }], tableFilter: { }},
        'lastAccess': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'access': {object:'userAccess', array: true, child: true},
        'enabled': {type: 'boolean', default: true},
    }, list: {
        fields: [
            {name: 'firstName'},
            {name: 'lastName'},
            {name: 'email'},
            {name: 'group', show: (user, appcontext, context, o) => !user.user.group},
            {name: 'roles', type: 'roles'},
            {name: 'lastAccess'}
        ],
        search: true
    }, view: {
        fields: [
            /*{name: 'userRole', type: 'choice', sourcetype: 'object', source:'userRole'},*/
            {name: 'roles', type: 'roles', sourcetype: 'object', source:'userRole'},
            {name: 'group', type: 'choice', sourcetype: 'object', source:'userGroup', show: (user, appcontext, context, o) => !user.user.group},
            {name: 'email', type: 'text', readonly: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            /*{name: 'phone', type: 'text', required: true}*/
            {name: 'access', type: 'embeddedlist'}
        ]
    }, creation: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'customer', type: 'choice', sourcetype: 'object', source:'customer'},
            {name: 'roles', type: 'choice', groupField:'profil', uniqueByGroup: true , sourcetype: 'object', source:'userRole', dependancy: {
                'profils': {required: true, sourcefilter: true}
            }},
            {name: 'email', type: 'text', required: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'group', type: 'choice', sourcetype: 'object', source:'userGroup', show: (user, appcontext, context, o) => { return !user.user.group}},
        ]
    }, edition: {
        fields: [
            {name: 'roles', type: 'choice', groupField:'profil', uniqueByGroup: true, sourcetype: 'object', source:'userRole', dependancy: {
                'profils': {required: true, sourcefilter: true}
            }},
            {name: 'email', type: 'text', readonly: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'group', type: 'choice', sourcetype: 'object', source:'userGroup', show: (user, appcontext, context, o) => !user.user.group},
        ]
    }, createObject: function(newItem, user, context) {

        if (newItem.profils.indexOf('FLEET_MANAGER') === -1) {
            newItem.profils = []
            if (user.user.operator) {
                newItem.profils.push('STATION_OPERATOR')
            }
            if (user.user.serviceProvider) {
                newItem.profils.push('SERVICE_MANAGER')
            } 
        }

        return newItem;
    }},
    {name: 'userAccess', icon: (null), menu: false, fields: {
        'id': {},
        'context': {},
        'validated': {type: 'boolean'},
        'u2fs': {},
        'lastAccess': {type: 'date'},
    },
    viewEmbedded: {
        fields: [
            {name: 'validated'}
        ],
        workflowActions: [
            {
                name: 'usersendemailvalidation',
                icon: (<SendIcon/>),
                title: 'action.usersendemailvalidation',
                //intro:'action.enable.intro',
                validationLabel:'action.send',
                enabled: object => {
                    return !object.validated;
                },
                needReload: true
            },
            {
                name: 'useraccessreinit',
                icon: (<SendIcon/>),
                title: 'action.useraccessreinit',
                //intro:'action.enable.intro',
                validationLabel:'action.reinit',
                enabled: object => {
                    return object.validated;
                },
                needReload: true
            },
        ]
    }
    },
    {name: 'userU2F', icon: (<UsbIcon/>), menu: false, fields: {
        'id': {},
        'name': {},
        'ready': {type: 'boolean', displayName: 'associated'},
        'enabled': {type: 'boolean'},
        'createdAt': {type: 'date', displayName: 'added.on'},
        'registrationResponse': {required: true, type: 'u2fregistration', displayName: 'registration'},
    }, list: {
        fields: [
            {name: 'createdAt'},
            {name: 'name'},
            {name: 'ready'},
            {name: 'enabled'}
        ]
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'registrationResponse'}
        ]
    }, edition: {
        fields: [
            {name: 'name'}
        ]
    }
    },
    {name: 'userTOTP', icon: (<MobileFriendlyIcon/>), menu: false, fields: {
        'id': {},
        'name': {},
        'ready': {type: 'boolean', displayName: 'associated'},
        'enabled': {type: 'boolean'},
        'createdAt': {type: 'date', displayName: 'added.on'},
        'token': {required: true, type: 'totpregistration', displayName: 'token'},
    }, list: {
        fields: [
            {name: 'createdAt'},
            {name: 'name'},
            {name: 'ready'},
            {name: 'enabled'}
        ]
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'token'}
        ]
    }, edition: {
        fields: [
            {name: 'name'}
        ]
    }
    },
    {name: 'operator', icon: (<AccountCircle/>), menu: true, fields: {
        'id': {},
        'publicId': {},
        'name': {},
        'img': {},
    }, list: {
        fields: [
            {name: 'publicId'},
            {name: 'name'}
        ]
    }, view: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    }, creation: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    }, edition: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    },
    toString: object => {return object.name}},
    {name: 'serviceProvider', icon: (<AccountCircle/>), menu: true, fields: {
        'id': {},
        'publicId': {},
        'name': {},
        'customers': {array: true, child: true, object:'customer'},
    }, list: {
        fields: [
            {name: 'publicId'},
            {name: 'name'}
        ]
    }, view: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'customers', type: 'list'},
        ]
    }, creation: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    }, edition: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true}
        ]
    },
    toString: object => {return object.name}},
    {name: 'energy', icon: (<AccountCircle/>), menu: true, fields: {
        'id': {},
        'name': {},
        'types': {array: true, child: true, object:'energyType'},
    }, list: {
        fields: [
            {name: 'name'}
        ]
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'types', type: 'ownlist', sourcetype: 'object', source:'energyType'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'types', type: 'ownlist', sourcetype: 'object', source:'energyType'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'types', type: 'ownlist', sourcetype: 'object', source:'energyType'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'energyType', icon: (<AccountCircle/>), fields: {
        'id': {},
        'name': {},
    }, list: {
        fields: [
            {name: 'name'}
        ]
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
        ]
    },
    toString: object => {return object.name}},
    {name: 'customer', icon: (<FolderSharedIcon/>), menu: true, fields: {
        'id': {},
        'reference': {},
        'name': {required: true},
        'supportphone': {},
        'language': {type: 'language'},
        'country': {type: 'country'},
        'energies': {array: true, object:'energyType'},
        'status': {values: {0: 'customer.status.closed', 1: 'customer.status.created', 2: 'customer.status.ready', 3: 'customer.status.locked'}, tableFilter: { }},
        'contracts': {array: true, child: true, object:'customerContract'},
        'fleets': {array: true, child: true, object:'fleet'},
        'serviceProvider': {object:'serviceProvider'},
    }, parentField: 'serviceProvider',
    list: {
        fields: [
            {name: 'name'},
            {name: 'supportphone'},
            {name: 'status'}
        ],
        search: true,
        /*kpis: [
            {name: 'top5', type: 'horizontalBar', defaultPeriode: 'last30days'}
        ]*/
    }, preview: {
        fields: [
            {name: 'name'},
            {name: 'reference'}
        ],
        search: true
    }, view: {
        fields: [
            {name: 'name'},
            {name: 'reference'},
            {name: 'status', type: 'choice', sourcetype: 'value'},
            {name: 'supportphone'},
            {name: 'energies', type: 'choicelist', sourcetype: 'object', source:'energyType'},
            {name: 'fleets', type: 'list', embeddedView: true, listEdition: true}
        ], workflowActions: [
            {
                name: 'customervalidate',
                icon: (<CheckIcon/>),
                title: 'action.customervalidate',
                //intro:'action.enable.intro',
                validationLabel:'action.validate',
                enabled: object => {
                    return object.status === 1;
                },
                needReload: true
            },
            {
                name: 'customerlock',
                icon: (<LockIcon/>),
                title: 'action.customerlock',
                //intro:'action.enable.intro',
                validationLabel:'action.lock',
                enabled: object => {
                    return object.status === 2;
                },
                needReload: true
            },
            {
                name: 'customerunlock',
                icon: (<LockOpenIcon/>),
                title: 'action.customerunlock',
                //intro:'action.enable.intro',
                validationLabel:'action.unlock',
                enabled: object => {
                    return object.status === 3;
                },
                needReload: true
            },
            {
                name: 'customerclose',
                icon: (<CloseIcon/>),
                title: 'action.customerclose',
                validationLabel:'action.close',
                enabled: object => {
                    return object.status > 1;
                },
                needReload: true
            }
        ]
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'reference'},
            {name: 'country', type: 'country'},
            {name: 'language', type: 'language'},
            {name: 'supportphone'},
            {name: 'energies', type: 'choicelist', sourcetype: 'object', source:'energyType'},
            {name: 'fleets', type: 'choicelist', sourcetype: 'object', source:'fleet', canCreate: true, embedded: true},
        ]
    }, edition: {
        fields: [
            {name: 'name'},
            {name: 'reference'},
            {name: 'country', type: 'country'},
            {name: 'language', type: 'language'},
            {name: 'supportphone'},
            {name: 'energies', type: 'choicelist', sourcetype: 'object', source:'energyType'}
        ]
    },
    kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'last30days', view: {mode: 1}, full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byVehicleModel', name: 'vehicleModels', icon: <DirectionsCarIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }},
            {name: 'refills', type: 'attemptsSummary', defaultPeriode: 'last30days', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                ]
            }},
        ],
        full: {object: true}
    }, createObject: function(newItem, user, context) {

        newItem.fleets = [{}]

        return newItem;
    },
    toString: object => {return object.name}},
    {name: 'address', icon: (<LocationCityIcon/>), fields: {
        'street': {required: true},
        'zipcode': {},
        'city': {required: true},
        'country': {required: true},
    }, viewEmbedded:{
        component: 'addressView'
    }, creation: {
        fields: [
            {name: 'street'},
            {name: 'zipcode'},
            {name: 'city'},
            {name: 'country'},
        ]
    }, edition: {
        fields: [
            {name: 'street'},
            {name: 'zipcode'},
            {name: 'city'},
            {name: 'country'},
        ]
    }
    },
    {name: 'gps', icon: (<LocationIcon/>), fields: {
        'latitude': {required: true, regexp: /^([-+]?)([\d]{1,2})(((\.)(\d+))?)$/},
        'longitude': {required: true, regexp: /^([-+]?)([\d]{1,2})(((\.)(\d+))?)$/}
    }, viewEmbedded: {
        component: 'gpsView'
    }, creation: {
        fields: [
            {name: 'latitude'},
            {name: 'longitude'},
        ]
    }, edition: {
        fields: [
            {name: 'latitude'},
            {name: 'longitude'},
        ]
    }},
    {name: 'plc', icon: (<DashboardIcon/>), fields: {
    }, viewEmbedded: {
        component: 'plcView'
    }},
    {name: 'watchdog', icon: (<DashboardIcon/>), fields: {
        'plcConnectionOK': {},
        'countStatOK': {},
        'countStat': {},
        'countPosOK': {},
        'countPos': {},
    }, viewEmbedded: {
        component: 'watchDogView'
    }},
    {name: 'station', icon: (<EvStationIcon/>), menu: true, fields: {
        'id': {},
        'publicId': {},
        'name': {required: true},
        'modele': {},
        'phone': {},
        'operator': {object: 'operator', required: true},
        'networks': {object: 'stationNetwork', array: true, required: true},
        'gps': { object: 'gps', structure: {
                latitude: {},
                longitude: {},
            },
        },
        'address': { object: 'address', structure: {
                street: {},
                zipcode: {},
                city: {},
                country: {},
            },
        },
        'connected': {type: 'boolean', live: true},
        'lastping': {type: 'date', live: true, format: 'DD/MM/YYYY HH:mm:ss'},
        'version': {type: 'text', live: true, displayName: 'pos.version'},
        'wfconnectorVersion': {type: 'text', live: true, displayName: 'wfconnector.version'},
        'public': {type: 'boolean'},
        'h2mapShared': {type: 'boolean'},
        'h2mapId': {type: 'text'},
        'energys': {object: 'energy', array: true, displayName: 'delivered.energys'},
        'dispensers': {array: true, child: true, object:'dispenser', live: true},
        'barriers': {array: true, child: true, object:'barrier'},
        'refills': {array: true, child: false, object:'refillgroup'},
        'plc': {live: true, object: 'plc'},
        'watchdog': {live: true, object: 'watchdog'},
        'quantity': {live: true, type: 'quantity'},
        'livequantity': {live: true, type: 'quantity'},
        'vehicleCount': {live: true},
        'publicState': {live: true, type: 'stationstate', computed: o => {return o}, sortingGetter: o => { return o.connected ? o.publicState : 'offline'} },
        'manualState': {live: true, type: 'state'},
        'liveState': {live: true, type: 'stationdispenserstate', computed: o => { return o.connected ? o.liveState : 'offline' }},
    }, list: {
        fields: [
            {name: 'operator', tableFilter: { }},
            {name: 'networks'},
            {name: 'name'},
            {name: 'phone'},
            {name: 'public'},
            {name: 'publicState'},
            {name: 'liveState', profils: ['SERVICE_MANAGER']},
        ]
    }, view: {
        fields: [
            {name: 'operator'},
            {name: 'networks', type: 'listlight'},
            {name: 'publicState'},
            {name: 'liveState', profils: ['SERVICE_MANAGER']},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'text', required: true, profils: ['SERVICE_MANAGER']},
            {name: 'energys', type: 'listlight', profils: ['SERVICE_MANAGER']},
            {name: 'public', profils: ['SERVICE_MANAGER']},
            {name: 'h2mapShared', profils: ['SERVICE_MANAGER']},
            {name: 'phone'},
            {name: 'address', type: 'structure', required: true, fields: [
                {name: 'street', type: 'text', required: true},
                {name: 'zipcode', type: 'text', required: true},
                {name: 'city', type: 'text', required: true},
                {name: 'country', type: 'text', required: true},
            ]},
            {name: 'gps', type: 'structure', required: true, fields: [
                {name: 'latitude', type: 'text', required: true},
                {name: 'longitude', type: 'text', required: true}
            ]},
            {name: 'barriers', type: 'list', embeddedView: true, listEdition: true},
            {name: 'dispensers', type: 'list', embeddedView: true, listEdition: true},
        ],
		data_views: [
			{
				title: "last.refills",
                field: "refills",
                props: {
                    small: true
                },
                component: RefillGroupView
			}
		], workflowActions: [
            {
                name: 'stationforcestate',
                icon: (<PushPinIcon/>),
                title: 'action.stationforcestate',
                intro:'action.stationforcestate.intro',
                validationLabel:'action.forcestate',
                waitingLabel: 'action.authorizeunlock.waiting',
                doneLabel: 'action.authorizeunlock.done',
                enabled: object => {
                    return !object.manualState;
                },
                fields: [
                    {name: 'state', type: 'choice', sourcetype: 'value', values: {'open': 'state.open', 'maintenance': 'state.maintenance', 'closed': 'state.closed'}}
                ],
                needReload: true
            },
            {
                name: 'stationclearstate',
                icon: (<PushPinOffIcon/>),
                title: 'action.stationclearstate',
                intro:'action.stationclearstate.intro',
                validationLabel:'action.clearstate',
                waitingLabel: 'action.authorizeunlock.waiting',
                doneLabel: 'action.authorizeunlock.done',
                enabled: object => {
                    return object.manualState;
                },
                fields: [
                ],
                needReload: true
            }
        ]
    }, liveView: {
        fields: [
            {name: 'version'},
            {name: 'wfconnectorVersion'},
            {name: 'lastping'},
            {name: 'quantity'},
            {name: 'livequantity'},
            {name: 'plc', type: 'structure'},
            {name: 'watchdog', type: 'structure'},
        ],
        show: (user, appcontext, context, object) => {
            return object.plc ? true: false;
        }, profils: ['SERVICE_MANAGER'], features: ['STATION_OPERATOR', 'SCADA']
    }, creation: {
        fields: [
            {name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'text', required: true},
            {name: 'phone'},
            {name: 'address', type: 'structure', required: true, fields: [
                {name: 'street', type: 'text', required: true},
                {name: 'zipcode', type: 'text', required: true},
                {name: 'city', type: 'text', required: true},
                {name: 'country', type: 'text', required: true},
            ]},
            {name: 'gps', type: 'structure', required: true, fields: [
                {name: 'latitude', type: 'text', required: true},
                {name: 'longitude', type: 'text', required: true}
            ]},
            {name: 'energys', type: 'choicelistlight', sourcetype: 'object', source:'energy'},
            {name: 'public'},
            {name: 'h2mapShared'},
        ]
    }, edition: {
        fields: [
            //{name: 'operator', type: 'choice', sourcetype: 'object', source:'operator', required: true},
            //{name: 'publicId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            //{name: 'modele', type: 'text', required: true},
            //{name: 'energys', type: 'choicelistlight', sourcetype: 'object', source:'energy'},
            {name: 'phone'},
            {name: 'address', type: 'structure', required: true, fields: [
                {name: 'street', type: 'text', required: true},
                {name: 'zipcode', type: 'text', required: true},
                {name: 'city', type: 'text', required: true},
                {name: 'country', type: 'text', required: true},
            ]},
            {name: 'gps', type: 'structure', required: true, fields: [
                {name: 'latitude', type: 'text', required: true},
                {name: 'longitude', type: 'text', required: true}
            ]},
            {name: 'public'},
            {name: 'h2mapShared'},
        ]
    }, kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'today', view: {mode: 1}, full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byVehicleModel', name: 'vehicleModels', icon: <DirectionsCarIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }},
            {name: 'refills', type: 'attemptsSummary', defaultPeriode: 'today', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byEndStatus', name: 'endStatus', icon: <BubbleChartIcon/>}
                ]
            }},
            {name: 'availability', type: 'availability', defaultPeriode: 'today', full: {}}
        ]
    }, toString: object => {return object.name}},
    {name: 'dispenser', icon: (<KitchenIcon/>),  fields: {
        'id': {},
        'station': {object:'station'},
        'name': {},
        'label': {},
        'energyTypes': {
            object: 'energyType', array: true, filter: [{
                field: 'energy',
                path: 'station.energys.id'
            }]
        },
        'publicState': {live: true, type: 'stationstate', computed: o => {return o}, sortingGetter: o => { return o.publicState } },
        'liveState': {live: true, type: 'stationdispenserstate'},
        'plc': {live: true, object: 'plc'},
        'locked': {live: true, type: 'boolean'},
        'unlocked': {live: true, type: 'boolean', computed: (o, t) => { return !o.locked}},
        'fuelingStatus': {live: true, computed: (o, t) => { return o.fuelingStatus ? t('dispenserFuelingStatus-' + o.fuelingStatus, o.fuelingStatus) : undefined}},
        'receptacles': {live: true, array: true, object:'receptacle'},
        'vehicleCount': {live: true},
        'waitUntil': {live: true, type: 'duration', computed: (o, t) => { return o.waitUntil ? {start: new Date(), end: new Date(o.waitUntil)} : null} },        
        'refiller': {live: true, liveOnly: true, object: 'refillSession'},
        'vehicle': {live: true, liveOnly: true, object: 'refillVehicleState', displayName: 'vehicle.refill.state' },
        'currentRefillState': {computed: (o, t) => o.refill && o.refill.state ? t('refill.state.'+o.refill.state) : undefined, displayName: 'step'},
        'currentRefillProgress': {type: 'progress', computed: o => o.refill ? o.refill.progress : undefined, displayName: 'progress'},
        'currentRefillQuantity': {type: 'quantity-gramme', displayName: 'quantity', computed: o => o.refill ? o.refill.quantity : undefined},
        'refills': {array: true, child: false, object:'refillgroup'},
    }, parentField: 'station'
    , list: {
        fields: [
            {name: 'publicState'},
            {name: 'name'},
            {name: 'label'}
        ],
    }, view: {
        fields: [ 
            {name: 'publicState'},
            {name: 'liveState'},
            {name: 'name'},
            {name: 'label'},
            {name: 'receptacles', type: 'list', embeddedView: true},
            {name: 'vehicleCount'},
            {name: 'unlocked'},
            {name: 'fuelingStatus'},
            {name: 'waitUntil', show: (user, appcontext, context, o) => o.waitUntil > 0},
        ],
        parts: [
                {fields: ['publicState', 'liveState', 'name', 'label', 'energyTypes', 'receptacles']},
                {fields: ['fuelingStatus', 'vehicleCount', 'unlocked']}, 
                {label: 'current.refill', icon: (<OpacityIcon/>), fields: [], show: (user, appcontext, context, object) => {
                    return object.refiller && object.refiller.attempt ? true: false;
                }, component: RunningRefillView }
        ],
        kpi: {
            component: RefillsByDayResumeView, profils: ['SERVICE_MANAGER']
        },
        workflowActions: [
            {
                name: 'authorizeunlock',
                icon: (<CloudDoneIcon/>),
                title: 'action.authorizeunlock.dispenser',
                intro:'action.authorizeunlock.intro',
                validationLabel:'action.authorizeunlock',
                waitingLabel: 'action.authorizeunlock.waiting',
                doneLabel: 'action.authorizeunlock.done',
                enabled: object => {
                    return object.locked === true && !object.refiller && object.fuelingStatus === 'readyToFuel' && (object.publicState === 'open' || object.publicState === 'partial');
                },
                fields: [
                    {name: 'authorization', type: 'object', fields: [
                        {name: 'customer', required: true, type: 'choice', sourcetype: 'object', source:'customer', sourceEndpointSuffix:'customerauthorized', dependancy: {
							'driverSearch': {required: false, sourcefilter: true, copy: {fields : { "customerId": "id", "customerName": "name" }}}
						},
						filter: [
                            {mainobject: true}

                        ]},
                        {name: 'associatedDriver', required: true, type: 'choice', sourcetype: 'object', source:'driver', sourceEndpointSuffix:'driverauthorized' , dependancy: {
                            'driverSearch': {required: true, sourcefilter: true, copy: {fields : { "id": "id", "firstName": "firstName", "lastName" : "lastName" }}},
							'customer': {required: true, sourcefilter: true}
                        }, filter: [
                            {mainobject: true}
                        ]},
                        {name: 'driverSearch', header_field: true, label: "driver.search", type: 'search_text', not_an_input: true, res_display_pre: "<customerName>", res_display: "<firstName> <lastName>", res_display_post: "  (<phone>)", sourcetype: 'object', source:'driver', 
						dependancy: {
								'customer': { clear_on_change: true },
								'associatedDriver': { clear_on_change: true },
								'contactInfos': { clear_on_change: true }
						}},
                        {name: 'contactInfos', required: true, type: 'structure', fields: [
                            {name: 'reference', type: 'text'},
                            {name: 'firstName', type: 'text', required: true},
                            {name: 'lastName', type: 'text', required: true},
                            {name: 'phone', type: 'text'}
                        ], dependancy: {
                            'associatedDriver': {copy: {fields: {
															'firstName': { target: 'firstName', default: "" }, 
															'lastName': { target: 'lastName', default: "" }
														}}
												}
                        }},
                    ]}
                ]
            }, {
                name: 'unlockdispenser',
                icon: (<LockOpenIcon/>),
                title: 'action.unlockdispenser.dispenser',
                intro:'action.unlockdispenser.intro',
                validationLabel:'action.unlockdispenser',
                enabled: object => {
                    return object.locked === true && object.fuelingStatus === 'readyToFuel' && object.refiller  && object.refiller.canUnlock && (object.publicState === 'open' || object.publicState === 'partial');
                }
            }, {
                name: 'lockdispenser',
                icon: (<LockIcon/>),
                title: 'action.lockdispenser.dispenser',
                intro:'action.lockdispenser.intro',
                validationLabel:'action.lockdispenser',
                enabled: object => {
                    return object.locked === false && (object.fuelingStatus === 'readyToFuel' || object.fuelingStatus === 'noStatus');
                }
            }, {
                name: 'startrefill',
                icon: (<PlayCircleFilledIcon/>),
                title: 'action.startrefill.dispenser',
                intro:'action.startrefill.intro',
                validationLabel:'action.startrefill',
                enabled: object => {
                    return object.locked === false && object.fuelingStatus === 'readyToFuel' && object.refiller  && object.refiller.state === 'waitToStart' && (object.publicState === 'open' || object.publicState === 'partial');
                }
            }, {
                name: 'stoprefill',
                icon: (<StopIcon/>),
                title: 'action.stoprefill.dispenser',
                intro:'action.stoprefill.intro',
                validationLabel:'action.stoprefill',
                enabled: object => {
                    return (object.fuelingStatus === 'preparingToFuel' || object.fuelingStatus === 'inProgress');//object.locked === false && 
                }
            }
        ]
    }, viewEmbedded: {
        fields: [
            {name: 'publicState'},
            {name: 'label'},
            {name: 'energyTypes'},
            {name: 'fuelingStatus'},
            {name: 'vehicleCount'},
        ],
        parts: [
                {fields: ['publicState', 'label', 'energyTypes']},
                {fields: ['fuelingStatus', 'vehicleCount'], show: (user, appcontext, context, object) => {
                    return (object.publicState && object.publicState!=='unknown') ? true: false;
                }},
        ]
    }, liveView: {
        fields: [
            {name: 'plc', type: 'structure'}
        ],
        show: (user, appcontext, context, object) => {
            return object.plc ? true: false;
        },
        profils: ['SERVICE_MANAGER'], features: ['STATION_OPERATOR', 'SCADA']
    }, creation: {
        fields: [
        ]
    }, edition: {
        fields: [
            {name: 'label'},
        ]
    }, kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'today', view: {mode: 1}, full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byVehicleModel', name: 'vehicleModels', icon: <DirectionsCarIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }},
            {name: 'refills', type: 'attemptsSummary', defaultPeriode: 'today', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byEndStatus', name: 'endStatus', icon: <BubbleChartIcon/>}
                ]
            }},
            {name: 'availability', type: 'availability', defaultPeriode: 'today', full: {
                show: (object, user) => {
 
                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                ]
            }},
        ]
    },
    toString: object => {return object.name}},
    {name: 'workflowAction_enable', title: 'action.enable', intro:'action.enable.intro', validationLabel:'action.enable', fields: {
    }},
    {name: 'workflowAction_disable', title: 'action.disable', intro:'action.disable.intro', validationLabel:'action.disable', fields: {
    }},
    {name: 'workflowAction_customervalidate', title: 'action.customervalidate', intro:'action.customervalidate.intro', validationLabel:'action.customervalidate', fields: {
    }},
    {name: 'workflowAction_customerlock', title: 'action.customerlock', intro:'action.customerlock.intro', validationLabel:'action.customerlock', fields: {
    }},
    {name: 'workflowAction_customerunlock', title: 'action.customerunlock', intro:'action.customerunlock.intro', validationLabel:'action.customerunlock', fields: {
    }},
    {name: 'workflowAction_customerclose', title: 'action.customerclose', intro:'action.customerclose.intro', validationLabel:'action.customerclose', fields: {
    }},
    {name: 'workflowAction_stationforcestate', title: 'action.forcestate.station', intro:'action.forcestate.intro', validationLabel:'action.forcestate', fields: {
        'state': { type: 'text', required: true },
    }, createObject: function(newItem, user, context) {

        return newItem;
    }
    },
    {name: 'workflowAction_stationclearstate', title: 'action.stationclearstate', intro:'action.stationclearstate.intro', validationLabel:'action.stationclearstate', fields: {
    }},
    {name: 'workflowAction_authorizeunlock', title: 'action.unlock.dispenser', intro:'action.unlock.intro', validationLabel:'action.unlock', fields: {
        'authorization': { type: 'object', object: 'authorization', required: true },
    }, createObject: function(newItem, user, context) {

        newItem.authorization = {contactInfos: {}};
        newItem.authorization.customer = user.user.customer;

        return newItem;
    }
    },
    {name: 'workflowAction_unlockdispenser', title: 'action.unlockdispenser.dispenser', intro:'action.unlockdispenser.intro', validationLabel:'action.unlockdispenser', fields: {
    }},
    {name: 'workflowAction_lockdispenser', title: 'action.lockdispenser.dispenser', intro:'action.lockdispenser.intro', validationLabel:'action.lockdispenser', fields: {
    }},
    {name: 'workflowAction_startrefill', title: 'action.startrefill.dispenser', intro:'action.startrefill.intro', validationLabel:'action.startrefill', fields: {
    }},
    {name: 'workflowAction_stoprefill', title: 'action.stoprefill.dispenser', intro:'action.stoprefill.intro', validationLabel:'action.stoprefill', fields: {
    }},
    {name: 'workflowAction_usersendemailvalidation', title: 'action.usersendemailvalidation', intro:'action.usersendemailvalidation.intro', validationLabel:'action.send', fields: {
    }},
    {name: 'workflowAction_useraccessreinit', title: 'action.useraccessreinit', intro:'action.useraccessreinit.intro', validationLabel:'action.reinit', fields: {
    }},
    {name: 'workflowAction_devicesendpeering', title: 'action.devicesendpeering', intro:'action.devicesendpeering.intro', validationLabel:'action.send', fields: {
    }},
    {name: 'workflowAction_deviceunassociatepeering', title: 'action.deviceunassociatepeering', intro:'action.deviceunassociatepeering.intro', validationLabel:'action.unassociate', fields: {
    }},
    {name: 'workflowAction_dataexport', title: 'action.dataexport', intro:'action.dataexport.intro', validationLabel:'action.export', fields: {
        'periode': { type: 'periode', required: true },
    }, createObject: function(newItem, user, context) {

        newItem.periode = makePeriode(new Date(), 'daily')

        return newItem;
    }
    },
    {name: 'workflowAction_apiGenerateKey', title: 'action.apiGenerateKey', intro:'action.apiGenerateKey.intro', validationLabel:'action.generate', fields: {
        'keyName': { required: true },
    }},
    {name: 'workflowAction_apiDownloadKey', title: 'action.apiDownloadKey', intro:'action.apiDownloadKey.intro', validationLabel:'action.download', fields: {
    }},
    {name: 'workflowAction_apiRevokeKey', title: 'action.apiRevokeKey', intro:'action.apiRevokeKey.intro', validationLabel:'action.revoke', fields: {
    }},
    {name: 'workflowAction_doImport', title: 'action.import.do', intro:'action.enable.intro', validationLabel:'action.enable', fields: {
    }},
    {name: 'workflowAction_activate', title: 'action.contract.activate', intro:'action.activate.intro', validationLabel:'action.activate', fields: {
    }},
    {name: 'workflowAction_close', title: 'action.close', intro:'action.close.intro', validationLabel:'action.close', fields: {
    }},
    {name: 'barrier', icon: (<ScannerIcon/>), fields: {
        'id': {},
        'station': {object:'station'},
        'name': {required: true},
        'idrelay': {required: false},
        'phone': {required: true},
        'destphone': {required: true, displayName: 'barrier.destphone'},
        'wsphone': {required: true, displayName: 'barrier.wsphone'},
    }, parentField: 'station'
    , list: {
        fields: [
            {name: 'name'},
            {name: 'phone'}
        ]
    }, view: {
        fields: [
            {name: 'name'},
            {name: 'phone'},
            {name: 'destphone'},
            {name: 'idrelay'}            
        ]
    }, viewEmbedded: {
        fields: [
            {name: 'phone'},
            {name: 'destphone'},
        ]
    }, creation: {
        fields: [
            {name: 'name'},
            {name: 'phone'},
            {name: 'destphone'},
            {name: 'wsphone'},
            {name: 'idrelay'},            
            {name: 'station', hidden: true},
        ]
    }, edition: {
        fields: [
            {name: 'name'},
            {name: 'phone'},
            {name: 'destphone'},
            {name: 'wsphone'},
            {name: 'idrelay'},
        ]
    }, actions: [
        {code: 'BARRIER_OPEN', label: 'action.open', model: {
            name: 'barrierOpening',
            fields: {
                'barrier': {}
            },
            creation: {
                fields: [
                    {name: 'barrier', sourcetype: 'object', source:'barrier'}
                ]
            }
        }, createObject: (barrier, user, context) => { return {barrier}}, icon: <LockOpenIcon/>}
    ],
    toString: object => {return object.name}},
    {name: 'customerContract', icon: <ReceiptIcon/>, uriobject: 'contract', menu: true, fields: {
        'id': {},
        'fleet': {required: true, object:'fleet'},
        'customer': {required: true, object:'customer'},
		'serviceProvider': {required: true, object:'serviceProvider'},
        //'stationNetworks': {array: true, object:'stationNetwork', required: true},
        'energies': {array: true, object:'energyType', required: true},
        'status': {values: {0: 'customerContract.status.created', 1: 'customerContract.status.active', 2: 'customerContract.status.outdated', 3: 'customerContract.status.closed'}, tableFilter: { }},
        'start': {type: 'date', format: 'DD/MM/YYYY', displayName: 'start'},
        'end': {type: 'date', format: 'DD/MM/YYYY', displayName: 'end'}
    }, list: {
        fields: [
			{name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            { name: 'status' , type: 'choice', sourcetype: 'values'}
		]
    }, view: {
        fields: [
            {name: 'start'},
            {name: 'end'},
            { name: 'status'},
            {name: 'energies', type: 'choicelist', sourcetype: 'object', source:'energyType'},
            //{name: 'stationNetworks', type: 'list', sourcetype: 'object', source:'stationNetwork'},
		], workflowActions: [
            {
                name: 'activate',
                icon: (<CheckIcon/>),
                title: 'action.contract.activate',
                //intro:'action.enable.intro',
                validationLabel:'action.validate',
                enabled: object => {
                    return object.status === 0 && object.start// && object.stationNetworks && object.stationNetworks.length > 0;
                },
                needReload: true
            },
            {
                name: 'close',
                icon: (<LockIcon/>),
                title: 'action.contract.close',
                //intro:'action.enable.intro',
                validationLabel:'action.lock',
                enabled: object => {
                    return object.status > 0 && object.status < 3;
                },
                needReload: true
            }
        ]
    }, creation: {
        fields: [
            {name: 'fleet', hidden: true},
            {name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            {name: 'energies', type: 'choicelist', sourcetype: 'object', source:'energyType'},
            //{name: 'stationNetworks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork', required: true }
		]
    }, edition: {
        fields: [
            {name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            {name: 'energies', type: 'choicelist', sourcetype: 'object', source:'energyType'},
            //{name: 'stationNetworks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork' }
		]
    }, createObject: function(newItem, user, context) {

        if (context && context.activeFleet) {
            newItem.fleet = context.activeFleet;
        }

        return newItem;
    }},
    {name: 'operatorContract', icon: <ReceiptIcon/>, uriobject: 'operatorcontract', menu: true, fields: {
        'id': {},
        'fleets': {required: true, object:'fleet', array: true},
        'fleetTypes': {required: true, object:'fleetType', array: true},
        'operator': {required: true, object:'operator'},
		'serviceProvider': {required: true, object:'serviceProvider'},
        'stationNetworks': {array: true, object:'stationNetwork', required: true, filter: [{
            field: 'operator',
            path: 'id'
        }]},
        'status': {values: {0: 'customerContract.status.created', 1: 'customerContract.status.active', 2: 'customerContract.status.outdated', 3: 'customerContract.status.closed'}, tableFilter: { }},
        'start': {type: 'date', format: 'DD/MM/YYYY', displayName: 'start'},
        'end': {type: 'date', format: 'DD/MM/YYYY', displayName: 'end'}
    }, list: {
        fields: [
            {name: 'operator'},
            {name: 'serviceProvider'},
			{name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            { name: 'status' , type: 'choice', sourcetype: 'values'}
		]
    }, view: {
        fields: [
            {name: 'operator'},
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider'},
            {name: 'start'},
            {name: 'end'},
            { name: 'status'},
            {name: 'stationNetworks', type: 'list', sourcetype: 'object', source:'stationNetwork'},
		], workflowActions: [
            {
                name: 'activate',
                icon: (<CheckIcon/>),
                title: 'action.contract.activate',
                //intro:'action.enable.intro',
                validationLabel:'action.validate',
                enabled: object => {
                    return object.status === 0 && object.start && object.stationNetworks && object.stationNetworks.length > 0;
                },
                needReload: true
            },
            {
                name: 'close',
                icon: (<LockIcon/>),
                title: 'action.contract.close',
                //intro:'action.enable.intro',
                validationLabel:'action.lock',
                enabled: object => {
                    return object.status > 0 && object.status < 3;
                },
                needReload: true
            }
        ]
    }, creation: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider', required: true},
            {name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            {name: 'stationNetworks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork', required: true }
		]
    }, edition: {
        fields: [
            {name: 'serviceProvider', type: 'choice', sourcetype: 'object', source:'serviceProvider', required: true, readonly: true},
            {name: 'start', type: 'date'},
            {name: 'end', type: 'date'},
            {name: 'stationNetworks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork', required: true }
		]
    }, createObject: function(newItem, user, context) {
        newItem.start = new Date()
        newItem.end = null
        newItem.operator = user.operator
        return newItem;
    }},
	{name: 'stationNetwork', icon: <DeviceHubIcon/>, uriobject: 'network', menu: true, fields: {
        'id': {},
        'name': {},
        'private': {type: 'boolean'},
        'operator': {required: true, object:'operator'},
		'stations': {required: true, array: true, object:'station'}
    }, list: {
        fields: [
            {name: 'operator'}, 
            {name: 'name'}, 
		]
    }, view: {
        fields: [
            {name: 'operator'}, 
            {name: 'name'}, 
            {name: 'stations', type: 'list', fields: [
                {name: 'name'},
                {name: 'phone'}, 
            ]}
		]
    }, creation: {
        fields: [
            {name: 'name'}, 
			{name: 'private'}
		]
    }, edition: {
        fields: [
            {name: 'name'}, 
            {name: 'private'}
		]
    }, 
    toString: object => {return object.name}},
	/*{name: 'networkPackage', icon: <BubbleChartIcon/>, uriobject: 'package', menu: true, fields: {
        'id': {},
        'name': {},
        //'private': {type: 'boolean'},
        //'roaming': {type: 'boolean'},
        'serviceProvider': {required: true, object:'serviceProvider'},
		'networks': {required: true, array: true, object:'stationNetwork'}
    }, list: {
        fields: [
            {name: 'serviceProvider'}, 
            {name: 'name'}, 
		]
    }, view: {
        fields: [
            {name: 'serviceProvider'}, 
            {name: 'name'}, 
            {name: 'networks', type: 'list', fields: [
                {name: 'name'},
            ]}
		]
    }, creation: {
        fields: [
            {name: 'name'}, 
            //{name: 'private'},
            {name: 'networks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork', required: true }
		]
    }, edition: {
        fields: [
            {name: 'name'}, 
            //{name: 'private'},
            {name: 'networks', type: 'choicelist', sourcetype: 'object', source:'stationNetwork', required: true }
		]
    },
    toString: object => {return object.name}},*/
    {name: 'fleetType', icon: (<ViewModuleIcon/>), menu: false, fields: {
        'id': {},
        'name': {required: true},
    }, list: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, view: {
        fields: [
            {name: 'name', type: 'text'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
        ]
    },
    toString: object => {return object.name}},
    {name: 'fleet', icon: (<ViewModuleIcon/>), menu: false, fields: {
        'id': {},
        'name': {required: true},
        'type': {object:'fleetType'},
        'customer': {object:'customer'},
        'vehicles': {array: true, child: true, object:'vehicle'},
        'vehicleSharing': {type: 'boolean'},
        'vehicleSharingAutomatique': {type: 'boolean'},
        'defaultVehicleSharing': {object:'vehicleSharing', filter: [{
            field: 'fleet',
            path: 'id'
        }]},
        'vehicleSharings': {array: true, child: true, object:'vehicleSharing', filter: [{
            field: 'fleet',
            path: 'id'
        }]},
        'vehicleTypes': {object:'vehicleType'},
        'vehicleModels': {array: true, object:'vehicleModele', filter: [{
            field: 'vehicleTypes',
            path: 'id'
        }]},
        'drivers': {array: true, child: true, object:'driver'},
        'badges': {array: true, child: true, object:'badge'},
        'contracts': {array: true, child: true, object:'customerContract'},
    }, parentField: 'customer', list: {
        fields: [
            {name: 'name'}
        ]
    }, view: {
        fields: [
            {name: 'name', type: 'text'},
            //{name: 'type', type: 'choice', sourcetype: 'object', source:'fleetType'},
            {name: 'vehicleTypes', type: 'choicelist', sourcetype: 'object', source:'vehicleType'},
            {name: 'vehicleModels', type: 'choicelist', sourcetype: 'object', source:'vehicleModele'},
            {name: 'contracts', type: 'list', sourcetype: 'object', source:'vehicleModele'},
        ]
    }, viewEmbedded: {
        fields: [
            /*{name: 'type'}
            {name: 'vehicleSharing'},*/
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            //{name: 'type', type: 'choice', sourcetype: 'object', source:'fleetType'},
            {name: 'customer', hidden: true},
            {name: 'vehicleTypes', type: 'choicelist', sourcetype: 'object', source:'vehicleType'},
            {name: 'vehicleModels', type: 'choicelist', sourcetype: 'object', source:'vehicleModele'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            //{name: 'type', type: 'choice', sourcetype: 'object', source:'fleetType'},
            {name: 'vehicleTypes', type: 'choicelist', sourcetype: 'object', source:'vehicleType'},
            {name: 'vehicleModels', type: 'choicelist', sourcetype: 'object', source:'vehicleModele'},
        ]
    }, kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'last30days', view: {mode: 1}, full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byVehicleModel', name: 'vehicleModels', icon: <DirectionsCarIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }},
            {name: 'refills', type: 'attemptsSummary', defaultPeriode: 'last30days', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                ]
            }},
        ]
    }, createObject: function(newItem, user, context) {
/*
        newItem.vehicleSharing = false;
        newItem.vehicleSharingAutomatique = false;
  */      
        return newItem;
    },
    toString: object => {return object.name}},
    {name: 'vehicle', icon: (<DirectionsCarIcon/>), menu: true, fields: {
        'id': {},
        'fleet': {object: 'fleet', required: true},
        'vin': {required: false},
        'immatriculation': {required: true},
        'name': {},
        'modele': {object: 'vehicleModele', required: true, tableFilter: { }},
        'sharing': {object: 'vehicleSharing', filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'owner': {object: 'driver', filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'devices': {array: true, child: true, object:'device'},
        'refills': {array: true, child: false, object:'refillgroup'},
    }, parentField: 'fleet', list: {
        title: 'Vehicules',
        fields: [
            {name: 'vin'},
            {name: 'immatriculation'},
            {name: 'name'},
            {name: 'modele'}
        ],
        search: true,
		toolbar_actions: {
			import: {
				icon: <VerticalAlignTopIcon />,
				label: "action.import"
			}
		}
    }, view: {
        fields: [
            {name: 'vin', type: 'text', required: true},
            {name: 'immatriculation', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'choice', sourcetype: 'object', source:'vehicleModele', required: true},
            {name: 'owner', type: 'choice', sourcetype: 'object', source:'driver'},
            /*{name: 'sharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing', required: true, show: (user, appcontext, context, object) => {
                return !(appcontext.fleet && appcontext.fleet.vehicleSharingAutomatique);
            }},*/
            {name: 'devices', type: 'embeddedlist'},
        ],
		data_views: [
			{
				title: "last.refills",
                field: "refills",
                props: {
                    small: true
                },
                component: RefillGroupView
			}
		]
    }, creation: {
        fields: [
            {name: 'fleet', hidden: true},
            {name: 'vin', type: 'text', required: true},
            {name: 'immatriculation', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'choice', sourcetype: 'object', source:'vehicleModele', required: true},
            {name: 'owner', type: 'choice', sourcetype: 'object', source:'driver'},
            {name: 'devices', type: 'choicelist', sourcetype: 'object', source:'device', canCreate: true, embedded: true},
            /*{name: 'sharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing', required: true, show: (user, appcontext, context, object) => {
                return !(appcontext.fleet && appcontext.fleet.vehicleSharingAutomatique);
            }},*/
        ]
    }, edition: {
        fields: [
            {name: 'vin', type: 'text', required: true},
            {name: 'immatriculation', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'modele', type: 'choice', sourcetype: 'object', source:'vehicleModele', required: true},
            {name: 'owner', type: 'choice', sourcetype: 'object', source:'driver'},
            {name: 'devices', type: 'choicelist', sourcetype: 'object', source:'device', canCreate: true, embedded: true},
            /*{name: 'sharing', type: 'choice', sourcetype: 'object', source:'vehicleSharing', required: true, show: (user, appcontext, context, object) => {
                return !(appcontext.fleet && appcontext.fleet.vehicleSharingAutomatique);
            }},*/
        ]
    }, kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'last30days', view: {mode: 1}, full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }},
            {name: 'refills', type: 'attemptsSummary', defaultPeriode: 'last30days', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                ]
            }},
        ]
    }, createObject: function(newItem, user, context) {

        if (context && context.activeFleet) {
            newItem.fleet = context.activeFleet;
        }
        
        return newItem;
    }, toString: o => {
        return (o.modele ? o.modele.brand + ' ' + o.modele.name + ' ' : '') + o.immatriculation;
    }
    },
    {name: 'vehicleType', icon: (<DirectionsCarIcon/>), menu: true, fields: {
        'id': {},
        'name': {},
        'mainEnergyType': {object:'energyType', required: true},
        'alternativeEnergyType': {object:'energyType', array: true},
    }, list: {
        fields: [
            {name: 'name'}
        ]
    }, view: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'mainEnergyType', type: 'choice', sourcetype: 'object', source:'energyType'},
            {name: 'alternativeEnergyType', type: 'listchoice', sourcetype: 'object', source:'energyType'},
        ]
    }, creation: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'mainEnergyType', type: 'choice', sourcetype: 'object', source:'energyType'},
            {name: 'alternativeEnergyType', type: 'listchoice', sourcetype: 'object', source:'energyType'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'mainEnergyType', type: 'choice', sourcetype: 'object', source:'energyType'},
            {name: 'alternativeEnergyType', type: 'listchoice', sourcetype: 'object', source:'energyType'},
        ]
    },
    toString: object => {return object.name}},
    {name: 'vehicleModele', icon: (<DirectionsCarIcon/>), menu: true, fields: {
        'id': {},
        'key': {},
        'name': {},
        'brand': {},
        'img': {},
        'type': {object: 'vehicleType', required: true},
    }, list: {
        fields: [
            {name: 'type'},
            {name: 'name'},
            {name: 'brand'},
        ]
    }, view: {
        fields: [
            {name: 'type', type: 'choice', sourcetype: 'object', source:'vehicleType'},
            {name: 'name', type: 'text', required: true},
            {name: 'brand', type: 'text', required: true},
            {name: 'key', type: 'text', required: true},
            {name: 'img', type: 'file', required: true},
        ]
    }, creation: {
        fields: [
            {name: 'type', type: 'choice', sourcetype: 'object', source:'vehicleType'},
            {name: 'name', type: 'text', required: true},
            {name: 'brand', type: 'text', required: true},
            {name: 'key', type: 'text', required: true},
        ]
    }, edition: {
        fields: [
            {name: 'type', type: 'choice', sourcetype: 'object', source:'vehicleType'},
            {name: 'name', type: 'text', required: true},
            {name: 'brand', type: 'text', required: true},
            {name: 'key', type: 'text', required: true},
            {name: 'img', type: 'file', required: true},
        ]
    },
    toString: object => {return object.brand +' '+ object.name}},
    {name: 'vehicleSharing', icon: (<ShareIcon/>), menu: true, fields: {
        'id': {},
        'fleet': {object: 'fleet'},
        'name': {},
        'vehicles': {object: 'vehicle', array: true, filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},        
    }, parentField: 'fleet', list: {
        fields: [
            {name: 'name'}
        ]
    }, view: {
        fields: [
            {name: 'name'},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
        ]
    }, viewEmbedded: {
        fields: [
        ]
    }, creation: {
        fields: [
            {name: 'fleet', hidden: true},
            {name: 'name', type: 'text', required: true},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
        ]
    }, edition: {
        fields: [
            {name: 'name', type: 'text', required: true},
            {name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle'},
        ]
    },
    toString: object => {return object.name}},
    {
        name: 'driver', icon: (<PersonIcon/>),
        schema: 'Driver',
        menu: true,
        fields: {
        'id': {},
        'customer': {object: 'customer'},
        'fleet': {object: 'fleet'},
        'firstName': {required: true},
        'lastName': {required: true},
        'peerings': {object: 'peering', array: true},
        'vehicles': {object: 'vehicle', array: true, filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'sharings': {object: 'vehicleSharing', array: true, filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'phoneNumbers': {computed: o => { return o.devices ? o.devices.map( device => { return device.phone.nationalNumber ? device.phone.nationalNumber : device.phone.number } ).filter( (phone, index, array) => index === array.indexOf(phone) ).join(', ') : undefined;}},
        'devices': {array: true, child: true, object:'device'},
        'badges': {array: true, child: true, object:'badge'},
        'refills': {array: true, child: false, object:'refillgroup'},
        'enabled': {type: 'boolean', tableFilter: { }},
    }, toString: object => {return object.firstName +' '+ object.lastName}, parentField: 'fleet',
    list: {
        fields: [
            {name: 'firstName'},
            {name: 'lastName'},
            {name: 'phoneNumbers', displayName: 'phone'},
            {name: 'enabled'}
        ],
        search: true
    }, view: {
        fields: [
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'devices', type: 'embeddedlist'},
            {name: 'badges', type: 'embeddedlist'},
            /*{name: 'vehicles', type: 'choicelist', sourcetype: 'object', source:'vehicle', show: (user, appcontext, context, object) => {
                return !(appcontext.fleet && appcontext.fleet.vehicleSharingAutomatique);
            }},
            {name: 'sharings', type: 'choicelist', sourcetype: 'object', source:'vehicleSharing', show: (user, appcontext, context, object) => {
                return !(appcontext.fleet && appcontext.fleet.vehicleSharingAutomatique);
            }}*/,
        ],
		data_views: [
			{
				title: "last.refills",
                field: "refills",
                props: {
                    small: true
                },
                component: RefillGroupView
			}
		],
		workflowActions: [
            {
                name: 'enable',
                icon: (<ToggleOnIcon/>),
                title: 'action.enable',
                //intro:'action.enable.intro',
                validationLabel:'action.enable',
                enabled: object => {
                    return object.enabled === false;
                },
                needReload: true
            },
            {
                name: 'disable',
                icon: (<ToggleOffIcon/>),
                title: 'action.disable',
                //intro:'action.disable.intro',
                validationLabel:'action.disable',
                enabled: object => {
                    return object.enabled === true;
                },
                needReload: true
            }
        ]
    }, preview: {
        fields: [
            {name: 'firstName'},
            {name: 'lastName'},
            {name: 'phoneNumbers'},
            {name: 'badges'},
        ]
	}, creation: {
        fields: [
            {name: 'fleet', hidden: true},
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'devices', type: 'choicelist', sourcetype: 'object', source:'device', canCreate: true, embedded: true},
            {name: 'badges', type: 'choicelist', sourcetype: 'object', source:'badge', canCreate: true, embedded: true},
        ]
    }, edition: {
        fields: [
            {name: 'firstName', type: 'text', required: true},
            {name: 'lastName', type: 'text', required: true},
            {name: 'devices', type: 'choicelist', sourcetype: 'object', source:'device', canCreate: true, embedded: true},
            {name: 'badges', type: 'choicelist', sourcetype: 'object', source:'badge', canCreate: true, embedded: true},

        ]
    }, kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'last30days', view: {mode: 1}, full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byVehicleModel', name: 'vehicleModels', icon: <DirectionsCarIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }},
            {name: 'refills', type: 'attemptsSummary', defaultPeriode: 'last30days', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                ]
            }},
        ]
    }, createObject: function(newItem, user, context) {

        if (context && context.activeFleet) {
            newItem.fleet = context.activeFleet;
        }

        return newItem;
    }},
    {name: 'deviceInfos', icon: (<SmartphoneIcon/>), menu: true, fields: {
        'manufacturer': {},
        'model':{},
        'os':{},
        'version':{},
        'appVersion':{},
        }, viewEmbedded: {
            component: 'deviceInfoView'
        }
    },
    {name: 'device', icon: (<SmartphoneIcon/>), menu: true, fields: {
        'id': {},
        'fleet': {object: 'fleet'},
        'driver': {object: 'driver'},
        'vehicle': {object: 'vehicle'},
        'phone': {required: true, type: 'phone', computed: (o,t) => { return o.phone.number; }},
        'enabled': {type: 'boolean', default: true},
        'peeringState' : { computed: (o, t) => {
                if (o.peerings && o.peerings.length > 0) {
                    const lastPeering = o.peerings[o.peerings.length - 1];
                    const deviceInfos = lastPeering.updatedDeviceInfos && lastPeering.updatedDeviceInfos.os ? lastPeering.updatedDeviceInfos : o.updatedDeviceInfos && o.updatedDeviceInfos.os ? o.updatedDeviceInfos : lastPeering.deviceInfos && lastPeering.deviceInfos.os ? lastPeering.deviceInfos : null;
                    if (lastPeering.state === 1 && deviceInfos) {
                        return <DeviceInfos object={deviceInfos} checkIcon={true} />;
                    }
                    let state = lastPeering.state === 1 ? t('app.peered') : t('app.notpeered');
                    if (lastPeering.state !== 1 && lastPeering.token) {
                        const expiration = moment(lastPeering.token.expire);
                        if (expiration.isAfter(moment())) {
                            state += ' ('+ t('timeout.at')+' '+expiration.format('DD/MM/YYYY HH:mm')+')';
                        } else {
                            state += ' ('+ t('expired')+')';
                        }
                    }
                    return state;
                }
                return null;
            }
        },        
    }, parentField: 'driver',
    list: {
        fields: [
            {name: 'phone'},
            {name: 'enabled'}
        ]
    }, view: {
        fields: [
            {name: 'phone', type: 'text'},
            {name: 'driver', hideIfEmpty: true},
            {name: 'enabled', type: 'boolean'},
        ]
    }, viewEmbedded: {
        fields: [
            {name: 'phone'},
            {name: 'enabled'},
            {name: 'peeringState'},
        ],
        workflowActions: [
            {
                name: 'devicesendpeering',
                icon: (<SmsIcon/>),
                title: 'action.devicesendpeering',
                //intro:'action.enable.intro',
                validationLabel:'action.send',
                enabled: object => {
                    if (object.peerings && object.peerings.length > 0) {
                        const lastPeering = object.peerings[object.peerings.length - 1];
                        return lastPeering.state !== 1;
                    }
                    return false;
                }, 
                needReload: true
            },{
                name: 'deviceunassociatepeering',
                icon: (<PhonelinkEraseIcon/>),
                title: 'action.deviceunassociatepeering',
                intro:'action.deviceunassociatepeering.intro',
                validationLabel:'action.unassociate',
                enabled: object => {
                    if (object.peerings && object.peerings.length > 0) {
                        const lastPeering = object.peerings[object.peerings.length - 1];
                        return lastPeering.state === 1;
                    }
                    return false;
                },
                needReload: true
            },
        ],
        //actions: ['SEND_SMS_PEERING']
    }, creation: {
        fields: [
            {name: 'driver', hidden: true},
            {name: 'vehicle', hidden: true},
            {name: 'fleet', hidden: true},
			{name: 'phone', type: 'phone'},
            {name: 'enabled', type: 'switch'},
        ]
    }, edition: {
        fields: [
            {name: 'phone', type: 'phone'},
            {name: 'enabled', type: 'switch'},
        ]
    }, createObject: function(newItem, user, context) {
        newItem.enabled = true;
        return newItem;
    }/*, actions: {
        'SEND_SMS_PEERING':{ label: 'action.sendsmspeering', model: {
            name: 'peeringSend',
            fields: {
                'peering': {}
            },
            creation: {
                fields: [
                    {name: 'peering', sourcetype: 'object', source:'peering'}
                ]
            }
        }, show: (device, user, context) => { return device.peerings.find( peering => peering.state === 0 )}, createObject: (device, user, context) => { return {peering: device.peerings.find( peering => peering.state === 0 )}}, icon: <SmsIcon/>}
    }*/},
    {name: 'peering', icon: (<AccountCircle/>), schema: 'Peering', fields: {
        'id': {},
        'token': {type: 'structure'},
        'keyId': {},
        'states': {},
        //'auth': {},
    }, list: {
        fields: [
            {name: 'firstName'},
            {name: 'lastName'},
            {name: 'email'},
            {name: 'phone'}
        ]
    }, toString: o => {
        const lastState = o.states[o.states.length - 1];
        if (lastState.state === 0) {
            const expiration = moment(o.token.expire);
            const expire = moment().isAfter(expiration);
            if (expire) {
                return 'Token expiré';
            } else {
                return 'Token ' + o.token.value + ' en attente (valide jusqu\'au ' + expiration.format('dddd DD MM YYYY [à] HH:mm:ss')+')';
            }
        }
        return o.token.value;
    }},
    {name: 'badge', icon: <BadgeIcon/>, menu: true, fields: {
        'id': {},
        'fleet': {object: 'fleet'},
        'driver': {object: 'driver', hideIfEmbedded: true, filter: [{
            field: 'fleet',
            path: 'fleet.id'
        }]},
        'badgeId': {required: true, regexp: /^[A-Za-z0-9]{8}$/},
        'name': {},
        'enabled': {type: 'boolean', default: true, tableFilter: { }},
    }, parentField: 'driver',
    list: {
        fields: [
            {name: 'badgeId'},
            {name: 'name'},
            {name: 'driver'},
            {name: 'enabled'}
        ],
        search: true
    }, view: {
        fields: [
            {name: 'badgeId', type: 'text'},
            {name: 'name', type: 'text', required: true},
            {name: 'driver', preview: true},
            {name: 'enabled', type: 'boolean'},
        ]
    }, viewEmbedded: {
        fields: [
            {name: 'badgeId'},
            {name: 'name'},
            {name: 'enabled'},
        ]
    }, creation: {
        fields: [
            {name: 'fleet', hidden: true, type: 'context', field: 'activeFleet'},
            {name: 'badgeId', type: 'text', required: true},
            {name: 'name', type: 'text', required: true},
            {name: 'driver', type: 'choice', sourcetype: 'object', source:'driver'},
            {name: 'enabled', type: 'switch'},
        ]
    }, edition: {
        fields: [
            {name: 'badgeId', type: 'text'},
            {name: 'name', type: 'text'},
            {name: 'driver', type: 'choice', sourcetype: 'object', source:'driver'},
            {name: 'enabled', type: 'switch'},
        ]
    }, createObject: function(newItem, user, context) {
        newItem.enabled = true;
        return newItem;
    }, toString : o => o ? o.badgeId : undefined },
    {name: 'refillgroup', icon: (<OpacityIcon/>), menu: false, fields: {
        'id': {},
        'groupId': {},
        'attemptsCount': {},
        'realAttemptsCount': {},
        'attempts': {object: 'refill', array: true},
        'state': {},
        'startState': { object: 'refillVehicleState', structure: {
                pressure: {},
                temperature: {type: 'temperature'},
                soc: {type: 'soc'},
            },
            displayName: 'vehicleRefill.startState'
        },
        'endState': { object: 'refillVehicleState', structure: {
                pressure: {},
                temperature: {type: 'temperature'},
                soc: {type: 'soc'},
            },
            displayName: 'vehicleRefill.endState'
        },
        'vehicleComState': {type: 'vehicleComState', computed: o => { return {vehicleCommunication: o.vehicleCommunication, vehicleCommunicationLost: o.vehicleCommunicationLost }} },
        'vehicleCommunication': {type: 'boolean'},
        'vehicleCommunicationLost': {type: 'boolean'},
        'startSoc': {type: 'soc', computed: (o, t) => { return o.startState && o.startState.soc ? Math.round(o.startState.soc) : null}, sortingGetter: o => { return o.startState && o.startState.soc ? o.startState.soc : 0}, displayName: 'soc.start'},
        'endSoc': {type: 'soc', computed: (o, t) => { return o.endState && o.endState.soc ? Math.round(o.endState.soc): null}, sortingGetter: o => { return o.endState && o.endState.soc ? o.endState.soc : 0}, displayName: 'soc.end'},
        'date': {type: 'date', format: 'DD/MM/YYYY HH:mm'},
        'start': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss', sortingGetter: o => { return o.start ? moment(o.start).unix() : 0}},
        'end': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'duration': {type: 'duration', computed: (o, t) => { return {start: o.start, end: o.end};}},
        'quantity': {live: true, type: 'quantity-gramme', sortingGetter: o => { return o.quantity ? o.quantity : 0}},
        'serviceProvider': {object:'serviceProvider'},
        'customer': {object:'customer', sortingGetter: o => { return o.customer ? o.customer.id : 0}, tableFilter: { }},
        'vehicle': {object:'vehicle'},
        'vehicleModel': {object:'vehicleModele', displayName: 'vehicleModele'},
        'driver': {object:'driver', tableFilter: { }},
        'station': {object:'station', sortingGetter: o => { return o.station ? o.station.id : 0}, tableFilter: { }},
        'dispenser': {object:'dispenser'},
        'authorization': {object:'authorization'},
        'authorizationContact': { computed: o => {
            if (o.authorization && o.authorization.contactInfos) {
                return o.authorization.contactInfos.firstName+' '+o.authorization.contactInfos.lastName;
            }
            return null;
        }, displayName: 'driver' },
        'deviceInfos': {object:'deviceInfos', type: 'structure'},
        'energy': {object:'energy'},
        'energyType': {sortingGetter: o => { return o.energyType ? o.energyType.id : 0}, tableFilter: { }, type: 'energyType', object:'energyType'},
        'pos': {type: 'pos', tableFilter: { }, values: {'fillndrive.app': 'pos.fillndrive.app', 'fillndrive.badge': 'pos.fillndrive.badge', 'fillndrive.manager': 'pos.fillndrive.manager', 'tokheim': 'pos.tokheim', 'gtis': 'pos.gtis'}},
    }, list: {
        fields: [
            {name: 'customer', profils: ['SERVICE_MANAGER']},
            {name: 'driver'},
            {name: 'station'},
            {name: 'energyType'},
            {name: 'realAttemptsCount'},
            {name: 'start'},
            {name: 'duration'},
            {name: 'quantity'}, 
            {name: 'endSoc', type: 'soc', profils: ['SERVICE_MANAGER']},
            {name: 'pos'}],
        filters: {
            'pos': {label:'filter.refill.pos', type:'choice', field:'pos', default: () => undefined, required: true, choicemultiple: true, choicesource: {'fillndrive.app': 'pos.fillndrive.app', 'fillndrive.badge': 'pos.fillndrive.badge', 'fillndrive.manager': 'pos.fillndrive.manager', 'tokheim': 'pos.tokheim', 'gtis': 'pos.gtis'}, profils: ['SERVICE_MANAGER']},
            'date': {label:'filter.refill.date', type:'periode', field:'date' , default: () => new Date(), required: true},
        },
		filtered_lists: ['station', 'dispenser', 'driver', 'vehicle'],
        liveDisabled: true,
    }, view: {
        fields: [
            {name: 'customer', profils: ['SERVICE_MANAGER'], preview: true},
            {name: 'driver', preview: true, show: (user, appcontext, context, object) => { return object.driver || !(object.authorization && object.authorization.contactInfos)}},
            {name: 'authorizationContact', show: (user, appcontext, context, object) => { return !object.driver && object.authorization && object.authorization.contactInfos}},
            {name: 'vehicle', preview: true},
            {name: 'realAttemptsCount'},
            {name: 'start'},
            {name: 'end'},
            {name: 'duration'}, 
            {name: 'energyType'},
            {name: 'quantity'},
            {name: 'attempts', object:'refill', type: 'list', fields: [
                {name: 'attemptCount'},
                {name: 'state'},
                {name: 'start'},
                {name: 'duration'},
                {name: 'quantity'},
                {name: 'startSoc', type: 'soc', displayName: 'soc.start'},
                {name: 'endSoc', type: 'soc', displayName: 'soc.end'},
                {name: 'vehicleComState'},
            ]},
        ],
        liveDisabled: true,
    },
    liveView: {
        fields: [
            {name: 'station'},
            {name: 'dispenser'},
            {name: 'pos'},
            {name: 'deviceInfos', type: 'structure', fields: [
                {name: 'manufacturer'},
                {name: 'model'},
                {name: 'os'},
                {name: 'version'},
                {name: 'appVersion'},
            ], show: (user, appcontext, context, object) => { return (object.pos === 'nexopus.app' || object.pos === 'fillndrive.app')}},
            {name: 'authorization', type: 'structure', show: (user, appcontext, context, object) => { return object.authorization ? true : false}},
            {name: 'vehicleModel', show: (user, appcontext, context, object) => { return object.vehicleModel ? true : false}},
        ]
    },
    kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'last30days', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byVehicleModel', name: 'vehicleModels', icon: <DirectionsCarIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }}
        ],
        full: {object: true}
    }
    },
    {name: 'refill', icon: (<OpacityIcon/>), menu: false, fields: {
        'id': {},
        'transactionId': {},
        'attemptCount': {},
        'start': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss', sortingGetter: o => { return o.start ? moment(o.start).unix() : 0}},
        'end': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'duration': {type: 'duration', computed: (o, t) => { return {start: o.start, end: o.end};}},
        'quantity': {live: true, type: 'quantity-gramme', sortingGetter: o => { return o.quantity ? o.quantity : 0}},
        'progress': {live: true},
        'vehicleCommunication': {type: 'boolean'},
        'vehicleComState': {type: 'vehicleComState', displayName: 'vehicleCommunication', computed: o => { return {vehicleCommunication: o.vehicleCommunication, vehicleCommunicationLost: o.vehicleCommunicationLost }} },
        'startState': { object: 'refillVehicleState', structure: {
                pressure: {},
                temperature: {type: 'temperature'},
                soc: {type: 'soc'},
            },
            displayName: 'vehicleRefill.startState'
        },
        'endState': { object: 'refillVehicleState', structure: {
                pressure: {},
                temperature: {type: 'temperature'},
                soc: {type: 'soc'},
            },
            displayName: 'vehicleRefill.endState'
        },
        'startSoc': {type: 'soc', computed: (o, t) => { return o.startState && o.startState.soc ? Math.round(o.startState.soc) : null}, sortingGetter: o => { return o.startState && o.startState.soc ? o.startState.soc : 0}, displayName: 'soc.start'},
        'endSoc': {type: 'soc', computed: (o, t) => { return o.endState && o.endState.soc ? Math.round(o.endState.soc) : null}, sortingGetter: o => { return o.endState && o.endState.soc ? o.endState.soc : 0}, displayName: 'soc.end'},
        'state': {computed: (o, t) => {
            let more = '';
            if (o.state === 'ended') {
                return <span className="color-success" title={t('refill.state.'+o.state, '-')}><CheckCircleIcon/></span>;
            }
            if (o.state === 'inError') {
                return <span className="color-error" title={t('refill.state.'+o.state, '-')}><ErrorIcon/></span>;
            }
            if (o.state === 'cancelled') {
                return <span title={t('refill.state.'+o.state, '-')}><CancelIcon/></span>;
            }
            if (o.state === 'unlocktimeout' || o.state === 'starttimeout') {
                return <span title={t('refill.state.'+o.state, '-')}><AvTimerIcon/></span>;
            }
            
            return t('refill.state.'+o.state, '-') + more;
        }, sortingGetter: o => { return o.state ? ['cancelled', 'unlocktimeout', 'starttimeout', 'waitinqueue', 'firstinqueue', 'waitToStart', 'preparing','inProgress', 'ending', 'ended', 'inError'].indexOf(o.state) : 0}},
        'stateTechnique': {computed: (o, t) => {
            let stateTechnique = '';
            stateTechnique =  o.endStatus ? o.endStatus : '-';
            stateTechnique += '/';
            stateTechnique +=  o.endSubStatus ? o.endSubStatus : '-';
            return stateTechnique;
        }},
        'states': {array: true, object:'refillState', displayName: 'steps'},
        'stationState': {},
        'serviceProvider': {object:'serviceProvider'},
        'customer': {object:'customer', sortingGetter: o => { return o.customer ? o.customer.id : 0}, tableFilter: { }},
        'vehicle': {object:'vehicle'},
        'vehicleModel': {object:'vehicleModele', displayName: 'vehicleModele'},
        'driver': {object:'driver', tableFilter: { }},
        'station': {object:'station', sortingGetter: o => { return o.station ? o.station.id : 0}, tableFilter: { }},
        'dispenser': {object:'dispenser'},
        'authorization': {object:'authorization'},
        'deviceInfos': {object:'deviceInfos', type: 'structure'},
        'energy': {object:'energy'},
        'energyType': {sortingGetter: o => { return o.energyType ? o.energyType.id : 0}, tableFilter: { }, type: 'energyType', object:'energyType'},
        'pos': {type: 'pos', tableFilter: { }, values: {'fillndrive.app': 'pos.fillndrive.app', 'fillndrive.badge': 'pos.fillndrive.badge', 'fillndrive.manager': 'pos.fillndrive.manager', 'tokheim': 'pos.tokheim', 'gtis': 'pos.gtis'}},
    }, list: {
        fields: [
            {name: 'customer', profils: ['SERVICE_MANAGER']},
            {name: 'state', profils: ['SERVICE_MANAGER']},
            {name: 'driver'},
            {name: 'station'},
            {name: 'energyType'},
            {name: 'start'},
            {name: 'duration'},
            {name: 'quantity'}, 
            {name: 'endSoc', profils: ['SERVICE_MANAGER']},
            {name: 'pos'}],
        filters: {
            'pos': {label:'filter.refill.pos', type:'choice', field:'pos', default: () => undefined, required: true, choicemultiple: true, choicesource: {'fillndrive.app': 'pos.fillndrive.app', 'fillndrive.badge': 'pos.fillndrive.badge', 'fillndrive.manager': 'pos.fillndrive.manager', 'tokheim': 'pos.tokheim', 'gtis': 'pos.gtis'}, profils: ['SERVICE_MANAGER']},
            'date': {label:'filter.refill.date', type:'periode', field:'stationCreatedAt' , default: () => new Date(), required: true},
        },
        liveDisabled: true,
    },view: {
        fields: [
            {name: 'customer', profils: ['SERVICE_MANAGER'], preview: true },
            {name: 'driver', preview: true},
            {name: 'vehicle', preview: true},
            {name: 'state', profils: ['SERVICE_MANAGER']},
            {name: 'stateTechnique', profils: ['SERVICE_MANAGER']},
            {name: 'start'},
            {name: 'end'},
            {name: 'duration'},
            {name: 'quantity'},
            {name: 'vehicleCommunication'},
            {name: 'startState', type: 'structure', fields: [
                {name: 'temperature', type: 'temperature'},
                {name: 'pressure'},
                {name: 'soc'},
            ], show: (user, appcontext, context, object) => { return object.startState && object.startState.temperature ? true : false } },
            {name: 'endState', type: 'structure', fields: [
                {name: 'temperature', type: 'temperature'},
                {name: 'pressure'},
                {name: 'soc'},
            ], show: (user, appcontext, context, object) => { return object.endState && object.endState.temperature ? true : false } },
            {name: 'energyType'},
            {name: 'states', type: 'list'},
        ],
        parts: [
            {fields: ['customer', 'driver', 'state', 'stateTechnique', 'start', 'end', 'duration', 'energyType']},
            {fields : ['quantity', 'vehicleCommunication','startState','endState']},
            {fields : ['states']},
        ],
        liveStopOnError: true
    }, viewEmbedded: {
        fields: [
            {name: 'attemptCount'},
            {name: 'state', profils: ['SERVICE_MANAGER']},
            {name: 'stateTechnique', profils: ['SERVICE_MANAGER']},
            {name: 'start'},
            {name: 'end'},
            {name: 'duration'},
            {name: 'quantity'},
            {name: 'vehicleCommunication'},
            {name: 'startSoc', profils: ['SERVICE_MANAGER']},
            {name: 'endSoc', profils: ['SERVICE_MANAGER']}
        ]
    },
    liveView: {
        fields: [
            {name: 'station'},
            {name: 'dispenser'},
            {name: 'pos'},
            {name: 'deviceInfos', type: 'structure', fields: [
                {name: 'manufacturer'},
                {name: 'model'},
                {name: 'os'},
                {name: 'version'},
                {name: 'appVersion'},
            ], show: (user, appcontext, context, object) => { return (object.pos === 'nexopus.app' || object.pos === 'fillndrive.app')}},
            {name: 'authorization', type: 'structure', show: (user, appcontext, context, object) => { return object.authorization ? true : false}},
            {name: 'vehicleModel', show: (user, appcontext, context, object) => { return object.vehicleModel ? true : false}},
        ]
    },
    kpis: {
        items: [
            {name: 'refillgroups', type: 'refillsSummary', defaultPeriode: 'last30days', view: {mode: 1}, full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                    {type: 'byVehicleModel', name: 'vehicleModels', icon: <DirectionsCarIcon/>},
                    {type: 'byPos', name: 'poss', icon: <DnsIcon/>}
                ]
            }},
            {name: 'refills', type: 'attemptsSummary', defaultPeriode: 'last30days', full: {
                show: (object, user) => {

                },
                parts: [
                    {type: 'total', name: 'summary', icon: <SubjectIcon/>},
                    {type: 'byEnergyType', name: 'energies', icon: <BubbleChartIcon/>},
                    {type: 'byStartSoc', name: 'socs.start', icon: <Battery80Icon/>},
                    {type: 'byEndSoc', name: 'socs.end', icon: <BatteryChargingFullIcon/>},
                ]
            }},
        ],
        full: {object: true}
    },
    toString: object => {return object.quantity}},
    {name: 'refillVehicleState', icon: (<Battery80Icon/>), fields: {
        'temperature': {type: 'temperature'},
        'pressure': {},
        'soc': {type: 'soc'}
    }, view: {
        fields: [
            {name: 'temperature'},
            {name: 'pressure'},
            {name: 'soc'},
        ]
    }},
    {name: 'authorization', icon: (<CloudDoneIcon/>), menu: false, fields: {
        'id': {},
        'customer': {object:'customer', required: true},
        'associatedDriver': {object:'driver', displayName:'driver', filter: [{
            field: 'customer',
            path: 'customer.id'
        }]},
        'badge': {object:'badge'},
        'contactInfos': {object: 'contactInfos'},
        'enabled': {},
    },view: {
        fields: [
            {name: 'id'},
            {name: 'enabled'}
        ]
    }, viewEmbedded: {
        component: 'authorizationView'
    },
    toString: object => {return object.id}},
    {name: 'contactInfos', icon: (<PersonIcon/>), fields: {
        'reference': {},
        'firstName': {required: true},
        'lastName': {required: true},
        'phone': {},
    }, creation: {
        fields: [
            {name: 'reference'},
            {name: 'firstName'},
            {name: 'lastName'},
            {name: 'phone'},
        ]
    }, edition: {
        fields: [
            {name: 'reference'},
            {name: 'firstName'},
            {name: 'lastName'},
            {name: 'phone'},
        ]
    }},
    {name: 'refillSession', icon: (<AssignmentIcon/>), menu: false, fields: {
        'state': {translationKey: 'refill.state.'},
        'authorization': {object:'authorization', type: 'structure'},
        'deviceInfos': {object:'deviceInfos', type: 'structure'},
        'timeout': {live: true, type: 'duration', computed: (o, t) => { return o.timeout ? {start: new Date(), end: new Date(o.timeout)} : null} },
        'enabled': {},
    }, view: {
        fields: [
            {name: 'authorization', type: 'structure'},
            {name: 'timeout', show: (user, appcontext, context, o) => o.timeout > 0}
        ]
    }, viewEmbedded: {
        fields: [
            {name: 'authorization', type: 'structure'},
            {name: 'timeout'}
        ]
    },
    defaultRights: {read: true},
    toString: object => {return object.id}},
    {name: 'refillState', icon: <LabelImportantIcon/>, fields: {
        'id': {},
        'status': {computed: (o, t) => t('refill.state.' + o.status, o.status)},
        'start': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'end': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'duration': {type: 'duration'},
        'raw': {}
    },list: {
        fields: [
            {name: 'start'},
            {name: 'status'}
        ],
        expandedView: RefillStepRaw
    },
    toString: object => {return object.id}},
    {name: 'receptacle', icon: <LabelImportantIcon/>, fields: {
        'energy': {},
        'state': {type: 'state'},
        'nozzlePosition': {type: 'boolean'},
    },list: {
        fields: [
            {name: 'energy'},
            {name: 'state'},
            {name: 'nozzlePosition'}
        ]
    },viewEmbedded: {
        component: 'receptacleView'
    },
    defaultRights: {list: true},
    toString: object => {return object.energy}},
    {name: 'stationAvailability', icon: <LabelImportantIcon/>, fields: {
        'start': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'end': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'resume': {computed: (o, t) => {

            let infos;

            if (o.serverOnline !== o.serverOnlineBefore) {
                infos = o.serverOnline ? t('station.availability.event.serveronline') : t('station.availability.event.serveroffline');
            } else if (o.stationOnline !== o.stationOnlineBefore) {
                infos = o.stationOnline ? t('station.availability.event.stationonline') : t('station.availability.event.stationoffline');
            } else if (o.wfconnectorOnline !== o.wfconnectorOnlineBefore) {
                infos = o.wfconnectorOnline ? t('station.availability.event.wfconnectoronline') : t('station.availability.event.wfconnectoroffline');
            } else if (o.plcOnline !== o.plcOnlineBefore) {
                infos = o.plcOnline ? t('station.availability.event.plconline') : t('station.availability.event.plcoffline');
            }
            
            if (infos || o.publicState !== o.publicStateBefore) {
                return (<span><StateReadView inline={true} t={t} value={o.publicState}/> {infos ? '- '+ infos : ''}</span>);
            }

            if (o.publicState !== 'unknown' && o.dispenserStates) {
                return Object.keys(o.dispenserStates).map( dName => {
                    const dState = o.dispenserStates[dName];
                    return <div>{dName} : <StateReadView inline={true} t={t} value={dState.publicState}/> - {Object.keys(dState.receptacles).map( rName => {
                        return <StateReadView inline={true} square={true} label={rName} t={t} value={dState.receptacles[rName]}/>
                    }) }</div>
                });
            }

            return <StateReadView inline={true} t={t} value={o.publicState}/>;
        }},
        'station': {object: 'station'},
    },list: {
        fields: [
            {name: 'start'},
            {name: 'end'},
            {name: 'resume'}
        ],
        filters: {
            'start': {label:'filter.start', type:'date', field:'start' , default: () => new Date(), required: true},
        },
    }},
	{name: 'dispenserAvailability', icon: <LabelImportantIcon/>, fields: {
        'start': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
        'end': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
		'resume': {computed: (o, t) => { 

            let infos;
            
            let manualInfos = o.manualState ? <span className="flex-row-center"><StationDispenserStateReadView t={t} value={o.manualState} manual={true}/></span> : null;

            if (o.cloudOnline !== o.cloudOnlineBefore) {
                infos = o.cloudOnline ? t('station.availability.event.conline') : t('station.availability.event.coffline');
            } else if (o.wfconnectorOnline !== o.wfconnectorOnlineBefore) {
                infos = o.wfconnectorOnline ? t('station.availability.event.wfconnectoronline') : t('station.availability.event.wfconnectoroffline');
            } else if (o.plcOnline !== o.plcOnlineBefore) {
                infos = o.plcOnline ? t('station.availability.event.plconline') : t('station.availability.event.plcoffline');
            }
         		 
            if (infos || o.publicState !== o.publicStateBefore) {
                return (<span className="flex-row-center">{manualInfos} <StationDispenserStateReadView inline={true} t={t} value={o.publicState}/> {infos ? '- '+ infos : ''}</span>);
            }

			if (!underscore.isEqual(o.energyStates, o.energyStatesBefore)) {
                return <span className="flex-row-center">{manualInfos} <StationDispenserStateReadView inline={true} t={t} value={o.publicState}/> <span className="flex-row-center">{Object.keys(o.energyStates).map( energyKey => {
                    const eState = o.energyStates[energyKey];
                    return <div className="flex-row-center"><EnergyTypeView inline={true} t={t} value={{name: energyKey}} state={eState}/> </div>;
                })}</span></span>;
            }

            return <span className="flex-row-center">{manualInfos} <StationDispenserStateReadView inline={true} t={t} value={o.publicState}/></span>

        }},
        'station': {object: 'station'},
		'dispenser': {object: 'dispenser'},
    },list: {
        fields: [
            {name: 'start'},
            {name: 'end'},
			{name: 'station'},
			{name: 'dispenser'},
			{name: 'resume'}
        ],
        filters: {
            'start': {label:'filter.start', type:'date', field:'start' , default: () => new Date(), required: true},
        }
    }},
    {name: 'outputField', icon: <CloudDownloadIcon/>, fields: {
        'id': {},
        'name': {},
        'description': {},
        'example': {},
        'order': {displayName: 'column'},
        //'outputField': {},
        //'outputFields': {array: true, child: true, object:'outputField'},
        'enabled': {type: 'boolean'},
    },
    list: {
        fields: [{name: 'order'}, {name: 'name'}, {name: 'description'}, {name: 'example'}]
    }, view: {
        fields: [
        ],
    }
    },
    {name: 'dataExport', icon: <CloudDownloadIcon/>, fields: {
        'id': {},
        'name': {},
        'object': {},
        'description': {},
        'serviceProvider': {object:'serviceProvider'},
        'customer': {object:'customer'},
        'features': {object:'feature', array: true},
        'outputFields': {array: true, child: true, object:'outputField', computed: (o, t) => {
            
            if (!o.outputFields) {
                return null;
            }

            const array = [];
            
            const doFields = (outputFields, parent) => {

                outputFields.forEach( outputField => {

                    if (outputField.outputFields && outputField.outputFields.length > 0) {
                        doFields(outputField.outputFields, outputField);
                    } else {

                        const name = outputField.outputName ? outputField.outputName : ( (parent ? (parent.name + '.') : '') + outputField.name);

                        array.push({order: array.length + 1, name: name, description: t('dataExport.outputField.desc.' + o.object.name+'.'+name, '-'), example: t('dataExport.outputField.example.' + o.object.name+'.'+name, '-')})
                    }

                })

            }

            doFields(o.outputFields);

            return array;
        }},
        'enabled': {type: 'boolean'},
    },
    list: {
        fields: [{name: 'name'}, {name: 'description'}]
    }, view: {
        fields: [
            {name: 'name'},
            {name: 'description', hideIfEmpty: true},
            {name: 'outputFields', type:'list'},
        ],
        workflowActions: [
            {
                name: 'dataexport',
                icon: (<CloudDownloadIcon/>),
                title: 'action.dataexport',
                //intro:'action.enable.intro',
                validationLabel:'action.export',
                enabled: object => {
                    return object.enabled;
                },
                fields: [
                    { name: 'periode', type: 'periode', required: true },
                ],
                outputType: 'file',
                needReload: false
            }
        ]
    }}, 
	{name: 'apiClient', icon: <ExtensionIcon/>, menu: true, fields: {
        'id': {},
        'keyId': {},
        'keyName': {},
		'keyDate': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss'},
		'publicKey': {},
        'serviceProvider': {object:'serviceProvider'},
        'enabled': {type: 'boolean'},
    }, list: {
        fields: [{name: 'keyId'}, {name: 'keyName'}, {name: 'keyDate'}]
    }, view: {
        fields: [
            {name: 'keyId'},
            {name: 'keyName'}, 
			{name: 'keyDate'},
        ],
        workflowActions: [
            {
                name: 'apiGenerateKey',
                icon: (<EnhancedEncryptionIcon/>),
                title: 'action.generatekey',
                //intro:'action.enable.intro',
                validationLabel:'action.generate',
                enabled: object => {
                    return !object.keyId;
                },
                fields: [
                    { name: 'keyName', required: true },
                ],
                needReload: true
            },
            {
                name: 'apiDownloadKey',
                icon: (<GetAppIcon/>),
                title: 'action.downloadprivatekey',
                intro:'action.downloadprivatekey.intro',
                validationLabel:'action.download',
                enabled: object => {
                    return object.privateKeyAvailable;
                },
                fields: [
                ],
                outputType: 'file',
                needReload: true
            },
            {
                name: 'apiRevokeKey',
                icon: (<DeleteForeverIcon/>),
                title: 'action.apiRevokeKey',
                intro:'action.apiRevokeKey.intro',
                validationLabel:'action.revoke',
                enabled: object => {
                    return object.keyId && !object.privateKeyAvailable;
                },
                fields: [
                ],
                needReload: true
            }
        ]
    }}, 
	{name: 'deviceLog', icon: <ExtensionIcon/>, menu: false, fields: {
        'id': {},
        'deviceId': {},
		'date': {type: 'date', format: 'DD/MM/YYYY HH:mm:ss', tableFilter: { }},
        'level': {type: 'loglevel', tableFilter: { }},
        'subLevel': {},
        'content': {},
        'subContent': {},
        'parameters': {},
        'device': {object: 'device'},
    }, list: {
        fields: [{name: 'date'}, {name: 'level'}, {name: 'content'}],
        filters: {
            'date': {label:'filter.date', type:'periode', field:'date' , default: () => new Date(), required: true},
        },
        search: true
    }, view: {
        fields: [
            {name: 'date'},
            {name: 'level'}, 
            {name: 'subLevel'},
            {name: 'content'}, 
            {name: 'subContent'},
            {name: 'parameters'},
        ]
    }},
	{name: 'import', icon: <VerticalAlignTopIcon/>, menu: true, fields: {
        'id': {},
		'name': {required: true},
		'state': {type: "label"},
		'objType': {object:"object"},
		'parentType': {object:"object"},
		'parentId_': {},
		'nbImportable': {},
		'nbErrorsAnalyze': {},
		'nbErrorsImport': {},
		'media': {object:"media", required: true, type: "download", filenameProvider: "originalFilename"},
		'serviceProvider': {object: 'serviceProvider'},
		'customer': {object: 'customer'}
    }, list: {
        fields: [
			{name: 'name'},  
			{name: 'objType'},
			{name: 'state'},
			{name: 'nbImportable'},
			{name: 'nbErrorsAnalyze'},
			{name: 'nbErrorsImport'},
			{name: 'media'}
		]
    }, view: {
        fields: [
			{name: 'name'},  
			{name: 'customer'},
			{name: 'objType'},
			{name: 'parentType'},
			{name: 'state'},
			{name: 'nbImportable'},
			{name: 'nbErrorsAnalyze'},
			{name: 'nbErrorsImport'},
            {name: 'media'}
        ],
		main_list: {
				uriobject: "importLine",
				filter_field: "importRef"
		},
        workflowActions: [
            {
                name: 'analyzeImport',
                icon: (<RemoveRedEyeIcon/>),
                title: 'action.import.analyze',
                validationLabel:'action.send',
                enabled: object => {
                    return object.state == "import.state.created" || object.state == "import.state.ready";
                },
                needReload: true
            },
            {
                name: 'doImport',
                icon: (<VerticalAlignTopIcon/>),
                title: 'action.import.do',
                validationLabel:'action.send',
                enabled: object => {
                    return object.state == "import.state.ready";
                },
                needReload: true
            }
        ]
    }, creation: {
        fields: [
			{name: 'name'},
            {name: 'objType', type: 'choice', fill_then_hide: true, sourcetype: 'object', source:'object'},
			{name: 'parentType', type: 'choice', fill_then_hide: true, sourcetype: 'object', source:'object'},
			{name: 'parentId_', hidden: true},
            {name: 'media', type: 'upload' },
        ]
    }, edition: {
        fields: [
			{name: 'name'},
            {name: 'media', type: 'upload', filenameField: "originalFilename" }
        ]
    }, createObject: function(newItem, user, context, parent, parentModel, customData) {
	
		if(parentModel) {
			newItem["parentType"] = { name: parentModel.name };
		}
		
		if(parent) {
			newItem["parentId_"] = parent.id;
		}
		
		if(customData && customData["objType"]) {
			newItem["objType"] = { name: customData["objType"] };
		}

        return newItem;
    }},
	{name: 'importLine', menu: false, fields: {
        'id': {},
		'importRef': {object: 'import'},
		'state': {},
		'lineNumber': {},
		'data': {},
		'errorAnalyze': {type: "multilabel"},
		'errorImport': {type: "multilabel"},
		'importable': {},
		'customer': {},
		'serviceProvider': {},
    }, list: {
        fields: [{name: "lineNumber"},
				 {name: 'errorAnalyze'},
				 {name: 'errorImport'}]
    }},
	{name: 'media', menu: false, fields: {
        'name': {},
		'originalFilename': {},
		'originalFilename_tmp': {}
    }, list: {
        fields: [{name: 'name'},{name: 'originalFilename'}]
    }, view: {
        fields: [{name: 'name'},{name: 'originalFilename'}]
    },
	toString: object => {console.log(object); return object.originalFilename}},
	{name: 'object', menu: false, fields: {
        'id': {},
		'name': {}
    }, list: {
        fields: [{name: 'name'}]
    }, view: {
        fields: [
            {name: 'name'}
        ]
    },
	toString: object => {return object.name}}
];


export const ObjectsMap = {};

Objects.forEach( model => {
        ObjectsMap[model.name] = model;
    }
);