import React, { Component } from 'react';

import LockIcon from '@material-ui/icons/Lock';

import { StateColorHelper } from '../../_helpers';

class StationDispenserStateReadView extends Component {
    
    render() {
    
       const { t, value, label, inline, manual } = this.props;
       const state = value ? value : 'unknown';
        const stateLabel = label ? label : 'state.'+state;
        
        const content = <div className={"station-state station-state-" + StateColorHelper.stateToColorClass(state)}><div className="station-state-label">{manual ? <LockIcon/> : null}{t(stateLabel)}</div></div>

       return inline ? <div className="flex-row-center">{content}</div> : content;
    }
}

export { StationDispenserStateReadView as StationDispenserStateReadView };