import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';

import { Chart, Line } from 'react-chartjs-2';

import { DurationReadView } from '../read/Duration';

import { commonActions } from '../../_actions';

import { KpiTools } from './KpiTools'
import GridRowView from './common/GridRow'
import SubPartView from './common/SubPart'
import { RefillGroupSummary, RefillGroupSummaryBreaks, RefillGroupSatisfactionView, AttemptsSummaryView, AttemptsSummaryBreaksView, SocsBy5View, SocsSatisfactionView, SocsSatisfactionBreaksView, VehicleModelView, VehicleModelsIdentifiedView, EnergySummaryView, EnergySummaryBarsView, PossSummaryView, PossSummaryBreaksView, AvailabilitySummaryPieView, AvailabilitySummaryTextView, AvailabilitySummaryBreaksView} from './blocs'


class KpiView extends Component {

    state = {

    }

    constructor(props) {
        super(props)
    }

    componentWillMount() {
        this.periode = this.props.periode
        this.displayPrefetchedData = this.props.prefetchedData ? true : false
        
        if (!this.displayPrefetchedData) {
            this.props.dispatch(commonActions.loadKpi(this.props.scope, this.props.kpi.type, this.props.model, this.props.object, this.periode, this.props.parts))
        }

        Chart.defaults.global.defaultFontFamily = '"MarkPro"';

    } 

    componentDidUpdate() {
        const periodeChanged = this.props.periode && (!this.periode || (this.props.periode.type !== this.periode.type || this.props.periode.start !== this.periode.start || this.props.periode.end !== this.periode.end) ) ? true : false
        if (periodeChanged) {    
            this.periode = this.props.periode;
            this.props.dispatch(commonActions.loadKpi(this.props.scope, this.props.kpi.type, this.props.model, this.props.object, this.periode, this.props.parts))
            this.displayPrefetchedData = false
        }
    }

    render() {
        
        const { t, scope, kpi, model, data, prefetchedData, mode, parts } = this.props;
        
        if (this.displayPrefetchedData || data) {

            let kpiData = this.displayPrefetchedData ? prefetchedData : data

            if (data && scope) {
                const scopedData = data[scope];
                kpiData = scopedData ? scopedData : data;
            }
            
            const kpiKey = kpi.type;

            if (kpiData.kpis) {
                const remoteData =  kpiData.kpis[kpiKey];
                const periode = kpiData.kpis[kpiKey+'_periode']
                const loading = kpiData.kpis[kpiKey+'_loading']
                if (remoteData || loading) {
                    return <div className={'kpi-view-bloc'}>
                        {mode === 1 && <Grid container xs={12}  direction="row"
                                        justify="space-between"
                                        alignItems="center">
									<Grid item xs={6}>
                                        <div className={'kpi-view-main-title-bloc'}><AssessmentIcon/> <span className={'kpi-view-main-title'}>{t('kpi.'+kpi.name+'.title')}</span></div>
									</Grid>
									<Grid item xs={6} style={{textAlign: 'right'}}>
										{this.props.onMorePress && <Button variant="contained" size="small" onClick={this.props.onMorePress} style={{boxShadow: 'none', fontSize: '0.7rem', padding: '2px 8px', color: '#042959', backgroundColor: '#f9f9f9'}}>
												{t('see.more')}
										</Button>}
									</Grid>
								</Grid>}
                        {this.__renderKpi(t, kpi, remoteData, periode, loading, mode, parts)}
                    </div>
                }
            }

        }

        return null
    }

    __renderKpi = (t, kpi, remoteData, periode, loading, mode, parts) => {

        if (kpi.type === 'refillsSummaryLight') {
            return this.__renderRefillsSummaryLight(t, kpi, remoteData, periode, loading, mode, parts)
        }

        if (kpi.type === 'refillsSummary') {
            return this.__renderRefillsSummary(t, kpi, remoteData, periode, loading, mode, parts)
        }

        if (kpi.type === 'attemptsSummary') {
            return this.__renderAttempsSummary(t, kpi, remoteData, periode, loading, mode, parts)
        }

        if (kpi.type === 'availability') {
            return this.__renderAvailability(t, kpi, remoteData, periode, loading, mode, parts)
        }

        return null;
    }

    __renderAvailability = (t, kpi, remoteData, periode, loading, mode, parts) => {

        const periodeType = periode ? periode.type : kpi.defaultPeriode;

        const periodeKpis = remoteData && !loading ? remoteData[periodeType] : null;
        const previousPeriodeKpis = remoteData && !loading ? remoteData['previous'+periodeType] : null;
        const breakPeriodeKpis = remoteData && !loading ? remoteData['break'+periodeType] : null;

        const breakConfig = breakPeriodeKpis ? this.__getBreakConfig(periodeType, periode) : null

        const summary = (parts ? parts : ['total', 'byEndSoc', 'byEnergyType']).map( part => {

            if (part === 'total') {
                return this.__renderAvailabilitySummary(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }
        })

        return summary.length > 0 ? <div className={'kpi-view'}>{mode === 1 && this.__renderPeriode(t, periodeType)}{summary}</div> : <div className="kpi-view-nodata">{t('nodata.onperiode')}</div>

    }

    __renderAttempsSummary = (t, kpi, remoteData, periode, loading, mode, parts) => {

        const periodeType = periode ? periode.type : kpi.defaultPeriode;

        const periodeKpis = remoteData && !loading ? remoteData[periodeType] : null;
        const previousPeriodeKpis = remoteData && !loading ? remoteData['previous'+periodeType] : null;
        const breakPeriodeKpis = remoteData && !loading ? remoteData['break'+periodeType] : null;

        const breakConfig = breakPeriodeKpis ? this.__getBreakConfig(periodeType, periode) : null

        const summary = (parts ? parts : ['total', 'byEndSoc', 'byEnergyType']).map( part => {

            if (part === 'total') {
                return this.__renderAttemptsSummary(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

            if (part === 'byEnergyType') {

                const energyParts = mode > 1 ? ['', 'count', 'quantity', 'average', 'duration.avg', 'duration.ecart', 'vehicleCommunication', 'vehicleCommunicationLost', 'failedToStart', 'endError'] : ['', 'quantity', 'average']

                return this.__renderAttempsEnergySummary(t, mode, loading, energyParts, periodeKpis, previousPeriodeKpis)
            }

            if (part === 'byStartSoc') {
                return this.__renderStartSocs(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

            if (part === 'byEndSoc') {
                return this.__renderEndSocs(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

        })

        return summary.length > 0 ? <div className={'kpi-view'}>{mode === 1 && this.__renderPeriode(t, periodeType)}{summary}</div> : <div className="kpi-view-nodata">{t('nodata.onperiode')}</div>

    }

    __renderRefillsSummaryLight = (t, kpi, remoteData, periode, loading, mode, parts) => {
        
        const summary = []

        const periodeType = periode ? periode.type : kpi.defaultPeriode;


            const periodeKpis = remoteData[periodeType];
            const previousPeriodeKpis = remoteData['previous'+periodeType];

            if (periodeKpis.total) {
                summary.push(this.__renderRefillGroupSummary(t, mode, loading, periodeKpis, previousPeriodeKpis))
            }

        return summary.length > 0 ? <div className={'kpi-view'}>{summary}</div> : <div className="kpi-view-nodata">{t('nodata.onperiode')}</div>

    }

    __renderRefillsSummary = (t, kpi, remoteData, periode, loading, mode, parts) => {
        
        const periodeType = periode ? periode.type : kpi.defaultPeriode;

        const periodeKpis = remoteData && !loading ? remoteData[periodeType] : null;
        const previousPeriodeKpis = remoteData && !loading ? remoteData['previous'+periodeType] : null;
        const breakPeriodeKpis = remoteData && !loading ? remoteData['break'+periodeType] : null;

        const breakConfig = breakPeriodeKpis ? this.__getBreakConfig(periodeType, periode) : null
        
        const summary = (parts ? parts : ['total', 'byEnergyType', 'byVehicleModel', 'byPos']).map( part => {

            if (part === 'total') {
                return this.__renderRefillGroupSummary(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

            if (part === 'byEnergyType') {

                const energyParts = mode > 1 ? ['', 'count', 'quantity', 'average', 'duration.avg', 'duration.ecart'] : ['', 'quantity', 'average']

                return this.__renderEnergySummary(t, mode, loading, energyParts, periodeKpis, previousPeriodeKpis)
            }

            if (part === 'byStartSoc') {
                return this.__renderStartSocs(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

            if (part === 'byEndSoc') {
                return this.__renderEndSocs(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

            if (part === 'byVehicleModel') {
                return this.__renderRefillGroupSummaryVehicleModels(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

            if (part === 'byPos') {
                return this.__renderRefillGroupSummaryByPos(t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig)
            }

        })

        return summary.length > 0 ? <div className={'kpi-view'}>{mode === 1 && this.__renderPeriode(t, periodeType)}{summary}</div> : <div className="kpi-view-nodata">{t('nodata.onperiode')}</div>

    }


    __renderAvailabilitySummary = (t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig ) => {

        const summaryPart1 = <AvailabilitySummaryTextView mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>;

        const rowOne = [summaryPart1]
        const rows = [rowOne]

        if (mode === 2) {
            rowOne.push(<AvailabilitySummaryPieView mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>)
            rows.push([<AvailabilitySummaryBreaksView mode={mode} loading={loading} data={breakPeriodeKpis} breakConfig={breakConfig}/>])
        }

        return this.__makeKpiPart(mode, t('summary'), rows)
    }

    __renderPeriode = (t, type) => {
        return <div className="kpi-view-periode">Periode : {t('periode.' + type)}</div>
    }

    __renderRefillGroupSummary = ( t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig ) => {

        const summaryPart1 = <RefillGroupSummary mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>;

        const rows = [[summaryPart1]]

        if (mode === 2) {
            rows.push([<RefillGroupSummaryBreaks mode={mode} loading={loading} data={breakPeriodeKpis} breakConfig={breakConfig}/>, <RefillGroupSatisfactionView mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>])
        }

        return this.__makeKpiPart(mode, t('summary'), rows)

    }

    __renderEnergySummary = (t, mode, loading, parts, periodeKpis, previousPeriodeKpis) => {

        const energyPart1 = <EnergySummaryView mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis} parts={parts}/>
        const energyPart2 = mode === 2 ? <SubPartView title={t('repartition.count')} content={<EnergySummaryBarsView mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>}/> : null;

        return this.__makeKpiPart(mode, t('energies'), [[energyPart1, energyPart2]])

    }

    __renderRefillGroupSummaryVehicleModels = ( t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig ) => {

        const vehicleModelsContent = <SubPartView title={t('repartition.count')} content={<VehicleModelView mode={mode} data={periodeKpis} previousData={previousPeriodeKpis} loading={loading}/>}/>
        const vehicleModelsIdentifiedContent = mode === 2 ? <SubPartView title={t('repartition.identified')} content={<VehicleModelsIdentifiedView mode={mode} data={periodeKpis} previousData={previousPeriodeKpis} loading={loading}/>}/> : null

        return this.__makeKpiPart(mode, t('vehicleModels'), [[vehicleModelsIdentifiedContent, vehicleModelsContent]])

    }

    __renderRefillGroupSummaryByPos = ( t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig ) => {

        const posContentPart1 = <SubPartView title={t('repartition.quantity')} content={<PossSummaryView mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>}/>
        let posContentPart2 = mode === 2 ? <SubPartView content={<PossSummaryBreaksView mode={mode} loading={loading} data={breakPeriodeKpis} breakConfig={breakConfig}/>}/> : null

        return this.__makeKpiPart(mode, t('poss'), [[posContentPart1, posContentPart2]])
    }

    __renderAttemptsSummary = ( t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig ) => {

        return this.__makeKpiPart(mode, t('summary'), [[<AttemptsSummaryView loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>, <AttemptsSummaryBreaksView loading={loading} data={breakPeriodeKpis} breakConfig={breakConfig}/>]])

    }

    __renderAttempsEnergySummary = (t, mode, loading, parts, periodeKpis, previousPeriodeKpis) => {

        return this.__makeKpiPart(mode, t('energies'), [[<EnergySummaryView mode={mode} loading={loading} data={periodeKpis} previousData={previousPeriodeKpis} parts={parts}/>]], 1)
    }

    __renderStartSocs = (t, mode, loading, periodeKpis) => {
        const socEndContent = <SocsBy5View kpiField="byStartSoc" socField="startSoc" loading={loading} data={periodeKpis}/>

        return this.__makeKpiPart(mode, t('socs.start'), [[socEndContent]])
    }

    __renderEndSocs = (t, mode, loading, periodeKpis, previousPeriodeKpis, breakPeriodeKpis, breakConfig) => {
        const socEndContent = <SocsBy5View kpiField="byEndSoc" socField="endSoc" loading={loading} data={periodeKpis}/>

        const socEndSatisfaction = <SocsSatisfactionView kpiField="byEndSoc" socField="endSoc" loading={loading} data={periodeKpis} previousData={previousPeriodeKpis}/>

        const socEndBreakRepartition = <SocsSatisfactionBreaksView kpiField="byEndSoc" socField="endSoc" loading={loading} data={breakPeriodeKpis} breakConfig={breakConfig}/>

        return this.__makeKpiPart(mode, t('socs.end'), [[socEndContent], [socEndSatisfaction, socEndBreakRepartition]], 2)
    }

    __makeKpiPart = (mode, title, rows, fullSize) => {
 
        return <div className={'kpi-view-part'}>
            {mode === 1 && <div className={'kpi-view-title'}>{title}</div>}
            <Grid
            container
            direction="row"
            justify="center">
                {rows.map( (row, index) => <GridRowView mode={mode} key={'row_' + index} cells={row} fullSize={fullSize}/>
                    )}
            </Grid>
        </div>
    }

    __getBreakConfig = (periodeType, periode) => {
        let subPeriodeType;
        if (periodeType === 'today' || periodeType === 'daily') {
            subPeriodeType = 'hourly'
        } else if (periodeType === 'weekly' || periodeType === 'monthly' || periodeType === 'last7days' || periodeType === 'last14days' || periodeType === 'last30days' || periodeType === 'last90days') {
            subPeriodeType = 'daily'
        } else if (periodeType === 'trimester' || periodeType === 'semester') {
            subPeriodeType = 'daily'
        } else if (periodeType === 'yearly') {
            subPeriodeType = 'monthly'
        }
        if (subPeriodeType) {
            return KpiTools.makePeriodeDefaultValues(periodeType, subPeriodeType, periode.start, periode.end)
        }
        return null
    }

    __formatQuantity = (rawValue) => {
        return Math.round((rawValue / 10) ) / 100
    }

    __formatDuration = (t, rawValue) => {
        return <DurationReadView t={t} value={rawValue}/>
    }

}

function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext } = state;
    
    const { user, context} = authentication;

    return {
        data
    };
}

const connectedKpiView = translate('translations')(connect(mapStateToProps)(KpiView));
export { connectedKpiView as KpiView };