import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { InlineDatePicker } from 'material-ui-pickers';

import moment from 'moment'

import { makePeriode } from '../../_helpers';
import localCache from '../../_helpers/local.cache';

const allPeriodeTypes = {
    'daily': 'filter.daily',
    'last7days': 'filter.last7days',
    'weekly': 'filter.weekly',
    'last14days': 'filter.last14days',
    'last30days': 'filter.last30days',
    'monthly': 'filter.monthly',
    'last90days': 'filter.last90days',
    'trimester': 'filter.trimester',
    'semester': 'filter.semester',
    'yearly': 'filter.yearly',
    'custom': 'filter.periodecustom',
}
 
const styles = theme => ({
    gridCell: {
        alignSelf: 'flex-start'
    },
    formControl: {
        marginRight: '1rem',
        minWidth: '250px'
    }
});

class DateTimePeriodePicker extends Component {

    constructor(props) {
        super(props)
        const dates = makePeriode(this.props.defaultDate ? this.props.defaultDate : new Date(), this.props.defaultPeriodeType ? this.props.defaultPeriodeType : 'daily');
        this.state = dates;
		this.dateTimeId = this.props.cacheRefId+"_"+this.props.pickerId;
		this.restoreState();
    }

	saveStateUpdate(toMerge) {
		
		let dateTimeStates = localCache.getCache(this.props.cacheRefId).dateTimeStates;
		
		if(dateTimeStates == null) {
			dateTimeStates = {};
		}
		
		if(dateTimeStates[this.dateTimeId] == null) {
			dateTimeStates[this.dateTimeId] = {};
		}
		
		dateTimeStates[this.dateTimeId] = {...dateTimeStates[this.dateTimeId], ...toMerge};
		
		localCache.cacheVal(this.props.cacheRefId, "dateTimeStates", dateTimeStates);
	}
	
	restoreState() {
	
		let displayCache = localCache.getCache(this.props.cacheRefId);
	
		if(displayCache && displayCache.dateTimeStates && displayCache.dateTimeStates[this.dateTimeId]) {
			
			this.state.periodeType = displayCache.dateTimeStates[this.dateTimeId].periodeType;
		}
	}
	
    componentDidMount() {
        if (this.props.initOnMount) {
            this.props.onChange(this.state);
        }
    }

    handleDateChange = date => {
        const config = makePeriode(date, this.state.periodeType);
        this.setState(config);
        this.props.onChange(config);
    };

    handlePeriodeTypeChange = e => {
        const newPeriodeType = e.target.value;
        if (this.state.periodeType !== newPeriodeType) {
            const config = makePeriode(this.state.start, newPeriodeType);
            this.setState({ periodeType: newPeriodeType, subPeriodeType: config.subPeriodeType, start: config.start, end: config.end, previous: config.previous, next: config.next, canPick: config.canPick });
            this.saveStateUpdate({ periodeType: newPeriodeType });
			this.props.onChange(config);
        }
    }

    formatPeriodeDateSelectedLabel = (date, invalidLabel) => {

        const t = this.props.t;

        let datem = moment(date);
        
        if (date && datem.isValid()) {
            if (this.state.periodeType === 'daily') {
                return moment(this.state.start).format('DD/MM/YYYY');
            } else if (this.state.periodeType === 'weekly') {
                return t('periode.from')+' '+moment(this.state.start).format('DD/MM/YYYY') +' '+t('periode.to')+' '+moment(this.state.end).format('DD/MM/YYYY');
            } else if (this.state.periodeType === 'monthly') {
                return moment(this.state.start).format('MMMM YYYY');
            } else if (this.state.periodeType === 'yearly') {
                return moment(this.state.start).format('YYYY');
            } else {
                return t('periode.from')+' '+moment(this.state.start).format('DD/MM/YYYY') +' '+t('periode.to')+' '+moment(this.state.end).format('DD/MM/YYYY');
            }
        }

        return invalidLabel;
    };

    render() {

     const { t, label, value, disabled, disableFuture, periodeTypes, pagination, classes } = this.props;

        const choicePeriodeType = periodeTypes ? true : false;

       return (
            <div className="dateperiode-container">
            {pagination && <Button size="small" onClick={() => this.handleDateChange(this.state.previous)} disabled={disabled || !this.state.previous}><SkipPreviousIcon/></Button>}
                    {choicePeriodeType && <FormControl required className={classes.formControl}>
                    <InputLabel htmlFor="periodeType-required">{t(label)}</InputLabel>
                    <Select
                        value={this.state.periodeType}
                        onChange={(e) => this.handlePeriodeTypeChange(e)}
                        name="periodeType"
                        inputProps={{
                        id: 'periodeType-required',
                        }}
                    >
                        {periodeTypes.map( p => <option key={p} value={p}>{t(allPeriodeTypes[p])}</option>)}
                    </Select>
                    
                    </FormControl>}
                    <FormControl required className={classes.formControl}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                         <InlineDatePicker value={value} onChange={this.handleDateChange} disableFuture={disableFuture} 
                            labelFunc={this.formatPeriodeDateSelectedLabel}
                            label="Date" disabled={disabled || !this.state.canPick}/>
                    </MuiPickersUtilsProvider>
                    </FormControl>  
            {pagination && <Button size="small" onClick={() => this.handleDateChange(this.state.next)} disabled={disabled || !this.state.next}><SkipNextIcon/></Button>}      
            </div>
        )
    }
    
}

const connectedDatePicker = (withStyles(styles, { withTheme: true })(translate('translations')(DateTimePeriodePicker)));
export { connectedDatePicker as DateTimePeriodePicker };