import React, { Component } from 'react';

import { translate } from 'react-i18next';

import { HorizontalBar } from 'react-chartjs-2';

const xUserCallback = function(item, index) {
    return Math.round(item / 100 * 24)+'h';
 };

class AvailabilityDayResume extends Component {
    
    render() {

        const { t, user, kpi, light } = this.props;
        
       return <div className="kpi-bloc">
           {kpi && kpi.kpi && kpi.kpi.length > 0 && this.renderKpi(t, kpi, light)}
       </div>;
    }

    renderKpi = (t, availabilityResume, light) => {
        
        const colors = {
            'unkown': '#e1e1e1',
            'open' : '#B8E986',
            'partial': 'orange',
            'breakdown': 'red',
            'maintenance': '#cc0000',
            'closed': '#800000',
        }
        
        const options = {
            maintainAspectRatio: false,
            legend: {
                display: false
             },
             tooltips: {
                enabled: false
             },
             layout: {
                padding: {
                    bottom: 0,
                    top: 0
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    barThickness: light ? 5 : 5,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: true,
                        min: 0,
                        max: 100,
                        userCallback: xUserCallback,
                        fontColor: '#d6d6d6'
                    }
                }],
                yAxes: [{
                    stacked: true,
                    barThickness: light ? 5 : 5,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false
                    }
                }]
            }
        }

        const maxDuration = 86400;

            const datasets = availabilityResume.kpi.map( (av, index) => {
                const duration = av.duration / maxDuration * 100;
                
                return {
                    label: t('state.' + av.state)+' '+index,
                    backgroundColor: colors[av.state],
                    hoverBackgroundColor: colors[av.state],
                    data: [duration]
                  }
            } );

            const data = {
                labels: [''],
                datasets: datasets
            };
            
            return <div className={ light ? 'kpi-availability-resume-day-light':'kpi-availability-resume-day'}>
            {!light && <div className="kpi-availability-resume-day-title">{availabilityResume.name}</div>}
            <HorizontalBar
            data={data}
            options={options}
        /></div>

    }

    
}

const connectedAvailabilityDayResume = translate('translations')(AvailabilityDayResume);
export { connectedAvailabilityDayResume as AvailabilityDayResume };