import { combineReducers } from 'redux';
import { api } from './api.reducer';
import { authentication } from './authentication.reducer';
import { data } from './common.reducer';
import { uiaction } from './uiaction.reducer';
import { appcontext } from './appcontext.reducer';
import { kpi } from './kpi.reducer';
import { context } from './context.reducer';

const rootReducer = combineReducers({
  api,
  authentication,
  data,
  uiaction,
  appcontext,
  kpi,
  context
});

export default rootReducer;