import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import BarChartView from '../common/BarChart';
import BarChartLoadingView from '../common/BarChartLoading';


class AttemptsSummaryBreaksView extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { loading, mode, data } = this.props;
        
        if (loading) {
            return <BarChartLoadingView/>
        }

        if (data && data.total && data.total.length > 0) {
            return this.renderKpiBloc( data.total )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( rawData ) => {
        
        const { t, breakConfig } = this.props;

        const datasetFailedToStart = {
            label: t('failedToStart'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#f1aa00',
            type: 'bar',
        }
        
        const datasetAttemptsCount = {
            label: t('count'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#d7ecfb',
            type: 'line',
            borderWidth: 1,
            borderColor:  '#87c7f3',
        }

        const datasetEndWithErrorCount = {
            label: t('endError'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#ff000061',
            type: 'bar',
        } 

        rawData.forEach( totalKpis => {

            breakConfig.matcher(totalKpis, datasetFailedToStart.data, totalKpis.failedToStart)
            breakConfig.matcher(totalKpis, datasetAttemptsCount.data, totalKpis.count)
            breakConfig.matcher(totalKpis, datasetEndWithErrorCount.data, totalKpis.endError)
        })

        datasetFailedToStart.data = Object.values(datasetFailedToStart.data)
        datasetAttemptsCount.data = Object.values(datasetAttemptsCount.data)
        datasetEndWithErrorCount.data = Object.values(datasetEndWithErrorCount.data)

        const data = {
            labels: breakConfig.labels,
            datasets: [datasetFailedToStart, datasetEndWithErrorCount, datasetAttemptsCount]
        }; 

        return <BarChartView data={data} />

    }

}

const connectedBloc = translate('translations')(AttemptsSummaryBreaksView);
export { connectedBloc as AttemptsSummaryBreaksView };