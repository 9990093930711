import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import DoughnutChartView from '../common/DoughnutChart';
import PieChartLoadingView from '../common/PieChartLoading';

import BlocSubPartView from '../common/BlocSubPart';
import ProgressionPercentView from '../common/ProgressionPercent';

import { DurationReadView } from '../../read/Duration';

class AvailabilitySummaryTextView extends React.Component {

    render() {

        const { t, mode, loading, data, previousData } = this.props;
        
        if (loading) {
            return <PieChartLoadingView/>
        }
 
        if (data && data.total && data.total.length > 0) {
            return this.renderKpiBloc( t, mode, data.total, previousData.total )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, mode, rawData, previousRawData ) => {

        const emptyTotalValue = {}

        const totalData = rawData.length > 0 ? rawData[0] : emptyTotalValue
        const totalPreviousData = previousRawData.length > 0 ? previousRawData[0] : emptyTotalValue
        const open = <div>
                        <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={totalData.openDuration}/></span>
                        <ProgressionPercentView currentValue={totalData.openDuration} previousValue={totalPreviousData.openDuration}/>
                    </div>
        const partial = <div>
                            <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={totalData.partialDuration}/></span>
                            <ProgressionPercentView currentValue={totalData.partialDuration} previousValue={totalPreviousData.partialDuration}/>
                        </div>

        const breakdown = <div>
                            <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={totalData.breakdownDuration}/></span>
                            <ProgressionPercentView currentValue={totalData.breakdownDuration} previousValue={totalPreviousData.breakdownDuration}/>
                        </div>
        const maintenance = <div>
                                <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={totalData.maintenanceDuration}/></span>
                                <ProgressionPercentView currentValue={totalData.maintenanceDuration} previousValue={totalPreviousData.maintenanceDuration}/>
                            </div>
        const closed = <div>
                            <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={totalData.closedDuration}/></span>
                            <ProgressionPercentView currentValue={totalData.closedDuration} previousValue={totalPreviousData.closedDuration}/>
                        </div>
                        
        const shutdown = <div>
                            <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={totalData.shutdownDuration}/></span>
                            <ProgressionPercentView currentValue={totalData.shutdownDuration} previousValue={totalPreviousData.shutdownDuration}/>
                        </div>

        const offline = <div>
                            <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={totalData.cloudOfflineDuration}/></span>
                            <ProgressionPercentView currentValue={totalData.cloudOfflineDuration} previousValue={totalPreviousData.cloudOfflineDuration}/>
                        </div>

        return this.__renderBloc(t, mode, open, partial, breakdown, maintenance, closed, shutdown, offline)

    }

    __renderBloc = ( t, mode, open, partial, breakdown, maintenance, closed, shutdown, offline ) => {

        const content = <div>
            <div>
                <div className={'kpi-view-subtitle'}>{t('state.open')}</div>
                {open}
            </div>
            <div>
                <div className={'kpi-view-subtitle'}>{t('state.partial')}</div>
                {partial}
            </div>
            <div>
                <div className={'kpi-view-subtitle'}>{t('state.breakdown')}</div>
                {breakdown}
            </div>
            <div>
                <div className={'kpi-view-subtitle'}>{t('state.maintenance')}</div>
                {maintenance}
            </div>
            <div>
                <div className={'kpi-view-subtitle'}>{t('state.closed')}</div>
                {closed}
            </div>
            <div>
                <div className={'kpi-view-subtitle'}>{t('state.shutdown')}</div>
                {shutdown}
            </div>
            <div>
                <div className={'kpi-view-subtitle'}>{t('state.offline')}</div>
                {offline}
            </div>
        </div>


        return <BlocSubPartView content={content}/>
    }

}

const translatedBloc = translate('translations')(AvailabilitySummaryTextView);
export { translatedBloc as AvailabilitySummaryTextView };