import React from 'react';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

export default class BarChartLoadingView extends React.PureComponent {

    render() {

        return <div style={{paddingLeft: 10, paddingRight: 10}}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-end" spacing={2}>
                    <Grid item md={1}><Skeleton variant="rectangle" height={40} width={20}/></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={80} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={180} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={100} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={60} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={110} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={40} width={20}/></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={80} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={180} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={100} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={60} width={20} /></Grid>
                    <Grid item md={1}><Skeleton variant="rectangle" height={110} width={20} /></Grid>
            </Grid>
        </div>
    }

}

const translated = translate('translations')(BarChartLoadingView);
export { translated as BarChartLoadingView };