import React, { PureComponent } from 'react';

import FieldViewSimple from './FieldViewSimple';
import { PosView } from './Pos';

export class RefillGroupView extends PureComponent {
    
    render() {
       const { t, object, mode, model, small, classes } = this.props;

       return <div className={small ? 'small-view' :''}>
           <div className="refill-header"><FieldViewSimple classes={classes} t={t} value={object.date} fieldConfig={model.fields.date} valueClass="legende"/><div className="legende"><PosView t={t} value={object.pos} small={true}/></div></div>
           {object.station ? <div className="form-read-field"><div className="form-read-field-value blue bold">{object.station.name}</div></div> : null}
           {object.customer ? <div className="form-read-field-small"><div className="form-read-field-value blue-light">{object.customer.name}</div></div> : null}
           {object.driver ? <div className="form-read-field"><div className="form-read-field-value">{object.driver.firstName+' '+object.driver.lastName}</div></div> : null}
           <div className="refill-delivery-resume">
            <div>{object.energyType ? <FieldViewSimple classes={classes} t={t} value={object.energyType} fieldConfig={{type: 'energyType'}} /> : null}
            </div>
            <div>{object.quantity ? <FieldViewSimple classes={classes} t={t} value={object.quantity} fieldConfig={model.fields.quantity} /> : null}
            </div>
            {object.endState ? <FieldViewSimple classes={classes} t={t} value={object.endState.soc} fieldConfig={{type: 'soc'}} /> : null}
            {object.price ? <FieldViewSimple classes={classes} t={t} value={object.price} fieldConfig={model.fields.price} /> : null}
           </div>
       </div>
    }
}