import React, { Component } from 'react';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { getRights } from '../../_helpers';

import FieldViewSimple from './FieldViewSimple';

export default class FieldView_ListChildren extends Component {

    componentWillMount() {
        this.columns = this.props.fields.map(field => { return {id: field.name, numeric: false, disablePadding: true, label: field }});
        this.expandedView = this.props.objectModel.list ? this.props.objectModel.list.expandedView : null;
    }

    render() {
        const { t, label, items, objectModel, fields, rights, handleReadChild, handleAddChild, handleEditChild, handleAction, handleWorkflowAction, user, context, dispatch, classes } = this.props;

        if (rights.list && (rights.create || items.length > 0)) {

            const itemsCount = items.length;
                        
            return <div>
                    <Divider/>
                    <div className="list-field-header">
                        <div className="list-field-title">
                        {objectModel.icon}
                        {label} ({itemsCount})
                        </div>
                    </div>
                    {   items.map( (item, index) => {
                            const itemContent = this.columns.map( (col, colIndex) => {
                                
                                const subFieldConfig = objectModel.fields[col.id];
                                
                                const subFieldValue = subFieldConfig.computed ? subFieldConfig.computed(item, t) : item[col.id];
                                
                                return subFieldConfig.hideIfEmbedded ? (null) : <FieldViewSimple key={'list-item-' + index +'_col_' + colIndex} classes={classes} t={t} label={t(col.id)} value={subFieldValue} fieldConfig={subFieldConfig} subObjectModel={objectModel} fieldClass="embeddedlist-col"/>;


                            });
                            
                            const objectRights = item && item._metadata && item._metadata.rights ? item._metadata.rights : null;

                            return (<div className="embeddedlist-row" key={'list-item-' + index}>
                                {itemContent}
                                {objectModel.viewEmbedded.actions &&
                                            objectModel.viewEmbedded.actions.map( (actionName, actionIndex) => {
                                                const action = objectModel.actions[actionName];
                                                
                                                const actionRights = getRights(user, null, action.model.name);
                                                
                                                const showAction = actionRights.create && (action.show ? action.show(item, user, context) : true);
                                                return (
                                                    showAction && <Button key={'action-' + actionIndex} variant="contained" size="small" className={classes.buttonEmbedded} aria-label={t(action.label)}  onClick={() => handleAction(item, action)}>
                                                {action.icon} {t(action.label)}
                                                </Button>
                                                );
                                            })
                                        }
                                {objectModel.viewEmbedded.workflowActions && objectModel.viewEmbedded.workflowActions.map( (workflowAction, index) => 
                                    {
                                        if (rights.actions && rights.actions[workflowAction.name]) {

                                            let userEnabled = true;
                                            if (objectRights && objectRights.actions) {
                                                userEnabled = objectRights.actions[workflowAction.name]
                                            }

                                            const enabled = userEnabled ? (workflowAction.enabled ? workflowAction.enabled(item) : true) : false;
                                            
                                            return enabled ? <Button key={'btn-workflowAction-' + index} variant="contained" size="small" className={classes.buttonEmbedded} onClick={() => handleWorkflowAction(workflowAction, objectModel, item.id)}>
                                                {workflowAction.icon}
                                                {t('workflowaction.'+workflowAction.name, workflowAction.name)}
                                            </Button> : null;
                                        } else {
                                            return null;
                                        }
                                    }
                                )}
                                </div>);
                        })
                    }
                </div>
        }

        return null;
    }
}
