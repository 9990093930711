import googlePhone from 'google-libphonenumber';
import countryCodes from 'country-list';
import countryData from 'country-data';

const phoneUtil = googlePhone.PhoneNumberUtil.getInstance();
const PNF = googlePhone.PhoneNumberFormat;

export function isValidPhone(phoneInfos) {
	return formatPhone(phoneInfos).number != null;
}

/**
 * Return: Formatted phone if valid, null otherwise.
 */
export function formatPhone(phoneInfos) {
	
	var theNumber = phoneInfos ? phoneInfos.number : null;
	var countryCode = phoneInfos ? phoneInfos.countryCode : null;
	var googleNumber = null;
	
	var formatted = { 
		number: null, 
		number_no_indic: null, 
		countryCode: countryCode, 
		indicatif: null 
	};
	
	if(theNumber && countryCode) {
		try {
			
			googleNumber = phoneUtil.parseAndKeepRawInput(theNumber, countryCode);
			
			if(phoneUtil.isValidNumber(googleNumber)) {
			
				formatted.number = phoneUtil.format(googleNumber, PNF.INTERNATIONAL).replace(/\s/g, '');
				formatted.indicatif = "+"+googleNumber.getCountryCode();
				formatted.number_no_indic = phoneUtil.format(googleNumber, PNF.NATIONAL)
			}
			
		} catch(err) {
			console.log(err);
		}
	}

	return formatted;
}

export function getCodes() {
	return countryCodes.getCodes();
}

export function getCountryName(alpha2) {
	return countryCodes.getName(alpha2);
}

export function getCountryIndicatif(alpha2) {
	
	var val = "";
	
	if(countryData.callingCountries && 
	   countryData.callingCountries[alpha2] && 
	   countryData.callingCountries[alpha2].countryCallingCodes) {
	
		val = countryData.callingCountries[alpha2].countryCallingCodes[0];
	}
	
	return val;
}
