import React from 'react';
import { ItemEditPage } from '../../EditItem';

class CustomerEditUserPage extends React.Component {
    
    constructor(props) {
        super(props);
        if (this.props.match.params.itemId === undefined) {
            this.defaultUser = { profil: 'FLEET_MANAGER'}
        }
    }

    render() {
        return <ItemEditPage { ...this.props} filterFields={['userRole', 'email', 'firstName', 'lastName', 'phone']} newItem={this.defaultUser}/>
    }
}

export { CustomerEditUserPage as CustomerEditUserPage };