import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';

const styles = theme => ({
    formControl: {
        marginRight: '1rem',
        minWidth: '250px'
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class SelectPickerChoice extends Component {

    constructor(props) {
        super(props)
        this.state = {selected: this.props.selected};
    }

    handleChange = event => {
        this.setState({ selected: event.target.value });
        this.props.onChange(event.target.value);
    };

    handleClose = event => {
        
    };

    render = () => {

        const { t, label, selected, choices, classes, disabled, required } = this.props;

        return <FormControl className={classes.formControl} required={required}>
          <InputLabel htmlFor="select-multiple-checkbox">{t(label)}</InputLabel>
          <Select
            multiple
            value={this.state.selected}
            onChange={this.handleChange}
            input={<Input id="select-multiple-checkbox" />}
            renderValue={selected => selected.map(key => t(choices[key])).join(', ')}
            MenuProps={MenuProps}
            disabled={disabled}
            displayEmpty
          >
            {Object.keys(choices).map(key => {return (
              <MenuItem key={key} value={key}>
                <Checkbox value={key} checked={this.state.selected.indexOf(key) > -1} />
                <ListItemText primary={t(choices[key])} />
              </MenuItem>
            )})}
          </Select>
        </FormControl>
    }
}

const connectedSelectPickerChoice = (withStyles(styles, { withTheme: true })(translate('translations')(SelectPickerChoice)));
export { connectedSelectPickerChoice as SelectPickerChoice };