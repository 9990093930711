import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

class WatchDogView extends Component {

    render() {
     const { t, object } = this.props;
     
     if (object) {
        return <div><div><span className="form-read-field-label">countStat OK :</span> {t(object.countStatOK ? 'true':'false')}</div><div><span className="form-read-field-label">countPos OK</span> : {t(object.countPosOK ? 'true':'false')}</div></div>;
     }
     return null;
    }
    
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    return {
        itemId,
        data,
    };
}

const connectedPLCView = connect(mapStateToProps)(translate('translations')(WatchDogView));
export { connectedPLCView as WatchDogView };