import moment from 'moment'

import { DurationReadView } from '../read/Duration';

const POS_COLORS = {
    'unknown': '#e1e1e1',
    'fillndrive.app': '#273b54',
    'fillndrive.badge': '#2a5da0',
    'fillndrive.manager': '#2a87fc',
    'tokheim': '#ff0000',
    'gtis': '#ffa500'
}

const makePeriodeDefaultValues = (periodeType, subPeriodeType, periodeStart, periodeEnd) => {
    const defaultValues = {};
    const orderedKeys = [];
    let labels;
    let matcher;
    
    if (subPeriodeType === 'hourly') {
        for (let i=0; i<24; i++) {
            defaultValues[i] = 0;
            orderedKeys.push(i);
        }
        labels = Object.keys(defaultValues).map( v => v+'h');
        
        matcher = (source, target, value, callback) => {
            if (callback) {
                callback(source._id.hour)
            } else {
                target[source._id.hour] = value
            }
        }

    } else if (subPeriodeType === 'daily') {
        let d = moment(periodeStart);
        let end = moment(periodeEnd);
        labels = [];
        while (end.isAfter(d)) {
            const keyDay = d.toDate().getDate()+'/'+(d.toDate().getMonth() + 1);
            defaultValues[keyDay] = 0;
            orderedKeys.push(keyDay);
            if (periodeType === 'weekly') {
                labels.push(d.format('dddd'));
            } else {
                labels.push(keyDay);
            }
            d = d.add(1, 'day');
        }

        matcher = (source, target, value, callback) => {
            if (callback) {
                callback(source._id.day+'/'+source._id.month)
            } else {
                target[source._id.day+'/'+source._id.month] = value
            }
        }

    } else if (subPeriodeType === 'monthly') {
        let d = moment(periodeStart);
        let end = moment(periodeEnd);
        labels = [];
        while (end.isAfter(d)) {
            const keyDay = d.toDate().getMonth() + 1;
            defaultValues[keyDay] = 0;
            orderedKeys.push(keyDay);
            labels.push(d.format('MMMM'));
            d = d.add(1, 'month');
        };

        matcher = (source, target, value, callback) => {
            if (callback) {
                callback(source._id.month)
            } else {
                target[source._id.month] = value
            }
        }

    }

return {defaultValues, orderedKeys, labels, matcher}
}

const formatQuantity = (rawValue) => {
    return Math.round((rawValue / 10) ) / 100
}

const computeProgression = (currentValue, previousValue, lessIsBetter) => {

    const progression = previousValue > 0 ? (100 - Math.round(currentValue / previousValue * 100)) : -Math.round(currentValue * 100)
    
    if (previousValue > currentValue) {
        return {progress: false, value: progression}
    } else if (previousValue < currentValue) {
        return {progress: true, value: -progression}
    } else {
        //quantityProgression = <span className={'kpi-value kpi-value-progress'}>{t('progress.stable')}</span>
    }

}

const makeCustomTooltips = (legendLabels, dataLabels, valueColors, progression) => {

    const options = {tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,

        custom: function(tooltipModel) {
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
                return bodyItem.lines;
            }

            
            // Set Text
            if (tooltipModel.body) {

                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);

                var innerHtml = '<thead>';

                titleLines.forEach(function(title) {
                    innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                tooltipModel.dataPoints.forEach( dataPoint => {

                    const legendLabel = legendLabels[dataPoint.index]
                    const dataLabel = dataLabels[dataPoint.index]
                    const progressionValue = progression[dataPoint.index]

                    var colors = valueColors[dataPoint.index];
                    var style = 'background-color:' + colors.backgroundColor;
                    style += '; border-color:' + colors.borderColor;
                    style += '; border-width: 2px';
                    style += '; height: 10px; width: 10px; display: inline-box';
                    var span = '<span style="' + style + '">  </span> ';

                    innerHtml += '<tr><td><span style="">' + span + legendLabel + ': </span><span style="">' + dataLabel + '</span></td></tr>';
                    if (progressionValue) {
                        innerHtml += '<tr><td><span style="font-weight: normal">Progression :</span> <span class="kpi-value kpi-value-progress kpi-value-progress-dark-' + (progressionValue.progress ? 'positif' : 'negatif')+ '">' +(progressionValue.progress ? '+':'-')+progressionValue.value+'%'+ '</span></td></tr>'
                    }
                })

                /*bodyLines.forEach(function(body, i) {
                    
                    var colors = tooltipModel.labelColors[i];
                    var style = 'background:' + colors.backgroundColor;
                    style += '; border-color:' + colors.borderColor;
                    style += '; border-width: 2px';
                    var span = '<span style="' + style + '"></span>';
                    innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });

                const progressionValue = progression[tooltipModel.dataPoints[0].index]
                if (progressionValue) {
                    innerHtml += '<tr><td><span style="font-weight: normal">Progression :</span> <span class="kpi-value kpi-value-progress kpi-value-progress-dark-' + (progressionValue.progress ? 'positif' : 'negatif')+ '">' +(progressionValue.progress ? '+':'-')+progressionValue.value+'%'+ '</span></td></tr>'
                }*/
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            var position = this._chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.fontWeight = 'bold'
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.color = '#ffffff'
            tooltipEl.style.backgroundColor = '#000000bf'
            tooltipEl.style.borderRadius = '5px'
        }
    }}

    return options

}

export const KpiTools = {
    makePeriodeDefaultValues,
    posColors: POS_COLORS,
    formatQuantity,
    computeProgression,
    makeCustomTooltips
}