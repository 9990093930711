import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import moment from 'moment'

class PLCView extends Component {

    render() {
     const { t, object } = this.props;
     
     
      const infos = object ? Object.keys(object).map( key => {
           return key !== 'date' ? <div key={'plc_'+key} className="form-read-field">
           <span className="form-read-field-label">{t('plc-'+key, key)} : </span>{object[key]}
           </div> : null
       }) : null;

       return (<div className="plcbloc">
           {object.date && <div className="legende">date : {moment(object.date).format('DD/MM/YYYY HH:mm:ss')}</div>}
           {infos}
       </div>)
    }
    
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    return {
        itemId,
        data,
    };
}

const connectedPLCView = connect(mapStateToProps)(translate('translations')(PLCView));
export { connectedPLCView as PLCView };