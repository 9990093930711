export default {
    LINE: {
        legend: {
            display: false
         },
         tooltips: {
            mode: 'index'
        },
         scales: {
            xAxes: [{
                barThickness: 5,
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true
                }
            }],
            yAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true,
                    min: 0
                }
            }]
        }
    },
    BAR: {
        legend: {
            display: true
        },
        tooltips: {
            mode: 'index'
        },
        scales: {
            xAxes: [{
                stacked: false,
                barThickness: 5,
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true
                }
            }],
            yAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true,
                    min: 0
                }
            }]
        }
    },
    BAR_AUTOTHICKNESS: {
        maintainAspectRatio: true,
        legend: {
            display: false
         },
         tooltips: {
            enabled: true,
            mode: 'index'
         },
         layout: {
            padding: {
                bottom: 0,
                top: 0
            }
        },
        scales: {
            xAxes: [{
                stacked: false,
                //barThickness: 10,
                gridLines: {
                    display: false,
                    drawBorder: true,
                },
                ticks: {
                    display: true
                }
            }],
            yAxes: [{
                stacked: false,
                //barThickness: 10,
                gridLines: {
                    display: false,
                    drawBorder: true,
                },
                ticks: {
                    display: false
                }
            }]
        }
    },
    BAR_NO_LEGEND: {
        legend: {
            display: false
        },
        tooltips: {
            mode: 'index'
        },
        scales: {
            xAxes: [{
                stacked: true,
                //barThickness: 5,
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true
                }
            }],
            yAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true,
                    min: 0
                }
            }]
        }
    },
    BAR_STACKED: {
        legend: {
            display: true
        },
        tooltips: {
            mode: 'index'
        },
        scales: {
            xAxes: [{
                stacked: true,
                //barThickness: 5,
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true
                }
            }],
            yAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: true,
                    min: 0
                }
            }]
        }
    }
}