import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import DoughnutChartView from '../common/DoughnutChart';
import PieChartLoadingView from '../common/PieChartLoading';

class PossSummaryView extends React.Component {

    render() {

        const { t, mode, loading, data, previousData } = this.props;
        
        if (loading) {
            return <PieChartLoadingView/>
        }

        if (data && data.byPos && data.byPos.length > 0) {
            return this.renderKpiBloc( t, data.byPos, previousData.byPos )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData, previousRawData ) => {

        const poss = []
        const values = []
        const labels = []
        const colors = []
        
        let totalQuantity = 0

        const previousPossQuantity = {}
        const progressionValues = []

        previousRawData.forEach( posKpis => {
            const pos = posKpis._id.pos ? posKpis._id.pos : 'unknown'
            previousPossQuantity[pos] = posKpis.quantity
        })

        rawData.forEach( posKpis => {

            totalQuantity += posKpis.quantity

            const formatedQuantity = KpiTools.formatQuantity(posKpis.quantity)
            values.push(formatedQuantity)

            const pos = posKpis._id.pos ? posKpis._id.pos : 'unknown'

            labels.push(posKpis._id.pos ? t('pos.'+pos) : t(pos))
            colors.push(KpiTools.posColors[pos])
            poss.push(pos)

            const previousQuantity = previousPossQuantity[pos]
            progressionValues.push(KpiTools.computeProgression(posKpis.quantity, previousQuantity))

        })

        const valueLabels = values.map( (value, index) => {
            return value+' Kg (' + (Math.round( rawData[index].quantity / totalQuantity * 100)) +'%)'
        })

        const options = KpiTools.makeCustomTooltips(labels, valueLabels, colors.map( color => { return {backgroundColor: color, borderColor: color}}), progressionValues)

        const data = {
            labels: labels,
            datasets: [
            {
                data: values,
                backgroundColor: colors
            }
            ]
        };

        return <DoughnutChartView data={data} options={options}/>

    }

}

const translatedBloc = translate('translations')(PossSummaryView);
export { translatedBloc as PossSummaryView };