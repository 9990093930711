import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import BarChartView from '../common/BarChart';
import BarChartLoadingView from '../common/BarChartLoading';

class VehicleModelView extends React.Component {

    render() {

        const { t, mode, loading, data } = this.props;
        
        if (loading) {
            return <BarChartLoadingView/>
        }

        if (data && data.byVehicleModel && data.byVehicleModel.length > 0) {
            return this.renderKpiBloc( t, data.byVehicleModel )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData ) => {

        const values = []
        const labels = []

        const colors = []
        const borderColors = []

        let notIdentified = 0;

        values.push(notIdentified)
        colors.push('#f4f5f5')
        borderColors.push('#e4e5e7')
        labels.push(t('not.identified'))

        rawData.forEach( kpi => {
            if (kpi._id.vehicleModel) {
                values.push(kpi.count)
                colors.push('#d7ecfb')
                borderColors.push('#87c7f3')
                labels.push(kpi._id.vehicleModel.brand+' '+kpi._id.vehicleModel.name)
            } else {
                notIdentified = kpi.count
            }
        })

        values[0] = notIdentified
        
        const data = {
            labels: labels,
            datasets: [
            {
                data: values,
                backgroundColor: colors,
                borderColor: borderColors,
                borderWidth: 1 
            }
            ]
        };


        return <BarChartView data={data} options={ChartOptions.BAR_AUTOTHICKNESS}/>

    }

}

const translatedBloc = translate('translations')(VehicleModelView);
export { translatedBloc as VehicleModelView };