import { commonConstants } from '../_constants';

const initialState = {};

export function api(state = initialState, action) {
  switch (action.type) {
    case commonConstants.CLOUDINFO_SUCCESS:
      return {...state, ...{infos: action.infos}}
    default:
      return state
  }
}