import React from 'react';
import { translate } from 'react-i18next';
import { Route, Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

class Breadcrumb extends React.Component {

    render () {

        const { t, menu, elements } = this.props;
        const breadcrumbElements = [
        ];

        if (menu) {
            breadcrumbElements.push((<div key={'breadcrumb_root'}><MenuIcon/> {t('menu.'+menu)}</div>));
        }

        elements.forEach((element, index) => {
            if (breadcrumbElements.length > 0) {
                breadcrumbElements.push((<div className="breadcrumb-sep"><span>></span></div>));
            }
            const divClassName = element.icon ? 'breadWithIcon' : 'breadWithoutIcon';
            if (element.url) {
                breadcrumbElements.push((<div className={divClassName}><Link to={element.url}>{element.icon} {t(element.name)}</Link></div>));
            } else {
                breadcrumbElements.push((<div className={divClassName}>{element.icon} {t(element.name)}</div>));
            }
            
        });

        const breadcrumbContent = breadcrumbElements.map( (breadcrumbElement, index) => {
            return <li key={'breadcrum_li_' + index}>{breadcrumbElement}</li>;
        })

        return (
            <ul className="breadcrumb">{breadcrumbContent}</ul>
        );

    }

}
  
export default translate('translations')(Breadcrumb);