import React, { PureComponent } from 'react';

import { StateColorHelper } from '../../_helpers';

export class EnergyTypeView extends PureComponent {
    
    render() {
       const { value, state } = this.props;

        const displayState = state ? state : 'open';

        const className = StateColorHelper.stateToColorClass(displayState);

       return value ? <div className={"energy-type energy-type-" + className}>{value.name}</div> : null;
    }
}