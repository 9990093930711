import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

import Divider from '@material-ui/core/Divider';

import { StationStateReadView } from '../../_components/read/StationState';
import { ReceptacleView } from '../../_components/custom/ReceptacleView';

import HomeDashBoard from './HomeDashBoard';

import { history, StateColorHelper } from '../../_helpers';
import { QuantityGrammeReadView } from '../../_components/read/QuantityGramme';
import { AvailabilityDayResume } from '../../_components/kpi/AvailabilityDayResume';

import {Bar} from 'react-chartjs-2';

import moment from 'moment'

const appSpecificAssetFolder = process.env.REACT_APP__SPC_ASSET_FLD ? process.env.REACT_APP__SPC_ASSET_FLD : "fillndrive";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem'
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      cardRoot: {
        boxShadow: '0px 1px 20px 0px rgba(14, 68, 135, 0.1), 0px 0px 1px 0px rgba(14, 68, 135,0.1), 0px 0px 0px -2px rgba(14, 68, 135,0.1)'
      }
});

class HomeFleetManager extends HomeDashBoard {

  constructor(props) {
    super(props)
    this.state = {expanded: false};
}

handleExpandClick() {
    this.setState({expanded: !this.state.expanded});
}

handleGoTo = url => {
    history.push(url);
}

render() {

    const { t, data, user, classes } = this.props;

    let livefullContent;

    const hourlyValues =  {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0, 20: 0, 21: 0, 22: 0, 23: 0};
    const datasets = [];
    const availabilityResumes = {};

    const kpiSum = { count: 0, quantity: 0, averageQuantity: 0, averageDuration: 0, stationCount: 0 };

    if (data.livefull) {

        const colors = ['#ED506A52', '#F2A25452', '#906EF652', '#6CBEBF52', '#ED506A52', '#F2A25452', '#906EF652', '#6CBEBF52']
        const borderColors = ['#ED506A', '#F2A254', '#906EF6', '#6CBEBF', '#ED506A', '#F2A254', '#906EF6', '#6CBEBF']

        livefullContent = data.livefull.map( (station, indexStation) => {

            const stationId = station.id;

            let stationContent = null;

            const stationDataSet = {
              label: station.name,
              backgroundColor: colors[indexStation],
              borderWidth: 1,
              borderColor: borderColors[indexStation],
              hoverBackgroundColor: colors[indexStation],
            };

            const stationHourlyValues = {...hourlyValues}; 
            if (station.kpi.hourly) {
            station.kpi.hourly.forEach( hourlyKpi => {
              stationHourlyValues[hourlyKpi.hour] = hourlyKpi.count;
            })
            }

            if (station.kpi.daily) {
            station.kpi.daily.forEach( dailyKpi => {
              kpiSum.stationCount += 1;
              kpiSum.count += dailyKpi.count;
              kpiSum.quantity += dailyKpi.quantity;
              kpiSum.averageQuantity += dailyKpi.averageQuantity;
              kpiSum.averageDuration += dailyKpi.averageDuration;
            });
            }

            stationDataSet.data = Object.values(stationHourlyValues);
            datasets.push(stationDataSet);
            
            if (station.kpi.availabilityResume) {
                availabilityResumes[station.id] = {name: station.name, kpi: station.kpi.availabilityResume};
            }

            const stationState = station.publicState ? station.publicState : 'unknown';

            let elevation;

            if (station.connected) {
                elevation = 2;
                const showStationInfos = stationState !== 'unknown';

                const dispensers = station.dispensers ? station.dispensers.map( d => {
                    
                    const dName = d.name;
                    const dState = d.publicState ? d.publicState : 'unknown';
                    return (
                        <CardActionArea key={'dispenser_' + dName} onClick={() => this.handleGoTo('/station/'+stationId+'/read/dispenser/' + d.id)}>
                        
                        <Divider/>

                        <div className={'dispenser' + (d.busy ? ' busy':'')}>
                            <div className="line station-headline">
                                <div className="station-name">{dName}</div>
                                <div className="station-info"><img className={"station-info-picto" + (!d.vehicleCount ? ' station-info-picto-fade' : '')} src={require('../../assets/img/vehicle-count-picto.png')} /> {d.vehicleCount ? d.vehicleCount : 0}</div>
                                {d.cardReader ? <div className="station-nexopus-state"><span title={d.cardReader.connected ? t('card.reader.online') : t('card.reader.offline')} className={'legende'}><img height="20px" src={d.cardReader.connected ? require('../../assets/img/badge-success.svg') : require('../../assets/img/badge-error.svg')}/></span></div> : null}
                            </div>
                            <div className="receptacle-line">{dState !== 'unknown' && d.receptacles && d.receptacles.map( (r, rIndex) => <ReceptacleView key={'d_'+dName+'_r_'+rIndex} t={t} object={r} hideLegende={true}/>)}</div>
                        {d.refiller ? (
                            this._renderSessionInfos(d.refiller, false)
                        ) : null}
                        </div>
                        </CardActionArea>
                    );
                }) : (null);

                let nexopusReady = station.watchdog && station.watchdog.plcConnectionOK;

                stationContent = (<div className={'bloc-station '+stationState}>
                    <CardActionArea onClick={() => this.handleGoTo('/read/station/' + stationId)}>
                    
                        <div className="line station-headline">
                            <div className="station-name">{station.name}</div>
                            <div className="station-state-bloc"><StationStateReadView t={t} value={station} inline={true} /></div>
                            <div className="station-nexopus-state"><img height="20px" src={nexopusReady ? require('../../assets/img/'+appSpecificAssetFolder+'/success.png') : require('../../assets/img/'+appSpecificAssetFolder+'/error.png')}/></div>
                        </div>
                        {showStationInfos && <div className="line station-headline">
                            {station.quantity > 0 && <div className="station-info"><img className="station-info-picto" src={require('../../assets/img/mass-picto-small.png')} /> {Math.round(station.quantity / 2.5)} x 2.5kg</div>}
                            {station.estimatedFuelingTime > 0 && <div className="station-info"><img className="station-info-picto" src={require('../../assets/img/timelapse-picto-small.png')} /> {Math.round(station.estimatedFuelingTime / 60)}min</div>}
                        </div>}
                        
                        </CardActionArea>
                        <div className="text-left">
                        {dispensers}
                        </div>
                </div>);
            } else {
                elevation = 0;

                stationContent = (
                    <div className="bloc-station offline">
                    <CardActionArea onClick={() => this.handleGoTo('/read/station/' + stationId)}>
                        <div className="line station-headline">
                            <div className="station-name">{station.name}</div>
                            <div className="station-state-bloc"><StationStateReadView t={t} value={station} inline={true} /></div>
                        </div>
                        </CardActionArea>
                        </div>);
            }

            return ( <Grid key={'station_'+stationId} item md={3}>
                            <Card elevation={elevation} classes={{root: classes.cardRoot}}>
                                <CardContent>{stationContent}</CardContent>
                            </Card>
                    </Grid>
            );
            
        })
    }

    if (kpiSum.stationCount > 0) {
        kpiSum.averageQuantity = kpiSum.averageQuantity / kpiSum.stationCount;
        kpiSum.averageDuration = kpiSum.averageQuantity / kpiSum.averageDuration;
    }

    const hourlyData = {
      labels: Object.keys(hourlyValues).map( v => v+'h'),
      datasets: datasets
    };

    const options = {
      legend: {
          display: true
       },
       tooltips: {
          enabled: false
       },
       layout: {
          padding: {
              bottom: 10,
              top: 10
          }
      },
      scales: {
          xAxes: [{
              stacked: true,
              gridLines: {
                  display: false,
                  drawBorder: false,
              },
              ticks: {
                  display: true
              }
          }],
          yAxes: [{
              stacked: true,
              gridLines: {
                  display: false,
                  drawBorder: true,
              },
              ticks: {
                  display: true,
                  min: 0,
                  stepSize: 1
              }
          }]
      }
  }

    return (
        <div className="text-center">
            <Grid container spacing={16} >{livefullContent}</Grid>
            <Grid container spacing={16} >
              <Grid item md={3}>
                  <div className="kpi-chart-bloc kpi-chart-bloc-dashboard">
                    <div className="kpi-chart-bloc-label">{moment().format('DD/MM/YYYY')}</div>
                    <div className="line"></div>
                    <div className="line text-left">
                        <div>{t('refillsCount')} : {kpiSum.count ? kpiSum.count : '-'}</div>
                    </div>
                    <div className="line text-left">
                        <div>{t('quantity.total')} : <QuantityGrammeReadView t={t} value={kpiSum.quantity}/></div> 
                    </div>
                    <div className="line text-left">
                        <div>{t('quantity.avg')} : <QuantityGrammeReadView t={t} value={kpiSum.averageQuantity}/></div>
                    </div>
                  </div>
              </Grid>
              
              <Grid item md={6}>
              <div className="kpi-chart-bloc kpi-chart-bloc-dashboard">
                                <div className="kpi-chart-bloc-label">Pleins par stations</div>
                  {data.livefull && <Bar
                            data={hourlyData}
                            options={options}
                        />}
                </div>
              </Grid>
              <Grid item md={3}>
              <div className="kpi-chart-bloc">
                    <div className="kpi-chart-bloc-label">Disponibilité</div>
              {data.livefull && <div class="kpi-chart-bloc-dashboard">{Object.keys(availabilityResumes).map( availabilityResumeKey => <AvailabilityDayResume kpi={availabilityResumes[availabilityResumeKey]} user={user}/>)}</div>}
              </div>
              </Grid>
            </Grid>
        </div>
    );
}
}

function mapStateToProps(state) {
    const { itemId, data, authentication } = state;
    
    const { user, context} = authentication;

    return {
        itemId,
        data,
        user,
        context
    };
}

const connectedHomeFleetManager = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(HomeFleetManager)));
export { connectedHomeFleetManager as HomeFleetManager };