import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import BarChartView from '../common/BarChart';
import BarChartLoadingView from '../common/BarChartLoading';

const SOC_STEPS = ['unknown', 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95]
const SOC_LABELS = ['?', 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95]
const SOC_COLORS = ['#e1e1e1', '#FF0C01', '#FF1802', '#FF3103', '#FF4705', '#FF6207', '#FF7C08', '#FFA00B', '#FF950A', '#FFA90B', '#FFBE0D', '#DAC710', '#BBCF13', '#9DD716', '#81DE19', '#5CE81C', '#37F120', '#26F521', '#16F923', '#00FF25']

class SocsBy5View extends React.Component {

    render() {

        const { t, mode, loading, data, kpiField, socField } = this.props;
        
        if (loading) {
            return <BarChartLoadingView/>
        }

        if (data && data[kpiField] && data[kpiField].length > 0) {
            return <div style={{margin: '4rem'}}>{this.renderKpiBloc( t, data[kpiField], socField )}</div>
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData, socField ) => {

        const socKpiValues = {}

        SOC_STEPS.forEach( soc => {
            socKpiValues[soc] = null;
        })

        rawData.forEach( socKpi => {
            socKpiValues[socKpi._id[socField]] = socKpi.count
        })
 
        const datasets= [{
            label: t('count'),
            backgroundColor: SOC_COLORS,
            data: SOC_STEPS.map( soc => socKpiValues[soc] )
            }]

        const data = {
            labels: SOC_LABELS,
            datasets: datasets
        };

        return <BarChartView data={data} options={ChartOptions.BAR_AUTOTHICKNESS}/>

    }

}

const translatedBloc = translate('translations')(SocsBy5View);
export { translatedBloc as SocsBy5View };