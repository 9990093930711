import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';
import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import BarChartView from '../common/BarChart';
import BarChartLoadingView from '../common/BarChartLoading';


class AvailabilitySummaryBreaksView extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { loading, mode, data } = this.props;
        
        if (loading) {
            return <BarChartLoadingView/>
        }

        if (data && data.total && data.total.length > 0) {
            return this.renderKpiBloc( data.total )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( rawData ) => {
        
        const { t, breakConfig } = this.props;

        const datasetOpen = {
            label: t('state.open'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#f1aa00',
            stack: '1'
        }
        
        const datasetPartial = {
            label: t('state.partial'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#d7ecfb',
            borderWidth: 1,
            borderColor:  '#87c7f3',
            stack: '1'
        }

        const datasetBreakdown = {
            label: t('state.breakdown'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#ff000061',
            stack: '1'
        }

        const datasetMaintenance = {
            label: t('state.maintenance'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#f1aa00',
            stack: '1'
        }
        
        const datasetClosed = {
            label: t('state.closed'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#d7ecfb',
            borderWidth: 1,
            borderColor:  '#87c7f3',
            stack: '1'
        }

        const datasetShutdown = {
            label: t('state.shutdown'),
            data: {...breakConfig.defaultValues},
            backgroundColor: '#ff000061',
            stack: '1'
        } 

        rawData.forEach( totalKpis => {

            const totalDuration = totalKpis.openDuration + totalKpis.partialDuration + totalKpis.breakdownDuration + totalKpis.maintenanceDuration + totalKpis.closedDuration + totalKpis.shutdownDuration

            breakConfig.matcher(totalKpis, datasetOpen.data, totalDuration > 0 ? Math.round(totalKpis.openDuration / totalDuration * 100) : 0)
            breakConfig.matcher(totalKpis, datasetPartial.data, totalDuration > 0 ? Math.round(totalKpis.partialDuration / totalDuration * 100) : 0)
            breakConfig.matcher(totalKpis, datasetBreakdown.data, totalDuration > 0 ? Math.round(totalKpis.breakdownDuration / totalDuration * 100) : 0)
            breakConfig.matcher(totalKpis, datasetMaintenance.data, totalDuration > 0 ? Math.round(totalKpis.maintenanceDuration / totalDuration * 100) : 0)
            breakConfig.matcher(totalKpis, datasetClosed.data, totalDuration > 0 ? Math.round(totalKpis.closedDuration / totalDuration * 100) : 0)
            breakConfig.matcher(totalKpis, datasetShutdown.data, totalDuration > 0 ? Math.round(totalKpis.shutdownDuration / totalDuration * 100) : 0)

        })

        datasetOpen.data = Object.values(datasetOpen.data)
        datasetPartial.data = Object.values(datasetPartial.data)
        datasetBreakdown.data = Object.values(datasetBreakdown.data)
        datasetMaintenance.data = Object.values(datasetMaintenance.data)
        datasetClosed.data = Object.values(datasetClosed.data)
        datasetShutdown.data = Object.values(datasetShutdown.data)

        const data = {
            labels: breakConfig.labels,
            datasets: [datasetOpen, datasetPartial, datasetBreakdown, datasetMaintenance, datasetClosed, datasetShutdown]
        }; 

        return <BarChartView data={data} options={ChartOptions.BAR_STACKED}/>

    }

}

const connectedBloc = translate('translations')(AvailabilitySummaryBreaksView);
export { connectedBloc as AvailabilitySummaryBreaksView };