import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import LineChartView from '../common/LineChart';
import BarChartLoadingView from '../common/BarChartLoading';

class PossSummaryBreaksView extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { loading, mode, data } = this.props;
        
        if (loading) {
            return <BarChartLoadingView/>
        }

        if (data && data.byPos && data.byPos.length > 0) {
            return this.renderKpiBloc( data.byPos )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( rawData ) => {
        
        const { t, breakConfig } = this.props;

        const datasetsByPos = {};

        const poss = []
        const values = []
        const labels = []
        const colors = []

        rawData.forEach( posKpis => {
            values.push(KpiTools.formatQuantity(posKpis.quantity))

            const pos = posKpis._id.pos ? posKpis._id.pos : 'unknown'

            labels.push(posKpis._id.pos ? t('pos.'+pos) : t(pos))
            colors.push(KpiTools.posColors[pos])
            poss.push(pos)
        })

        poss.forEach( pos => {

            const values = {...breakConfig.defaultValues}

            datasetsByPos[pos] = {
                label: t('pos.'+pos),
                data: values,
                borderColor: KpiTools.posColors[pos],
                backgroundColor: KpiTools.posColors[pos],
                pointRadius: 0,
                fill: false,
                lineTension: 0
            }

        })

        rawData.forEach( posKpis => {

            const pos = posKpis._id.pos ? posKpis._id.pos : 'unknown'

            breakConfig.matcher(posKpis, datasetsByPos[pos].data, KpiTools.formatQuantity(posKpis.quantity))
            
        })

        const datasets = Object.values(datasetsByPos)

        datasets.forEach( dataset => {
            dataset.data = Object.values(dataset.data)
        })

        const data = {
            labels: breakConfig.labels,
            datasets: datasets
        };

        return <LineChartView data={data} />

    }

}

const connectedBloc = translate('translations')(PossSummaryBreaksView);
export { connectedBloc as PossSummaryBreaksView };