import React from 'react';

import CheckIcon from '@material-ui/icons/Check';

export class DeviceInfos extends React.PureComponent {
    
    render() {

          const { t, object, checkIcon } = this.props;

          if (!object) {
               return null;
          }

          const os = (object.os ? object.os : '').toUpperCase();
          const srcImg = os === 'ANDROID' ? require('../../assets/img/android-os-icon.svg') : 'IOS' ? require('../../assets/img/ios-os-icon.svg') : null;

          return <div className="device-infos-container"><div className="device-infos">{checkIcon ? <span className="check-icon"><CheckIcon/></span> : null} {srcImg ? <img src={srcImg} /> : null} {object.manufacturer +' '+ object.model+' ('+object.version+' - '+object.appVersion+')'}</div></div>
    }
}