import React from 'react';
import { history, AppColors, AppColorsDark, AppColorsLight } from '../../../_helpers';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


import Divider from '@material-ui/core/Divider';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import { QuantityGrammeReadView } from '../../../_components/read/QuantityGramme';
import { DurationReadView } from '../../../_components/read/Duration';
import { DateTimePeriodePicker } from '../../../_components/read/DateTimePeriodePicker';


import {Bar, Doughnut, Pie} from 'react-chartjs-2';

import moment from 'moment'

const styles = theme => ({
    gridCell: {
        alignSelf: 'flex-start'
    },
    formControl: {
        marginRight: '1rem',
        minWidth: '250px'
    }
});

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
}

class RefillsKpiView extends React.Component {

    constructor(props) {
        super(props)
        this.defaultDate = new Date();
        this.state = {...{periodeType: 'daily', subPeriodeType: 'hourly', tab: 0}};
    }

    handleDatePeriodeChange = config => {
        this.setState(config);
        this.props.loadKpi(config.periodeType, config.start, config.end);
    };

    render() {

        const { t, user, classes, kpi } = this.props;
        const { tab, periodeType, subPeriodeType } = this.state;

        const handleChangeTab = (event, newValue) => {
            this.setState({tab: newValue});
        }

        const showRefillsKPI = user && user.isServiceProvider;
        const showSimpleRefillsKPI = user && user.isFleetManager;

        let periodeKpi = {};
        let subPeriodeKpi;
        if (kpi && kpi.data) {
            const periodeData = kpi.data[periodeType];
            if (periodeData && periodeData.length > 0) {
                periodeKpi = showSimpleRefillsKPI ? periodeData : periodeData[0];
            }
            subPeriodeKpi = kpi.data[subPeriodeType];
        }        
        
        const hasAvailabilityKPI = (kpi && kpi.data && kpi.data.availability && kpi.data.availability.length > 0) ? true : false;
        
        return <div className="kpi-view">

                <div className="kpi-filter-bloc">
                
                    <DateTimePeriodePicker value={this.state.start} onChange={this.handleDatePeriodeChange} label={t('Periode')} defaultDate={this.defaultDate} periodeTypes={['daily', 'weekly', 'monthly', 'yearly']} defaultPeriodeType={'daily'} initOnMount={true} pagination={true} disableFuture={true} disabled={kpi && kpi.loading} />
                
                    
                </div>
            

            <Tabs
                value={tab}
                onChange={(e, v) => handleChangeTab(e, v)}
            >
                {(showRefillsKPI || showSimpleRefillsKPI) && <Tab
                disableRipple
                label={t('refills')}
                />}
                {showRefillsKPI && <Tab
                disableRipple
                label={t('energys')}
                />
                }
                {hasAvailabilityKPI && <Tab
                disableRipple
                label={t('availability')}
                />}
            </Tabs>

            
            {kpi && kpi.loading && <div>{t('loading.data')}</div>}

            
            {showRefillsKPI && tab === 0 && <TabContainer>{kpi && !kpi.loading && this._renderRefillsTab(t, classes, periodeKpi, subPeriodeKpi)}</TabContainer>}
            {showRefillsKPI && tab === 1 && <TabContainer>{kpi && !kpi.loading && this._renderEnergyTab(t, classes, periodeKpi, subPeriodeKpi)}</TabContainer>}
            {showSimpleRefillsKPI && tab === 0 && <TabContainer>{kpi && !kpi.loading && this._renderSimpleRefillsTab(t, classes, periodeKpi, subPeriodeKpi)}</TabContainer>}
            {(tab === 2 || (tab === 1 && !showRefillsKPI)) && hasAvailabilityKPI && <TabContainer>{kpi && !kpi.loading && this._renderAvailabilityTab(t, classes, kpi.data.availability)}</TabContainer>}

        </div>;
    }

    _renderResume = (t, periodeKpi) => {

            const notStarted = periodeKpi.count ? (periodeKpi.cancelled + periodeKpi.timeoutunlock + periodeKpi.timeoutstart + periodeKpi.notStarted) : '-';
            const notFinished = periodeKpi.count ? (periodeKpi.count - (periodeKpi.success + periodeKpi.error + notStarted)) : '-';

        return <div className="line">
                <div>
                    <div>{t('refillsCount')} : {periodeKpi.count ? periodeKpi.count : '-'}</div>
                </div>
                <div className="kpi-bloc-count-line">
                  
                    <div className="kpi-bloc-count notstarted">
                        <div className="kpi-bloc-count-label">{t('not.started')}</div>
                        <div className="kpi-bloc-count-value">{notStarted}</div>
                    </div>
                    <div className="kpi-bloc-count success">
                        <div className="kpi-bloc-count-label">{t('success')}</div>
                        <div className="kpi-bloc-count-value">{(periodeKpi.success!==null && periodeKpi.success!==undefined) ? periodeKpi.success : '-'}</div>
                    </div>
                    <div className="kpi-bloc-count error">
                        <div className="kpi-bloc-count-label">{t('error')}</div>
                        <div className="kpi-bloc-count-value">{(periodeKpi.error!==null && periodeKpi.error!==undefined) ? periodeKpi.error : '-'}</div>
                    </div>
                </div>
                <div>
                    {periodeKpi.performance && <div>Performance : {(Math.round(periodeKpi.performance * 10000) / 100) + '%'}</div>}
                    <div>{t('quantity.total')} : <QuantityGrammeReadView t={t} value={periodeKpi.quantity}/></div> 
                    <div>{t('quantity.delivered')} : <QuantityGrammeReadView t={t} value={periodeKpi.startedQuantity}/></div> 
                    <div>{t('quantity.avg')} : <QuantityGrammeReadView t={t} value={periodeKpi.averageQuantity}/></div>
                    <div>{t('duration.avg')} : <DurationReadView t={t} value={periodeKpi.averageDuration}/></div>
                    <div>{t('duration.ecart')} : <DurationReadView t={t} value={periodeKpi.stdDevDuration}/></div>
                    <div>{t('refillsCount')} {t('ircom.with')} : {periodeKpi.vehicleCommunication >= 0 ? periodeKpi.vehicleCommunication : '-'}</div>
                    <div>{t('refillsCount')} {t('ircom.without')} : {periodeKpi.vehicleCommunication >= 0 ? (periodeKpi.success + periodeKpi.error + periodeKpi.notStarted) - periodeKpi.vehicleCommunication : '-'}</div>
                </div>
            </div>;

    }

    _renderSimpleResume = (t, periodeKpi) => {

        if (!Array.isArray(periodeKpi)) {
            return null;
        }

        const kpiSum = { count: 0, quantity: 0, averageQuantity: 0, averageDuration: 0, stationCount: 0 };
        
        periodeKpi.forEach( dailyKpi => {
            kpiSum.stationCount += 1;
            kpiSum.count += dailyKpi.count;
            kpiSum.quantity += dailyKpi.quantity;
            kpiSum.averageQuantity += dailyKpi.averageQuantity;
            kpiSum.averageDuration += dailyKpi.averageDuration;
          });

        if (kpiSum.stationCount > 0) {
            kpiSum.averageQuantity = kpiSum.averageQuantity / kpiSum.stationCount;
            kpiSum.averageDuration = kpiSum.averageQuantity / kpiSum.averageDuration;
        }

        return <div className="line">
                <div>
                    <div>{t('refillsCount')} : {kpiSum.count ? kpiSum.count : '-'}</div>
                    <div>{t('quantity.total')} : <QuantityGrammeReadView t={t} value={kpiSum.quantity}/></div> 
                    <div>{t('quantity.avg')} : <QuantityGrammeReadView t={t} value={kpiSum.averageQuantity}/></div>
                </div>
            </div>;

    }

    _renderRefillsTab = (t, classes, periodeKpi, subPeriodeKpis) => {

        const {subPeriodeType} = this.state;

        const subPeriodeCountMap = {count: {}, success: {}, error: {}};

        let keySubPeriodeCount = 'hour';
        if (subPeriodeType === 'monthly') {
            keySubPeriodeCount = 'month';
        } if (subPeriodeType === 'daily') {
            keySubPeriodeCount = 'day';
        }

        if (subPeriodeKpis) {
            subPeriodeKpis.forEach( subPeriodeKpi => {
                subPeriodeCountMap.count[subPeriodeKpi[keySubPeriodeCount]] = subPeriodeKpi.count;
                subPeriodeCountMap.success[subPeriodeKpi[keySubPeriodeCount]] = subPeriodeKpi.success;
                subPeriodeCountMap.error[subPeriodeKpi[keySubPeriodeCount]] = subPeriodeKpi.error;
            });
        }

        return <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center" >
                        <Grid item xs={6} className={classes.gridCell}>
                            <div className="kpi-chart-bloc">
                                <div className="kpi-chart-bloc-label">Résumé</div>
                                {this._renderResume(t, periodeKpi)}
                            </div>
                        </Grid>
                        <Grid item xs={6} className={classes.gridCell}>
                        <div className="kpi-chart-bloc">
                            <div className="kpi-chart-bloc-label">Répartition par POS</div>
                            <div className="kpi-chart-bloc-chart">
                            {this._renderPosPercentChart(t, periodeKpi)}
                            </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} className={classes.gridCell}>
                        <div className="kpi-chart-bloc">
                            <div className="kpi-chart-bloc-label">Pleins terminés</div>
                            <div className="kpi-chart-bloc-chart">
                                {this._renderSubPeriodeCountChart(t, subPeriodeCountMap)}
                            </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} className={classes.gridCell}>
                            <div className="kpi-chart-bloc">
                            <div className="kpi-chart-bloc-label">Status de fin</div>
                            <div className="kpi-chart-bloc-chart">
                            {this._renderEndStatusCountChart(t, periodeKpi)}
                            </div>
                            </div>
                        </Grid>
                </Grid>
    }

    _renderSimpleRefillsTab = (t, classes, periodeKpi, subPeriodeKpis) => {

        const {subPeriodeType} = this.state;

        const subPeriodeCountMap = {count: {}, success: {}, error: {}};

        let keySubPeriodeCount = 'hour';
        if (subPeriodeType === 'monthly') {
            keySubPeriodeCount = 'month';
        } if (subPeriodeType === 'daily') {
            keySubPeriodeCount = 'day';
        }

        if (subPeriodeKpis) {
            subPeriodeKpis.forEach( subPeriodeKpi => {
                subPeriodeCountMap.count[subPeriodeKpi[keySubPeriodeCount]] = subPeriodeKpi.count;
                subPeriodeCountMap.success[subPeriodeKpi[keySubPeriodeCount]] = subPeriodeKpi.success;
                subPeriodeCountMap.error[subPeriodeKpi[keySubPeriodeCount]] = subPeriodeKpi.error;
            });
        }

        return <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center" >
                        <Grid item xs={6} className={classes.gridCell}>
                            <div className="kpi-chart-bloc">
                                <div className="kpi-chart-bloc-label">Résumé</div>
                                {this._renderSimpleResume(t, periodeKpi)}
                            </div>
                        </Grid>
                        <Grid item xs={6} className={classes.gridCell}>
                        <div className="kpi-chart-bloc">
                            <div className="kpi-chart-bloc-label">Pleins par période</div>
                            <div className="kpi-chart-bloc-chart">
                            {subPeriodeKpis && this._renderByStationRefillsTab(t, subPeriodeKpis)}
                            </div>
                            </div>
                        </Grid>
                </Grid>
    }

    _renderByStationRefillsTab = (t, subPeriodeKpis) => {

        const {periodeType, subPeriodeType} = this.state;

        let keySubPeriodeCount = 'hour';
        if (subPeriodeType === 'monthly') {
            keySubPeriodeCount = 'month';
        } if (subPeriodeType === 'daily') {
            keySubPeriodeCount = 'day';
        }

        const colors = ['#ED506A52', '#F2A25452', '#906EF652', '#6CBEBF52', '#ED506A52', '#F2A25452', '#906EF652', '#6CBEBF52']
        const borderColors = ['#ED506A', '#F2A254', '#906EF6', '#6CBEBF', '#ED506A', '#F2A254', '#906EF6', '#6CBEBF']

        const defaultValues = {};
            const orderedKeys = [];
            let labels;
            
            if (subPeriodeType === 'hourly') {
                for (let i=0; i<24; i++) {
                    defaultValues[i] = 0;
                    orderedKeys.push(i);
                }
                labels = Object.keys(defaultValues).map( v => v+'h');
            } else if (subPeriodeType === 'daily') {
                let d = moment(this.state.start);
                let end = moment(this.state.end);
                labels = [];
                while (end.isAfter(d)) {
                    const keyDay = d.toDate().getDate();
                    defaultValues[keyDay] = 0;
                    orderedKeys.push(keyDay);
                    if (periodeType === 'weekly') {
                        labels.push(d.format('dddd'));
                    } else {
                        labels.push(keyDay);
                    }
                    d = d.add(1, 'day');
                };
            } else if (subPeriodeType === 'monthly') {
                let d = moment(this.state.start);
                let end = moment(this.state.end);
                labels = [];
                while (end.isAfter(d)) {
                    const keyDay = d.toDate().getMonth() + 1;
                    defaultValues[keyDay] = 0;
                    orderedKeys.push(keyDay);
                    labels.push(d.format('MMMM'));
                    d = d.add(1, 'month');
                };
            }

        const hourlyValues =  {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0, 20: 0, 21: 0, 22: 0, 23: 0};
        const datasets = [];

        const byStation = {};

        subPeriodeKpis.forEach( subPeriodeKpi => {
            let tab = byStation[subPeriodeKpi.station];
            if (tab) {
                tab.push(subPeriodeKpi);
            } else {
                byStation[subPeriodeKpi.station] = [subPeriodeKpi] 
            }
        })

        Object.keys(byStation).forEach( (stationId, indexStation) => {

            let label = stationId;
            if (stationId === 'c34f46f8-9bca-4f30-bec3-7237a6b5876e') {
                label = 'Les loges';
            } else if (stationId === 'a38a6838-92f0-41fb-b3ea-7d400eab3191') {
                label = 'Orly';
            } else if (stationId === 'b6af36ca-9504-4ab3-a537-f5e10c22a5b3') {
                label = 'Alma';
            } else if (stationId === '8763269e-e069-429a-ab89-fd88410a43f6') {
                label = 'Roissy';
            }

            const stationDataSet = {
                label: label,
                backgroundColor: colors[indexStation],
                borderWidth: 1,
                borderColor: borderColors[indexStation],
                hoverBackgroundColor: colors[indexStation],
              };
    
              const stationHourlyValues = {...defaultValues}; 
              byStation[stationId].forEach( hourlyKpi => {
                stationHourlyValues[hourlyKpi[keySubPeriodeCount]] = hourlyKpi.count;
              })
    
              stationDataSet.data = Object.values(stationHourlyValues);
              datasets.push(stationDataSet);
        })
        
        const hourlyData = {
            labels: labels,
            datasets: datasets
          };
      
          const options = {
            legend: {
                display: true
             },
             tooltips: {
                enabled: false
             },
             layout: {
                padding: {
                    bottom: 10,
                    top: 10
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: true
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                        drawBorder: true,
                    },
                    ticks: {
                        display: true,
                        min: 0,
                        stepSize: 1
                    }
                }]
            }
        }

        return <Bar
        data={hourlyData}
        options={options}
    />

    }

    _renderEndStatusCountChart = (t, periodeKpi) => {

        if (periodeKpi && periodeKpi.endStatusCount) {
            const options = {};
                const labels = []
                Object.keys(periodeKpi.endStatusCount).forEach( endStatusKey => {
                    const endStatusKeyArray = endStatusKey.split('_');
                    const endStatus = endStatusKeyArray[0];
                    const endSubStatus = endStatusKeyArray[1];
                    const rawLabel = ' ('+endStatus+'/'+endSubStatus+')';
                    if (endSubStatus !== '0') {
                        labels.push(t('refill.endSubStatus.' + endSubStatus, '-')+rawLabel)
                    } else {
                        labels.push(t('refill.endStatus.' + endStatus, '-')+rawLabel)
                    }
                })

            const values = Object.values(periodeKpi.endStatusCount);

            const data = {
                labels: labels,
                datasets: [
                {
                    data: values,
                    backgroundColor: values.map((v, index) => AppColorsDark[index % AppColorsDark.length]),
                }
                ]
            };

            return <Doughnut
                    data={data}
                    options={options}
                />
        }

          return this._renderNoData(t);

    }

    _renderPosPercentChart = (t, periodeKpi) => {

        if (periodeKpi.posCount) {
        const labels = [];
        const values = [];
        Object.keys(periodeKpi.posCount).forEach( pos => {
            const posValue = periodeKpi.posCount[pos];
            labels.push(pos);
            values.push(posValue);
        })
            

            const data = {
                labels: labels,
                datasets: [
                {
                    data: values,
                    backgroundColor: values.map((v, index) => AppColorsLight[index % AppColorsLight.length]),
                }
                ]
            };

            const options = {};

            return <Pie
                    data={data}
                    options={options}
                />
        } else {
            return this._renderNoData(t);
        }
    }

    _renderEnergiePercentChart = (t, periodeKpi, key) => {

        if (periodeKpi.energyCount) {
        const labels = [];
        const values = [];
        Object.keys(periodeKpi.energyCount).forEach( energy => {
            const energyKpi = periodeKpi.energyCount[energy];
            Object.keys(energyKpi).forEach( energyType => {
                const energyTypeKpi = energyKpi[energyType];
                labels.push(energyType);
                values.push(energyTypeKpi[key]);
            });
        })
            

            const data = {
                labels: labels,
                datasets: [
                {
                    data: values,
                    backgroundColor: values.map((v, index) => AppColorsLight[index % AppColorsLight.length]),
                }
                ]
            };

            const options = {};

            return <Pie
                    data={data}
                    options={options}
                />
        } else {
            return this._renderNoData(t);
        }
    }

    _renderEnergieQuantityPercentChart = (t, periodeKpi) => {

        if (periodeKpi.energyCount) {
        const labels = [];
        const values = [];
        Object.keys(periodeKpi.energyCount).forEach( energy => {
            const energyKpi = periodeKpi.energyCount[energy];
            Object.keys(energyKpi).forEach( energyType => {
                const energyTypeKpi = energyKpi[energyType];
                labels.push(energyType);
                values.push(Math.round(energyTypeKpi.quantity / 10) / 100);
            });
        })
            

            const data = {
                labels: labels,
                datasets: [
                {
                    data: values,
                    backgroundColor: values.map((v, index) => AppColorsLight[index % AppColorsLight.length]),
                }
                ]
            };

            const options = {};

            return <Pie
                    data={data}
                    options={options}
                />
        } else {
            return this._renderNoData(t);
        }
    }
    
    _renderEnergyTab = (t, classes, periodeKpi, subPeriodeKpis) => {

        if (periodeKpi && periodeKpi.energyCount) {

        const {subPeriodeType} = this.state;

        let keySubPeriodeCount = 'hour';
        if (subPeriodeType === 'monthly') {
            keySubPeriodeCount = 'month';
        } if (subPeriodeType === 'daily') {
            keySubPeriodeCount = 'day';
        }

        const content = [];

        Object.keys(periodeKpi.energyCount).forEach( energy => {
            const energyKpi = periodeKpi.energyCount[energy];
            Object.keys(energyKpi).forEach( energyType => {
                const energyTypeKpi = energyKpi[energyType];

                const subPeriodeCountMap = {count: {}, success: {}, error: {}};


                if (subPeriodeKpis) {
                    subPeriodeKpis.forEach( subPeriodeKpi => {
                        if (subPeriodeKpi.energyCount) {
                            const energySubPeriodeKpi = subPeriodeKpi.energyCount[energy];
                            if (energySubPeriodeKpi) {
                                const energyTypeSubPeriodeKpi = energySubPeriodeKpi[energyType];
                                if (energyTypeSubPeriodeKpi) {
                                    subPeriodeCountMap.count[subPeriodeKpi[keySubPeriodeCount]] = energyTypeSubPeriodeKpi.count;
                                    subPeriodeCountMap.success[subPeriodeKpi[keySubPeriodeCount]] = energyTypeSubPeriodeKpi.success;
                                    subPeriodeCountMap.error[subPeriodeKpi[keySubPeriodeCount]] = energyTypeSubPeriodeKpi.error;
                                }
                            }
                        }
                    });
                }

                content.push(
                    <div key={energyType} className="kpi-content-part">
                        <div className="kpi-content-part-label">{energyType} bar</div>
                        <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" >
                            <Grid item xs={6} className={classes.gridCell}>
                                <div className="kpi-chart-bloc">
                                    <div className="kpi-chart-bloc-label">Résumé</div>
                                    {this._renderResume(t, energyTypeKpi)}
                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.gridCell}>
                                <div className="kpi-chart-bloc">
                                    <div className="kpi-chart-bloc-label">Pleins terminés</div>
                                    <div className="kpi-chart-bloc-chart">
                                        {this._renderSubPeriodeCountChart(t, subPeriodeCountMap)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </div>
                );
            });
        })

        return <div>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center" >
                        <Grid item xs={6} className={classes.gridCell}>
                            <div className="kpi-chart-bloc">
                                <div className="kpi-chart-bloc-label">{t('repartition.count')}</div>
                                {this._renderEnergiePercentChart(t, periodeKpi, 'count')}
                            </div>
                        </Grid>
                        <Grid item xs={6} className={classes.gridCell}>
                        <div className="kpi-chart-bloc">
                            <div className="kpi-chart-bloc-label">{t('repartition.quantity')}</div>
                            <div className="kpi-chart-bloc-chart">
                            {this._renderEnergieQuantityPercentChart(t, periodeKpi, 'quantity')}
                            </div>
                            </div>
                        </Grid>
                    </Grid>
                {content}
        </div>;

    }

    return this._renderNoData(t);

    }

    _renderSubPeriodeCountChart = (t, subPeriodeCountMap) => {
        
        if (subPeriodeCountMap) {

            const {periodeType, subPeriodeType} = this.state;
            
            const defaultValues = {};
            const orderedKeys = [];
            let labels;
            let xUserCallback;
            if (subPeriodeType === 'hourly') {
                for (let i=0; i<24; i++) {
                    defaultValues[i] = 0;
                    orderedKeys.push(i);
                }
                labels = Object.keys(defaultValues).map( v => v+'h');
                xUserCallback = function(item, index) {
                    if (!(index % 2)) {
                        return item;
                    }
                 };
            } else if (subPeriodeType === 'daily') {
                let d = moment(this.state.start);
                let end = moment(this.state.end);
                labels = [];
                while (end.isAfter(d)) {
                    const keyDay = d.toDate().getDate();
                    defaultValues[keyDay] = 0;
                    orderedKeys.push(keyDay);
                    if (periodeType === 'weekly') {
                        labels.push(d.format('dddd'));
                    } else {
                        labels.push(keyDay);
                    }
                    d = d.add(1, 'day');
                };
            } else if (subPeriodeType === 'monthly') {
                let d = moment(this.state.start);
                let end = moment(this.state.end);
                labels = [];
                while (end.isAfter(d)) {
                    const keyDay = d.toDate().getMonth() + 1;
                    defaultValues[keyDay] = 0;
                    orderedKeys.push(keyDay);
                    labels.push(d.format('MMMM'));
                    d = d.add(1, 'month');
                };
            }

            
        const periodicallyBarData = {};
        
        periodicallyBarData.count = {...defaultValues, ...subPeriodeCountMap.count};
        periodicallyBarData.success = {...defaultValues, ...subPeriodeCountMap.success};
        periodicallyBarData.error = {...defaultValues, ...subPeriodeCountMap.error};

            let data = {
                labels: labels,
                datasets: [
                  {
                    label: t('success'),
                    backgroundColor: '#43af004f',
                    borderColor: '#43af00',
                    borderWidth: 1,
                    hoverBackgroundColor: '#43af007d',
                    hoverBorderColor: '#43af00',
                    data: orderedKeys.map( k => periodicallyBarData.success[k])
                  },
                  {
                    label: t('error'),
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: orderedKeys.map( k => periodicallyBarData.error[k])
                  }
                ]
              };

              
            let options = {
                legend: {
                    display: true
                 },
                 tooltips: {
                    enabled: true
                 },
                 layout: {
                    padding: {
                        bottom: 10,
                        top: 10
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: false,
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            display: true,
                            userCallback: xUserCallback,
                             autoSkip: false
                        }
                    }],
                    yAxes: [{
                        stacked: false,
                        gridLines: {
                            display: false,
                            drawBorder: true,
                        },
                        ticks: {
                            display: true,
                            min: 0,
                            autoSkip: true,
                            callback: function(value, index, values) {
                                if (Math.floor(value) === value) {
                                    return value;
                                }
                            }
                        }
                    }]
                }
            }

            return <Bar
            data={data}
            options={options}
        />;

        } else {
            return this._renderNoData(t);
        }
    }

    _renderAvailabilityTab  = (t, classes, availabilityKpi) => {
        const first = availabilityKpi[0];
        const sum = {durationUnknown: 0, durationOpen: 0, durationPartial: 0, durationMaintenance: 0, durationBreakdown: 0, durationClosed: 0};

        availabilityKpi.forEach( kpi => {
            sum.durationUnknown += kpi.durationUnknown;
            sum.durationOpen += kpi.durationOpen;
            sum.durationPartial += kpi.durationPartial;
            sum.durationMaintenance += kpi.durationMaintenance;
            sum.durationBreakdown += kpi.durationBreakdown;
            sum.durationClosed += kpi.durationClosed;
        })

        return <Grid
                container
                direction="row"
                justify="center"
                alignItems="center" >
                    <Grid item xs={6} className={classes.gridCell}>
                        <div className="kpi-chart-bloc">
                            <div className="kpi-chart-bloc-label">Répartition par période</div>
                            <div className="kpi-chart-bloc-chart">
                                {this._renderAvailbilityBarChart(t, availabilityKpi, sum)}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6} className={classes.gridCell}>
                    <div className="kpi-chart-bloc">
                        <div className="kpi-chart-bloc-label">Répartition globale</div>
                        <div className="kpi-chart-bloc-chart">
                            {this._renderAvailabilityStateChart(t, sum)}
                        </div>
                        </div>
                    </Grid>
            </Grid>
    }

    _renderAvailbilityBarChart = (t, availabilityKpi, sum) => {
        
        const {periodeType, subPeriodeType} = this.state;
            
        const defaultValues = {};
        const orderedKeys = [];
        let labels;
        
        let valueKey;
        if (subPeriodeType === 'hourly') {
            valueKey = 'hour';
            for (let i=0; i<24; i++) {
                defaultValues[i] = 0;
                orderedKeys.push(i);
            }
            labels = Object.keys(defaultValues).map( v => v+'h');
            
        } else if (subPeriodeType === 'daily') {
            valueKey = 'day';
            let d = moment(this.state.start);
            let end = moment(this.state.end);
            labels = [];
            while (end.isAfter(d)) {
                const keyDay = d.toDate().getDate();
                defaultValues[keyDay] = 0;
                orderedKeys.push(keyDay);
                if (periodeType === 'weekly') {
                    labels.push(d.format('dddd'));
                } else {
                    labels.push(keyDay);
                }
                d = d.add(1, 'day');
            };
        } else if (subPeriodeType === 'monthly') {
            valueKey = 'month';
            let d = moment(this.state.start);
            let end = moment(this.state.end);
            labels = [];
            while (end.isAfter(d)) {
                const keyDay = d.toDate().getMonth() + 1;
                defaultValues[keyDay] = 0;
                orderedKeys.push(keyDay);
                labels.push(d.format('MMMM'));
                d = d.add(1, 'month');
            };
        }

        const openDataSet = {
            label: t('state.open'),
            backgroundColor: '#B8E986',
            borderWidth: 1,
            hoverBackgroundColor: '#B8E986',
          };

        const openData = {...defaultValues};
        
        const partielDataSet = {
            label: t('state.partial'),
            backgroundColor: 'orange',
            borderWidth: 1,
            hoverBackgroundColor: 'orange',
          };

          const partielData = {...defaultValues};

        const closeDataSet = {
            label: t('state.unavailable'),
            backgroundColor: 'red',
            borderWidth: 1,
            hoverBackgroundColor: 'red',
          };

          const closeData = {...defaultValues};

          const unknownDataSet = {
            label: t('state.unknown'),
            backgroundColor: '#e1e1e1',
            borderWidth: 1,
            hoverBackgroundColor: '#e1e1e1',
          };

          const unknownData = {...defaultValues};

        availabilityKpi.forEach( kpi => {

            const close = kpi.durationClosed + kpi.durationBreakdown + kpi.durationMaintenance;
            const total = kpi.durationOpen + kpi.durationPartial + kpi.durationUnknown + close;

            if (total > 0 ) {
                openData[kpi[valueKey]] = Math.round(kpi.durationOpen / total * 100);
                partielData[kpi[valueKey]] = Math.round(kpi.durationPartial / total * 100);
                unknownData[kpi[valueKey]] = Math.round(kpi.durationUnknown / total * 100);
                closeData[kpi[valueKey]] = Math.round( close / total * 100);
            }
        })

        const orderValues = (data) => {
            const result = [];
            orderedKeys.forEach( orderedKey => {
                const val = data[orderedKey];
                result.push(val)
            })
            return result;
        }

        openDataSet.data = orderValues(openData);
        partielDataSet.data = orderValues(partielData);
        closeDataSet.data = orderValues(closeData);
        unknownDataSet.data = orderValues(unknownData);

        const options = {
            legend: {
                display: true
             },
             tooltips: {
                enabled: false
             },
             layout: {
                padding: {
                    bottom: 10,
                    top: 10
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: true
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                        drawBorder: true,
                    },
                    ticks: {
                        display: true,
                        min: 0,
                        max: 100,
                        stepSize: 10
                    }
                }]
            }
        }

        const data = {
            labels: labels,
            datasets: [openDataSet, partielDataSet, closeDataSet, unknownDataSet]
          };

            return <Bar
            data={data}
            options={options}
        />

    }

    _renderAvailabilityStateChart = (t, availabilityKpi) => {

        let totalDuration = availabilityKpi.durationUnknown + availabilityKpi.durationOpen + availabilityKpi.durationMaintenance + availabilityKpi.durationBreakdown + availabilityKpi.durationClosed + availabilityKpi.durationPartial;

        const labels = [t('state.unknown'), t('state.open'), t('state.partial'), t('state.maintenance'), t('state.breakdown'), t('state.closed')];
        const values = totalDuration > 0 ? [availabilityKpi.durationUnknown, availabilityKpi.durationOpen, availabilityKpi.durationPartial, availabilityKpi.durationMaintenance, availabilityKpi.durationBreakdown, availabilityKpi.durationClosed ].map( v => Math.round( (v / totalDuration) * 10000) / 100) : [0,0,0,0,0,0];
            

            const data = {
                labels: labels,
                datasets: [
                {
                    data: values,
                    backgroundColor: ['#e1e1e1', '#B8E986', 'orange', 'red', '#cc0000', '#800000'],
                }
                ]
            };

            const options = {};

            return <Pie
                    data={data}
                    options={options}
                />
        
    }

    _renderNoData =(t) => {
        return <div>{t('nodata.todisplay')}</div>
    }
}



const connectedRefillsKpiView = translate('translations')(withStyles(styles, { withTheme: true })(RefillsKpiView));
export { connectedRefillsKpiView as RefillsKpiView };