import React, { Component } from 'react';

import { translate } from 'react-i18next';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';

import {Bar} from 'react-chartjs-2';

import { QuantityGrammeReadView } from '../read/QuantityGramme';

class RefillsByDayResumeView extends Component {
    
    render() {

        const { t, user, kpi, resume, title, onMorePress } = this.props;

       return <div className="kpi-bloc">
            {title && <div className="kpi-title">
                <AssessmentIcon/> {title}
            </div>} 
           {(kpi && (kpi.daily && kpi.daily.length > 0) ) ? kpi.daily.map( dailyKpi => this._renderKpi(t, dailyKpi, kpi.hourly, kpi.availability, resume, user) ) : this._renderKpi(t, {}, null, kpi ? kpi.availability : null, resume, user)}
           <div className="kpi-footer">{onMorePress && <div className="btn-see-more"><Button size="small" onClick={() => onMorePress()}>details</Button></div>}</div>
       </div>;
    }

    _renderKpi = (t, dailyKpi, hourlyKpi, availabilityKpi, resume, user) => {
        if (user && user.isFleetManager) {
            return this._renderFleetKpi(t, dailyKpi, availabilityKpi, resume);
        } else if (user && user.isServiceProvider) {
            return this._renderDailyRefills(t, dailyKpi, hourlyKpi, resume);
        }
        return null;
    }

    _renderFleetKpi = (t, dailyKpi, availabilityKpi, resume, user) => {

        const hourlyValues =  {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0, 20: 0, 21: 0, 22: 0, 23: 0};
        
        let labels = Object.keys(hourlyValues).map( v => v+'h');

        const openDataSet = {
            label: t('state.open'),
            backgroundColor: '#B8E986',
            borderWidth: 1,
            hoverBackgroundColor: '#B8E986',
          };

        const openData = {...hourlyValues};
        
        const partielDataSet = {
            label: t('state.partial'),
            backgroundColor: 'orange',
            borderWidth: 1,
            hoverBackgroundColor: 'orange',
          };

          const partielData = {...hourlyValues};

        const closeDataSet = {
            label: t('state.unavailable'),
            backgroundColor: 'red',
            borderWidth: 1,
            hoverBackgroundColor: 'red',
          };

          const closeData = {...hourlyValues};

          const unknownDataSet = {
            label: t('state.unknown'),
            backgroundColor: '#e1e1e1',
            borderWidth: 1,
            hoverBackgroundColor: '#e1e1e1',
          };

          const unknownData = {...hourlyValues};

          if (availabilityKpi) {
            availabilityKpi.forEach( kpi => {

            const close = kpi.durationClosed + kpi.durationBreakdown + kpi.durationMaintenance;
            const total = kpi.durationOpen + kpi.durationPartial + kpi.durationUnknown + close;

            if (total > 0 ) {
                openData[kpi.hour] = Math.round(kpi.durationOpen / total * 100);
                partielData[kpi.hour] = Math.round(kpi.durationPartial / total * 100);
                unknownData[kpi.hour] = Math.round(kpi.durationUnknown / total * 100);
                closeData[kpi.hour] = Math.round( close / total * 100);
            }
            })
            }

        openDataSet.data = Object.values(openData);
        partielDataSet.data = Object.values(partielData);
        closeDataSet.data = Object.values(closeData);
        unknownDataSet.data = Object.values(unknownData);

        const options = {
            legend: {
                display: false
             },
             tooltips: {
                enabled: false
             },
             layout: {
                padding: {
                    bottom: 10,
                    top: 10
                }
            },
            scales: {
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: true
                    }
                }],
                yAxes: [{
                    stacked: true,
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                        min: 0,
                        max: 100,
                        stepSize: 10
                    }
                }]
            }
        }

        const data = {
            labels: labels,
            datasets: [openDataSet, partielDataSet, closeDataSet, unknownDataSet]
          };

        return <div key={'fleet_kpi'}>
                    <div className="line">
                        <div>
                            <div>{t('refillsCount')} : {dailyKpi.count ? dailyKpi.count : '-'}</div>
                        </div>
                        <div>{t('quantity')} : <QuantityGrammeReadView t={t} value={dailyKpi.quantity}/></div>  
                        <div>{t('quantity.avg')} : <QuantityGrammeReadView t={t} value={dailyKpi.averageQuantity}/></div> 
                    </div>
                    <div className="line">
                    <div class="kpi-title">{t('availability')}</div>
                    <Bar
                        data={data}
                        options={options}
                    />
                    </div>
                </div>
    }

    _renderDailyRefills = (t, dailyKpi, hourlyKpi, resume) => {
        const notStarted = dailyKpi.count ? (dailyKpi.cancelled + dailyKpi.timeoutunlock + dailyKpi.timeoutstart + dailyKpi.notStarted) : '-';
        const notFinished = dailyKpi.count ? (dailyKpi.count - (dailyKpi.success + dailyKpi.error + notStarted)) : '-';

        const hourlyValues =  {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0, 19: 0, 20: 0, 21: 0, 22: 0, 23: 0};
        const hourlyBarData = {count: {...hourlyValues}, success: {...hourlyValues}, error: {...hourlyValues}};
        let hourlyData;
        let options;
        if (hourlyKpi) {
            hourlyKpi.map( hourKpi => {
                hourlyBarData.count[hourKpi.hour] = hourKpi.count;
                hourlyBarData.success[hourKpi.hour] = hourKpi.success;
                hourlyBarData.error[hourKpi.hour] = hourKpi.error;
            });


            hourlyData = {
                labels: Object.keys(hourlyBarData.count).map( v => v+'h'),
                datasets: [
                  {
                    label: t('success'),
                    backgroundColor: '#43af004f',
                    borderColor: '#43af00',
                    borderWidth: 1,
                    hoverBackgroundColor: '#43af007d',
                    hoverBorderColor: '#43af00',
                    data: Object.values(hourlyBarData.success)
                  },
                  {
                    label: t('error'),
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: Object.values(hourlyBarData.error)
                  }
                ]
              };

              
            options = {
                legend: {
                    display: false
                 },
                 tooltips: {
                    enabled: false
                 },
                 layout: {
                    padding: {
                        bottom: 10,
                        top: 10
                    }
                },
                scales: {
                    xAxes: [{
                        stacked: false,
                        gridLines: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            display: true,
                            userCallback: function(item, index) {
                                if (!(index % 2)) {
                                    return item;
                                }
                             },
                             autoSkip: false
                        }
                    }],
                    yAxes: [{
                        stacked: false,
                        gridLines: {
                            display: false,
                            drawBorder: true,
                        },
                        ticks: {
                            display: true,
                            stepSize: 1,
                            min: 0
                        }
                    }]
                }
            }
        }
        
        return <div>
                    <div className="line">
                        {!resume && <div>
                            <div>{t('refillsCount')} : {dailyKpi.count ? dailyKpi.count : '-'}</div>
                        </div>}
                        <div className="kpi-bloc-count-line">
                            
                            <div className="kpi-bloc-count notstarted">
                                <div className="kpi-bloc-count-label">{t('not.started')}</div>
                                <div className="kpi-bloc-count-value">{notStarted}</div>
                            </div>
                            <div className="kpi-bloc-count success">
                                <div className="kpi-bloc-count-label">{t('success')}</div>
                                <div className="kpi-bloc-count-value">{dailyKpi.success ? dailyKpi.success : '-'}</div>
                            </div>
                            <div className="kpi-bloc-count error">
                                <div className="kpi-bloc-count-label">{t('error')}</div>
                                <div className="kpi-bloc-count-value">{dailyKpi.error ? dailyKpi.error : '-'}</div>
                            </div>
                        </div>
                        {!resume && <div>
                            <div>{t('performance')} : {dailyKpi.performance ? (Math.round(dailyKpi.performance * 10000) / 100) + '%' : '-'}</div>
                            <div>{t('quantity')} : <QuantityGrammeReadView t={t} value={dailyKpi.startedQuantity}/> {dailyKpi.quantity && (dailyKpi.quantity !== dailyKpi.startedQuantity) && <span>(total : <QuantityGrammeReadView t={t} value={dailyKpi.quantity}/>)</span>}</div> 
                            <div>{t('quantity.avg')} : <QuantityGrammeReadView t={t} value={dailyKpi.averageQuantity}/></div>
                        </div>}
                    </div>

            {false && hourlyData && <div className="line">
                    <Bar
                            data={hourlyData}
                            options={options}
                        />
                    </div>}
                    {false && !resume && <div>
                    {dailyKpi.energyCount && Object.keys(dailyKpi.energyCount).map( energy => {
                        {
                            const energyKpi = dailyKpi.energyCount[energy];
                            return <div>
                                {Object.keys(energyKpi).map( energyType => {
                                    const energyTypeKpi = energyKpi[energyType];
                                    return <div key={'energy_'+energyType} className="kpi-energy-line">
                                            <div className="kpi-energy-line-bloc">
                                                <div className="kpi-energy-line-bloc-label">{t('energy')}</div>
                                                <div className="kpi-energy-line-bloc-value">{energyType} <span className="unit">bar</span></div>
                                            </div>
                                            <div className="kpi-energy-line-bloc">
                                                <div className="kpi-energy-line-bloc-label">{t('total')}</div>
                                                <div className="kpi-energy-line-bloc-value">{energyTypeKpi.count}</div>
                                            </div>
                                            <div className="kpi-energy-line-bloc">
                                                <div className="kpi-energy-line-bloc-label">{t('quantity')}</div>
                                                <div className="kpi-energy-line-bloc-value"><QuantityGrammeReadView t={t} html={true} value={energyTypeKpi.quantity}/></div>
                                            </div>
                                        </div>;
                                })}
                            </div>;
                        }
                    })}
                </div>}
            </div>
        
    }
}

const connectedRefillsByDayResumeView = translate('translations')(RefillsByDayResumeView);
export { connectedRefillsByDayResumeView as RefillsByDayResumeView };