import { authHeader, endpoints } from '../_helpers';
import { u2fService } from '../_services';
import { commonService } from '../_services';


export const userService = {
    login,
    rememberme,
    logout,
    checkValidationAccessToken,
    validateAccessAndSetPassword,
    validateAccessTOTPAndSetPassword,
    requestU2F,
    requestU2FForUser,
    reponseU2F,
    registerU2F,
    signU2FChallenges,
    verifyTOTP,
    loadUserContextData,
    sendValidationEmail,
    requestTOTPSecret,
    requestTOTPForUser,
    passwordForgotten,
    checkResetPasswordToken,
    resetPassword
};

function login(username, password, rememberme) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": username, password })
    };

    return fetch(`${endpoints.api}/login`, requestOptions)
        .then(handleResponse)
        .then(result => {
            // login successful if there's a jwt token in the response
            if (result.loggedIn) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(result));
                //return result.user;
            }
            if (result.authentified) {
                if (rememberme) {
                    localStorage.setItem('rememberme', username);
                } else {
                    localStorage.removeItem('rememberme')
                }
            }
            return result;
        });
}

function rememberme() {
    return localStorage.getItem('rememberme');
}

function requestU2F(token) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "token": token })
    };

    return fetch(`${endpoints.api}/u2f/registrationRequest`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });

}

function requestU2FForUser() {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({})
    };

    return fetch(`${endpoints.api}/user/u2f/registrationRequest`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });

}

function reponseU2F(u2frequest) {
    return u2fService.register(u2frequest.registrationRequest).then( result => {
        return result;
    });
}

function registerU2F(token, id, registrationResponse, password, confirmPassword) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, id, registrationResponse, password, confirmPassword })
    };

    return fetch(`${endpoints.api}/u2f/registration`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return Promise.resolve(true);
        });

}

function signU2FChallenges(token, challenges) {
    return u2fService.sign(challenges).then( signature => {
        return sign(token, signature);
    });
}

function verifyTOTP(token, totpToken) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, totpToken })
    };

    return fetch(`${endpoints.api}/totp/verify`, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.loggedIn) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(result));
                return result;
            } else return null;
        });
}

function checkValidationAccessToken(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "token": token })
    };

    return fetch(`${endpoints.api}/checkAccessToken`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}

function validateAccessAndSetPassword(token, password, confirmPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password, confirmPassword })
    };

    return fetch(`${endpoints.api}/accessValidationWithPassword`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function validateAccessTOTPAndSetPassword(token, totpToken, password, confirmPassword) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, totpToken, password, confirmPassword })
    };

    return fetch(`${endpoints.api}/totp/registration`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return Promise.resolve(true);
        });

}

function sign(token, signature) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, signature })
    };

    return fetch(`${endpoints.api}/u2f/sign`, requestOptions)
        .then(handleResponse)
        .then(result => {
            if (result.loggedIn) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(result));
                return result;
            } else return null;
        });
}

function requestTOTPSecret(token) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token})
    };

    return fetch(`${endpoints.api}/totp/registrationRequest`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });

}

function requestTOTPForUser() {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({})
    };

    return fetch(`${endpoints.api}/user/totp/registrationRequest`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });

}

function logout(sessionExpired) {
    commonService.logout(sessionExpired);
}


function loadUserContextData(userInfo) {
    
    if (userInfo.isFleetManager) {

        return commonService.getAll({name: 'fleet'}).then( fleets => {

            const userContext = {
                fleets,
                activeFleet: fleets[0]
            }

            localStorage.setItem('fleet', JSON.stringify(userContext.activeFleet));

            return Promise.resolve(userContext);

        });
    }

    if (userInfo.isServiceProvider) {
        return commonService.getAll({name: 'customer'}).then( customers => {

            const userContext = {
                customers
            }

            return Promise.resolve(userContext);

        });
    }

    return Promise.resolve();
}

function sendValidationEmail(userAccess) {

    const send = {
        userAccess: userAccess
    }

    const model  = {
        name: 'userAccessValidationSend',
        creation: {
            fields: [
                {name: 'userAccess', sourcetype: 'object', source:'userAccess'}
            ]
        }
    }

    return commonService.save(true, send, model)
}

function passwordForgotten(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": email })
    };

    return fetch(`${endpoints.api}/passwordforgotten`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function checkResetPasswordToken(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "token": token })
    };

    return fetch(`${endpoints.api}/checkResetPwdToken`, requestOptions)
        .then(handleResponse)
        .then(user => {
            return user;
        });
}


function resetPassword(token, password, confirmPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, password, confirmPassword })
    };

    return fetch(`${endpoints.api}/resetpassword`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function handleResponse(response) {
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout(true);
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}