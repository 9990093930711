import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import DoughnutChartView from '../common/DoughnutChart';
import PieChartLoadingView from '../common/PieChartLoading';

const BACKGROUND_COLORS = ['#ff000061', '#ffe6aa', '#7dff81']
const BORDER_COLORS = ['#ff0000ab', '#fecf5f', '#00f107']

const COLORS = BACKGROUND_COLORS.map( (value, index) => {
    return {backgroundColor: value, borderColor: BORDER_COLORS[index]} 
})

class RefillGroupSatisfactionView extends React.Component {

    render() {

        const { t, mode, loading, data, previousData } = this.props;
        
        if (loading) {
            return <PieChartLoadingView/>
        }

        if (data && data.total && data.total.length > 0) {
            return this.renderKpiBloc( t, data.total[0], (previousData.total && previousData.total.length > 0) ? previousData.total[0] : {sastisfactionLevel0: 0, sastisfactionLevel1: 0, sastisfactionLevel2: 0} )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData, previousRawData ) => {

        const repartition = {'0': rawData.sastisfactionLevel0, '1': rawData.sastisfactionLevel1, '2': rawData.sastisfactionLevel2}
        
        const total = rawData.count

        const previousRepartition = {'0': previousRawData.sastisfactionLevel0, '1': previousRawData.sastisfactionLevel1, '2': previousRawData.sastisfactionLevel2}
        const previousTotal = previousRawData.count

        const repartitionPercents = Object.values(repartition).map( value => { return Math.round( value / total * 100)})
        
        const progression = {}

        Object.keys(previousRepartition).forEach( key => {
            progression[key] = KpiTools.computeProgression(Math.round( repartition[key] / total * 100), Math.round( previousRepartition[key] / previousTotal * 100))
        })


        const legendLabels = [t('not.satisfying'), t('satisfying'), t('very.satisfying')]
        const valueLabels = repartitionPercents.map( p => p+'%')
        const progressionValues = Object.values(progression)

        const options = KpiTools.makeCustomTooltips(legendLabels, valueLabels, COLORS, progressionValues)

            const data = {
                labels: legendLabels,
                datasets: [
                {
                    label: valueLabels, 
                    data: repartitionPercents,
                    backgroundColor: BACKGROUND_COLORS,
                    borderColor: BORDER_COLORS
                }
                ]
            };

        return <DoughnutChartView data={data} options={options}/>

    }

}

const translatedBloc = translate('translations')(RefillGroupSatisfactionView);
export { translatedBloc as RefillGroupSatisfactionView };