export const commonConstants = {

    CLOUDINFO_REQUEST: 'CLOUDINFO_REQUEST',
    CLOUDINFO_SUCCESS: 'CLOUDINFO_SUCCESS',
    CLOUDINFO_FAILURE: 'CLOUDINFO_FAILURE',

    READ_REQUEST: 'COMMON_READ_REQUEST',
    READ_SUCCESS: 'COMMON_READ_SUCCESS',
    READ_FAILURE: 'COMMON_READ_FAILURE',

    EDIT_REQUEST: 'COMMON_EDIT_REQUEST',
    EDIT_SUCCESS: 'COMMON_EDIT_SUCCESS',
    EDIT_FAILURE: 'COMMON_EDIT_FAILURE',

    GET_VIEWDATA_REQUEST: 'COMMON_GET_VIEWDATA_REQUEST',
    GET_VIEWDATA_SUCCESS: 'COMMON_GET_VIEWDATA_SUCCESS',
    GET_VIEWDATA_FAILURE: 'COMMON_GET_VIEWDATA_FAILURE',
	
	SEND_MEDIA_FILE_REQUEST: 'COMMON_SEND_MEDIA_FILE_REQUEST',
    SEND_MEDIA_FILE_SUCCESS: 'COMMON_SEND_MEDIA_FILE_SUCCESS',
    SEND_MEDIA_FILE_FAILURE: 'COMMON_SEND_MEDIA_FILE_FAILURE',
	
	DOSEARCHTXT_REQUEST: 'COMMON_DOSEARCHTXT_REQUEST',
    DOSEARCHTXT_SUCCESS: 'COMMON_DOSEARCHTXT_SUCCESS',
    DOSEARCHTXT_FAILURE: 'COMMON_DOSEARCHTXT_FAILURE',
	
    GETALL_REQUEST: 'COMMON_GETALL_REQUEST',
    GETALL_SUCCESS: 'COMMON_GETALL_SUCCESS',
    GETALL_FAILURE: 'COMMON_GETALL_FAILURE',

    CREATE_REQUEST: 'COMMON_CREATE_REQUEST',
    CREATE_SUCCESS: 'COMMON_CREATE_SUCCESS',
    CREATE_FAILURE: 'COMMON_CREATE_FAILURE',

    WORKFLOWACTION_REQUEST: 'COMMON_WORKFLOWACTION_REQUEST',
    WORKFLOWACTION_SUCCESS: 'COMMON_WORKFLOWACTION_SUCCESS',
    WORKFLOWACTION_FAILURE: 'COMMON_WORKFLOWACTION_FAILURE',

    SAVE_REQUEST: 'COMMON_SAVE_REQUEST',
    SAVE_SUCCESS: 'COMMON_SAVE_SUCCESS',
    SAVE_FAILURE: 'COMMON_SAVE_FAILURE',

    LOADCONTEXT_REQUEST: 'COMMON_LOADCONTEXT_REQUEST',
    LOADCONTEXT_SUCCESS: 'COMMON_LOADCONTEXT_SUCCESS',
    LOADCONTEXT_FAILURE: 'COMMON_LOADCONTEXT_FAILURE',

    UPDATE_ITEM_EDITION_REQUEST: 'COMMON_UPDATE_EDITION',

    DELETE_REQUEST: 'COMMON_DELETE_REQUEST',
    DELETE_SUCCESS: 'COMMON_DELETE_SUCCESS',
    DELETE_FAILURE: 'COMMON_DELETE_FAILURE',

    ARCHIVE_REQUEST: 'COMMON_ARCHIVE_REQUEST',
    ARCHIVE_SUCCESS: 'COMMON_ARCHIVE_SUCCESS',
    ARCHIVE_FAILURE: 'COMMON_ARCHIVE_FAILURE',

    GO_CREATE_REQUEST: 'COMMON_GO_CREATE_REQUEST',

    LIVEALL_REQUEST: 'COMMON_LIVEALL_REQUEST',
    LIVEALL_SUCCESS: 'COMMON_LIVEALL_SUCCESS',
    LIVEALL_FAILURE: 'COMMON_LIVEALL_FAILURE',

    LIVEONE_REQUEST: 'COMMON_LIVEONE_REQUEST',
    LIVEONE_SUCCESS: 'COMMON_LIVEONE_SUCCESS',
    LIVEONE_FAILURE: 'COMMON_LIVEONE_FAILURE',

    LIVE_STOP: 'COMMON_LIVE_STOP',

    LIVE_FULL_REQUEST: 'COMMON_LIVE_FULL_REQUEST',
    LIVE_FULL_SUCCESS: 'COMMON_LIVE_FULL_SUCCESS',
    LIVE_FULL_FAILURE: 'COMMON_LIVE_FULL_FAILURE',

    APP_CONTEXT_SET: 'APP_CONTEXT_SET',

    LOAD_KPI_REQUEST: 'COMMON_LOAD_KPI_REQUEST',
    LOAD_KPI_SUCCESS: 'COMMON_LOAD_KPI_SUCCESS',
    LOAD_KPI_FAILURE: 'COMMON_LOAD_KPI_FAILURE',
};