export const StateColorHelper = {
    stateToColorClass
}

function stateToColorClass(state) {
    if (state === 'open' || state === 'busy') {
        return 'success';
    } else if (state === 'partial') {
        return 'warning';
    } else if (state === 'unknown' || state === 'offline') {
        return 'default';
    } else {
        return 'error';
    }
}