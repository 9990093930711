import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Route, Link } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Fade from '@material-ui/core/Fade';

import { userActions } from '../../../_actions';
import { userService } from '../../../_services';

import '../Login.css';

const appSpecificAssetFolder = process.env.REACT_APP__SPC_ASSET_FLD ? process.env.REACT_APP__SPC_ASSET_FLD : "fillndrive";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    mainTitle: {
        color: '#2B70ED'
    },
    mainContainer: {
        textAlign: 'center',
        marginTop: '6rem'
    },
    formContainer: {
        padding: theme.spacing.unit * 2,
    },
    margin: {
        margin: theme.spacing.unit,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
    appBarColorPrimary: {
        backgroundColor: 'none',
        background: 'linear-gradient(0.25turn, #273b54, #2a87fc)'
    },
});

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout());

        const rememberme = userService.rememberme();

        this.state = {
            email: this.props.prefillEmail,
            email: this.props.prefillEmail ? this.props.prefillEmail : (rememberme ? rememberme : ''),
            password: '',
            rememberme: rememberme ? true : false,
            submitted: false,
            showPassword: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        if (this.state.rememberme) {
            if (this.passwordInput) {
                this.passwordInput.focus(); 
            }
        } else {
            if (this.emailInput) {
                this.emailInput.focus(); 
            }
        }
     }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
      };

    handleToggle = prop => event => {
        this.setState({ [prop]: this.state[prop] ? false : true });
    };

    keyPress(e){
        if(e.keyCode === 13){
            this.handleSubmit(e);
        }
     }

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
      };

    handleChoiceTwoAuth = twoAuthSolution => {
        if ('U2F' === twoAuthSolution) {
            this.props.dispatch(userActions.signU2F(this.props.user));
        } else if ('TOTP' === twoAuthSolution) {
            this.props.dispatch(userActions.requestTOTP(this.props.user));
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        const { loggingIn } = this.props;

        if (loggingIn) {
            return;
        }

        this.setState({ submitted: true });
        const { email, password, rememberme } = this.state;
        const { dispatch } = this.props;
        if (email && password) {
            dispatch(userActions.login(email, password, rememberme));
        }
    }

    handleSubmitTOTP(e) {
        e.preventDefault();

        const { totpVerifying } = this.props;

        if (totpVerifying) {
            return;
        }

        this.setState({ totpSubmitted: true });
        if (this.state.token) {
            this.props.dispatch(userActions.verifyTOTP(this.props.user, this.state.token));
        }
    }

    handleKeyPressTOTP(e) {
        if(e.keyCode === 13){
            this.handleSubmitTOTP(e);
        }
     }

    render() {
        
        const { t, step, twoauth, u2ferror, classes } = this.props;

        return <div>
            <AppBar position="fixed" className={classes.appBar} classes={{colorPrimary: classes.appBarColorPrimary}}>
      <Toolbar>
      <img height="32px" src={require('../../../assets/img/'+appSpecificAssetFolder+'/logo-h.svg')}/>
      </Toolbar>
    </AppBar>
            <Grid container spacing={24}>
                <Grid item xs={false} md={4}>
                </Grid>
                <Grid item xs={12}  md={4} className={classNames(classes.mainContainer)} >
                    
                    <Typography variant="h5" component="h2">{t('context.manager')}</Typography>
                    
                    <div className="login-container">
                        
                        {step === 'login' && this._renderLoginForm()}
                        
                        {step === 'choosetwoauth' && this._renderTwoAuthChoice(twoauth)}
                        
                        {step === 'totp' && this._renderTOTPForm()}

                        {step === 'u2f' &&  this._renderU2FCall2Action(twoauth, u2ferror)}

                    </div>
                </Grid>
                <Grid item xs={false} md={4}>
                </Grid>
            </Grid>
            </div>;
    }

    _renderLoginForm = () => {

        const { t, loggingIn, classes, user, prefillEmail, error } = this.props;
        const { email, password, submitted } = this.state;

        let valueEmail = '';
        if (email) {
            valueEmail = email
        } else if (prefillEmail) {
            valueEmail = prefillEmail;
        }

        return (
            <form name="form" onSubmit={this.handleSubmit} className="form-login">
                <div className="form-title"><Typography><LockIcon/> Authentication</Typography></div>
                <FormControl fullWidth className={classNames(classes.margin, classes.textField)} error={submitted && !email ? true : false }>
                    <InputLabel htmlFor="adornment-email">{t('email')}</InputLabel>
                    <Input
                        id="adornment-email"
                        inputRef={(input) => { this.emailInput = input; }} 
                        type="email"
                        value={valueEmail}
                        onChange={this.handleChange('email')}
                        endAdornment={
                        <InputAdornment position="end">
                            <MailIcon/>
                        </InputAdornment>
                        }
                    />
                    {submitted && !email &&
                    <FormHelperText id="component-error-email">{t('email.error.required')}</FormHelperText>
                    }
                </FormControl>
                <br/>
                <FormControl fullWidth className={classNames(classes.margin, classes.textField)} error={submitted && !password ? true : false }>
                    <InputLabel htmlFor="adornment-password">{t('password')}</InputLabel>
                    <Input
                        id="adornment-password"
                        inputRef={(input) => { this.passwordInput = input; }} 
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={password}
                        onKeyDown={(e) => {this.keyPress(e)}}
                        onChange={this.handleChange('password')}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    {submitted && !password &&
                    <FormHelperText id="component-error-password">{t('password.error.required')}</FormHelperText>
                    }
                </FormControl>
                <FormControl>
                    <FormControlLabel
                            control={
                            <Checkbox checked={this.state.rememberme} onChange={this.handleToggle('rememberme')} value="rememberme" />
                            }
                            label={t('rememberme')}
                        />
                </FormControl>
                {error && 
                    <div className="form-error">
                        <div>{t('login.failed')}</div>
                    </div>
                }
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    >
                <Button variant="outlined" color="primary" className={classes.margin} disabled={loggingIn} onClick={this.handleSubmit}>
                {t('action.login')}
                {loggingIn &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                </Button>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    >
                    <Link to="/passwordforgotten">{t('c2a.passwordforgotten')}</Link>
                </Grid>
            </form>
        );
    }

    _renderTwoAuthChoice = (twoauth) => {

        const { t } = this.props;

        return twoauth ? (
            <div className="u2Container">
                <div>
                    <Typography>{t('double.security')}</Typography>
                </div>
                {this._renderTwoAuthChoiceButtons(twoauth, 'Choisissez votre second facteur d\'authentification :')}
            </div>
        ) : (<div></div>);
    }

    _renderTwoAuthChoiceButtons = (twoauth, title) => {
        const { t } = this.props;
        return <div>
            {title && <div className="bloc-msg">{title}</div>}
            {twoauth.map( twoAuthSolution => {
                return (<div key={twoAuthSolution}>
                    <Button variant="outlined" color="primary" onClick={() => this.handleChoiceTwoAuth(twoAuthSolution)}>{t('twoauth.solution.'+twoAuthSolution)}</Button>
                </div>)
            })}
        </div>
    }

    _renderTOTPForm = () => {

        const { t, classes, submitted, totpVerifying, totperror } = this.props;

        return (
            <div className="u2Container">
                <div>
                    <Typography>{t('double.security')}</Typography>
                </div>
                <img className="u2fLogo" src={require('../../../assets/img/u2f-illustration.png')} />
                <div>
                <div className="form-title"><Typography><MobileFriendlyIcon/> Veuillez entrer le code généré par votre application mobile :</Typography></div>
                <FormControl fullWidth className={classNames(classes.margin, classes.textField)} error={false}>
                    <InputLabel htmlFor="adornment-email">{t('token')}</InputLabel>
                    <Input
                        id="topt-token"
                        inputRef={(input) => { this.totpInput = input; }} 
                        type="number"
                        value={this.state.token}
                        onKeyDown={(e) => {this.handleKeyPressTOTP(e)}}
                        onChange={this.handleChange('token')}
                        maxLength={6}
                    />
                    {submitted && !this.state.token &&
                    <FormHelperText id="component-error-email">{t('email.error.required')}</FormHelperText>
                    }
                </FormControl>
                    {totperror && !totpVerifying && <Typography variant="button" gutterBottom className="u2f-error">{t('totp.verify.error')}</Typography>}
                    <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        >
                    <Button variant="outlined" color="primary" className={classes.margin}  disabled={totpVerifying} onClick={(e) => this.handleSubmitTOTP(e)}>
                    {t('action.validate')}
                    {totpVerifying &&
                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        }
                    </Button>
                    </Grid>
                </div>
            </div>
        )
    }

    _renderU2FCall2Action = (twoauth, error) => {

        const { t } = this.props;

        const txt = error ? (
            <Typography variant="button" gutterBottom className="u2f-error">{t('u2f.sign.error')}</Typography>
        ) : (
            <Typography variant="button" gutterBottom className="u2f-instruction">{t('u2f.please.click.key.to.login')}</Typography>
        );
            
        const otherAuthMethod = twoauth ? twoauth.filter( method => method != 'U2F') : [];

        return (
            <div className="u2Container">
                <div>
                    <Typography>{t('double.security')}</Typography>
                </div>
                    <img className="u2fLogo" src={require('../../../assets/img/u2f-illustration.png')} />
                <div>
                    {txt}
                </div>
                {otherAuthMethod.length > 0 && this._renderTwoAuthChoiceButtons(otherAuthMethod, 'ou essayez avec une autre méthode d\'authentification :')}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { step, loggingIn, u2fWaiting, u2ferror, user, prefillEmail, error, twoauth, totpVerifying, totperror } = state.authentication;
    return {
        step, loggingIn, u2fWaiting, u2ferror, user, prefillEmail, error, twoauth, totpVerifying, totperror
    };
}

const connectedLoginPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(LoginPage)));
export { connectedLoginPage as LoginPage }; 