import { authHeader, endpoints } from '../_helpers';
import { ObjectsMap } from '../_config';
import moment from 'moment'
export const kpiService = {
    resumeRefills,
    stationRefills,
    dispenserRefills
};

function resumeRefills(periodeType, start, end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    var url = new URL(`${endpoints.api}/kpi/refills`);
    
    url.searchParams.append('periodeType', periodeType);
    url.searchParams.append('start', moment(start).format('DD/MM/YYYY'));
    url.searchParams.append('end', moment(end).format('DD/MM/YYYY'));

    return fetch(url, requestOptions).then(handleResponse);
}

function stationRefills(stationId, periodeType, start, end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    var url = new URL(`${endpoints.api}/kpi/station/${stationId}/refills`);
    
    url.searchParams.append('periodeType', periodeType);
    url.searchParams.append('start', moment(start).format('DD/MM/YYYY'));
    url.searchParams.append('end', moment(end).format('DD/MM/YYYY'));

    return fetch(url, requestOptions).then(handleResponse);
}

function dispenserRefills(dispenserId, periodeType, start, end) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    var url = new URL(`${endpoints.api}/kpi/dispenser/${dispenserId}/refills`);
    
    url.searchParams.append('periodeType', periodeType);
    url.searchParams.append('start', moment(start).format('DD/MM/YYYY'));
    url.searchParams.append('end', moment(end).format('DD/MM/YYYY'));

    return fetch(url, requestOptions).then(handleResponse);
}

function logout(sessionExpired) {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    if (sessionExpired) window.location.reload();
}

function handleResponse(response) {
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout(true);
    }
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}