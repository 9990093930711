import React, { Component } from 'react';

import { translate } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    errorMessage: {
        color: 'red'
    }
});

class PopinDialog extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { t, title, intro, validationLabel, handleCancel, handleValidate } = this.props;

        return <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={true}
          aria-labelledby="max-width-dialog-title"
        >
          {title && <DialogTitle id="max-width-dialog-title">{t(title)}</DialogTitle>}
          <DialogContent>
          {intro && <DialogContentText>{t(intro)}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} variant="contained" >
              {t('action.cancel')}
            </Button>
            <Button onClick={handleValidate} color="primary" variant="contained">
              {t(validationLabel ? validationLabel : 'action.validate')}
            </Button>
          </DialogActions>
        </Dialog>

    }
    
}

const connectedPopinDialog = withStyles(styles, { withTheme: true })(translate('translations')(PopinDialog));
export { connectedPopinDialog as PopinDialog };