import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { userActions } from '../../../_actions';

import '../Login.css';

const appSpecificAssetFolder = process.env.REACT_APP__SPC_ASSET_FLD ? process.env.REACT_APP__SPC_ASSET_FLD : "fillndrive";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    mainTitle: {
        color: '#2B70ED'
    },
    mainContainer: {
        textAlign: 'center',
        marginTop: '6rem'
    },
    formContainer: {
        padding: theme.spacing.unit * 2,
    },
    margin: {
        margin: theme.spacing.unit,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    appBarColorPrimary: {
        backgroundColor: 'none',
        background: 'linear-gradient(0.25turn, #273b54, #2a87fc)'
    },
});

class PasswordForgottenPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout());

        this.state = {
            email: '',
            submitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        if (this.emailInput) {
            this.emailInput.focus(); 
        }
     }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
      };

    keyPress(e){
        if(e.keyCode === 13){
            this.handleSubmit(e);
        }
     }

    handleSubmit(e) {
        e.preventDefault();

        const { submitIn } = this.props;

        if (submitIn) {
            return;
        }

        this.setState({ submitted: true });
        const { email } = this.state;
        const { dispatch } = this.props;
        if (email) {
            dispatch(userActions.passwordForgotten(email));
        }
    }

    handleKeyPressEmail(e){
        if(e.keyCode === 13){
            this.handleSubmit(e);
        }
     }

    render() {
        
        const { t, classes } = this.props;

        return <div>
        <AppBar position="fixed" className={classes.appBar} classes={{colorPrimary: classes.appBarColorPrimary}}>
      <Toolbar>
      <img height="32px" src={require('../../../assets/img/'+appSpecificAssetFolder+'/logo-h.svg')}/>
      </Toolbar>
    </AppBar>
            <Grid container spacing={24}>
                <Grid item xs={false} md={4}>
                </Grid>
                <Grid item xs={12}  md={4} className={classNames(classes.mainContainer)} >
                    <Typography variant="h5" component="h2">{t('context.manager')}</Typography>
                    
                    <div className="login-container">
                        {this._renderPasswordForgottenForm()}
                    </div>
                </Grid>
                <Grid item xs={false} md={4}>
                </Grid>
            </Grid>
        </div>
    }

    _renderPasswordForgottenForm = () => {

        const { t, success, submitIn, classes, error } = this.props;
        const { email, submitted } = this.state;


        if (success && submitted) {
            return <div>
                <div className="bloc-msg"><CheckCircleOutlineIcon className="success-icon"/> {t('password.forgotten.success')}</div>
            </div>
        }

        return (
            <form name="form" onSubmit={this.handleSubmit} className="form-login">
                <div className="form-title"><Typography><LockIcon/> {t('title.passwordforgotten')}</Typography></div>
                <FormControl fullWidth className={classNames(classes.margin, classes.textField)} error={submitted && !email ? true : false }>
                    <InputLabel htmlFor="adornment-email">{t('email')}</InputLabel>
                    <Input
                        id="adornment-email"
                        inputRef={(input) => { this.emailInput = input; }} 
                        type="email"
                        value={email}
                        onChange={this.handleChange('email')}
                        onKeyDown={(e) => {this.keyPress(e)}}
                        endAdornment={
                        <InputAdornment position="end">
                            <MailIcon/>
                        </InputAdornment>
                        }
                    />
                    {submitted && !email &&
                    <FormHelperText id="component-error-email">{t('email.error.required')}</FormHelperText>
                    }
                </FormControl>
                
                {error && 
                    <div className="form-error">
                        <div>{t('password.forgotten.failed')}</div>
                    </div>
                }
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    >
                <Button variant="outlined" color="primary" className={classes.margin} disabled={submitIn} onClick={this.handleSubmit}>
                {t('action.validate')}
                {submitIn &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                </Button>
                </Grid>
            </form>
        );
    }
   
}

function mapStateToProps(state) {
    const { success, submitIn, error } = state.authentication;
    return {
        success, submitIn, error
    };
}

const connectedPasswordForgottenPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(PasswordForgottenPage)));
export { connectedPasswordForgottenPage as PasswordForgottenPage }; 