import moment from 'moment'

export const makePeriode = (date, type) => {

    const result = {};
    const mdate = moment(date);
    const now = new Date()
    let next;
    if (type === 'daily') {
        const unit = 'day';
        result.periodeType = type;
        result.start = mdate.startOf(unit).toDate();
        result.end = mdate.endOf(unit).toDate();
        result.previous = mdate.add(-1, unit).toDate();
        result.subPeriodeType = 'hourly';
        next = moment(result.start).add(1, unit).toDate();
        result.canPick = true
    } else if (type === 'last7days') {
        
        const unit = 'day';
        result.periodeType = type;
        result.end = moment(now).endOf(unit).toDate();
        result.start = moment(now).startOf(unit).add(-7, unit).toDate();
        result.previous = null
        result.subPeriodeType = 'daily';
        result.next = null
        result.canPick = false
    } else if (type === 'last14days') {
        const unit = 'day';
        result.periodeType = type;
        result.end = moment(now).endOf(unit).toDate();
        result.start = moment(now).startOf(unit).add(-14, unit).toDate();
        result.previous = null
        result.subPeriodeType = 'daily';
        result.next = null
        result.canPick = false
    } else if (type === 'last30days') {
        const unit = 'day';
        result.periodeType = type;
        result.end = moment(now).endOf(unit).toDate();
        result.start = moment(now).startOf(unit).add(-30, unit).toDate();
        result.previous = null
        result.subPeriodeType = 'daily';
        result.next = null
        result.canPick = false
    } else if (type === 'last90days') {
        const unit = 'day';
        result.periodeType = type;
        result.end = moment(now).endOf(unit).toDate();
        result.start = moment(now).startOf(unit).add(-90, unit).toDate();
        result.previous = null
        result.subPeriodeType = 'daily';
        result.next = null
        result.canPick = false
    } else if (type === 'weekly') {
        const unit = 'isoWeek';
        result.periodeType = type;
        result.start = mdate.startOf(unit).toDate();
        result.end = mdate.endOf(unit).toDate();
        result.previous = mdate.add(-7, 'day').toDate();
        result.subPeriodeType = 'daily';
        next = moment(result.start).add(7, 'day').toDate();
        result.canPick = true
    } else if (type === 'monthly') {
        const unit = 'month';
        result.periodeType = type;
        result.start = mdate.startOf(unit).toDate();
        result.end = mdate.endOf(unit).toDate();
        result.previous = mdate.add(-1, unit).toDate();
        result.subPeriodeType = 'daily';
        next = moment(result.start).add(1, unit).toDate();
        result.canPick = true
    } else if (type === 'trimester') {

        const quarterIndex = (Math.floor(mdate.month()/3))

        mdate.set('month', quarterIndex * 3)

        const unit = 'month';
        result.periodeType = type;
        result.start = mdate.startOf(unit).toDate();
        result.end = mdate.add(2, unit).endOf(unit).toDate();
        result.previous = mdate.add(-3, unit).toDate();
        result.subPeriodeType = 'daily';
        next = moment(result.start).add(3, unit).toDate();
        result.canPick = true

    } else if (type === 'semester') {

        const semesterIndex = (Math.floor(mdate.month()/6))

        mdate.set('month', semesterIndex * 6)

        const unit = 'month';
        result.periodeType = type;
        result.start = mdate.startOf(unit).toDate();
        result.end = mdate.add(5, unit).endOf(unit).toDate();
        result.previous = mdate.add(-6, unit).toDate();
        result.subPeriodeType = 'daily';
        next = moment(result.start).add(6, unit).toDate();
        result.canPick = true

    } else if (type === 'yearly') {
        const unit = 'year';
        result.periodeType = type;
        result.start = mdate.startOf(unit).toDate();
        result.end = mdate.endOf(unit).toDate();
        result.previous = mdate.add(-1, unit).toDate();
        result.subPeriodeType = 'monthly';
        next = moment(result.start).add(1, unit).toDate();
        result.canPick = true
    }
    
    if (result.next === undefined) {
        if (result.end && moment(result.end).isBefore(moment().startOf('day'))) {
            result.next = next;
        } else {
            result.next = null;
        }
    }
    
    return result;
}