
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import EnhancedTable from '../../_components/EnhancedTable';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';

import { EditObject } from './EditObject';



const styles = theme => ({
    selectBtn: {
        padding: "0.2rem 0.5rem",
        fontSize: "0.7rem",
        backgroundColor: "#e0e0e0",
        borderRadius: "15px",
        marginLeft: "1rem",
        color: "rgba(0, 0, 0, 0.87)"
    }
});

class ChoiceList extends React.Component {

    constructor(props) {
        super(props)
        this.model = this.props.model;
        this.columns = this.model.list.fields.map(field => { return {id: field.name, numeric: false, disablePadding: true, label: field.name }});
        this.newObjects = {};
        this.state = { opened: false }
    }

    componentDidMount() {
    }

    insertObject = (item, editing) => {
        const obj = { value: item, editing: editing };
        this.itemsById[item.id] = obj;
    }

    handleSelect = (_items) => {
        
        if (this.props.handleSelect) {
            //const objectItems = _items.map(itemId => this.itemsById[itemId].value);
            //objectItems.push({id:'test', vin: 'ok'});
            
            this.props.handleSelect(this.props.items.filter( item => _items.find ( itemId => item.id === itemId )));
        }
    }

    handleChangeItemEdition = item => {
        
    }

    handleAddNewItem = model => e => {
        
        if (this.props.handleSelect) {
            const objectItems = this.props.selectedItems ? this.props.selectedItems : [];
            let obj = {};
            if (model.createObject) {
                obj = model.createObject(obj, this.props.user, this.props.context);
            }
            //this.insertObject(obj, true);
            
            objectItems.push(obj);
            
            this.props.handleSelect(objectItems);
        }
    }

    handleChangeSubObject = (object, obj) => {
        
        this.handleSelect(this.props.selectedItems.map( item => item.id ));
    }

    handleValidateSelect = () => {
        this.setState({opened: false});
    }

    handleCancelSelect = () => {
        this.setState({opened: false});
    }

    render() {
        const { t, items, selectedItems, classes, icon, title } = this.props;
        const { opened } = this.state;

        const list = this.model.list;

        let content;
        if (this.props.canCreate && this.props.embedded) {
            let edition = this.props.selectedItems ? this.props.selectedItems.map( (item, index) => {
                return (
                    <div key={'choicelist_edit_sub_' + index}>
                        {(index > 0) && <Divider/>}
                    <EditObject
                        key={'choicelist-edit-item_' + index}
                        embedded={true}
                        embeddedErrors={this.props.embeddedErrors && this.props.embeddedErrors[index] && this.props.embeddedErrors[index].error}
                        model={this.props.model}
                        classes={classes}
                        fields={this.props.model.creation.fields}
                        object={item}
                        handleChange={ object => {
                            this.props.handleSelect(this.props.selectedItems);
                        }}
                    />
                    </div>
                );
            }) : (null);
            

            
            content = (
                <div>
                    <div className="list-field-header">
                    <div className="list-field-title">
                    {icon}
                    {title}
                    </div>
                    <Chip
                        deleteIcon={<AddIcon/>}
                        label={t('action.add.new')}
                        clickable
                        onClick={this.handleAddNewItem(this.model)}
                        onDelete={this.handleAddNewItem(this.model)}
                    />
                    </div>
                    {edition}
                </div>
                
            );
        } else {

            const _selectedItems = selectedItems ? selectedItems.filter( obj => obj).map(obj => obj.id) : null;
            content = (
                <div>
                    <div className="list-field-header">
                    <div className="list-field-title">
                    {icon}
                    {title}
                    <Button onClick={() => {this.setState({opened : true})}} color="primary" classes={{
									root: classes.selectBtn
								}}>
              {t('action.select')}
            </Button>
                    </div>
                    </div>
                    {
                    <div>{selectedItems ? selectedItems.map( item => item.name).join(',') : t('none')}</div>}
                {
                    items &&
                    <Dialog
          fullWidth={true}
          maxWidth={'lg'}
          open={opened}
          aria-labelledby="max-width-dialog-title"
        >
          {<DialogTitle id="max-width-dialog-title">{t('choice')}</DialogTitle>}
          <DialogContent>
          <EnhancedTable
                        model={this.model}
                        columns={this.columns}
                        rows={items}
                        tableTitle={list.title || this.model.name}
                        selectedItems={_selectedItems}
                        handleSelect={this.handleSelect}
                    />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelSelect} color="primary">
              {t('action.cancel')}
            </Button>
            <Button onClick={this.handleValidateSelect} color="primary" variant="contained">
              {t('action.validate')}
            </Button>
          </DialogActions>
        </Dialog>
                }
                </div>
            );
        }

        return content;
    }
}

const connectedChoiceListPage = withStyles(styles, { withTheme: true })(translate('translations')(ChoiceList));
export { connectedChoiceListPage as ChoiceList };