import React, { Component } from 'react';
import { translate } from 'react-i18next';
import FlagIconFactory from 'react-flag-icon-css'

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { TextInput } from "./TextInput"
import * as phoneHelper from '../../_helpers';

const FlagIcon = FlagIconFactory(React, { useCssModules: false })

const styles = theme => ({
    container: {
       display: "inline-flex"
    },
	indicatif: {
		marginLeft: "10px",
		fontSize: "14px",
		color: "grey"
	},
	countryName: {
		marginLeft: "5px",
	},
	countrySelect: {
		paddingLeft: "0.5rem",
    marginTop: "15px"
	},
	countrySelectIcon: {
    top: "calc(50% - 5px)"
	}
});

class PhoneInput extends Component {
		
	state = {
		countryCode: "fr",
		number_no_indic: "",
		number: "",
		selectBoxOpen: false
	};
		
	constructor(props) {
		super(props);

		if(this.props.value) {
			
			var formatted = phoneHelper.formatPhone(this.props.value);
			
			this.state.number = formatted.number;
			this.state.number_no_indic = formatted.number_no_indic;
			this.state.countryCode = formatted.countryCode.toLowerCase();
		}
	}
		
    handleCountryChange = event => {
		
		if(this.state.countryCode != event.target.value) {
			
			const currObjValue = {
				
				number: this.state.number,
				
				countryCode: (
					event.target.value ?
					event.target.value.toUpperCase() :
					""
				)
			};
			
			this.updateAfterChange(currObjValue);
		}
    };
	
	handlePhoneChange = event => {
			
		const currObjValue = {
			
			number: event.target.value,
			
			number_no_indic: event.target.value,
			
			countryCode: (
				this.state.countryCode ?
				this.state.countryCode.toUpperCase() :
				""
			)
		};
		
		this.updateAfterChange(currObjValue);
    }
	
	updateAfterChange(currObjValue) {
	
		const targetObjValue = phoneHelper.isValidPhone(currObjValue) ? phoneHelper.formatPhone(currObjValue) : currObjValue;

		const targetState = {
			
			number: targetObjValue.number,
			
			number_no_indic: targetObjValue.number_no_indic, 
			
			countryCode: (
				targetObjValue.countryCode ? 
				targetObjValue.countryCode.toLowerCase() :
				""
			)
		};
		
		this.setState(targetState);
		
		return this.props.onChange({
			number: targetObjValue.number,
			countryCode: targetObjValue.countryCode
		});
	}
	
	handleCountryBoxOpen = () => {
		this.setState({selectBoxOpen: true});
	}
	
	handleCountryBoxClose = () => {
		this.setState({selectBoxOpen: false});
	}
	
	render() {
		
		const { t, classes, key, id, label, value, fieldError, onChange, readonly } = this.props;
		
		const countryPicker = <Select 
								value={this.state.countryCode} 
								onChange={this.handleCountryChange}
								onOpen={this.handleCountryBoxOpen}
								onClose={this.handleCountryBoxClose}
								open={this.state.selectBoxOpen} 
								className="form-edit-field"
								classes={{
									select: classes.countrySelect,
									icon: classes.countrySelectIcon
								} }>
								{
								  phoneHelper.getCodes().map((countryAlpha) => {
									
									const countryIndicatif = phoneHelper.getCountryIndicatif(countryAlpha);
									const isSelected=(this.state.countryCode==countryAlpha.toLowerCase());
									
									return <MenuItem key={countryAlpha.toLowerCase()} value={countryAlpha.toLowerCase()}>
										    <span className={"flag-icon flag-icon-"+countryAlpha.toLowerCase()}></span>
											{(!isSelected||this.state.selectBoxOpen) && <span className={classes.countryName}>{phoneHelper.getCountryName(countryAlpha)}</span>}
											{countryIndicatif && <span className={classes.indicatif}>{countryIndicatif}</span>}
										   </MenuItem>
								  })
								}
							  </Select>
		
		const phoneInput = 	<TextInput 
								t={t}
								key={key}
								id={id}
								label={label}
								value={this.state.number_no_indic}
								fieldError={fieldError}
								onChange={this.handlePhoneChange}
								onBlur={this.handlePhoneChange}
                readonly={readonly}	
							/>
										
	return <div className="multi-input-field" >
				{countryPicker}
				{phoneInput}
		   </div>
	}
}

const connectedPhoneInput = (withStyles(styles, { withTheme: true })(translate('translations')(PhoneInput)));
export { connectedPhoneInput as PhoneInput };