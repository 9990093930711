import React, { Component } from 'react';

import { ReadObject, CustomReadObject } from '../../_pages/common/ReadObject';

export default class FieldViewStructure extends Component {

    componentWillMount() {
        if (this.props.objectModel && this.props.objectModel.viewEmbedded && this.props.objectModel.viewEmbedded.component) {
            this.componentViewEmbedded = this.props.objectModel.viewEmbedded.component;
        }
    }

    render() {
        const { t, label, value, fields, parts, objectModel, dispatch } = this.props;
            
            return <div>
                        <div className="list-field-header">
                            <div className="list-field-title">
                            {objectModel.icon}
                            {label}
                            </div>
                            </div>
                            <div>
                            {this.componentViewEmbedded ?
                            <CustomReadObject
                                tag={this.componentViewEmbedded}
                                dispatch={dispatch}
                                fields={fields}
                                object={value}
                                model={objectModel}
                                /> :  
                            <ReadObject
                                dispatch={dispatch}
                                fields={fields}
                                parts={parts}
                                object={value}
                                model={objectModel}
                            />}
                        </div>
                </div>
    }
}
