import { endpoints } from '../_helpers';
import { u2f } from 'u2f-api-polyfill';

export const u2fService = {
    register,
    sign
};


function register(registrationRequest) {
    return new Promise(function(resolve, reject) {
    window.u2f.register(registrationRequest.appId, [registrationRequest], [], (res) => {
        if (res.errorCode) {
            reject('U2F Error');
        } else {
            resolve(res);
        } 

    });
    });
}

function sign(authRequestArray) {
    return doSign(authRequestArray, 0)
}

function doSign(authRequestArray, index) {
    const authRequest = authRequestArray[index];
    return new Promise(function(resolve, reject) {
        window.u2f.sign(authRequest.appId, authRequest.challenge, authRequestArray, (res) => {
            if (!res.errorCode) {
                resolve(res);
            } else {
                reject();
            }/*else if ((index + 1) < authRequestArray.length && res.errorCode !== 5) { // errorCode === 5 => timeout
                return doSign(authRequestArray, index + 1);
            } */
        });
    });
}