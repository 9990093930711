import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TextInput } from './TextInput';

import { userActions } from '../../_actions';

class TOTPRegistration extends Component {
    

    componentDidMount() {
        this.props.dispatch(userActions.requestTOTPForUser());
    }

    render() {
    
       const { t, id, label, value, fieldError, readonly, onChange, authentication } = this.props;

       return (
           <div className="form-edit-field">
            {authentication.totpRequest &&
                <div>
                <div>
                    Scannez ce QRCode avec votre application mobile : </div>
                    <img src={authentication.totpRequest.qrcode}/>
                    <div>ou entrez votre clé manuellement : {authentication.totpRequest.secret}
                </div>
                </div>
            }
            <TextInput t={t}
                id={id}
                label={label}
                value={value}
                fieldError={fieldError}
                onChange={onChange}
                readonly={readonly}
            />
            </div>
       );
    }
}

function mapStateToProps(state) {
    const { itemId, data, authentication } = state;
    
    return {
        itemId,
        data,
        authentication
    };
}

const connectedTOTPRegistration = connect(mapStateToProps)(TOTPRegistration);
export { connectedTOTPRegistration as TOTPRegistration };