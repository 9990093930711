import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';

import AccountCircle from '@material-ui/icons/AccountCircle';

import { history } from '../../_helpers';

import Breadcrumb from '../../_components/Breadcrumb';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem'
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      partTitle: {
          marginTop: '2rem',
          marginBottom: '2rem',
      }
});

class Profil extends React.Component {

    constructor(props) {
        super(props)
        this.state = {expanded: false};
    }

    componentWillMount() {
        const { t, user, data } = this.props;
        const breadcrumb = [];
        breadcrumb.push({icon: (<AccountCircle/>), name: 'Profil de ' + user.user.firstName +' '+user.user.lastName})
        this.breadcrumb = breadcrumb;
    }

    componentWillUnmount() {
        
    }

    handleGoTo = url => {
        history.push(url);
    }
    
    render() {
        const { t, user, data, classes } = this.props;

        const breadcrumb = this.breadcrumb;

        return (
            <div>
                <Breadcrumb elements={breadcrumb}/>

                <Grid container spacing={16} >
                <Grid className={classes.partTitle} key={'access-title'} item md={12}>
                    <Typography gutterBottom variant="h4" component="h2">
                         Gérer mon accès
                    </Typography>
                </Grid>

                        <Grid key={'card-u2f'} item md={4}>
                                <Card>
                                <CardActionArea onClick={() => this.handleGoTo('/profil/u2f')}>
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        U2F
                                    </Typography>
                                    <Typography component="p">
                                        Gérer vos clés U2F
                                    </Typography>
                                    </CardContent>
                                    </CardActionArea>
                                    <CardActions>
        
                                    </CardActions>
                                </Card>
                        </Grid>


                        <Grid key={'card-password'} item md={4}>
                                <Card>
                                <CardActionArea onClick={() => this.handleGoTo('/profil/totp')}>
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Application mobile
                                    </Typography>
                                    <Typography component="p">
                                        Gérer votre accès Google authenticator
                                    </Typography>
                                    </CardContent>
                                    </CardActionArea>
                                    <CardActions>
        
                                    </CardActions>
                                </Card>
                        </Grid>


                </Grid>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data, authentication } = state;
    
    const { user, context} = authentication;

    return {
        data,
        user,
        context
    };
}

const connectedProfil = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Profil)));
export { connectedProfil as Profil };