import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import AssessmentIcon from '@material-ui/icons/Assessment';

import { commonActions, kpiActions } from '../../../_actions';
import Breadcrumb from '../../../_components/Breadcrumb';

import { ObjectsMap } from '../../../_config';

import { RefillsKpiView } from './RefillsKpi';

const styles = theme => ({
    gridCell: {
        alignSelf: 'flex-start'
    },
    formControl: {
        marginRight: '1rem',
        minWidth: '250px'
    }
});

function TabContainer(props) {
    return (
      <Typography component="div" style={{ padding: 8 * 3 }}>
        {props.children}
      </Typography>
    );
}

class StationKpiPage extends React.Component {

    constructor(props) {
        super(props)
        this.model = ObjectsMap['station'];
        this.stationId = this.props.match.params.stationId;
    }

    componentDidMount() {
        this.props.dispatch(commonActions.readAndSetContext('main', this.stationId, this.model, this.props.user, this.props.context));
    }

    loadKpi = (periodeType, start, end) => {
        this.props.dispatch(kpiActions.stationRefills(this.stationId, periodeType, start, end));
    }

    render() {

        const { t, classes, data, user, context, appcontext, kpi } = this.props;

        this.breadcrumb = [];

        if (data && data.main && data.main.item) {
            this.breadcrumb.push({icon: this.model.icon, name: data.main.item.name, url: ('/read/' + this.model.name +'/' + this.stationId)});
            this.breadcrumb.push({icon: <AssessmentIcon/>, name: 'KPI'});
        }

        return <div>
            <Breadcrumb menu={'station'} elements={this.breadcrumb}/>
            <div className="pageContent">

                <RefillsKpiView user={user} kpi={kpi} loadKpi={this.loadKpi}/>

            </div>
        </div>;
    }

}


function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext, kpi } = state;
    
    const { user, context} = authentication;

    return {
        itemId,
        data,
        user,
        context,
        appcontext,
        kpi
    };
}

const connectedStationKpiPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(StationKpiPage)));
export { connectedStationKpiPage as StationKpiPage };