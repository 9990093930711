import React, { PureComponent } from 'react';
 
export class PushPinIcon extends PureComponent {
    
    render() {
       return <svg {...this.props} viewBox="0 0 340 340" version="1.1" className="svg-icon" title={this.props.title} aria-hidden="true" role="presentation" focusable="false">
             <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="pushpin" fill="#000000" fill-rule="nonzero">
                    <path d="M2.69,320.439 C-1.078,324.744 -0.863,331.235 3.184,335.281 L4.719,336.817 C8.766,340.863 15.256,341.079 19.561,337.31 L124.938,245.111 L94.889,215.062 L2.69,320.439 Z" id="Path"></path>
                    <path d="M339.481,119.739 C339.122,118.621 330.212,91.866 289.171,50.827 C248.133,9.788 221.377,0.878 220.262,0.52 C216.383,-0.724 212.135,0.303 209.254,3.184 L168.291,44.147 C164.049,48.39 164.049,55.272 168.291,59.516 L172.824,64.05 L65.086,147.171 C62.613,149.08 61.08,151.961 60.879,155.079 C60.68,158.197 61.832,161.251 64.041,163.46 L105.266,204.686 L135.317,234.737 L176.542,275.963 C178.753,278.172 181.808,279.324 184.923,279.124 C188.042,278.923 190.923,277.392 192.833,274.917 L275.952,167.179 L280.487,171.712 C284.726,175.956 291.61,175.956 295.854,171.712 L336.817,130.75 C339.698,127.866 340.726,123.618 339.481,119.739 Z M187.751,109.478 L121.212,165.988 C116.866,169.679 110.462,169.36 106.499,165.399 C106.29,165.19 106.087,164.97 105.892,164.74 C102.009,160.166 102.568,153.306 107.142,149.422 L173.679,92.913 C178.253,89.027 185.107,89.58 188.997,94.162 C192.882,98.735 192.322,105.595 187.751,109.478 Z" id="Shape"></path>
                </g>
            </g>
            </svg>
    }
}