import React from 'react';
import { translate } from 'react-i18next';

import { Line } from 'react-chartjs-2';

import ChartOptions from './ChartOptions';

export default class LineChartView extends React.PureComponent {

    render() {

        const { data, options } = this.props;

        return <Line data={data} options={options ? options : ChartOptions.LINE} />
    }

}

const translated = translate('translations')(LineChartView);
export { translated as LineChartView };