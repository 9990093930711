import { userConstants } from '../_constants';

function computeProfils(state) {

  const profils = state.user.user.profils;
  
  if (profils) {
    state.user.isServiceProvider = profils.indexOf('SERVICE_MANAGER') > -1 ? true : false;
    state.user.isStationOperator = profils.indexOf('STATION_OPERATOR') > -1 ? true : false;
    state.user.isFleetManager = profils.indexOf('FLEET_MANAGER') > -1 ? true : false;
  }
  
  return state;
}


let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? computeProfils({ loggedIn: true, user }) : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        step: 'login',
        loggingIn: true
      };
    case userConstants.LOGIN_TOTP_REQUEST:
    return {...state,
      ...{
        step: 'totp',
        totpVerifying: false,
        u2fWaiting: false,
        user: action.loginResult
      }
    };
    case userConstants.LOGIN_SUCCESS:

        const loginSuccessNewState = {
          step: 'logged',
          loggedIn: action.loginResult.loggedIn,
          //u2f: action.loginResult.challenges,
          user: action.loginResult
        };
  
        computeProfils(loginSuccessNewState)
        
        return loginSuccessNewState;
    case userConstants.LOGIN_REQUIRE_TWO_AUTH:
    return {
      step: 'choosetwoauth',
      twoauth: action.loginResult.twoauth,
      u2f: action.loginResult.challenges,
      user: action.loginResult
    };
    case userConstants.LOGIN_FAILURE:
      return {step: 'login', error: action.error};
    case userConstants.LOGOUT:
      return {prefillEmail: state.prefillEmail, step: 'login'};
    case userConstants.CHECK_ACCESS_TOKEN_REQUEST:
    case userConstants.CHECK_RESET_PWD_TOKEN_REQUEST:
      return {
        checkingToken: true
      };
    case userConstants.CHECK_ACCESS_TOKEN_SUCCESS:
      return {
        hasCheckedToken: true,
        email: action.email,
        mustSetPassword: action.mustSetPassword,
        mustSetU2F: action.mustSetU2F
      };
      case userConstants.CHECK_RESET_PWD_TOKEN_SUCCESS:
      return {
        hasCheckedToken: true,
        email: action.email
      };
    case userConstants.CHECK_RESET_PWD_TOKEN_FAILURE:
    case userConstants.CHECK_ACCESS_TOKEN_FAILURE:
      return {
        checkingTokenError: true
      };
      case userConstants.LOGIN_U2F_REQUEST:
      return {
        step: 'u2f',
        loggingIn: true,
        u2fWaiting: true,
        twoauth: action.loginResult.twoauth,
        user: action.loginResult
      };
    case userConstants.LOGIN_U2F_SUCCESS:

      const u2fLoginSuccessNewState = {
        step: 'logged',
        loggedIn: action.signResult.loggedIn,
        user: action.signResult
      };

      return computeProfils(u2fLoginSuccessNewState);
    case userConstants.LOGIN_U2F_FAILURE:
      if (state.step === 'u2f') {
        return {
          step: 'u2f',
          u2ferror: true,
          twoauth: state.twoauth,
          user: state.user
        };
      } else {
        return state;
      }
    case userConstants.LOGIN_TOTP_VERIFY_REQUEST:
      return {...state, ...{
        step: 'totp',
        loggingIn: true,
        totpVerifying: true
      }};
    case userConstants.LOGIN_TOTP_VERIFY_SUCCESS:
      const totpSuccessNewState = {
        step: 'logged',
        loggedIn: action.verifyResult.loggedIn,
        user: action.verifyResult
      };

      return computeProfils(totpSuccessNewState);
    case userConstants.LOGIN_TOTP_VERIFY_FAILURE:
      return {...state, ...{
        step: 'totp',
        totpVerifying: false,
        totperror: true
      }};
    case userConstants.U2F_GETREQUEST_REQUEST:
    if (action.userMode) {
      return {...state, ...{
        u2frequest: null,
        waitingU2FRequest: true
      }}
    }
    return {
      waitingU2FRequest: true,
      hasCheckedToken: true,
        email: state.email,
        mustSetPassword: state.mustSetPassword,
        mustSetU2F: state.mustSetU2F
    };
    case userConstants.U2F_GETREQUEST_SUCCESS:
    if (action.userMode) {
      return {...state, ...{
        u2frequest: action.u2frequest,
        waitingU2FRequest: false
      }}
    }
    return {
      u2frequest: action.u2frequest,
      hasCheckedToken: true,
      email: state.email,
      mustSetPassword: state.mustSetPassword,
      mustSetU2F: state.mustSetU2F
    };
    case userConstants.U2F_RESP_TO_REQUEST_FAILURE:
      if (action.userMode) {
        return {...state, ...{
          u2frequest: null,
          u2fresponseError: true
        }}
      }
      return state;
    case userConstants.U2F_RESP_TO_REQUEST_SUCCESS:
      if (action.userMode) {
        return {...state, ...{
          u2frequest: null,
          u2fregistrationResult: action.registrationResult
        }}
      }
    return {
      u2frequest: action.u2frequest,
      u2fregistrationResult: action.registrationResult,
      hasCheckedToken: true,
      email: state.email,
      mustSetPassword: state.mustSetPassword,
      mustSetU2F: state.mustSetU2F,
      saveU2FResponse: true
    };
    case userConstants.U2F_REGISTER_SUCCESS:
    return {
      hasCheckedToken: true,
      email: state.email,
      accountReady: true
    };
    case userConstants.GOTO_LOGIN_REQUEST:
    return {
      prefillEmail: action.email
    };
    case userConstants.LOAD_USERCONTEXT_REQUEST:
      return {
        user: state.user,
        loadingContext: true
      };
    case userConstants.LOAD_USERCONTEXT_SUCCESS:
      return {
        user: state.user,
        context: action.userContext
      };
    case userConstants.LOAD_USERCONTEXT_FAILURE:
      return {
        user: state.user,
        contextError: true
      };
    case userConstants.VALIDE_ACCESS_SETPASSWORD_REQUEST:
      return {...state, ...{validatingAccount: true}}
    case userConstants.VALIDE_ACCESS_SETPASSWORD_FAILURE:
      return {...state, ...{validatingAccount: false, validatingError: true}}
    case userConstants.VALIDE_ACCESS_SETPASSWORD_SUCCESS:
      return {
        email: state.email,
        accountReady: true,
        hasCheckedToken: true,
        mustSetU2F: false,
        validatingError: false
      };
    case userConstants.TOTP_GETREQUEST_REQUEST: {
      return {...state, ...{waitingTOTPRequest: true, totpRequest: null, totpRequestError: false}}
    }
    case userConstants.TOTP_GETREQUEST_SUCCESS: {
      return {...state, ...{waitingTOTPRequest: false, totpRequest: action.totprequest, totpRequestError: false}}
    }
    case userConstants.TOTP_GETREQUEST_FAILURE: {
      return {...state, ...{waitingTOTPRequest: false, totpRequest: null, totpRequestError: true}}
    }
    case userConstants.TOTP_GETREQUEST_REQUEST: {
      return {...state, ...{waitingTOTPRequest: true, totpRequest: null, totpRequestError: false}}
    }
    case userConstants.TOTP_GETREQUEST_SUCCESS: {
      return {...state, ...{waitingTOTPRequest: false, totpRequest: action.totprequest, totpRequestError: false}}
    }
    case userConstants.PASSWORD_FORGOTTEN_REQUEST: {
      return {...state, ...{success: false, submitIn: true, error: false}}
    }
    case userConstants.PASSWORD_FORGOTTEN_SUCCESS: {
      return {...state, ...{success: true, submitIn: false, error: false}}
    } 
    case userConstants.PASSWORD_FORGOTTEN_FAILURE: {
      return {...state, ...{success: false, submitIn: false, error: true}}
    }
    case userConstants.RESET_PWD_TOKEN_REQUEST:
      return {...state, ...{resetingPassword: true}}
    case userConstants.RESET_PWD_TOKEN_FAILURE:
      return {...state, ...{resetingPassword: false, resetingPasswordError: true}}
    case userConstants.RESET_PWD_TOKEN_SUCCESS:
      return {
        resetingPasswordSuccess: true
      };
    default:
      return state
  }
}