import React, { Component } from 'react';
import { translate } from 'react-i18next';

import { StateColorHelper } from '../../_helpers';
import { EnergyTypeView } from '../read/EnergyType';

import {NozzleIcon} from '../icons/nozzle';

class ReceptacleView extends Component {

    render() {
     const { t, object, hideLegende } = this.props;
     
     if (object) {
         const r = object;

        return <div className={'receptacle-bloc'+(r.nozzlePosition > 0 ? ' receptacle-bloc-nozzle-up' : '')}><EnergyTypeView value={{name: r.energy}} state={r.state}/><div className={'nozzle-icon'}><NozzleIcon/></div></div>
     }
     return null;
    }
    
}

const connectedReceptacleView = translate('translations')(ReceptacleView);
export { connectedReceptacleView as ReceptacleView };