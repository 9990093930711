import React from 'react';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import { KpiTools } from '../KpiTools';

import { NoDataView } from '../common/NoData';
import GridRowView from '../common/GridRow'
import SubPartView from '../common/SubPart'

import { EnergyTypeView } from '../../read/EnergyType';
import { DurationReadView } from '../../read/Duration';
import ProgressionPercentView from '../common/ProgressionPercent';

class EnergySummaryView extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        const { t, mode, loading, data, previousData, parts } = this.props;
        
        if (loading) {
            return this.renderLoading(t, parts)
        }

        if (data && data.byEnergyType && data.byEnergyType.length > 0) {
            return this.renderKpiBloc( t, mode, data.byEnergyType, previousData.byEnergyType, parts )
        }

        return <NoDataView mode={mode}/>
    }

    renderLoading = ( t, parts ) => {

        const rowElements = parts.map( part => <div style={{paddingRight: 20}}><Skeleton/></div>)

        const lines = [0, 1, 2].map( () => {
            return <GridRowView cells={rowElements}/>
        })

        return this.__renderEnergyLines(t, parts, lines)
    }

    renderKpiBloc = ( t, mode, rawData, previousRawData, parts ) => {
        
        const energies = {
            '350': null,
            '350HF': null,
            '700': null,
        }

        const previousByEnergyKpis = {}

        const byEnergyTypeKpis = rawData.filter( kpi => {
            if (kpi._id.energyType) {
                previousByEnergyKpis[kpi._id.energyType.name] = {quantity: 0, quantityAvg: 0, count: 0, vehicleCommunication: 0, vehicleCommunicationLost: 0, failedToStart: 0, endError: 0}
            }
            return kpi._id.energyType
        } )
     

        if (previousRawData) {

            previousRawData.forEach( byEnergyType => {
                if (byEnergyType._id.energyType) {
                    let previousByEnergyKpi = previousByEnergyKpis[byEnergyType._id.energyType.name]
                    if (!previousByEnergyKpi) {
                        previousByEnergyKpi = {quantity: 0, quantityAvg: 0}
                        previousByEnergyKpis[byEnergyType._id.energyType.name] = previousByEnergyKpi
                    }

                    previousByEnergyKpi.quantity = byEnergyType.quantity
                    previousByEnergyKpi.quantityAvg = byEnergyType.quantityAvg
                    previousByEnergyKpi.count = byEnergyType.count
                }
            })

        }

        const lines = byEnergyTypeKpis.map( byEnergyType => {

            const quantity = KpiTools.formatQuantity(byEnergyType.quantity)
            const quantityAvg = KpiTools.formatQuantity(byEnergyType.quantityAvg)
            const duration = byEnergyType.durationAvg
            const durationDev = byEnergyType.durationDev
            
            const previousByEnergyType = previousByEnergyKpis[byEnergyType._id.energyType.name]
            const previousQuantity = KpiTools.formatQuantity(previousByEnergyType.quantity)
            const previousQuantityAvg = KpiTools.formatQuantity(previousByEnergyType.quantityAvg)
            const previousDuration = previousByEnergyType.durationAvg
            const previousDurationDev = previousByEnergyType.durationDev

            const rowElements = []

            rowElements.push(<div>
                <EnergyTypeView value={byEnergyType._id.energyType}/>
            </div>)

                if (parts.indexOf('count') > -1) {
                        rowElements.push(<div>
                    <span className={'kpi-value kpi-value-second'}>{byEnergyType.count}</span>
                    {mode === 2 && <ProgressionPercentView currentValue={byEnergyType.count} previousValue={previousByEnergyType.count}/>}
                    </div>)
                }

                if (parts.indexOf('quantity') > -1) {
                    rowElements.push(<div>
                        <span className={'kpi-value kpi-value-second'}>{quantity} Kg</span>
                        {mode === 2 && <ProgressionPercentView currentValue={quantity} previousValue={previousQuantity}/>}
                        </div>)
                }

                if (parts.indexOf('average') > -1) {
                    rowElements.push(<div>
                        <span className={'kpi-value kpi-value-second'}>{quantityAvg} Kg</span>
                        {mode === 2 && <ProgressionPercentView currentValue={quantityAvg} previousValue={previousQuantityAvg}/>}
                        </div>)
                }
                
                if (parts.indexOf('duration.avg') > -1) {
                    rowElements.push(<div>
                        <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={duration}/></span>
                        {mode === 2 && <ProgressionPercentView currentValue={duration} previousValue={previousDuration}/>}
                        </div>)
                }

                if (parts.indexOf('duration.ecart') > -1) {
                    rowElements.push(<div>
                        <span className={'kpi-value kpi-value-second'}><DurationReadView t={t} value={durationDev}/></span>
                        {mode === 2 && <ProgressionPercentView currentValue={durationDev} previousValue={previousDurationDev}/>}
                        </div>)
                }

                if (parts.indexOf('vehicleCommunication') > -1) {
                    const comPercent = Math.round(byEnergyType.vehicleCommunication / byEnergyType.count * 100)
                    const previousComPercent = previousByEnergyType.count > 0 ? Math.round(previousByEnergyType.vehicleCommunication / previousByEnergyType.count * 100) : 0
                    rowElements.push(<div>
                <span className={'kpi-value kpi-value-second'}>{comPercent}%</span>
                {mode === 2 && <ProgressionPercentView currentValue={comPercent} previousValue={previousComPercent}/>}
                </div>)
                }

                if (parts.indexOf('vehicleCommunicationLost') > -1) {
                    rowElements.push(<div>
                <span className={'kpi-value kpi-value-second'}>{byEnergyType.vehicleCommunicationLost}</span>
                {mode === 2 && <ProgressionPercentView currentValue={byEnergyType.vehicleCommunicationLost} previousValue={previousByEnergyType.vehicleCommunicationLost}/>}
                </div>)
                }

                if (parts.indexOf('failedToStart') > -1) {
                    rowElements.push(<div>
                <span className={'kpi-value kpi-value-second'}>{byEnergyType.failedToStart}</span>
                {mode === 2 && <ProgressionPercentView currentValue={byEnergyType.failedToStart} previousValue={previousByEnergyType.failedToStart}/>}
                </div>)
                }

                if (parts.indexOf('endError') > -1) {
                    rowElements.push(<div>
                <span className={'kpi-value kpi-value-second'}>{byEnergyType.endError}</span>
                {mode === 2 && <ProgressionPercentView currentValue={byEnergyType.endError} previousValue={previousByEnergyType.endError}/>}
                </div>)
                }

            return <GridRowView cells={rowElements}/>

        })

        return this.__renderEnergyLines(t, parts, lines)
    }

    __renderEnergyLines = (t, titles, lines, justifyContent) => {

        const rowHeader = []

        titles.forEach( fieldTitle => {
            rowHeader.push(<div className={'kpi-view-subtitle'}>{t(fieldTitle)}</div>)
        })

        const content = <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center" >
                                <GridRowView cells={rowHeader} justify={justifyContent} fullSize={1}/>
                                <GridRowView cells={lines} justify={justifyContent} fullSize={1}/>
                        </Grid>

        return <SubPartView content={content}/>

    }

}

const connectedBloc = translate('translations')(EnergySummaryView);
export { connectedBloc as EnergySummaryView };