import React, { Component } from 'react';

import EnhancedTable from '../EnhancedTable';

export default class FieldView_List extends Component {

    componentWillMount() {
        this.columns = this.props.fields.map(field => { return {id: field.name, numeric: false, disablePadding: true, label: field.displayName ? field.displayName : field.name }});
        this.expandedView = this.props.objectModel.list ? this.props.objectModel.list.expandedView : null;
    }

    render() {
        const { label, items, objectModel, rights, handleBuildReadChildURL, handleAddChild, handleBuildEditChildURL } = this.props;
        
        if (rights.list && (rights.create || items.length > 0)) {

            return <div>
                <div className="list-field-header">
                    <div className="list-field-title">
                    {objectModel.icon}
                    {label}
                    </div>
                </div>
                <EnhancedTable
                    model={objectModel}
                    columns={this.columns}
                    rows={items}
                    tableTitle={label}
                    addAction={rights.create ? handleAddChild : null}
                    onBuildReadURL={rights.read && handleBuildReadChildURL ? (child) => {return handleBuildReadChildURL(child)} : null}
                    editAction={rights.update && handleBuildEditChildURL ? (child) => {return handleBuildEditChildURL(child)} : null}
                    ExpandedView={this.expandedView}
                />
            </div>
        }

        return null;
    }
}
