import React from 'react';
import { translate } from 'react-i18next';

import { KpiTools } from '../KpiTools';

import ChartOptions from '../common/ChartOptions';
import { NoDataView } from '../common/NoData';
import DoughnutChartView from '../common/DoughnutChart';
import PieChartLoadingView from '../common/BarChartLoading';

const BACKGROUND_COLORS = ['#f4f5f5', '#d7ecfb']
const BORDER_COLORS = ['#e4e5e7', '#87c7f3']

const COLORS = BACKGROUND_COLORS.map( (value, index) => {
    return {backgroundColor: value, borderColor: BORDER_COLORS[index]} 
})

class VehicleModelsIdentifiedView extends React.Component {

    render() {

        const { t, mode, loading, data, previousData } = this.props;
        
        if (loading) {
            return <PieChartLoadingView/>
        }

        if (data && data.byVehicleModel && data.byVehicleModel.length > 0) {
            return this.renderKpiBloc( t, data.byVehicleModel, previousData.byVehicleModel )
        }

        return <NoDataView mode={mode}/>
    }

    renderKpiBloc = ( t, rawData, previousRawData ) => {

        let count = 0;
        let notIdentified = 0;
        let identified = 0;

        rawData.forEach( kpi => {
            count += kpi.count
            if (kpi._id.vehicleModel) {
                identified += kpi.count
            } else {
                notIdentified = kpi.count
            }
        })
        
        const notIdentifiedPercent = Math.round(notIdentified / count * 100)
        const identifiedPercent = Math.round(identified / count * 100)

        const legendLabels = [t('not.identified'), t('identified')]
        const valueLabels = [notIdentified+' ('+notIdentifiedPercent+'%)', identifiedPercent+' ('+identifiedPercent+'%)']

        const previousValues = {'notIdentified': 0, 'identified': 0}
        let previousCount = 0
        previousRawData.forEach( kpi => {
            previousCount += kpi.count
            if (kpi._id.vehicleModel) {
                previousValues.identified += kpi.count
            } else {
                previousValues.notIdentified = kpi.count
            }
        })

        const previousNotIdentifiedPercent = Math.round(previousValues.notIdentified / previousCount * 100)
        const previousIdentifiedPercent = Math.round(previousValues.identified / previousCount * 100)

        const progressionValues = [KpiTools.computeProgression(notIdentifiedPercent, previousNotIdentifiedPercent), KpiTools.computeProgression(identifiedPercent, previousIdentifiedPercent)]

        const options = KpiTools.makeCustomTooltips(legendLabels, valueLabels, COLORS, progressionValues)

        const data = {
            labels: legendLabels,
            datasets: [
            {
                label: [notIdentifiedPercent + '%', identifiedPercent + '%'],
                data: [notIdentifiedPercent, identifiedPercent],
                backgroundColor: BACKGROUND_COLORS,
                borderColor: BORDER_COLORS
            }
            ]
        };

    return <DoughnutChartView data={data} options={options}/>

    }

}

const translatedBloc = translate('translations')(VehicleModelsIdentifiedView);
export { translatedBloc as VehicleModelsIdentifiedView };