import React, { Component } from 'react';
import { connect } from 'react-redux';

class RefillStepRaw extends Component {
    
    render() {
    
       const { value } = this.props;

       let raw = null;
        try {
            raw = JSON.parse(value.raw);
        } catch(err) {
        }

        if (raw) {
            return (
                <div>
                    {raw.station && <div><div className="raw-part-title">station</div>
                        {Object.keys(raw.station).map( key => <div key={key} className="raw-part-data"><span className="bold">{key} :</span> {raw.station[key]}</div>)}
                    </div>}
                    <div><div className="raw-part-title">dispenser</div>
                    {Object.keys(raw).map( key => (key !== 'station' && (raw[key] || raw[key] === 0)) ? <div key={key} className="raw-part-data"><span className="bold">{key} :</span> {raw[key]}</div> : null)}
                    </div>
                </div>);
        } else {
            return (
                <div>
                    aucune donnée
                </div>);
        }
    }
}

function mapStateToProps(state) {
    const { data } = state;
    
    return {
        data
    };
}

const connectedRefillStepRaw = connect(mapStateToProps)(RefillStepRaw);
export { connectedRefillStepRaw as RefillStepRaw };