import React from 'react';
import { translate } from 'react-i18next';

class NoDataView extends React.PureComponent {

    render() {

        const { t, mode } = this.props;

        return <div className={'kpi-view-nodata'+(mode < 2 ? ' kpi-view-nodata-small' : '')}>{t('nodata.onperiode')}</div>
    }

}

const translated = translate('translations')(NoDataView);
export { translated as NoDataView };