import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import AssessmentIcon from '@material-ui/icons/Assessment';

import { commonActions, kpiActions } from '../../../_actions';
import Breadcrumb from '../../../_components/Breadcrumb';

import { ObjectsMap } from '../../../_config';

import { RefillsKpiView } from './RefillsKpi';

const styles = theme => ({
    gridCell: {
        alignSelf: 'flex-start'
    },
    formControl: {
        marginRight: '1rem',
        minWidth: '250px'
    }
});

class DispenserKpiPage extends React.Component {

    constructor(props) {
        super(props)
        this.model = ObjectsMap['dispenser'];
        this.dispenserId = this.props.match.params.dispenserId;
    }

    componentDidMount() {
        this.props.dispatch(commonActions.readAndSetContext('main', this.dispenserId, this.model, this.props.user, this.props.context));
    }

    loadKpi = (periodeType, start, end) => {
        this.props.dispatch(kpiActions.dispenserRefills(this.dispenserId, periodeType, start, end));
    }

    render() {

        const { t, classes, data, user, context, appcontext, kpi } = this.props;

        this.breadcrumb = [];

        if (data && data.main && data.main.item) {
            const stationModel = ObjectsMap['station'];
            this.breadcrumb.push({icon: stationModel.icon, name: data.main.item.station.name, url: ('/read/' + stationModel.name +'/' + data.main.item.station.id)});
            this.breadcrumb.push({icon: this.model.icon, name: data.main.item.name, url: ('/station/'+data.main.item.station.id+'/read/' + this.model.name +'/' + this.dispenserId)});
            this.breadcrumb.push({icon: <AssessmentIcon/>, name: 'KPI'});
        }

        return <div>
            <Breadcrumb menu={'station'} elements={this.breadcrumb}/>
            <div className="pageContent">

            <RefillsKpiView kpi={kpi} loadKpi={this.loadKpi}/>

            </div>
        </div>;
    }

}


function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext, kpi } = state;
    
    const { user, context} = authentication;

    return {
        itemId,
        data,
        user,
        context,
        appcontext,
        kpi
    };
}

const connectedDispenserKpiPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(DispenserKpiPage)));
export { connectedDispenserKpiPage as DispenserKpiPage };