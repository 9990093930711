import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import PreviewIcon from '@material-ui/icons/Pageview';

import { commonActions } from '../../_actions';
import { ItemPreviewPage } from '../../_pages/common/PreviewItem';

import FieldDisplay from './FieldDisplay';

const DELAY_DISPLAY  = 1000;

const DELAY_DISAPEAR = 100;

const styles = theme => ({
    previewShow: {
      display: "",
	  position: "absolute",
	  backgroundColor: "white",
    },
    previewHide: {
		display: "none"
    },
	mainDiv: {
	},
	contentDiv: {
		display: "inline-flex"
	},
	iconStyle: {
		color: "#042859"
	},
	linkPreview: {
		marginLeft: '0.5rem'
	}
});

class FieldPreview extends PureComponent {
	
	state = {
		showPreview: false
	}
	
	constructor(props) {
		
		super(props);
		
		const { value, scope, model } = this.props;
		
		this.itemId               = value.id;
		this.scope                = scope;
		this.model                = model;
		this.isOnIcon             = false;
		this.scheduledStateChange = null;
	}
	
	loadObject() {
		this.props.dispatch(commonActions.read(this.scope, this.itemId, this.model));
	}
	
    getItem() {
        const data = this.getData();
        return data.item;
    }

    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }
	
	doConcurrentStateChange(stateToSet, delay) {
		
		var fun = () => { this.setState({ showPreview: this.isOnIcon }); };
		
		if(this.scheduledStateChange) {
			clearTimeout(this.scheduledStateChange);
		}
		
		this.scheduledStateChange = setTimeout(fun, delay);
	}
	
	onMouseEnter = () => {
		this.isOnIcon = true;
		this.loadObject();
		this.doConcurrentStateChange({ showPreview: true }, DELAY_DISPLAY);
	}
	
	onMouseLeave = () => {
		this.isOnIcon = false;
		this.doConcurrentStateChange({ showPreview: false }, DELAY_DISAPEAR);
	}

	render() {
		
		const { classes, label, uriobject, value, scope, model, subObjectModel, fieldClass, parent, routesConfig } = this.props;
		
        let displayedValue;
		
		let popInClass = this.state.showPreview ? classes.previewShow : classes.previewHide;

		let title = value ? (subObjectModel.toString ? subObjectModel.toString(value) :value) : '';
		
		let readUrl = "";
		
		let item = this.getItem();

		if(item && routesConfig[this.model.name].parent) {
			
			readUrl += "/";
			readUrl += routesConfig[this.model.name].parent;
			readUrl += "/";
			readUrl += item[routesConfig[this.model.name].parent].id;
			readUrl += "/read/";
			readUrl += this.model.name;
			readUrl += "/";
			readUrl += item.id;

		} else {
			readUrl = "/read/"+this.model.name+"/"+value.id;
		}
		
		const popIn = (
			<Card elevation="1" className={popInClass}>
				<ItemPreviewPage 
					scope={scope+"_popin"}
					itemId={value.id}
					model={model} 
					uriobject={uriobject}
				/>
			</Card>
		)
		
		return displayedValue = (
			<div className={classes.mainDiv}>
				
				<div className={classes.contentDiv}>
				
					<div >
						<FieldDisplay 
							label={label} 
							value={title} 
							fieldClass={fieldClass}
						/>
					</div>
				
					<div className={classes.linkPreview} onMouseEnter={this.onMouseEnter}
						 onMouseLeave={this.onMouseLeave} >
						 <Link to={readUrl}>
							<PreviewIcon className={classes.iconStyle} />
						 </Link>
					</div>
				</div>
				
				<div className={classes.contentDiv}>
					{popIn}
				</div>
			</div>);
	}
}

function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext } = state;
    const { user, context } = authentication;
    return {
        itemId,
        data,
        user,
        context,
        appcontext
    };
}

const ConnectedReadObject = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(FieldPreview)));
export { ConnectedReadObject as FieldPreview };