import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { userActions } from '../../../_actions';

import '../Login.css';

const appSpecificAssetFolder = process.env.REACT_APP__SPC_ASSET_FLD ? process.env.REACT_APP__SPC_ASSET_FLD : "fillndrive";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    mainContainer: {
        textAlign: 'center',
        marginTop: '6rem'
    },
    mainTitle: {
        color: '#2B70ED'
    },
    formContainer: {
        padding: theme.spacing.unit * 2,
    },
    margin: {
        margin: theme.spacing.unit,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      appBarColorPrimary: {
        backgroundColor: 'none',
        background: 'linear-gradient(0.25turn, #273b54, #2a87fc)'
    },      
});

class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.token = this.props.match.params.token;

        this.state = {
            loginStep: 0,
            password: '',
            confirmPassword: '',
            submitted: false,
            showPassword: false,
            showConfirmPassword: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount = () => {
        if (this.token) {
            this.props.dispatch(userActions.checkResetPasswordToken(this.token));
        }
    }

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
      };

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };

    handleClickShowConfirmPassword = () => {
        this.setState(state => ({ showConfirmPassword: !state.showConfirmPassword }));
    };

    validatePasswordForm = () => {
        
        const { password, confirmPassword } = this.state;
        let valide = false;
        if (password && confirmPassword) {
            if (password !== confirmPassword) {
                this.setState({ loginFormError: 'form.error.login.not.equal.confirmpassword' });
            } else {
                this.setState({ loginFormError: null });
                valide = true;
            }
        }
        return valide;
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { password, confirmPassword } = this.state;
        const { dispatch } = this.props;
        if (this.validatePasswordForm()) {
            dispatch(userActions.resetPassword(this.token, password, confirmPassword));
        }
    }

    handleGoLogin = () => {
        const { email, dispatch } = this.props;
        dispatch(userActions.gotoLogin(email));
    }

    render() {
        const { t, checkingToken, hasCheckedToken, checkingTokenError, resetingPasswordSuccess, classes } = this.props;
        
        return <div>
                    <AppBar position="fixed" className={classes.appBar} classes={{colorPrimary: classes.appBarColorPrimary}}>
            <Toolbar>
            <img height="32px" src={require('../../../assets/img/'+appSpecificAssetFolder+'/logo-h.svg')}/>
            </Toolbar>
            </AppBar>
            <Grid container spacing={24} className={classNames(classes.mainContainer)} >
                <Grid item xs={12}>
                    <Typography variant="h5" component="h2">{t('context.manager')}</Typography>

                    <Grid container spacing={24} style={{textAlign: 'center'}}>
                    <Grid item xs={false} md={4}>
                </Grid>
                <Grid item xs={12}  md={4} className={classNames(classes.mainContainer)} >    
                
                    {checkingToken && this._renderImgLoading()}
                    {(hasCheckedToken || resetingPasswordSuccess) && this._renderValidationForm()}
                    {checkingTokenError && this._renderCheckingError()}
                    
                </Grid>
                <Grid item xs={false} md={4}>
                </Grid>
                </Grid>
                </Grid>
            </Grid>
        </div>;
    }

    _renderImgLoading = () => {
        return (<img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />);
    }

    _renderCheckingError = () => {
        const { t } = this.props;
        return (
            <div>
                <div><ErrorOutlineIcon className="color-red" style={{ fontSize: '3rem', margin: '3rem' }}/></div>
                <div><Typography variant="button" gutterBottom className="bold">{t('reset.pwd.token.validation.error')}</Typography></div>
            </div>
        );
    }
    
    _renderPasswordForm = () => {
        const { t, resetingPassword, classes } = this.props;
        const { password, confirmPassword, loginFormError, submitted } = this.state;
        
        const passwordError = (submitted && !password) || (loginFormError === 'form.error.login.not.equal.confirmpassword')
        const confirmPasswordError = (submitted && !confirmPassword) || (loginFormError === 'form.error.login.not.equal.confirmpassword')

        return (
            <div>
            <FormControl fullWidth className={classNames(classes.margin, classes.textField)} error={passwordError}>
                    <InputLabel htmlFor="adornment-password">{t('password')}</InputLabel>
                    <Input
                        id="adornment-password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        value={this.state.password}
                        onChange={this.handleChange('password')}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    {submitted && !password &&
                    <FormHelperText id="component-error-password">{t('password.error.required')}</FormHelperText>
                    }
                </FormControl>
                <br/>
                <FormControl fullWidth className={classNames(classes.margin, classes.textField)} error={confirmPasswordError}>
                    <InputLabel htmlFor="adornment-password">{t('confirmPassword')}</InputLabel>
                    <Input
                        id="adornment-confirm-password"
                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                        value={this.state.confirmPassword}
                        onChange={this.handleChange('confirmPassword')}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                            >
                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    {submitted && !confirmPassword &&
                    <FormHelperText id="component-error-password">{t('confirmPassword.error.required')}</FormHelperText>
                    }
                </FormControl>
                {loginFormError && (
                    <div className="form-error">{t(loginFormError)}</div>
                )}
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    >
                <Button variant="contained" color="primary" className={classes.margin} disabled={resetingPassword}  onClick={this.handleSubmit}>
                {t('action.validate')}
                {resetingPassword &&
                        this._renderImgLoading()
                    }
                </Button>
                </Grid>
            </div>
        );
    }

    _renderValidationForm = () => {
        const { t, email, resetingPasswordSuccess } = this.props;
        
        let formBody;
        if (resetingPasswordSuccess) {
            formBody = this._renderAccountReady();
        } else {
            formBody = this._renderPasswordForm();
        }

        return (
            <form name="form" onSubmit={this.handleSubmit} className="form-login login-container">
                <div className="form-title"><Typography><LockIcon/> {t('title.resetpassword')}</Typography></div>
                <div>
                {t('account')} {email}
                </div>
                {formBody}
            </form>
        );
    }

    _renderAccountReady = () => {

        const { t, classes } = this.props;

        return (
            <div>
                <div className="bloc-msg"><CheckCircleOutlineIcon className="success-icon"/> {t('resetpassword.validated')}</div>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    >
                    <Button variant="outlined" color="primary" className={classes.margin}  onClick={this.handleGoLogin}>
                    {t('action.gologin')}
                    </Button>
                </Grid>
            </div>
        );
    }

}

function mapStateToProps(state) {
    const { email, checkingToken, hasCheckedToken, checkingTokenError, resetingPassword, resetingPasswordSuccess } = state.authentication;
    return {
        email, checkingToken, hasCheckedToken, checkingTokenError, resetingPassword, resetingPasswordSuccess
    };
}

const connectedResetPasswordPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ResetPasswordPage)));
export { connectedResetPasswordPage as ResetPasswordPage }; 