import React from 'react';

import {ReadObject} from './ReadObject';

export default class ReadObjectStation extends ReadObject {

    componentWillMount() {
        this.fieldsByName = {};
        this.props.fields.forEach(field => {
            this.fieldsByName[field.name] = field;
        });
    }

    render() {

        const { object } = this.props;
        
        return (<div>
            {this.renderField(this.fieldsByName['dispensers'], 0)}
        </div>);
    }

}