import React from 'react';

export class VehicleComState extends React.PureComponent {
    
    render() {

        const { t, value } = this.props;

       if (value.vehicleCommunicationLost) {
            return t('com.lost');
       } else if (value.vehicleCommunication) {
            return t('yes');
       } else  {
            return t('no');
       }
    }
}