import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserView } from '../../../_components/custom/UserView'
import { DriverView } from '../../../_components/custom/DriverView'
import { AdresseView } from '../../../_components/custom/AdresseView'
import { GPSView } from '../../../_components/custom/GPSView'
import { PLCView } from '../../../_components/custom/PLCView'
import { AuthorizationView } from '../../../_components/custom/AuthorizationView'
import { WatchDogView } from '../../../_components/custom/WatchDogView'
import { ReceptacleView } from '../../../_components/custom/ReceptacleView'
import { RefillAttemptView } from '../../../_components/custom/RefillAttemptView'
import { DeviceInfos } from '../../../_components/read/DeviceInfos'

import ReadObjectStation from './ReadObjectStation';

class CustomReadObject extends Component {
    components = {
        userView: UserView,
        driverView: DriverView,
        addressView: AdresseView,
        gpsView: GPSView,
        stationView: ReadObjectStation,
        plcView: PLCView,
        authorizationView: AuthorizationView,
        watchDogView: WatchDogView,
        receptacleView: ReceptacleView,
        refillAttemptView: RefillAttemptView,
        deviceInfoView: DeviceInfos
    };
    render() {
       const TagName = this.components[this.props.tag || 'userView'];
       return <TagName {...this.props}/>
    }
}

function mapStateToProps(state) {
    const { itemId, data } = state;
    
    return {
        itemId,
        data
    };
}

const connectedCustomReadObject = connect(mapStateToProps)(CustomReadObject);
export { connectedCustomReadObject as CustomReadObject };