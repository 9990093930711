import { kpiConstants } from '../_constants';

const initialState = {};

export function kpi(state = initialState, action) {
  switch (action.type) {
    case kpiConstants.STATION_REFILLS_REQUEST:
      return {loading: true};
    case kpiConstants.STATION_REFILLS_SUCCESS:
      return {data: action.kpis};
    case kpiConstants.STATION_REFILLS_FAILURE:
      return {error: true};
    default:
      return state
  }
}