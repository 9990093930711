import React, { Component } from 'react';
/**
 * 
 * <object data={require('../../assets/img/icon-temperature.svg')} type="image/svg+xml"></object>
 * 
 * 
 * <svg width="12px" height="24px" viewBox="0 0 12 24" version="1.1">
       <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
           <g id="icon-temperature" fill="#000000" fill-rule="nonzero">
               <path d="M10,13.6 L10,4 C10,1.8 8.2,0 6,0 C3.8,0 2,1.8 2,4 L2,13.6 C0.8,14.7 0,16.3 0,18 C0,21.3 2.7,24 6,24 C9.3,24 12,21.3 12,18 C12,16.2 11.2,14.7 10,13.6 Z M6,22 C3.8,22 2,20.2 2,18 C2,16.5 2.8,15.2 4,14.6 L4,4 C4,2.9 4.9,2 6,2 C7.1,2 8,2.9 8,4 L8,14.6 C9.2,15.3 10,16.6 10,18 C10,20.2 8.2,22 6,22 Z" id="Shape"></path>
               <path d="M7,15.2 L7,10 L5,10 L5,15.2 C3.8,15.6 3,16.7 3,18 C3,19.7 4.3,21 6,21 C7.7,21 9,19.7 9,18 C9,16.7 8.2,15.6 7,15.2 Z" id="Path"></path>
           </g>
       </g></svg> 
 * 
 */
export class TemperatureView extends Component {
    
    render() {
       const { value } = this.props;
        
       return value ? <span>{((Math.round(((value / 10) - 273.15) * 100) / 100) +' °')}</span> : '';
    }
}