import React, { Component } from 'react';

export default class FieldView_ListLight extends Component {

    render() {
        const { t, label, items } = this.props;
            
            return <div className="form-read-field">
            <span className="form-read-field-label">{label} : </span>
                {items.map( (item, itemIndex) => {
                    let itemValue = '';
                    if (itemIndex > 0) {
                        itemValue += ', ';
                    }
                    itemValue += t(item.name);
                    return (<span key={'listlight_'+itemIndex}>{itemValue}</span>);
                })}
            </div>
            ;
    }
}
