import React from 'react';
import { Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '../_i18n/i18n';
import { translate } from 'react-i18next';

import MenuIcon from '@material-ui/icons/Menu';
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CategoryIcon from '@material-ui/icons/Category';

import { HomeServiceProvider, HomeFleetManager } from '../_pages/home';
import { Profil, ProfilU2F, ProfilU2FEdition, ProfilU2FCreation, ProfilTOTP, ProfilTOTPCreation, ProfilTOTPEdition} from '../_pages/profil';

import { ResumeRefillsKpiPage } from '../_pages/kpi/stations/ResumeRefillsKpi';
import { StationKpiPage } from '../_pages/kpi/stations/StationKpi';
import { DispenserKpiPage } from '../_pages/kpi/stations/DispenserKpi';

import { MainList } from '../_pages/common/MainList';
import { ItemEditPage } from '../_pages/common/EditItem';
import { ItemReadPage } from '../_pages/common/ReadItem';
import { KpiItemPage } from '../_pages/common/KpiItem';
import { KpiListPage } from '../_pages/common/KpiList';
import { CustomerEditUserPage } from '../_pages/common/custom/user/CustomerEditUser';
import { ServiceEditUserPage } from '../_pages/common/custom/user/ServiceEditUser';

import LiveClient from '../_classes/LiveClient';

import { ResponsiveDrawer }  from './GlobalLayout';

import { ObjectsMap } from '../_config';
import { hasFeature, getRights } from '../_helpers';

import { commonActions, userActions } from '../_actions';

import moment from 'moment'
require("moment/min/locales.min");

moment.locale(i18n.language);

moment.updateLocale('fr', {
    relativeTime : {
      s: "%d secondes",
    }
  });

class HomePage extends React.Component {
    
    constructor() {
        super()
        this.liveClient = new LiveClient()
    }

    componentDidMount() {

        const { t, user } = this.props;
        this.props.dispatch(commonActions.cloudInfo());
        this.props.dispatch(userActions.loadUserContextData(this.props.user));

        this.liveClient.run()

        const menu = {
            station: {
                name: 'menu.station',
                items: []
            },
            customer: {
                name: 'menu.customer',
                items: []
            },
            fleet: {
                name: 'menu.fleet',
                items: []
            },
            contracts: {
                name: 'contracts',
                items: []
            },
            admin: {
                name: 'menu.admin',
                items: []
            }
        };

        const crudObjects = []
        const routes = [];

        routes.push((<Route key="profil" exact path="/profil" render={routeProps => <Profil {...routeProps} />} />));
        routes.push((<Route key="profilU2f" exact path="/profil/u2f" render={routeProps => <ProfilU2F {...routeProps} model={ObjectsMap.userU2F}/>} />));
        routes.push((<Route key="profilU2fedit" exact path="/profil/u2f/edit/:itemId" render={routeProps => <ProfilU2FEdition {...routeProps} model={ObjectsMap.userU2F}/>} />));
        routes.push((<Route key="profilU2fcreate" exact path="/profil/u2f/create" render={routeProps => <ProfilU2FCreation {...routeProps} model={ObjectsMap.userU2F}/>} />));

        routes.push((<Route key="profilTOTP" exact path="/profil/totp" render={routeProps => <ProfilTOTP {...routeProps} model={ObjectsMap.userTOTP}/>} />));
        routes.push((<Route key="profilTOTPedit" exact path="/profil/totp/edit/:itemId" render={routeProps => <ProfilTOTPEdition {...routeProps} model={ObjectsMap.userTOTP}/>} />));
        routes.push((<Route key="profilTOTPcreate" exact path="/profil/totp/create" render={routeProps => <ProfilTOTPCreation {...routeProps} model={ObjectsMap.userTOTP}/>} />));

        const operatorContractRights = getRights(user, null, ObjectsMap.operatorContract.name);
        const stationNetworkRights = getRights(user, null, ObjectsMap.stationNetwork.name);
        const stationRights = getRights(user, null, ObjectsMap.station.name);
        const dispenserRights = getRights(user, null, ObjectsMap.dispenser.name);
        const driverRights = getRights(user, null, ObjectsMap.driver.name);
        const badgeRights = getRights(user, null, ObjectsMap.badge.name);
        const vehicleRights = getRights(user, null, ObjectsMap.vehicle.name);
        const userRights = getRights(user, null, ObjectsMap.user.name);
        const userGroupRights = getRights(user, null, ObjectsMap.userGroup.name);
        const customerRights = getRights(user, null, ObjectsMap.customer.name);
        const refillRights = getRights(user, null, ObjectsMap.refill.name);
        const refillGroupRights = getRights(user, null, ObjectsMap.refillgroup.name);
        const fleetTypeRights = getRights(user, null, ObjectsMap.fleetType.name);
        const fleetRights = getRights(user, null, ObjectsMap.fleet.name);
        const stationAvailabilityRights = getRights(user, null, ObjectsMap.stationAvailability.name);
        const dispenserAvailabilityRights = getRights(user, null, ObjectsMap.dispenserAvailability.name);
        const apiClientRights = getRights(user, null, ObjectsMap.apiClient.name);
		const importRights = getRights(user, null, ObjectsMap.import.name);
        const deviceLogRights = getRights(user, null, ObjectsMap.deviceLog.name);
        //const networkPackageRights = getRights(user, null, ObjectsMap.networkPackage.name);
        const customerContractRights = getRights(user, null, ObjectsMap.customerContract.name);

        if (user.isFleetManager) {
            
            routes.push((<Route key="home" exact path="/" render={routeProps => <HomeFleetManager liveClient={this.liveClient} {...routeProps} />} />));

            if (stationRights.list) {
                crudObjects.push({uriobject: 'station', model: ObjectsMap.station, menuaccess: true, menu: 'station', menuname: t('stations'), icon: ObjectsMap.station.icon, list: stationRights.list, read: stationRights.read, create: stationRights.create, edit: stationRights.update, delete: stationRights.delete});
            }
            
            if (dispenserRights.list) {
                crudObjects.push({uriobject: 'dispenser', appcontext: 'station', parent: 'station', model: ObjectsMap.dispenser, menu: 'station', menuname: t('station'), icon: ObjectsMap.dispenser.icon, list: dispenserRights.list, read: dispenserRights.read, create: dispenserRights.create, edit: dispenserRights.update, delete: dispenserRights.delete});
            }

/*            if (refillRights.list) {
                crudObjects.push({uriobject: 'refill', model: ObjectsMap.refill, menuaccess: true, menu: 'station', menuname: t('refills'), icon: ObjectsMap.refill.icon, list: refillRights.list, read: refillRights.read, create: refillRights.create, edit: refillRights.update, delete: refillRights.delete});
            }
*/
            if (refillGroupRights.list) {
				
				var refillGroupListActions = [];
				
                crudObjects.push({uriobject: 'refillgroup', model: ObjectsMap.refillgroup, menuaccess: true, menu: 'station', menuname: t('refillgroups'), icon: ObjectsMap.refillgroup.icon, list: refillGroupRights.list, read: refillGroupRights.read, create: refillGroupRights.create, edit: refillGroupRights.update, delete: refillGroupRights.delete, listActions: refillGroupListActions});
			}

			if(importRights.list) {
				crudObjects.push({uriobject: 'import', model: ObjectsMap.import, menuaccess: true, menu: 'admin', menuname: t('imports'), icon: ObjectsMap.import.icon, list: importRights.list, read: importRights.read, create: importRights.create, edit: importRights.update, delete: importRights.delete});				
            }
            
            if (driverRights.list) {
                crudObjects.push({uriobject: 'driver', appcontext: 'fleet', parent: 'fleet', model: ObjectsMap.driver, menuaccess: true, menu: 'fleet', menuname: t('drivers'), icon: ObjectsMap.driver.icon, list: driverRights.list, read: driverRights.read, create: driverRights.create, edit: driverRights.update, delete: driverRights.delete});
            }
			
            if (badgeRights.list) {
                crudObjects.push({uriobject: 'badge', appcontext: 'fleet', parent: 'fleet', model: ObjectsMap.badge, menuaccess: true, menu: 'fleet', menuname: t('badges'), icon: ObjectsMap.badge.icon, list: badgeRights.list, read: badgeRights.read, create: badgeRights.create, edit: badgeRights.update, delete: badgeRights.delete});
            }

            if (vehicleRights.list) {
				
				var vehicleListToolbarActions = [];
				
			    const haveImport = 
				   vehicleRights.import &&
				   ObjectsMap.vehicle.list && 
				   ObjectsMap.vehicle.list.toolbar_actions && 
				   ObjectsMap.vehicle.list.toolbar_actions.import;


			    if(haveImport) {
					
					vehicleListToolbarActions.push({
							icon: ObjectsMap.vehicle.list.toolbar_actions.import.icon, 
							label: ObjectsMap.vehicle.list.toolbar_actions.import.label,
							url: '/vehicle/import', 
							name: t('import')
					});
				}
				
                crudObjects.push({uriobject: 'vehicle', appcontext: 'fleet', parent: 'fleet', model: ObjectsMap.vehicle, menuaccess: true, menu: 'fleet', menuname: t('vehicles'), icon: ObjectsMap.vehicle.icon, list: vehicleRights.list, read: vehicleRights.read, create: vehicleRights.create, edit: vehicleRights.update, delete: vehicleRights.delete, listToolbarActions: vehicleListToolbarActions});
            }

            if (userRights.list) {
                crudObjects.push({uriobject: 'fleetuser', model: ObjectsMap.user, menuaccess: true, menu: 'admin', menuname: t('users'), icon: ObjectsMap.user.icon, EditComponent : CustomerEditUserPage, list: userRights.list, read: userRights.read, create: userRights.create, edit: userRights.update, delete: userRights.delete});
            }
/*
            if (stationRights.kpi) {
                routes.push((<Route key="stationKpi" exact path="/read/station/:stationId/kpi" render={routeProps => <StationKpiPage liveClient={this.liveClient} {...routeProps} />} />));
            }
            if (refillRights.kpi) {
                routes.push((<Route key="resumeRefillsKpi" exact path="/read/refills/kpi/" render={routeProps => <ResumeRefillsKpiPage liveClient={this.liveClient} {...routeProps} />} />));
            }
*/

            if (refillGroupRights.kpi) {

                const globalKpis = []

                ObjectsMap.refillgroup.kpis.items.forEach( kpiItem => {

                    if (kpiItem.type === 'refillsSummary') {
                        globalKpis.push({model: ObjectsMap.refillgroup, kpi: kpiItem})
                    }

                })

                if (refillRights.kpi) {
                    ObjectsMap.refill.kpis.items.forEach( kpiItem => {

                        if (kpiItem.type === 'attemptsSummary') {
                            globalKpis.push({model: ObjectsMap.refill, kpi: kpiItem})
                        }

                    })
                }

                routes.push((<Route key="resumeRefillsKpi" exact path="/read/refills/kpi/" render={routeProps => <KpiListPage liveClient={this.liveClient}  menu={'station'} scope="main" {...routeProps} kpis={globalKpis} />} />));
            }

			if (hasFeature(user, null, 'DEVELOPER')) {
                if (apiClientRights.list) {
                    crudObjects.push({uriobject: 'developerapi', model: ObjectsMap.apiClient, menuaccess: true, menu: 'admin', menuname: 'API', icon: ObjectsMap.apiClient.icon, list: true, read: true}); 
                }
            }

        } else if (user.isServiceProvider || user.isStationOperator) {
        
            routes.push((<Route key="home" exact path="/" render={routeProps => <HomeServiceProvider liveClient={this.liveClient} {...routeProps} />} />));
            
            

            if (stationNetworkRights.list) {
                crudObjects.push({uriobject: 'network', model: ObjectsMap.stationNetwork, menuaccess: true, menu: 'station', menuname: t('networks'), icon: ObjectsMap.stationNetwork.icon, list: stationNetworkRights.list, read: stationNetworkRights.read, create: stationNetworkRights.create, edit: stationNetworkRights.update, delete: stationNetworkRights.delete});
            }

            if (operatorContractRights.list) {
                crudObjects.push({uriobject: 'operatorcontract', model: ObjectsMap.operatorContract, menuaccess: true, menu: 'contracts', menuname: t('operatorContracts'), icon: ObjectsMap.operatorContract.icon, list: operatorContractRights.list, read: operatorContractRights.read, create: operatorContractRights.create, edit: operatorContractRights.update, delete: operatorContractRights.delete});
            }

            /*if (networkPackageRights.list) {
                crudObjects.push({uriobject: 'package', model: ObjectsMap.networkPackage, menuaccess: true, menu: 'contracts', menuname: t('packages'), icon: ObjectsMap.networkPackage.icon, list: networkPackageRights.list, read: networkPackageRights.read, create: networkPackageRights.create, edit: networkPackageRights.update, delete: networkPackageRights.delete});
            }*/


            if (stationRights.list) {

                let stationActions;
                if (stationAvailabilityRights.list) {
                    stationActions = [{icon: ObjectsMap.stationAvailability.icon, url: '/station/:objectId/list/availability', name: t('stationAvailability')}];
                    crudObjects.push({uriobject: 'availability', parent: 'station', model: ObjectsMap.stationAvailability, menu: 'station', menuname: t('stationAvailabilitys'), icon: ObjectsMap.stationAvailability.icon, list: stationAvailabilityRights.list, read: stationAvailabilityRights.read, create: stationAvailabilityRights.create, edit: stationAvailabilityRights.update, delete: stationAvailabilityRights.delete});
                }

                crudObjects.push({uriobject: 'station', model: ObjectsMap.station, menuaccess: true, menu: 'station', menuname: t('stations'), icon: ObjectsMap.station.icon, list: stationRights.list, read: stationRights.read, create: stationRights.create, edit: stationRights.update, delete: stationRights.delete, kpi: stationRights.kpi, viewActions: stationActions});
                
            }
			
			if(importRights.list) {
				crudObjects.push({uriobject: 'import', model: ObjectsMap.import, menuaccess: true, menu: 'admin', menuname: t('imports'), icon: ObjectsMap.import.icon, list: importRights.list, read: importRights.read, create: importRights.create, edit: importRights.update, delete: importRights.delete});				
			}

            if (refillRights.list) {
				
				var refillListActions = [];
				
				if(refillGroupRights.list) {
					refillListActions.push({icon: ObjectsMap.refill.icon, url: '/list/refillgroup', name: t('show.refillgroups'), enabledInFilteredList: false});
				}
				
                crudObjects.push({uriobject: 'refill', model: ObjectsMap.refill, menuaccess: false, menu: 'station', menuname: t('refills'), icon: ObjectsMap.refill.icon, list: refillRights.list, read: refillRights.read, create: refillRights.create, edit: refillRights.update, delete: refillRights.delete, listActions: refillListActions});
            }

            if (refillGroupRights.list) {
				
				var refillGroupListActions = [];
				
				if(refillRights.list) {
					refillGroupListActions.push({icon: ObjectsMap.refill.icon, url: '/list/refill', name: t('show.refills'), enabledInFilteredList: false});
				}
				
                crudObjects.push({uriobject: 'refillgroup', model: ObjectsMap.refillgroup, menuaccess: true, menu: 'station', menuname: t('refillgroups'), icon: ObjectsMap.refillgroup.icon, list: refillGroupRights.list, read: refillGroupRights.read, create: refillGroupRights.create, edit: refillGroupRights.update, delete: refillGroupRights.delete, listActions: refillGroupListActions});
			}
            
			
            if (dispenserRights.list) {
                
				let dispenserCrud = {uriobject: 'dispenser', appcontext: 'station', parent: 'station', model: ObjectsMap.dispenser, menu: 'station', menuname: t('station'), icon: ObjectsMap.dispenser.icon, list: dispenserRights.list, read: dispenserRights.read, create: dispenserRights.create, edit: dispenserRights.update, delete: dispenserRights.delete, kpi: dispenserRights.kpi, viewActions: []};
				
				if(dispenserAvailabilityRights.list) {
					
					let dispAvailActions = [{icon: ObjectsMap.dispenserAvailability.icon, url: '/dispenser/:objectId/list/dispenserAvailability', name: t('stationAvailability')}];;
				
					dispenserCrud.viewActions = dispenserCrud.viewActions.concat(dispAvailActions);

					crudObjects.push({uriobject: 'dispenserAvailability', parent: 'dispenser', model: ObjectsMap.dispenserAvailability, menu: 'station', menuname: t('Disponibilités'), icon: ObjectsMap.dispenserAvailability.icon, list: dispenserAvailabilityRights.list, read: dispenserAvailabilityRights.read, create: dispenserAvailabilityRights.create, edit: dispenserAvailabilityRights.update, delete: dispenserAvailabilityRights.delete});
				}
				
				crudObjects.push(dispenserCrud);
			}
            
            /*if (fleetTypeRights.create && fleetTypeRights.list && user.isServiceProvider) {
                crudObjects.push({uriobject: 'fleettype', model: ObjectsMap.fleetType, menuaccess: true, menu: 'customer', menuname: t('fleetTypes'), icon: ObjectsMap.fleetType.icon, list: fleetTypeRights.list, read: fleetTypeRights.read, create: fleetTypeRights.create, edit: fleetTypeRights.update, delete: fleetTypeRights.delete});
            }*/

            let customerActions;
            if (userRights.list && customerRights.read) {
                customerActions = [{icon: ObjectsMap.user.icon, url: '/customer/:objectId/list/user', name: t('users')}];
            }
            
            if (customerRights.list) {
                crudObjects.push({uriobject: 'customer', model: ObjectsMap.customer, menuaccess: true, menu: 'customer', menuname: t('customers'), icon: ObjectsMap.customer.icon, list: customerRights.list, read: customerRights.read, create: customerRights.create, edit: customerRights.update, delete: customerRights.delete, kpi: customerRights.kpi, viewActions: customerActions});
            }
            if (userRights.list) {
                crudObjects.push({uriobject: 'serviceuser', model: ObjectsMap.user, menuaccess: true, menu: 'admin', menuname: t('users'), icon: ObjectsMap.user.icon, EditComponent : ServiceEditUserPage, list: userRights.list, read: userRights.read, create: userRights.create, edit: userRights.update, delete: userRights.delete, filter : {customer: null}});
                crudObjects.push({uriobject: 'user', parent: 'customer', model: ObjectsMap.user, menu: 'customer', menuname: t('customers'), icon: ObjectsMap.user.icon, EditComponent : CustomerEditUserPage, list: userRights.list, read: userRights.read, create: userRights.create, edit: userRights.update, delete: userRights.delete});
            }


            if (userGroupRights.list && userGroupRights.create) {
                crudObjects.push({uriobject: 'usergroup', model: ObjectsMap.userGroup, menuaccess: true, menu: 'admin', menuname: t('userGroups'), icon: ObjectsMap.userGroup.icon, list: userGroupRights.list, read: userGroupRights.read, create: userGroupRights.create, edit: userRights.update, delete: userGroupRights.delete});
            }

            if (fleetRights.list) {

                const fleetActions = [];
                if (fleetRights.read) {
                    if (vehicleRights.list) {
                        fleetActions.push({icon: ObjectsMap.vehicle.icon, url: '/fleet/:objectId/list/vehicle', name: t('vehicles')});
                    }
                    if (driverRights.list) {
                        fleetActions.push({icon: ObjectsMap.driver.icon, url: '/fleet/:objectId/list/driver', name: t('drivers')});
                    }
                    if (badgeRights.list) {
                        fleetActions.push({icon: ObjectsMap.badge.icon, url: '/fleet/:objectId/list/badge', name: t('badges')});
                    }

                }

                crudObjects.push({uriobject: 'fleet', parent: 'customer', model: ObjectsMap.fleet, menu: 'customer', menuname: t('fleets'), icon: ObjectsMap.fleet.icon, list: fleetRights.list, read: fleetRights.read, create: fleetRights.create, edit: fleetRights.update, delete: fleetRights.delete, kpi: fleetRights.kpi, viewActions: fleetActions});
            }

            if (customerContractRights.list) {
                crudObjects.push({uriobject: ObjectsMap.customerContract.uriobject, appcontext: 'fleet', parent: 'fleet', model: ObjectsMap.customerContract, menuaccess: false, menu: 'fleet', menuname: t('contracts'), icon: ObjectsMap.customerContract.icon, list: customerContractRights.list, read: customerContractRights.read, create: customerContractRights.create, edit: customerContractRights.update, delete: customerContractRights.delete});
            }

            if (driverRights.list) {
                crudObjects.push({uriobject: 'driver', appcontext: 'fleet', parent: 'fleet', model: ObjectsMap.driver, menuaccess: true, menu: 'fleet', menuname: t('drivers'), icon: ObjectsMap.driver.icon, list: driverRights.list, read: driverRights.read, create: driverRights.create, edit: driverRights.update, delete: driverRights.delete, kpi: driverRights.kpi});
            }

            if (badgeRights.list) {
                crudObjects.push({uriobject: 'badge', appcontext: 'fleet', parent: 'fleet', model: ObjectsMap.badge, menuaccess: true, menu: 'fleet', menuname: t('badges'), icon: ObjectsMap.badge.icon, list: badgeRights.list, read: badgeRights.read, create: badgeRights.create, edit: badgeRights.update, delete: badgeRights.delete});
            }

            if (vehicleRights.list) {
				
				var vehicleListToolbarActions = [];
				
			    const haveImport = 
				   vehicleRights.import &&
				   ObjectsMap.vehicle.list && 
				   ObjectsMap.vehicle.list.toolbar_actions && 
				   ObjectsMap.vehicle.list.toolbar_actions.import;

			    if(haveImport) {
					
					vehicleListToolbarActions.push({
							icon: ObjectsMap.vehicle.list.toolbar_actions.import.icon, 
							label: ObjectsMap.vehicle.list.toolbar_actions.import.label,
							url: '/vehicle/import', 
							name: t('import')
					});
				}
				
                crudObjects.push({uriobject: 'vehicle', appcontext: 'fleet', parent: 'fleet', model: ObjectsMap.vehicle, menuaccess: true, menu: 'fleet', menuname: t('vehicles'), icon: ObjectsMap.vehicle.icon, list: driverRights.list, read: vehicleRights.read, create: vehicleRights.create, edit: vehicleRights.update, delete: vehicleRights.delete, kpi: vehicleRights.kpi, listToolbarActions: vehicleListToolbarActions});
            }
            
            if (refillGroupRights.kpi) {

                const globalKpis = []

                ObjectsMap.refillgroup.kpis.items.forEach( kpiItem => {

                    if (kpiItem.type === 'refillsSummary') {
                        globalKpis.push({model: ObjectsMap.refillgroup, kpi: kpiItem})
                    }

                })

                if (refillRights.kpi) {
                    ObjectsMap.refill.kpis.items.forEach( kpiItem => {

                        if (kpiItem.type === 'attemptsSummary') {
                            globalKpis.push({model: ObjectsMap.refill, kpi: kpiItem})
                        }

                    })
                }

                routes.push((<Route key="resumeRefillsKpi" exact path="/read/refills/kpi/" render={routeProps => <KpiListPage liveClient={this.liveClient}  menu={'station'} scope="main" {...routeProps} kpis={globalKpis} />} />));
            }

            if (hasFeature(user, null, 'DATAEXTRACTION')) {
                crudObjects.push({uriobject: 'dataexport', model: ObjectsMap.dataExport, menuaccess: true, menu: 'admin', menuname: t('dataexports'), icon: ObjectsMap.dataExport.icon, list: true, read: true}); 
            }

            if (hasFeature(user, null, 'DEVELOPER')) {
                if (apiClientRights.list) {
                    crudObjects.push({uriobject: 'developerapi', model: ObjectsMap.apiClient, menuaccess: true, menu: 'admin', menuname: 'API', icon: ObjectsMap.apiClient.icon, list: true, read: true}); 
                }
            }

            if (deviceLogRights.list) {		
                crudObjects.push({uriobject: 'devicelog', model: ObjectsMap.deviceLog, parent: 'device', menuaccess: false, menu: 'admin', menuname: t('devicelogs'), icon: ObjectsMap.deviceLog.icon, list: deviceLogRights.list});
            }

        }
        		
		const crudObjectsByUri = this.getCrudByURI(crudObjects);
        
		if (fleetRights.list) {
			if (fleetRights.read && vehicleRights.import) {
				routes.push((<Route key="FleetVehicleImport" exact path="/fleet/:parentId/vehicle/import" render={routeProps => <ItemEditPage liveClient={this.liveClient}  menu='customer' scope="main" {...routeProps} model={ObjectsMap.import} uriobject="import" creation={true} parent="parentId" parentModelRef="fleet" customData={{objType: "vehicle"}} routesConfig={crudObjectsByUri} />} />))			
			}
			
			if(importRights.list) {
				routes.push((<Route key={"route_menu_FleetVehicleImport" } exact path="/fleet/:objectId/list/import" render={routeProps => <MainList liveClient={this.liveClient} listId={"list_id_FleetVehicleImport" } setappcontext={true} scope="main" menu={crudObjectsByUri["import"].menu} {...routeProps} uriobject="vehicle" model={ObjectsMap.import} parent="parentId" parentModelRef="fleet" filter={crudObjectsByUri["import"].filter} canRead={crudObjectsByUri["import"].read} canCreate={crudObjectsByUri["import"].create} canEdit={crudObjectsByUri["import"].edit} canDelete={crudObjectsByUri["import"].delete} listActions={crudObjectsByUri["import"].listActions} listToolbarActions={crudObjectsByUri["import"].listToolbarActions} />} />));
			}
		}
		
        const routesEdition = [];
        crudObjects.forEach( (crudObject, index) => {

            let breadcrumbRoot;
            const breadcrumbSep = {content: (<div className="breadcrumb-sep"><span>></span></div>)};

            const parentPath = crudObject.parent ? '/'+crudObject.parent+'/:parentId' : '';

            if (crudObject.menu && crudObject.list) {
                if (crudObject.menuaccess) {
                    menu[crudObject.menu].items.push({key: 'menu-'+crudObject.uriobject, url: parentPath + '/list/'+crudObject.uriobject, name: crudObject.menuname, icon: crudObject.icon, model: crudObject.model, appcontext: crudObject.appcontext });
                }
                breadcrumbRoot = {content: (<div key={'breadcrumb_' + index + '_root'}><MenuIcon/> {t(menu[crudObject.menu].name)}</div>)};

                
                routes.push((<Route key={"route_menu_" + index } exact path={parentPath + '/list/'+crudObject.uriobject} render={routeProps => <MainList liveClient={this.liveClient} listId={"list_id_" + index } setappcontext={true} scope="main" menu={crudObject.menu} {...routeProps} uriobject={crudObject.uriobject} model={crudObject.model} parent={crudObject.parent} filter={crudObject.filter} canRead={crudObject.read} canCreate={crudObject.create} canEdit={crudObject.edit} canDelete={crudObject.delete} listActions={crudObject.listActions} listToolbarActions={crudObject.listToolbarActions} />} />));
 
				if(crudObject.model.list.filtered_lists) {
				
					crudObject.model.list.filtered_lists.forEach(filtered_list => {
						routes.push((<Route key={"route_menu_" + index + "_" + filtered_list } exact path={parentPath + '/list/'+crudObject.uriobject+'/'+filtered_list+'/:filteredListId'} render={routeProps => <MainList liveClient={this.liveClient} listId={"list_id_" + index } setappcontext={true} scope="main" menu={crudObject.menu} {...routeProps} uriobject={crudObject.uriobject} model={crudObject.model} parent={crudObject.parent} filter={crudObject.filter} filteredListName={filtered_list} canRead={crudObject.read} canCreate={crudObject.create} canEdit={crudObject.edit} canDelete={crudObject.delete} listActions={crudObject.listActions} />} />));
					});
				}
			}

            const breadcrumbFirst = {content: (<div><Link to={'/list/' + crudObject.uriobject}>{crudObject.icon} {t(crudObject.model.name + 's')}</Link></div>)};
            const breadcrumbCreation = [
                breadcrumbFirst,
                breadcrumbSep,
                {content: (<div> {<AddIcon/>}<span>{t('creation')}</span></div>)}
            ];
            const breadcrumbEdition = [
                breadcrumbFirst,
                breadcrumbSep,
                {content: (<div> {<EditIcon/>}<span>{t('edition')}</span></div>)}
            ];
            const breadcrumbRead = [
                breadcrumbFirst,
                breadcrumbSep,
                {content: (<div> {<PageviewIcon/>}<span>{t('read')}</span></div>)}
            ];

            if (breadcrumbRoot) {
                breadcrumbCreation.splice(0, 0, breadcrumbRoot, breadcrumbSep);
                breadcrumbEdition.splice(0, 0, breadcrumbRoot, breadcrumbSep);
                breadcrumbRead.splice(0, 0, breadcrumbRoot, breadcrumbSep);
            }

            const EditComponent = crudObject.EditComponent ? crudObject.EditComponent : ItemEditPage;

            routesEdition.push( (
                <div  key={"route_edition_" + index }>
                {crudObject.create && <Route key={"route_edition_create_" + index } exact path={parentPath + '/edit/' + crudObject.uriobject} render={routeProps => <EditComponent liveClient={this.liveClient} menu={crudObject.menu} scope="main" {...routeProps} model={crudObject.model} uriobject={crudObject.uriobject} parent={crudObject.parent}/>} />}
                {crudObject.edit && <Route key={"route_edition_update_" + index } exact path={parentPath + '/edit/' + crudObject.uriobject + '/:itemId'} render={routeProps => <EditComponent liveClient={this.liveClient}  menu={crudObject.menu} scope="main" {...routeProps} model={crudObject.model} uriobject={crudObject.uriobject} parent={crudObject.parent} />} />}
                {crudObject.read && <Route key={"route_read_" + index } exact path={parentPath + '/read/' + crudObject.uriobject + '/:itemId'} render={routeProps => <ItemReadPage liveClient={this.liveClient}  menu={crudObject.menu} scope="main" {...routeProps} model={crudObject.model} uriobject={crudObject.uriobject} parent={crudObject.parent} routesConfig={crudObjectsByUri} actions={crudObject.viewActions}/>} />}
                {crudObject.kpi && <Route key={"route_kpi_" + index } exact path={parentPath + '/read/' + crudObject.uriobject + '/:itemId/kpi'} render={routeProps => <KpiItemPage liveClient={this.liveClient}  menu={crudObject.menu} scope="main" {...routeProps} model={crudObject.model} uriobject={crudObject.uriobject} parent={crudObject.parent} routesConfig={crudObjectsByUri} />} />}
                </div>
            ));



        });

        if (refillGroupRights.kpi) {
            menu['station'].items.push({key: 'menu-refills-kpi', url: '/read/refills/kpi', name: 'KPI', icon: <AssessmentIcon/> });
        }

        this.menu = menu;
        this.routes = routes;
        this.routesEdition = routesEdition;
    }

    componentWillUnmount() {
        this.liveClient.disconnect()
    }

	getCrudByURI(crudObjects) {
	
		var crudObjectsByUri = {};
		
		crudObjects.forEach(function(crudObject) {
			crudObjectsByUri[crudObject.uriobject] = crudObject;
		});
		
		return crudObjectsByUri;
	}
	
	render() {
        const { t, user, loadingContext } = this.props;

        const menu = this.menu;
        const routes = this.routes;
        const routesEdition = this.routesEdition;

        const globalWait = false;

        return (
            <div>
            {loadingContext && (<div className="loading-context-bloc">{this._renderImgLoading()} {t('loading.context')}</div>)}
            {!loadingContext && <div className="relative"><ResponsiveDrawer user={user} menu={menu}>
                {routes}
                {routesEdition}

                <Route key={"edit_barrier_station" } exact path={'/station/:parentId/edit/barrier/:itemId'} render={routeProps => <ItemEditPage liveClient={this.liveClient} menu="station" parent="station" scope="main" {...routeProps} model={ObjectsMap.barrier} uriobject="barrier" />} />
                <Route key={"create_barrier_station" } exact path={'/station/:parentId/edit/barrier'} render={routeProps => <ItemEditPage liveClient={this.liveClient} menu="station" parent="station" scope="main" {...routeProps} model={ObjectsMap.barrier} uriobject="barrier" creation={true}/>} />

                
            </ResponsiveDrawer>{globalWait && <div id="global-wait"><div id="global-wait-msg"></div></div>}</div>}
            </div>
        );
    }


    _renderImgLoading = () => {
        return (<img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />);
    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user, loadingContext } = authentication;
    return {
        user,
        loadingContext
    };
}

const connectedHomePage = connect(mapStateToProps)(translate('translations')(HomePage));
export { connectedHomePage as HomePage };