
import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import Button from '@material-ui/core/Button';

import { KpiView } from '../../_components/kpi/KpiView';
import EnhancedTable from '../../_components/EnhancedTable';
import Breadcrumb from '../../_components/Breadcrumb';
import { PopinDialog } from '../../_components/edition/PopinDialog';
import { commonActions } from '../../_actions';
import { ObjectsMap } from '../../_config';
import { history, getRights } from '../../_helpers';
import localCache from '../../_helpers/local.cache';

const extractLiveFields = model => {
    const liveFields = {};
    Object.keys(model.fields).forEach(field => {
        const fieldConfig = model.fields[field];
        if (fieldConfig.live) {
            const liveField = {...fieldConfig}
            if (liveField.object) {
                liveField.liveFields = extractLiveFields(ObjectsMap[liveField.object]);
            }
            liveFields[field] = liveField;
        }
    });
    return liveFields;
}

class MainList extends React.Component {

    constructor(props) {
        super(props)	

		this.scope = this.props.scope;
        this.model = this.props.model;
		
        this.uriobject = this.props.uriobject ? this.props.uriobject : this.model.name;
        this.rights = getRights(this.props.user, this.props.context, this.model.name);
        this.parentPath = this.props.parent ? ('/' + this.props.parent + '/' + this.props.match.params.parentId) : '';
        
		this.parentModel = null;
		
		if(this.props.parentModelRef) {
			this.parentModel = ObjectsMap[this.props.parentModelRef];
		}
		
		if(!this.parentModel && this.props.parent) {
			this.parentModel = ObjectsMap[this.props.model.fields[this.props.parent].object];
		}
		
		this.noActions = this.props.noActions;
    }
	
	initFilters() {
		
		if (this.props.model.list.filters) {
            const userFilters = {};
            Object.keys(this.props.model.list.filters).forEach( filterKey => {
                const filter = this.props.model.list.filters[filterKey];
                if (!filter.profils || filter.profils.indexOf(this.props.user.user.profil) > -1) {
                    userFilters[filterKey] = filter;
                }
            })
            this.listFilters = userFilters;
        } else {
            this.listFilters = null;
        }
		
		if(this.props.match.params.filteredListId) {
			
			if(this.listFilters==null) {
				this.listFilters = {};
			}
			
			this.listFilters[this.props.filteredListName] = {
				field: this.props.filteredListName,
				default: () => { return this.props.match.params.filteredListId }
			}
		}
	}
	
    componentDidMount() {
				
        let filter;
		let displayCache = null; 
		
		this.initFilters();
		
		displayCache = localCache.getCache(this.props.listId);
		
        if (this.props.parent) {
            filter = this.props.filter ? this.props.filter : {};
            
            filter[this.props.parent] = this.props.match.params.parentId;

            this.props.dispatch(commonActions.loadContext(this.props.scope, this.props.match.params.parentId, this.parentModel));

        } else {
            filter = this.props.filter;
        }
        
        if (this.props.setappcontext) {

        }
        
		if(displayCache.filter && this.listFilters) {
		
			var modifiedListFilters = [];
		
            const listFilterKeys = Object.keys(this.listFilters);
            listFilterKeys.forEach( listFilterName => {
                
				const listFilter = Object.assign({}, this.listFilters[listFilterName]);
                				
				if (displayCache.filter[listFilter.field]) {
                    
					listFilter.default = function() {
						return displayCache.filter[listFilter.field];
					}
                }
				
				modifiedListFilters[listFilterName] = listFilter;
            });

            Object.keys(displayCache.filter).forEach( displayCacheFilterKey => {
                if (listFilterKeys.indexOf(displayCacheFilterKey) === -1) {
                    delete displayCache.filter[displayCacheFilterKey];
                }
            })

			
			this.listFilters = modifiedListFilters;
			filter = {...filter, ...displayCache.filter};
		}
		
        if (this.listFilters) {
			
            Object.keys(this.listFilters).forEach( listFilterName => {
                const listFilter = this.listFilters[listFilterName];
                if (listFilter.default) {
                    if (!filter) {
                        filter = {};
                    }
                    filter[listFilter.field] = listFilter.default();
                }
            })
        }

		this.filter = filter;		
        this.props.dispatch(commonActions.getAll(this.scope, this.model, undefined, filter));

        if (!this.model.list.liveDisabled) {
            const liveFields = extractLiveFields(this.model);
            if (Object.keys(liveFields).length > 0) {
                this.liveTimer = setInterval( () => {
                    if (this.props.liveFailed) {
                        clearInterval(this.liveTimer);
                    } else {
                        this.props.dispatch(commonActions.liveAll(this.scope, this.model, liveFields));
                    }
                }, 1000);
            }
        }
    }

    componentWillUnmount() {
        if (this.liveTimer) {
            clearInterval(this.liveTimer);
        }
    }

    handleAddAction = (e) => {
        this.props.history.push(this.parentPath + '/edit/' + this.uriobject);
    }

    handleEditAction = (item) => {
        this.props.history.push(this.handleBuildEditURL(item));
    }
	
	handleCustomAction = (customActionUrl) => {
		this.props.history.push(this.parentPath + customActionUrl);
    }

    handleReadAction = (item) => {
        this.props.history.push(this.handleBuildReadURL(item));
    }

    handleBuildEditURL = (item) => {
        return this.parentPath + '/edit/' + this.uriobject + '/' + item.id;
    }

    handleBuildReadURL = (item) => {
        return this.parentPath + '/read/' + this.uriobject + '/' + item.id;
    }

    handleDeleteAction = (item) => {
        this.props.dispatch(commonActions.deleteItem(this.scope, item, this.model));
    }

    handleArchiveAction = (item) => {
        this.props.dispatch({ type:'OPEN_DELETE_DIALOG', item});
    }

    handleExportAction = () => {
        this.props.dispatch(commonActions.exportAll(this.scope, this.model, null, this.filter));
    }

    handleFilterChange(filterName, filter, value) {
        if (!this.filter) {
            this.filter = {};
        }
        this.filter[filter.field] = value;
		localCache.cacheVal(this.props.listId, "filter", this.filter);
        this.props.dispatch(commonActions.getAll(this.scope, this.model, undefined, this.filter));
        
    }

    handleChangePage = (page) => {
        /*history.push({
            pathname: this.props.location.pathname,
            search: '?page='+page
          })*/
    };

    handleCancelDialog = () => {
        this.props.dispatch({ type:'CLOSE_DELETE_DIALOG'});
    }

    handleConfirmDialog = () => {
        this.props.dispatch(commonActions.archiveItem(this.scope, this.props.todelete_item, this.model, true));
        this.props.dispatch({ type:'CLOSE_DELETE_DIALOG'});
    }

    handleAction = (url) => {
        history.push(url);
    }
	

    render() {
        let { t, data, parent, appcontext, itemContext, user, showDeleteDialog, listActions, listToolbarActions } = this.props;
        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }
		const isFilteredList = (this.props.filteredListName && this.props.filteredListName.length > 0);
        const list = this.model.list;
        const items = data.items;

        const fieldsConfig = this.model.fields;

        const userProfils = user.user.profils;

        const columns = list.fields.filter( field => {
            if (field.profils && !field.profils.find( profil => userProfils.indexOf(profil) > -1)) {
                return false;
            }
            return true;
        }).map(field => {
            const fieldName = field.name;
            
            const fieldConfig = fieldsConfig[fieldName];
            const label = field.displayName ? field.displayName : fieldConfig.displayName ? fieldConfig.displayName : fieldName;
            return {id: fieldName, numeric: false, label: label }
        });

        const breadcrumb = [];

        if (parent) {

            const parentListBreadcrumItem = {icon: this.parentModel.icon, name: this.parentModel.name + 's'};

            if (user.isServiceProvider) {

                

                breadcrumb.push(parentListBreadcrumItem);
                let parentObject = appcontext[parent];
                if (parentObject) {
                    let urlParent  = '/read/' + this.parentModel.name + '/' + parentObject.id;
                    if (parent === 'fleet') {
                        const customer =  appcontext['customer'];
                        if (customer) {
                            urlParent = '/customer/' + customer.id + urlParent;
                        }
                    }
                    breadcrumb.push({icon: (null), name: parentObject.name, url: urlParent})
                } else if (itemContext && itemContext.parent) {

                    parentListBreadcrumItem.url  = '/list/' + this.parentModel.name;

                    let urlParent  = '/read/' + this.parentModel.name + '/' + itemContext.parent.id;
                    breadcrumb.push({icon: (null), name: itemContext.parent.name, url: urlParent})
                }

            } else if (user.isFleetManager) {

                if (parent !== 'fleet') {
                    breadcrumb.push(parentListBreadcrumItem);//, url: '/list/' + this.parentModel.name
                    let parentObject = appcontext[parent];
                    if (parentObject) {
                        let urlParent  = '/read/' + this.parentModel.name + '/' + parentObject.id;
                        if (parent === 'fleet') {
                            const customer =  appcontext['customer'];
                            if (customer) {
                                urlParent = '/customer/' + customer.id + urlParent;
                            }
                        }
                        breadcrumb.push({icon: (null), name: parentObject.name, url: urlParent})
                    } else if (itemContext && itemContext.parent) {

                        parentListBreadcrumItem.url  = '/list/' + this.parentModel.name;
    
                        let urlParent  = '/read/' + this.parentModel.name + '/' + itemContext.parent.id;
                        breadcrumb.push({icon: (null), name: itemContext.parent.name, url: urlParent})
                    }
                }
            }

            
            breadcrumb.push({icon: this.model.icon, name: this.model.name + 's'});
        } else {
            breadcrumb.push({icon: this.model.icon, name: this.model.name + 's'});

        }

		
        return (
            <div>
				
                <Breadcrumb menu={this.props.menu} elements={breadcrumb}/>
					<br/>
					{
						listActions && listActions.map((listAction, index) => 
						{
							const isEnabled = !isFilteredList || (isFilteredList && listAction.enabledInFilteredList);
							
							var content = <span></span>;
							
							if(isEnabled) {
							
								content = <Button key={'btn-action-'+index} variant="contained" size="small" onClick={() => this.handleAction(listAction.url) }>
											{listAction.icon}
											{listAction.name}
										</Button>
							}
							
							return content;										
						})
                    }
                    
                    {this.model.list.kpis && <div className="kpi-blocs">{this.model.list.kpis.map( kpi => <KpiView kpi={kpi} model={this.model} scope={this.scope} mode={1}/>)}</div>}

                    <EnhancedTable
						tableId={this.props.listId}
                        loading={data.loading}
                        scope={this.scope}
                        model={this.model}
                        columns={columns}
                        rows={items ? items : []}
                        tableTitle={list.title || this.model.name}
                        addAction={this.rights.create ? this.handleAddAction : null}
                        onBuildReadURL={this.rights.read ? this.handleBuildReadURL : null}
                        onBuildEditURL={this.rights.update ? this.handleBuildEditURL : null}
						onBuildActionURL={this.rights.update ? this.handleCustomAction : null}
                        deleteAction={this.rights.delete ? this.handleDeleteAction: null}
                        archiveAction={this.rights.archive ? this.handleArchiveAction: null}
                        exportAction={this.rights.export ? this.handleExportAction: null}
						customActions={listToolbarActions}
                        withPagination={true}
                        filters={this.listFilters}
						noActions={this.noActions}
                        onFilterChange={(filterName, filter, value) => this.handleFilterChange(filterName, filter, value)}
                        canSearch={this.model.list.search}
                        onChangePage={this.handleChangePage}
                    />
                    {showDeleteDialog && <PopinDialog title={'Confirmation'} intro="Etes-vous sur de vouloir supprimer cet élément ?" validationLabel={t('action.delete')} handleCancel={this.handleCancelDialog} handleValidate={this.handleConfirmDialog} />}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data, authentication, appcontext } = state;
    const itemContext = state.context;
    const { user, context} = authentication;
    return {
        data,
        user, 
        context,
        appcontext,
        itemContext,
        liveFailed: data.main ? data.main.liveFailed : null,
        showDeleteDialog: data.showDeleteDialog ? data.showDeleteDialog : false,
        todelete_item: data.todelete_item ? data.todelete_item : null
    };
}

const connectedMainListPage = translate('translations')(connect(mapStateToProps)(MainList));
export { connectedMainListPage as MainList };