import { uiactionConstants } from '../_constants';
import { uiactionService } from '../_services';

export const uiactionActions = {
    sendAction,
};

function sendAction(uiaction, object) {
    
    return dispatch => {
        dispatch(request(uiaction));

        uiactionService.sendAction(uiaction, object)
            .then(
                result => { 
                        dispatch(success(result));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(uiaction) { return { type: uiactionConstants['UI_ACTION_REQUEST'], uiaction } }
    function success(result) { return { type: uiactionConstants['UI_ACTION_SUCCESS'], result } }
    function failure(error) { return { type: uiactionConstants['UI_ACTION_FAILURE'], error } }

    
}