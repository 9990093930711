import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { commonActions } from '../../_actions';
import { history } from '../../_helpers';
import { EditObject } from './EditObject';
import Breadcrumb from '../../_components/Breadcrumb';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { ObjectsMap } from '../../_config';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem',
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
});

class ItemEditPage extends React.Component {

    constructor(props) {
        super(props)
        this.scope = this.props.scope;
        this.model = this.props.model;
		
		if(this.props.parentModelRef && this.parentModel == null) {
			this.parentModel = ObjectsMap[this.props.parentModelRef];
		}
		if(this.props.parent && this.parentModel == null) {
			this.parentModel = ObjectsMap[this.props.model.fields[this.props.parent].object];
		}
		  
        this.itemId = this.props.embedded ? this.props.itemId : this.props.match.params.itemId;
        this.creation = !this.itemId
        this.fields = this.creation ? this.model.creation.fields : this.model.edition.fields;
        
        if (this.props.filterFields) {
            this.fields = this.fields.filter( field => this.props.filterFields.indexOf(field.name) > -1);
        }
    }

    componentDidMount() {
        if (this.creation) {
            if (this.props.parent) {
                this.props.dispatch(commonActions.loadParentAndCreate(this.scope, this.model, this.props.parent, this.props.match.params.parentId, this.parentModel, this.props.user, this.props.context, this.props.appcontext, this.props.newItem, this.props.customData));
            } else {
                
                this.props.dispatch(commonActions.create(this.scope, this.model, undefined, undefined, this.props.user, this.props.context, this.props.newItem));
            } 
        } else {
            if (this.props.parent) {
                this.props.dispatch(commonActions.loadParentAndEdit(this.scope, this.itemId, this.model, this.props.parent, this.props.match.params.parentId, this.parentModel, this.props.user, this.props.context, this.props.appcontext));
            } else {
                this.props.dispatch(commonActions.edit(this.scope, this.itemId, this.model));
            }
        }
    }

    getData() {
        let { data } = this.props;

        if (data && this.scope) {
            const scopedData = data[this.scope];
            data = scopedData ? scopedData : data;
        }

        return data;
    }

    getItem() {
        const data = this.getData();
        return data.item;
    }

    getError() {
        const data = this.getData();
        return data.error;
    }

    handleSubmit = event => {
        const item = this.getItem();
        let redirectionUrl;
        if (this.props.parent && !this.props.parentModelRef) {
            //redirectionUrl = '/read/' + this.parentModel.name + '/' + this.props.match.params.parentId;
            redirectionUrl = '/' + this.parentModel.name + '/' + this.props.match.params.parentId + '/list/' + this.props.uriobject;
        } else {
            redirectionUrl = '/list/' + this.props.uriobject;
        }
        this.props.dispatch(commonActions.save(this.scope, this.creation, item, this.model, redirectionUrl, this.props.user, this.props.context));
    }

    handleCancel = e => {
        history.goBack();
    } 

    handleObjectChange = object => {
        this.props.dispatch(commonActions.updateCurrentEdition(this.scope, object, null, this.model));
    }

	getDataInfo(wantedInfo) {
		
		var info = null;
		
		if(this.props) {
			if(this.props.data) {
				if(this.props.data.main) {
					info = this.props.data.main[wantedInfo];
				}
			}
		}
		
		return info;
	}	
	
    render() {
        const { t, classes, embedded, parent } = this.props;

        const item = this.getItem();

        this.state = {item: item}; 


        const breadcrumb = [];

        if (parent) {

            if (this.props.user.isServiceProvider) {

                breadcrumb.push({icon: this.parentModel.icon, name: this.parentModel.name + 's', url: parent !== 'fleet' ? ('/list/' + this.parentModel.name) : null});

                if (item && item[parent]) {
                    breadcrumb.push({icon: (null), name: item[parent].name, url: '/read/' + this.parentModel.name + '/' + item[parent].id})
                    breadcrumb.push({icon: this.model.icon, name: this.model.name + 's'});
                    if (this.props.creation) {
                        breadcrumb.push({icon: <AddIcon/>, name: 'creation'});
                    } else {
                        breadcrumb.push({icon: <EditIcon/>, name: 'edition'});
                    }
                }

            } else if (this.props.user.isFleetManager) {

                if (parent !== 'fleet') {
                    breadcrumb.push({icon: this.parentModel.icon, name: this.parentModel.name + 's', url: '/list/' + this.parentModel.name});
                    breadcrumb.push({icon: (null), name: item[parent].name, url: '/read/' + this.parentModel.name + '/' + item[parent].id})
                }

                if (item && item[parent]) {
                    
                    breadcrumb.push({icon: this.model.icon, name: this.model.name + 's', url: '/' + this.parentModel.name + '/' + item[parent].id + '/list/' + this.props.uriobject});
                    if (this.props.creation) {
                        breadcrumb.push({icon: <AddIcon/>, name: 'creation'});
                    } else {
                        breadcrumb.push({icon: <EditIcon/>, name: 'edition'});
                    }
                }

            }

        } else {
            breadcrumb.push({icon: this.model.icon, name: this.model.name + 's', url: '/list/' + this.props.uriobject});
            if (this.props.creation) {
                breadcrumb.push({icon: <AddIcon/>, name: 'creation'});
            } else {
                breadcrumb.push({icon: <EditIcon/>, name: 'edition'});
            }
        }

        const footer = embedded ? (null) : (
                <div>
                <Divider />
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center" >
                    <Button disabled={this.getDataInfo("saving")} variant="contained" size="small" className={classes.button} onClick={this.handleCancel}>
                    <ArrowBackIcon />
                    {t('action.cancel')}
                </Button>
                <Button disabled={this.getDataInfo("saving")} variant="contained" size="small" className={classes.button} color="primary" onClick={this.handleSubmit}>
                    <SaveIcon />
                    {t('action.save')}
                </Button>
                </Grid>
                </div>
        );

        return (
            <div>
                <Breadcrumb menu={this.props.menu} elements={breadcrumb}/>
                {item &&
                <div>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <EditObject
                            key="editobject"
                            dispatch={this.dispatch}
                            scope={this.scope + "_object"}
                            fields={this.fields}
							isCreation={this.props.creation}
                            object={item}
                            model={this.props.model}
                            error={this.getError()}
                            handleChange={ object => {
                                this.handleObjectChange(object);
                            }}
                        />
                    </Grid>
                </Grid>
                {footer}
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { itemId, data, authentication } = state;
    
    const { user, context } = authentication;
    
    return {
        itemId,
        data,
        user,
        context
    };
}

const connectedItemEditPage = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ItemEditPage)));
export { connectedItemEditPage as ItemEditPage };