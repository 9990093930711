import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';

import HomeDashBoard from './HomeDashBoard';

import { StationStateReadView } from '../../_components/read/StationState';

import { ReceptacleView } from '../../_components/custom/ReceptacleView';
import { KpiView } from '../../_components/kpi/KpiView';

import { commonActions } from '../../_actions';

import { history, getRights } from '../../_helpers';
import { AvailabilityDayResume } from '../../_components/kpi/AvailabilityDayResume';

const appSpecificAssetFolder = process.env.REACT_APP__SPC_ASSET_FLD ? process.env.REACT_APP__SPC_ASSET_FLD : "fillndrive";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem'
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      cardRootNoElevation: {
        boxShadow: 'none'
      },
      cardRoot: {
        boxShadow: '0px 1px 20px 0px rgba(14, 68, 135, 0.1), 0px 0px 1px 0px rgba(14, 68, 135,0.1), 0px 0px 0px -2px rgba(14, 68, 135,0.1)'
      }
});


class HomeServiceProvider extends HomeDashBoard {

    constructor(props) {
        super(props)
        this.state = {expanded: false};
        this.rights = getRights(this.props.user, this.props.context, 'station');
    }

    componentDidMount() {
        this.liveTimer = setInterval( () => {
            this.props.dispatch(commonActions.liveFull());
        }, 1000);
    }

    componentWillUnmount() {
        if (this.liveTimer) {
            clearInterval(this.liveTimer);
        }
    }

    handleExpandClick() {
        this.setState({expanded: !this.state.expanded});
    }

    handleGoTo = url => {
        history.push(url);
    }

    render() {
        const { t, classes, data, user } = this.props;

        let livefullContent;
        if (data.livefull) {
            livefullContent = data.livefull.map( station => {

                const stationId = station.id;

                let stationContent = null;

                const availabilityContent = station.kpi ? <AvailabilityDayResume kpi={{name: station.name, kpi: station.kpi.availabilityResume}} user={user} light={true}/> : null;

                let elevation;

                if (station.connected) {
                    elevation = 2;
                    const stationState = station.publicState ? station.publicState : 'unknown';
                    const showStationInfos = stationState !== 'unknown';

                    const dispensers = station.dispensers ? station.dispensers.map( d => {
                        
                        const dName = d.name;
                        const dState = d.publicState ? d.publicState : 'unknown';
                        return (
                            <CardActionArea key={'dispenser_' + dName} onClick={() => this.handleGoTo('/station/'+stationId+'/read/dispenser/' + d.id)}>

                            <div className={'dispenser' + (d.busy ? ' busy':'')}>
                                <div className="line station-headline">
                                    <div className="station-name">{dName}</div>
                                    <div className="station-info"><img className={"station-info-picto" + (!d.vehicleCount ? ' station-info-picto-fade' : '')} src={require('../../assets/img/vehicle-count-picto.png')} /> {d.vehicleCount ? d.vehicleCount : 0}</div>
                                    {d.cardReader ? <div className="station-nexopus-state"><span title={d.cardReader.connected ? t('card.reader.online') : t('card.reader.offline')} className={'legende'}><img height="20px" src={d.cardReader.connected ? require('../../assets/img/badge-success.svg') : require('../../assets/img/badge-error.svg')}/></span></div> : null}
                                </div>
                                <div className="receptacle-line">{dState !== 'unknown' && d.receptacles && d.receptacles.map( (r, rIndex) => <ReceptacleView key={'d_'+dName+'_r_'+rIndex} t={t} object={r} hideLegende={true}/>)}</div>
                            {d.refiller ? (
                                this._renderSessionInfos(d.refiller, false)
                            ) : null}
                            </div>
                            </CardActionArea>
                        );
                    }) : (null);
                    

                    let nexopusReady = station.watchdog && station.watchdog.plcConnectionOK;


                    stationContent = (<div className={'bloc-station '+stationState}>
                        <CardActionArea onClick={() => this.handleGoTo('/read/station/' + stationId)}>
                            <div className="line station-headline">
                                <div className="station-name">{station.name}</div>
                                <div className="station-state-bloc"><StationStateReadView t={t} value={station} inline={true} /></div>
                                <div className="station-nexopus-state"><img height="20px" src={nexopusReady ? require('../../assets/img/'+appSpecificAssetFolder+'/success.png') : require('../../assets/img/'+appSpecificAssetFolder+'/error.png')}/></div>
                            </div>
                            
                            {showStationInfos && <div className="line station-headline">
                                {station.quantity > 0 && <div className="station-info"><img className="station-info-picto" src={require('../../assets/img/mass-picto-small.png')} /> {Math.round(station.quantity / 2.5)} x 2.5kg</div>}
                                {station.estimatedFuelingTime > 0 && <div className="station-info"><img className="station-info-picto" src={require('../../assets/img/timelapse-picto-small.png')} /> {Math.round(station.estimatedFuelingTime / 60)}min</div>}
                            </div>}
                            <div className="line"></div>
                            
                            </CardActionArea>
                            <CardActionArea key={'kpi_' + stationId} onClick={() => this.handleGoTo('/read/station/'+stationId+'/kpi')}>
                            {availabilityContent}
                                <div className="line"></div>
                                {this.rights.kpi && station.kpi && <div className="line">
                                    <KpiView kpi={{name: 'refillsSummaryLight', type: 'refillsSummaryLight', defaultPeriode: 'today'}} mode={0} prefetchedData={{kpis: station.kpi}} model={this.model} object={station} scope={this.scope}/>
                                </div>}
                            </CardActionArea>
                            <div className="text-left">
                            {dispensers}
                            </div>
                    </div>);
                } else {
                    elevation = 0;
                    stationContent = (
                        <div className="bloc-station offline">
                        <CardActionArea onClick={() => this.handleGoTo('/read/station/' + stationId)}>
                            <div className="line station-headline">
                                <div className="station-name">{station.name}</div>
                                <div className="station-state-bloc"><StationStateReadView t={t} value={station} inline={true} /></div>
                            </div>
                            
                            </CardActionArea>
                            <CardActionArea key={'kpi_' + stationId} onClick={() => this.handleGoTo('/read/station/'+stationId+'/kpi')}>
                            {availabilityContent}
                            {this.rights.kpi && station.kpi && <div className="line">
                            <KpiView kpi={{name: 'refillsSummaryLight', type: 'refillsSummaryLight', defaultPeriode: 'today'}} mode={0} prefetchedData={{kpis: station.kpi}} model={this.model} object={station} scope={this.scope}/>
                            </div>}
                        </CardActionArea></div>);
                }

                return ( <Grid key={'station_'+stationId} item md={3}>
                                <Card elevation={elevation} classes={{root: elevation > 0 ? classes.cardRoot : classes.cardRootNoElevation}}>
                                    <CardContent>{stationContent}</CardContent>
                                </Card>
                        </Grid>
                );
                
            })
        }


        return (
            <div className="text-center">
                <Grid container spacing={2} >{livefullContent}</Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { data, authentication } = state;
    
    const { user, context} = authentication;

    return {
        data,
        user,
        context
    };
}

const connectedHomeServiceProvider = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(HomeServiceProvider)));
export { connectedHomeServiceProvider as HomeServiceProvider };