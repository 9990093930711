import React from 'react';
import { ItemEditPage } from '../../EditItem';

class ServiceEditUserPage extends React.Component {
    
    constructor(props) {
        super(props);
        if (this.props.match.params.itemId === undefined) {
            this.defaultUser = { profils: ['SERVICE_MANAGER']}
        }
    }

    render() {
        return <ItemEditPage { ...this.props} filterFields={['profils', 'roles', 'email', 'firstName', 'lastName', 'phone', 'group']} newItem={this.defaultUser}/>
    }
}

export { ServiceEditUserPage as ServiceEditUserPage };