import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { translate } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import localCache from '../_helpers/local.cache';

const styles = theme => ({
    filterBtn: {
      padding: 5,
      zIndex: 20,
      color: 'black'
    },
    filterBtnOn: {
      color: '#ffffff',
      backgroundColor: '#000000'
    }
  });

class Filter extends React.Component {

    selection = [];

    state = {
        anchorEl: null,
        filterOn: false,
        selectCount: 0
    };
    
	constructor(props) {
		
		super(props);
		
		this.filterStateId  = this.props.cacheRefId+"_"+this.props.row.id;
		this.buttonFilterId = this.props.cacheRefId+"_"+this.props.row.id+"_button";
		
		this.restoreState();
	}
	
	saveStateUpdate(toMerge) {
		
		let filterStates = localCache.getCache(this.props.cacheRefId).filterStates;
		
		if(filterStates == null) {
			filterStates = {};
		}
		
		if(filterStates[this.filterStateId] == null) {
			filterStates[this.filterStateId] = {};
		}
		
		filterStates[this.filterStateId] = {...filterStates[this.filterStateId], ...toMerge};
		
		localCache.cacheVal(this.props.cacheRefId, "filterStates", filterStates);
	}
	
	restoreState() {
	
		let displayCache = localCache.getCache(this.props.cacheRefId);
	
		if(displayCache && displayCache.filterStates && displayCache.filterStates[this.filterStateId]) {
			
			this.state.filterOn    = displayCache.filterStates[this.filterStateId].filterOn;
			this.state.selectCount = displayCache.filterStates[this.filterStateId].selectCount;
			
			if(displayCache.filterStates[this.filterStateId].selection) {
				this.selection = displayCache.filterStates[this.filterStateId].selection;
			}
		}
	}
	
      handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
		this.saveStateUpdate({ anchorEl: event.currentTarget.id } );
      };
    
      handleClose = () => {
        this.setState({ anchorEl: null });
		this.saveStateUpdate({ anchorEl: null });
      };

      handleChange = (e, selected) => {
          const value = e.target.value;
          const index = this.selection.indexOf(value);
            if (index > -1 && !selected) {
                this.selection.splice(index, 1);
                this.handleSelectionChanged();
            } else if (selected) {
                this.selection.push(value);
                this.handleSelectionChanged();
            }
            //
      }

      handleClear = () => {
          this.selection = [];
          this.handleSelectionChanged();
      }

      handleSelectionChanged = () => {
        this.setState({selectCount: this.selection.length});
		this.saveStateUpdate({selectCount: this.selection.length, selection: this.selection});
        if (this.props.handleSelectionChanged) {
            this.props.handleSelectionChanged(this.selection);
        }
      }

    render () {

        const { t, classes, row, values } = this.props;
        const { anchorEl, selectCount } = this.state;
        const filterOn = selectCount > 0;

        const filterDisabled = Object.keys(values).length < 1;
        
        return <div>
                <Tooltip title={t('action.filter')}>
                    <IconButton id={this.buttonFilterId} disabled={filterDisabled} onClick={ (e) => this.handleClick(e, row)}  anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} aria-label={t('action.filter')} className={classNames(classes.filterBtn, {
                                        [classes.filterBtnOn]: filterOn,
                                    })}>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
                <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={ (e) => this.handleClose(e)}>
                <div className="table-filter-content">Filtre ({selectCount ? selectCount : 0})
                    <div className="table-filter-choices">
                        <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup>
                            {Object.keys(values).map( (value, index) => {
                                const label = values[value];
                                const selected = this.selection.indexOf(value) > -1;
                                
                                return <FormControlLabel key={'filter_choice_' + index}
                                control={
                                    <Checkbox checked={selected} onChange={this.handleChange} value={value} />
                                }
                                label={label}
                                />
                            })}
                        </FormGroup>
                        </FormControl>
                    </div>
                    <Button variant="outlined" size="small" disabled={!selectCount} onClick={() => this.handleClear()}>Effacer</Button>
                </div>
                
                </Popover>
            </div>;

    }

}
  
export default translate('translations')(withStyles(styles)(Filter));