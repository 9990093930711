import { commonService } from '../_services';

export const uiactionService = {
    sendAction
};

function sendAction(action, object) {

    const data = action.createObject(object);    
    return commonService.save(true, data, action.model);

}