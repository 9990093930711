
import React from 'react';

export default class BlocSubPartView extends React.PureComponent {

    render() {

        const {subtitle, content} = this.props;

        return <div className={'kpi-view-subpart'}>
        {subtitle && <div className={'kpi-view-subtitle'}>{subtitle}</div>}
        {content}
       </div>
    }

}

 