import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18next';

import Divider from '@material-ui/core/Divider';

import { commonActions, uiactionActions } from '../../../_actions';

import FieldView from '../../../_components/read/FieldView';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    formControl: {
      margin: '1rem'
    },
    chipInfo: {
        marginTop: '2rem'
    },
    button: {
        margin: theme.spacing.unit,
      },
    buttonEmbedded: {
        margin: 0,
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
      leftIcon: {
        marginRight: theme.spacing.unit,
      },
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
      listlightcontainer: {
        backgroundColor: theme.palette.background.paper,
      }
});

class ReadObject extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    makeFieldName = (fieldName, fieldConfig) => {
        return fieldConfig.displayName ? this.props.t(fieldConfig.displayName) : this.props.t(fieldName);
    }

    renderField = (field, index) => {

        const { t, object, user, appcontext, context, classes, handleWorkflowAction } = this.props;
		
        return <FieldView
        key={'view_field_'+index}
        dispatch={this.props.dispatch}
        t={t}
		    scope={this.props.scope}
        object={object}
        field={field}
		    routesConfig={this.props.routesConfig}
        objectModel={this.props.model}
        user={user}
        appcontext={appcontext}
        context={context}
        handleAddChild={(config) => this.handleAddChild(field.name, config)}
        handleReadChild={(config, item) => this.handleReadChild(field.name, config, item)}
        handleBuildReadUrl={(config, item) => this.handleBuildReadUrl(field.name, config, item)}
        handleEditChild={(config, item) => this.handleEditChild(field.name, config, item)}
        handleAction={(config, item, action) => this.handleAction(field.name, config, item, action)}
        handleWorkflowAction={handleWorkflowAction}
        classes={classes}
        />
    }

    makeInputFields = (t, fields, parts, object) => {
      
        const { user, appcontext, context } = this.props;

        const inputFields = [];

        if (object) {
            if (parts && parts.length > 0) {
                const fieldsByName = {};
                fields.forEach((element, index) => {
                    fieldsByName[element.name] = element;
                });
                parts.forEach( (part, indexPart) => {
                    const showPart = part.show ? part.show(user, appcontext, context, object) : true;
                    if (showPart) {

                      let partContent;
                      if (part.component) {
                        const PartComponent = part.component;
                        partContent = <PartComponent object={object} user={user}/>
                      } else {
                        partContent = <div className="item-read-part-content">
                          {part.fields.map( (fieldName, index) => {
                              const field = fieldsByName[fieldName];
                              return field ? this.renderField(field, index) : null;
                            })
                          }
                      </div>
                      }

                        inputFields.push(
                          <div className="item-read-part" key={'item-read-part_' + indexPart}>
                                {part.label && <div className="item-read-part-title"><Divider/><div className="item-read-part-title-bloc">{part.icon}<span className="item-read-part-title-text">{t(part.label)}</span></div></div>}
                                {partContent}
                            </div>
                        );

                    }
                });
            } else {
                fields.forEach((element, index) => {
                    inputFields.push(this.renderField(element, index));
                });
            }
        }

        return inputFields;

      }

      handleEditChild = (fieldName, config, object) => {
        this.props.dispatch(commonActions.goEdit(config, object, this.props.object, this.props.model));
      }

      handleReadChild = (fieldName, config, object) => {
        this.props.dispatch(commonActions.goRead(config, object, this.props.object, this.props.model));
      }

      handleBuildReadUrl = (fieldName, config, object) => {
        let readUrl;
        if (this.props.model.fields[fieldName].child) {
          readUrl = commonActions.buildReadUrl(config, object, this.props.object, this.props.model);
        } else {
          readUrl = commonActions.buildReadUrl(config, object);
        }
        return readUrl;
      }

      handleAddChild = (fieldName, config) => {
        this.props.dispatch(commonActions.goCreate(config, this.props.object, this.props.model));
      }

      handleAction = (fieldName, config, item, action) => {
        this.props.dispatch(uiactionActions.sendAction(action, item));
      }

    render() {
        const { t, user, object, fields, parts } = this.props;

        const filteredFields = fields.filter( field => {
          if (field.profils && !field.profils.find( profil => user.user.profils.indexOf(profil) > -1)) {
              return false;
          }
          return true;
        });

        return this.makeInputFields(t, filteredFields, parts, object);
    }
}

function mapStateToProps(state) {
    const { itemId, data, authentication, appcontext } = state;
    const { user, context } = authentication;
    return {
        itemId,
        data,
        user,
        context,
        appcontext
    };
}

const ConnectedReadObject = translate('translations')(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(ReadObject)));
export { ConnectedReadObject as ReadObject };