import { uiactionConstants } from '../_constants';

const initialState = {};

export function uiaction(state = initialState, action) {
  switch (action.type) {
    case uiactionConstants.UI_ACTION_REQUEST:
      return {
        actioning: true
      };
      case uiactionConstants.UI_ACTION_SUCCESS:
      return {
        actioned: true
      };
      case uiactionConstants.UI_ACTION_FAILURE:
      return {
        actionerror: true
      };
    default:
      return state
  }
}