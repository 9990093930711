import React from 'react';

export default class SubPartView extends React.PureComponent {

    render() {
        const {title, content} = this.props;
    
        return <div className={'kpi-view-subpart'}>
                {title && <div className={'kpi-view-subtitle'}>{title}</div>}
                {content}
            </div>
    }

}